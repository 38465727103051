import React from 'react';
import { FieldType, ThresholdsMode } from '@grafana/data';
import { PanelBuilders, VizPanelState } from '@grafana/scenes';
import {
  AxisPlacement,
  BarGaugeDisplayMode,
  BigValueColorMode,
  DataSourceRef,
  FieldColorModeId,
  GraphGradientMode,
  GraphThresholdsStyleMode,
  LegendDisplayMode,
  LineInterpolation,
  TableCellDisplayMode,
  TableCellHeight,
  VisibilityMode,
} from '@grafana/schema';
import { getGenericQueryRunner } from 'helpers/scenes';
import { SceneQueries } from 'queries';
import { Link } from 'react-router-dom';
import { containerAlertsQueryRunner, podAlertsQueryRunner, transformData } from './queryRunners';
import jsonToPanelBuilders from 'helpers/jsonToPanelBuilders';
import { FieldConfigType, OptionsType, TablePanelType, TimeSeriesPanelType } from 'types';

type LinkOptions = {
  label: string;
  title: string;
  url: string;
  targetBlank: boolean;
};

const links = {
  clusters: {
    label: 'All',
    title: 'See all clusters',
    url: '/a/grafana-k8s-app/navigation/cluster',
    targetBlank: false,
  } as LinkOptions,

  nodes: {
    label: 'All',
    title: 'See all nodes',
    url: '/a/grafana-k8s-app/navigation/nodes',
    targetBlank: false,
  } as LinkOptions,

  namespaces: {
    label: 'All',
    title: 'See all namespaces',
    url: '/a/grafana-k8s-app/navigation/namespace',
    targetBlank: false,
  } as LinkOptions,

  workloads: {
    label: 'All',
    title: 'See all workloads',
    url: '/a/grafana-k8s-app/navigation/workload',
    targetBlank: false,
  } as LinkOptions,

  alerts: {
    label: 'All',
    title: 'See all alerts',
    url: '/a/grafana-k8s-app/alerts',
    targetBlank: false,
  } as LinkOptions,
};

const statPanel = (link?: LinkOptions) => {
  const panel = PanelBuilders.stat()
    .setUnit('short')
    .setColor({ fixedColor: '#2fbde4', mode: FieldColorModeId.Fixed })
    .setOption('colorMode', BigValueColorMode.Background);

  if (link) {
    panel.setHeaderActions(
      <Link
        to={link.url}
        target={link.targetBlank ? '_blank' : ''}
        style={{ color: 'rgb(83, 138, 222)' }}
        title={link.title}
      >
        {link.label}
      </Link>
    );
  }

  return panel;
};

const timeSeriesPanel = (generalConfig?: Partial<VizPanelState>) => {
  const panel = PanelBuilders.timeseries();

  return jsonToPanelBuilders<TimeSeriesPanelType>(panel, {
    ...generalConfig,
    options: {
      legend: { calcs: ['p90'], sortBy: '90th %', sortDesc: true, displayMode: LegendDisplayMode.Table },
    },
    fieldConfig: {
      defaults: {
        unit: 'percentunit',
        min: 0,
        max: 1,
        links: [
          {
            title: 'View ${__field.labels.cluster}',
            url: '/a/grafana-k8s-app/navigation/cluster/${__field.labels.encoded_cluster}',
          },
        ],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'dark-orange', value: 0 },
            { color: 'dark-green', value: 0.6 },
            { color: 'dark-red', value: 0.9 },
          ],
        },
        custom: {
          showPoints: VisibilityMode.Never,
          lineInterpolation: LineInterpolation.Smooth,
          fillOpacity: 1,
          thresholdsStyle: { mode: GraphThresholdsStyleMode.Dashed },
        },
      },
      overrides: [],
    },
  });
};

const alertTablePanel = (link?: LinkOptions) => {
  const panel = PanelBuilders.table();

  jsonToPanelBuilders<TablePanelType>(panel, {
    options: {
      cellHeight: TableCellHeight.Md,
      sortBy: [{ desc: true, displayName: 'Firing Since' }],
      footer: {
        show: true,
        reducer: ['count'],
        countRows: true,
        enablePagination: false,
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          cellOptions: { type: TableCellDisplayMode.ColorText },
          filterable: true,
        },
        color: {
          fixedColor: 'text',
          mode: FieldColorModeId.Fixed,
        },
      },
      overrides: [
        {
          matcher: {
            id: 'byRegexp',
            options: '/cluster|namespace|workload|workload_type|encoded_cluster/',
          },
          properties: [
            {
              id: 'custom.hidden',
              value: true,
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'Alert',
          },
          properties: [
            {
              id: 'custom.width',
              value: 160,
            },
            {
              id: 'color',
              value: { fixedColor: 'light-red', mode: FieldColorModeId.Fixed },
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'Firing Since',
          },
          properties: [
            {
              id: 'unit',
              value: 'dateTimeAsIso',
            },
            {
              id: 'custom.width',
              value: 165,
            },
          ],
        },
      ],
    },
  });

  if (link) {
    panel.setHeaderActions(
      <Link
        to={link.url}
        target={link.targetBlank ? '_blank' : ''}
        style={{ color: 'rgb(83, 138, 222)' }}
        title={link.title}
      >
        {link.label}
      </Link>
    );
  }

  return panel;
};

export const panels = {
  clusters: (datasource: DataSourceRef) =>
    statPanel(links.clusters)
      .setTitle('Clusters')
      .setLinks([links.clusters])
      .setData(getGenericQueryRunner(datasource, SceneQueries.Home.ClusterCount)),

  nodes: (datasource: DataSourceRef) =>
    statPanel(links.nodes)
      .setTitle('Nodes')
      .setLinks([links.nodes])
      .setData(getGenericQueryRunner(datasource, SceneQueries.Home.NodeCount)),

  namespaces: (datasource: DataSourceRef) =>
    statPanel(links.namespaces)
      .setTitle('Namespaces')
      .setLinks([links.namespaces])
      .setData(getGenericQueryRunner(datasource, SceneQueries.Home.NamespaceCount)),

  workloads: (datasource: DataSourceRef) =>
    statPanel(links.workloads)
      .setTitle('Workloads')
      .setLinks([links.workloads])
      .setData(getGenericQueryRunner(datasource, SceneQueries.Home.WorkloadCount)),

  pods: (datasource: DataSourceRef) =>
    statPanel().setTitle('Pods').setData(getGenericQueryRunner(datasource, SceneQueries.Home.PodCount)),

  containers: (datasource: DataSourceRef) =>
    statPanel().setTitle('Containers').setData(getGenericQueryRunner(datasource, SceneQueries.Home.ContainerCount)),

  cpuUsageByCluster: (datasource: DataSourceRef) =>
    timeSeriesPanel()
      .setTitle('CPU Usage by Cluster')
      .setColor({ fixedColor: 'light-blue', mode: FieldColorModeId.Shades })
      .setData(
        getGenericQueryRunner(datasource, SceneQueries.Home.CPUUsageByCluster, {
          instant: false,
          range: true,
          transformations: [transformData],
          legendFormat: '{{cluster}}',
        })
      ),

  memoryUsageByCluster: (datasource: DataSourceRef) =>
    timeSeriesPanel()
      .setTitle('Memory Usage by Cluster')
      .setColor({ fixedColor: 'light-purple', mode: FieldColorModeId.Shades })
      .setData(
        getGenericQueryRunner(datasource, SceneQueries.Home.MemoryUsageByCluster, {
          instant: false,
          range: true,
          transformations: [transformData],
          legendFormat: '{{cluster}}',
        })
      ),

  deployedContainerImages: (datasource: DataSourceRef) =>
    PanelBuilders.table()
      .setTitle('Deployed Container Images (as of ${__to:date:YYYY-MM-DD HH:mm:ss})')
      .setData(
        getGenericQueryRunner(datasource, SceneQueries.Home.DeployedContainerImages, {
          format: 'table',
          transformations: [
            {
              id: 'organize',
              options: {
                excludeByName: {
                  Time: true,
                },
                renameByName: {
                  Value: 'Containers',
                  image_spec: 'Image Spec',
                  simple_spec: 'Image',
                },
              },
            },
            {
              id: 'sortBy',
              options: {
                sort: [
                  {
                    desc: true,
                    field: 'Containers',
                  },
                ],
              },
            },
          ],
        })
      )
      .setUnit('short')
      .setColor({ fixedColor: 'blue', mode: FieldColorModeId.ContinuousBlPu })
      .setCustomFieldConfig('filterable', true)
      .setOption('footer', {
        show: true,
        reducer: ['count'],
        countRows: true,
      })
      .setOverrides((b) =>
        b
          .matchFieldsByType(FieldType.number)
          .overrideCustomFieldConfig('filterable', false)
          .overrideCustomFieldConfig('width', 200)
          .overrideCustomFieldConfig('cellOptions', {
            mode: BarGaugeDisplayMode.Gradient,
            type: TableCellDisplayMode.Gauge,
          })
      ),

  firingAlerts: (datasource: DataSourceRef) =>
    PanelBuilders.timeseries()
      .setTitle('Firing Alerts')
      .setNoValue('No pod or container alerts are firing right now.')
      .setData(
        getGenericQueryRunner(datasource, SceneQueries.Home.FiringAlerts, {
          instant: false,
          range: true,
          legendFormat: 'Firing Alerts',
        })
      )
      .setOption('legend', { showLegend: false })
      .setColor({ fixedColor: 'light-red', mode: FieldColorModeId.Fixed })
      .setCustomFieldConfig('showPoints', VisibilityMode.Never)
      .setCustomFieldConfig('axisPlacement', AxisPlacement.Hidden)
      .setCustomFieldConfig('lineWidth', 3)
      .setCustomFieldConfig('lineInterpolation', LineInterpolation.Smooth)
      .setCustomFieldConfig('fillOpacity', 30)
      .setCustomFieldConfig('gradientMode', GraphGradientMode.Opacity)
      .setUnit('short'),

  containerAlertTable: (datasource: DataSourceRef) => {
    const panel = alertTablePanel(links.alerts);

    return jsonToPanelBuilders<TablePanelType, OptionsType<TablePanelType>, FieldConfigType<TablePanelType>>(panel, {
      title: 'Container Alerts (as of ${__to:date:YYYY-MM-DD HH:mm:ss})',
      description: 'Containers that have firing alerts.',
      fieldConfig: {
        defaults: {
          noValue: 'No container alerts are firing right now.',
        },
        overrides: [
          {
            matcher: {
              id: 'byName',
              options: 'pod',
            },
            properties: [
              {
                id: 'custom.hidden',
                value: true,
              },
            ],
          },
          {
            matcher: {
              id: 'byName',
              options: 'Container',
            },
            properties: [
              {
                id: 'custom.width',
                value: 150,
              },
              {
                id: 'links',
                value: [
                  {
                    title: 'Go to container',
                    url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}/${__data.fields.workload_type}/${__data.fields.workload}/${__data.fields.pod}/${__data.fields.container}',
                  },
                ],
              },
            ],
          },
          {
            matcher: {
              id: 'byName',
              options: 'Alert',
            },
            properties: [
              {
                id: 'custom.width',
                value: 180,
              },
              {
                id: 'links',
                value: [
                  {
                    title: 'Go to alert',
                    url:
                      '/alerting/list?view=state&search=' +
                      [
                        'type:alerting',
                        'datasource:"${datasource:percentencode}"',
                        'rule:${__data.fields.alertname:percentencode}',
                        'label:cluster=${__data.fields.cluster:percentencode}',
                        'label:namespace=${__data.fields.namespace}',
                        'label:container=${__data.fields.container}',
                      ].join('%20'),
                    targetBlank: true,
                  },
                ],
              },
            ],
          },
          {
            matcher: {
              id: 'byName',
              options: 'Cluster/Namespace/Pod',
            },
            properties: [
              {
                id: 'custom.minWidth',
                value: 190,
              },
            ],
          },
        ],
      },
    }).setData(containerAlertsQueryRunner(datasource));
  },

  podAlertTable: (datasource: DataSourceRef) =>
    alertTablePanel(links.alerts)
      .setTitle('Pod Alerts (as of ${__to:date:YYYY-MM-DD HH:mm:ss})')
      .setDescription('Pods that have firing alerts.')
      .setNoValue('No pod alerts are firing right now.')
      .setData(podAlertsQueryRunner(datasource))
      .setOverrides((b) =>
        b
          .matchFieldsWithName('Pod')
          .overrideCustomFieldConfig('minWidth', 230)
          .overrideLinks([
            {
              title: 'Go to pod',
              url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}/${__data.fields.workload_type}/${__data.fields.workload}/${__data.fields.pod}',
            },
          ])
      )
      .setOverrides((b) =>
        b.matchFieldsWithName('Alert').overrideLinks([
          {
            title: 'Go to alert',
            url:
              '/alerting/list?view=state&search=' +
              [
                'type:alerting',
                'datasource:"${datasource:percentencode}"',
                'rule:${__data.fields.alertname:percentencode}',
                'label:cluster=${__data.fields.cluster:percentencode}',
                'label:namespace=${__data.fields.namespace}',
                'label:pod=${__data.fields.pod}',
              ].join('%20'),
            targetBlank: true,
          },
        ])
      )
      .setOverrides((b) => b.matchFieldsWithName('Cluster/Namespace').overrideCustomFieldConfig('minWidth', 170)),
};
