import {
  EmbeddedScene,
  QueryVariable,
  SceneFlexItem,
  SceneFlexLayout,
  SceneTimeRange,
  SceneVariableSet,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';

import { addTimeRangeHandler, getGenericQueryRunner, query_result } from '../../../helpers/scenes';
import { SceneQueries } from 'queries';
import { ScenesCustomParams } from 'types';
import { ExplorablePanel } from '../ExplorablePanel';
import { nodeGraphConfig } from '../NodeDetailOptimization/panelConfig';
import { allocationRow, idleRow, setsBoxRow } from '../GenericDetailOptimization/scene';
import { getExtensionDetailsOptimizationControls } from 'components/Extensions/ObjectDetailExtension';
import { getNetworkScene } from '../Network/Network';

export function getVariables(datasource: DataSourceRef, cluster?: string) {
  const numContainers = new QueryVariable({
    name: 'numContainers',
    query: query_result(SceneQueries.ClusterDetail.NumContainers(cluster)),
    datasource,
    skipUrlSync: true,
  });

  const cpuCapacity = new QueryVariable({
    name: 'cpuCapacityUpperLimit',
    query: query_result(SceneQueries.ClusterDetail.CpuCapacity(cluster)),
    datasource,
    skipUrlSync: true,
  });

  const memCapacity = new QueryVariable({
    name: 'memCapacityUpperLimit',
    query: query_result(SceneQueries.ClusterDetail.MemoryCapacity(cluster)),
    datasource,
    skipUrlSync: true,
  });

  const variableSet = new SceneVariableSet({
    variables: [numContainers, cpuCapacity, memCapacity],
  });

  return variableSet;
}

export const firstRow = (datasource: DataSourceRef, cluster?: string) => {
  return new SceneFlexLayout({
    children: [
      {
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.ClusterDetail.CpuCapacity(cluster),
          {
            instant: false,
            interval: '',
            range: true,
            refId: 'capacity',
            legendFormat: 'Physical capacity of cluster',
            intervalMs: '1m',
            maxDataPoints: 1718,
          },
          [
            {
              expr: SceneQueries.ClusterDetail.CpuLimits(cluster),
              instant: false,
              interval: '',
              range: true,
              refId: 'limits',
              legendFormat: 'Sum of container CPU limits',
            },
            {
              expr: SceneQueries.ClusterDetail.CpuRequests(cluster),
              legendFormat: 'Sum of container CPU requests',
              refId: 'requests',
              interval: '',
              instant: false,
              range: true,
            },
            {
              expr: SceneQueries.ClusterDetail.CpuUsage(cluster),
              instant: false,
              interval: '',
              range: true,
              refId: 'usage',
              legendFormat: 'Cluster CPU usage',
            },
          ]
        ),
        title: 'Cluster CPU',
        unit: 'cores',
        predictable: {
          query: SceneQueries.ClusterDetail.CpuUsage(cluster),
          button: 'Predict CPU Usage',
          title: 'CPU Usage Prediction Model',
          name: 'Cluster CPU usage',
          metric: 'node_cpu_seconds_total',
          predictableVariableName: 'cpuCapacityUpperLimit',
          axisLabel: 'CPU usage (cores)',
        },
      },
      {
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.ClusterDetail.MemoryCapacity(cluster),
          {
            instant: false,
            interval: '',
            range: true,
            refId: 'capacity',
            legendFormat: 'Physical capacity of cluster',
            intervalMs: '1m',
            maxDataPoints: 1718,
          },
          [
            {
              expr: SceneQueries.ClusterDetail.MemoryLimits(cluster),
              instant: false,
              interval: '',
              range: true,
              refId: 'limits',
              legendFormat: 'Sum of container memory limits',
            },
            {
              expr: SceneQueries.ClusterDetail.MemoryRequests(cluster),
              instant: false,
              interval: '',
              range: true,
              legendFormat: 'Sum of container memory requests',
              refId: 'requests',
            },
            {
              expr: SceneQueries.ClusterDetail.MemoryUsage(cluster),
              instant: false,
              interval: '',
              range: true,
              refId: 'usage',
              legendFormat: 'Cluster memory usage',
            },
          ]
        ),
        title: 'Cluster Memory',
        unit: 'bytes',
        predictable: {
          query: SceneQueries.ClusterDetail.MemoryUsage(cluster),
          button: 'Predict memory usage',
          title: 'Memory Usage Prediction Model',
          name: 'Cluster memory usage',
          metric: 'kube_node_status_capacity',
          predictableVariableName: 'memCapacityUpperLimit',
          axisLabel: 'Memory usage (bytes)',
        },
      },
    ].map((panel) => {
      return new SceneFlexItem({
        height: 400,
        body: new ExplorablePanel(nodeGraphConfig(panel), panel.predictable),
      });
    }),
  });
};

export function getClusterDetailOptimization({
  datasource,
  cluster,
  relativeTimeRange,
  onTimeRangeChange,
  withPickers,
  prometheusName,
  lokiName,
  isEmbeddedExtension,
}: ScenesCustomParams) {
  const sceneTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(sceneTimeRange, onTimeRangeChange);

  const queries = {
    cpuRequests: SceneQueries.ClusterDetail.CpuRequestsText(cluster),
    cpuLimits: SceneQueries.ClusterDetail.CpuLimitsText(cluster),
    memoryRequests: SceneQueries.ClusterDetail.MemoryRequestsText(cluster),
    memoryLimits: SceneQueries.ClusterDetail.MemoryLimitsText(cluster),
    cpuAllocation: SceneQueries.ClusterDetail.CostCpuAllocation(cluster),
    memoryAllocation: SceneQueries.ClusterDetail.CostMemoryAllocation(cluster),
    cpuIdle: SceneQueries.ClusterDetail.CostCpuIdle(cluster),
    memoryIdle: SceneQueries.ClusterDetail.CostMemoryIdle(cluster),
  };

  const urlParams = `?from=${relativeTimeRange?.from}&to=${relativeTimeRange?.to}&promName=${prometheusName}&lokiName=${lokiName}`;
  const urlPath = `/cluster/${cluster}${urlParams}`;

  const children = [
    firstRow(datasource, cluster),
    setsBoxRow(datasource, queries),
    new SceneFlexLayout({
      direction: 'row',
      children: [allocationRow(datasource, queries), idleRow(datasource, queries)],
    }),
  ];

  if (isEmbeddedExtension) {
    children.push(
      getNetworkScene({
        type: 'cluster',
        cluster: cluster || '',
        datasource,
        relativeTimeRange,
        lokiName,
        prometheusName,
      })
    );
  }

  return new EmbeddedScene({
    $variables: getVariables(datasource, cluster),
    $timeRange: sceneTimeRange,
    controls: getExtensionDetailsOptimizationControls({ withPickers, relativeTimeRange, urlPath }),
    body: new SceneFlexLayout({
      direction: 'column',
      children: [...children],
    }),
  });
}
