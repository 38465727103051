import appEvents from 'grafana/app/core/app_events';
import { useEffect, useState } from 'react';
import { lastValueFrom } from 'rxjs';
import useSWR from 'swr';

import { AppEvents } from '@grafana/data';
import { BackendSrvRequest, config, getBackendSrv } from '@grafana/runtime';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';
import { Range } from 'types';
import useTimeRangeStore from 'store/timeRange';

function fetcher(optArray: BackendSrvRequest[]) {
  const fetch = (options: BackendSrvRequest) => lastValueFrom(getBackendSrv().fetch<any>(options));
  // @ts-ignore
  return Promise.allSettled(optArray?.map?.(fetch));
}

type QueryError = {
  error: string;
  errorType: string;
  message: string;
  status: string;
};

export function buildInstantResponse(response: any) {
  const errors: QueryError[] = [];
  const success: unknown[] = [];
  let hasErrors = false;

  response?.forEach?.((d: any) => {
    errors.push(d.status === 'rejected' ? d?.reason?.data : {});
    success.push(d.status === 'fulfilled' ? d?.value?.data?.data?.result : {});

    if (d.status === 'rejected') {
      hasErrors = true;
    }
  });

  return { success, errors, hasErrors };
}

export const buildInstantQueryOptions = (
  prometheusName: string,
  metricQueries: MetricQuery[],
  overrideRange?: Range
) => {
  const url = config.datasources[prometheusName]?.url;
  const allQueryOptions: BackendSrvRequest[] = [];

  metricQueries?.forEach?.(({ query, range, step }: MetricQuery) => {
    const endpoint = step ? '/api/v1/query_range' : '/api/v1/query';

    const q = {
      query,
      instant: true,
      range: false,
      start: overrideRange?.from || range?.from,
      end: overrideRange?.to || range?.to,
    };

    const options: BackendSrvRequest = {
      url: `${url}${endpoint}`,
      method: 'POST',
      headers: { ['Content-Type']: 'application/x-www-form-urlencoded' },
      data: q,
    };

    allQueryOptions.push(options);
  });

  return allQueryOptions;
};

export type MetricQuery = {
  query: string;
  range?: Range;
  step?: string;
};

function useMetrics(metricQueries: MetricQuery[], refresh = true) {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [errors, setErrors] = useState<any>([]);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const options = buildInstantQueryOptions(prometheusName, metricQueries);
  const refreshInterval = useTimeRangeStore((state) => state.refreshInterval);

  const {
    data: response,
    isLoading,
    isValidating,
    error,
  } = useSWR(options, fetcher, {
    refreshInterval: !refresh ? undefined : refreshInterval,
    revalidateOnFocus: false,
    refreshWhenHidden: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    errorRetryCount: 5,
  });

  useEffect(() => {
    if (response) {
      const { errors, success, hasErrors } = buildInstantResponse(response);

      setErrors(errors);
      setData(success);
      setFirstLoad(false);

      if (hasErrors) {
        errors?.forEach?.(({ message }: QueryError) => appEvents.emit(AppEvents.alertError, [message]));
      }
    }
  }, [response, error]);

  return { loading: isLoading, validating: isValidating, data, error: errors, firstLoad };
}

export default useMetrics;
