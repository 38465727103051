import {
  EmbeddedScene,
  SceneFlexLayout,
  SceneFlexItem,
  SceneControlsSpacer,
  SceneAppPage,
  VariableValueSelectors,
  SceneReactObject,
  SceneFlexItemLike,
  SceneVariableSet,
  QueryVariable,
  TextBoxVariable,
  SceneTimeRange,
  SceneTimePicker,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { ScenesCustomParams } from 'types';
import PrometheusPicker from 'components/PrometheusPicker';
import React from 'react';
import { getHomeNoDataScene } from './HomeNoData';
import { VariableHide, VariableRefresh } from '@grafana/data';
import {
  addDatasourceSyncHandler,
  addTimeRangeHandler,
  addVizPanelMenuHandler,
  getRefreshPicker,
  query_result,
} from 'helpers/scenes';
import { CostsScenesQueries, SceneQueries } from 'queries';
import { panels } from './panelConfig';
import logo from 'img/k8s.png';
import TitleRow from 'components/scenes/TitleRow/TitleRow';

const getVariables = (datasource: DataSourceRef, prometheusName: string, lokiName: string) => {
  const datasourceVar = new TextBoxVariable({
    name: 'datasource',
    value: prometheusName,
    hide: VariableHide.hideVariable,
  });

  const lokiVar = new TextBoxVariable({
    name: 'loki',
    value: lokiName,
    hide: VariableHide.hideVariable,
  });

  const cluster = new QueryVariable({
    name: 'cluster',
    query: CostsScenesQueries.clusterList,
    includeAll: true,
    defaultToAll: true,
    isMulti: true,
    datasource,
    allValue: '.+',
    refresh: VariableRefresh.onTimeRangeChanged,
  });

  const namespace = new QueryVariable({
    name: 'namespace',
    query: 'label_values(kube_namespace_status_phase{cluster=~"$cluster"}, namespace)',
    includeAll: true,
    defaultToAll: true,
    isMulti: true,
    datasource,
    allValue: '.+',
    refresh: VariableRefresh.onTimeRangeChanged,
  });

  const filteredClusters = new QueryVariable({
    name: 'filteredClusters',
    query: 'label_values(kube_namespace_status_phase{cluster=~"$cluster", namespace=~"$namespace"}, cluster)',
    includeAll: true,
    defaultToAll: true,
    datasource,
    skipUrlSync: true,
    refresh: VariableRefresh.onTimeRangeChanged,
    hide: VariableHide.hideVariable,
  });

  const containerAlertsPodNames = new QueryVariable({
    name: 'containerAlertsPodNames',
    query: query_result(SceneQueries.Home.ContainerAlertsPodNames),
    includeAll: true,
    defaultToAll: true,
    datasource,
    regex: '/.*pod="(?<value>[^"]*)".*/', // extract pod label value
    skipUrlSync: true,
    refresh: VariableRefresh.onTimeRangeChanged,
    hide: VariableHide.hideVariable,
  });

  const podAlertsPodNames = new QueryVariable({
    name: 'podAlertsPodNames',
    query: query_result(SceneQueries.Home.PodAlertsPodNames),
    includeAll: true,
    defaultToAll: true,
    datasource,
    regex: '/.*pod="(?<value>[^"]*)".*/', // extract pod label value
    skipUrlSync: true,
    refresh: VariableRefresh.onTimeRangeChanged,
    hide: VariableHide.hideVariable,
  });

  const variableSet = new SceneVariableSet({
    variables: [
      datasourceVar,
      lokiVar,
      cluster,
      namespace,
      filteredClusters,
      containerAlertsPodNames,
      podAlertsPodNames,
    ],
  });

  return variableSet;
};

export function getHomeScene({
  id,
  prometheusName,
  lokiName,
  dataFlowing,
  datasource,
  relativeTimeRange,
}: ScenesCustomParams) {
  let sceneChildren: SceneFlexItemLike[] = [];
  const sceneVariables: SceneVariableSet = getVariables(datasource, prometheusName, lokiName);

  const timeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(timeRange);

  if (dataFlowing) {
    sceneChildren = [
      new SceneFlexLayout({
        height: 106,
        direction: 'row',
        children: [
          panels.clusters,
          panels.nodes,
          panels.namespaces,
          panels.workloads,
          panels.pods,
          panels.containers,
        ].map((panel) => {
          return new SceneFlexItem({
            body: addVizPanelMenuHandler(panel(datasource).build()),
          });
        }),
      }),

      new SceneFlexLayout({
        height: 486,
        direction: 'row',
        children: [panels.cpuUsageByCluster, panels.memoryUsageByCluster, panels.deployedContainerImages].map(
          (panel) => {
            return new SceneFlexItem({
              body: addVizPanelMenuHandler(panel(datasource).build()),
            });
          }
        ),
      }),

      new SceneFlexLayout({
        height: 68,
        direction: 'row',
        children: [panels.firingAlerts].map((panel) => {
          return new SceneFlexItem({
            body: addVizPanelMenuHandler(panel(datasource).build()),
          });
        }),
      }),

      new SceneFlexLayout({
        height: 562,
        direction: 'row',
        children: [panels.containerAlertTable, panels.podAlertTable].map((panel) => {
          return new SceneFlexItem({
            body: addVizPanelMenuHandler(panel(datasource).build()),
          });
        }),
      }),
    ];
  } else {
    sceneChildren = [getHomeNoDataScene()];
  }

  const scene = new SceneAppPage({
    title: 'Kubernetes Overview',
    titleImg: logo,
    renderTitle: () => <TitleRow title="Kubernetes Overview" withNavigation page="home" />,
    url: `/a/${id}/home`,
    $timeRange: timeRange,
    controls: [
      new SceneReactObject({
        reactNode: React.createElement(PrometheusPicker),
      }),
      new SceneControlsSpacer(),
      new SceneTimePicker({}),
      getRefreshPicker(),
    ],
    getScene: () =>
      new EmbeddedScene({
        $behaviors: [],
        $variables: sceneVariables,
        controls: [new VariableValueSelectors({})],
        body: new SceneFlexLayout({
          direction: 'column',
          children: sceneChildren,
        }),
      }),
  });

  addDatasourceSyncHandler(scene, prometheusName, lokiName);

  return scene;
}
