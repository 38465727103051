import { DataFrameJSON } from '@grafana/data';
import { BackendSrvRequest, config, getBackendSrv } from '@grafana/runtime';
import { useState, useEffect, useMemo } from 'react';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';
import { Range } from '../types';
import useSWR from 'swr';
import { lastValueFrom } from 'rxjs';
import { transfromDataFrameToUseMetrics } from 'helpers/helpers';
import useTimeRangeStore from 'store/timeRange';

export function fetcher(optArray: BackendSrvRequest[]) {
  const fetch = (options: BackendSrvRequest) => lastValueFrom(getBackendSrv().fetch<any>(options));
  return Promise.allSettled(optArray?.map?.(fetch));
}

export type QueryErrors = {
  results?: {
    [key: string]: {
      error: string;
      errorSource: string;
      status: number;
    };
  };
};

type QueryError = {
  error: string;
  errorType: string;
  message: string;
  status: string;
};

export function buildRangeResponse(response: any, convertToRedableMetrics: boolean) {
  const errors: QueryError[] = [];
  const success: unknown[] = [];
  let hasErrors = false;

  response?.forEach?.((d: any) => {
    const data = d?.value?.data?.results?.['A'].frames as DataFrameJSON[];

    errors.push(d.status === 'rejected' ? d?.reason?.data : {});
    success.push(
      d.status === 'fulfilled' ? (convertToRedableMetrics ? transfromDataFrameToUseMetrics(data) : data) : []
    );

    if (d.status === 'rejected') {
      hasErrors = true;
    }
  });

  return { success, errors, hasErrors };
}

export const buildRangeQueryOptions = (
  prometheusName: string,
  metricQueries: string[],
  range?: Range,
  step?: string,
  maxDataPoints?: number,
  instant?: boolean
) => {
  const url = '/api/ds/query';
  const allQueryOptions: BackendSrvRequest[] = [];

  metricQueries?.forEach?.((query: string) => {
    const q = {
      queries: [
        {
          refId: 'A',
          expr: query,
          instant: instant ?? false,
          interval: step,
          range: !instant ?? true,
          utcOffsetSec: -28800,
          queryType: 'timeSeriesQuery',
          legendFormat: '',
          intervalMs: 15000,
          maxDataPoints,
          datasourceId: config.datasources[prometheusName]?.id,
          datasource: {
            uid: config.datasources[prometheusName]?.uid,
            type: 'prometheus',
          },
        },
      ],
      from: range?.from.toString(),
      to: range?.to.toString(),
    };

    const options: BackendSrvRequest = {
      url,
      method: 'POST',
      headers: { accept: 'application/json' },
      data: q,
    };

    allQueryOptions.push(options);
  });

  return allQueryOptions;
};

function useDataFrame(
  queries: string[],
  range?: Range,
  step?: string,
  refresh = true,
  maxDataPoints = 100,
  convertToRedableMetrics = false,
  instant?: boolean,
  enabled = true,
  firstLoadDependencies?: string[]
) {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [errors, setErrors] = useState<any>([]);
  const refreshInterval = useTimeRangeStore((state) => state.refreshInterval);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const options = useMemo(
    () => (enabled ? buildRangeQueryOptions(prometheusName, queries, range, step, maxDataPoints, instant) : null),
    [prometheusName, queries, range, step, maxDataPoints, instant, enabled]
  );

  const {
    data: response,
    isLoading,
    isValidating,
    error,
  } = useSWR(options, fetcher, {
    refreshInterval: !refresh ? undefined : refreshInterval,
    revalidateOnFocus: false,
    refreshWhenHidden: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    errorRetryCount: 0,
  });

  useEffect(() => {
    setFirstLoad(true);
  }, [firstLoadDependencies]);

  useEffect(() => {
    if (response) {
      const { errors, success } = buildRangeResponse(response, convertToRedableMetrics);

      setErrors(errors);
      setData(success);
      setFirstLoad(false);
    }
  }, [response, error, convertToRedableMetrics]);

  return { loading: isLoading, validating: isValidating, data, error: errors, firstLoad };
}

export default useDataFrame;
