import { Tag } from '@grafana/ui';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import PodHealthProgress from './PodHealthProgress';
import { DataTableWorkload, healthStatusSort } from './workloadListUtils';
import CopyObjectName from 'components/Table/CopyObjectName';
import { getAllColumns } from 'helpers/usageCostHelpers';
import { assertsColumns } from 'components/Asserts/helpers';
import { UsageCostErrors } from 'types';
import { encodeUrlString } from 'helpers/helpers';
import alertColumn from 'components/Table/alertColumn';

const workloadNameColumn = (id: string) => [
  {
    name: 'Workload',
    minWidth: '250px',
    selector: (row: DataTableWorkload) => row.name,
    sortable: true,
    sortFunction: (a: DataTableWorkload, b: DataTableWorkload) =>
      a.name.localeCompare(b.name, undefined, { numeric: true }),
    // eslint-disable-next-line react/display-name
    cell: (row: DataTableWorkload) => {
      let typeParam = row?.type?.toLowerCase();
      // Special case for StaticPods and Pods with no parent workload, go straight to pod detail
      if (row.type === 'staticpod' || row.type === 'pod') {
        typeParam = '_/_';
      }
      return (
        <CopyObjectName
          link={`/a/${id}/navigation/namespace/${encodeUrlString(row.cluster)}/${row.namespace}/${typeParam}/${
            row?.name
          }`}
          value={row.name}
        />
      );
    },
  },
];

const workloadDefaultColumns = (id: string, costView: boolean, hideNamespaceDetails?: boolean) => {
  return [
    ...assertsColumns<DataTableWorkload>(),
    ...workloadNameColumn(id),
    {
      name: 'Type',
      selector: (row: DataTableWorkload) => row.type,
      // eslint-disable-next-line react/display-name
      cell: (row: DataTableWorkload) => <Tag name={row.type} />,
      sortable: true,
    },
    ...(!hideNamespaceDetails
      ? [
          {
            name: 'Cluster',
            width: '150px',
            selector: (row: DataTableWorkload) => row.cluster,
            sortable: true,
            sortFunction: (a: DataTableWorkload, b: DataTableWorkload) => a.cluster.localeCompare(b.cluster, undefined),
          },
          {
            name: 'Namespace',
            width: '150px',
            selector: (row: DataTableWorkload) => row.namespace,
            sortable: true,
            sortFunction: (a: DataTableWorkload, b: DataTableWorkload) =>
              a.namespace.localeCompare(b.namespace, undefined, { numeric: true }),
          },
        ]
      : []),
  ];
};

export const defaultColumns = (
  hasDateChanged: boolean,
  id: string,
  costView: boolean,
  hideNamespaceDetails?: boolean
): Array<TableColumn<DataTableWorkload>> => {
  return [
    ...workloadDefaultColumns(id, costView, hideNamespaceDetails),
    {
      name: 'Pods',
      minWidth: '200px',
      selector: (row: DataTableWorkload) => '',
      // eslint-disable-next-line react/display-name
      cell: (row: DataTableWorkload) => (
        <PodHealthProgress
          desiredPods={row.desiredPods}
          podHealth={row.podCountByHealth}
          isStatic={row.type === 'staticpod' || row.type === 'pod'}
        />
      ),
      sortable: true,
      sortFunction: healthStatusSort,
    },
  ];
};

export const columnMap = (
  prometheusName: string,
  hasDateChanged: boolean,
  id: string,
  costView: boolean,
  errors: UsageCostErrors,
  usageLoading?: boolean,
  costLoading?: boolean,
  hideNamespaceDetails?: boolean
) => {
  const initialColumns = defaultColumns(hasDateChanged, id, costView, hideNamespaceDetails);
  const { currentColumns, extraColumns } = getAllColumns(
    costView,
    costView ? workloadNameColumn(id) : initialColumns,
    [],
    [alertColumn<DataTableWorkload>({ hasDateChanged, prometheusName, enablePodWildcard: true })],
    errors,
    usageLoading,
    costLoading
  );

  return [...currentColumns, ...extraColumns];
};
