import { GeneralQueryResult } from 'types';
import { DetailResult } from './WorkloadMetadata';

export type WorkloadTypes = '' | 'replicaset' | 'deployment' | 'daemonset' | 'statefulset' | 'job';

// Decide which data keys to use
export const dataKeys = (type: WorkloadTypes) => {
  const dataObj = {
    created: {
      key: '',
      name: 'Created',
    },
    replicas: {
      key: '',
      name: 'Replicas',
    },
    replicas_available: {
      key: '',
      name: 'Replicas ready',
    },
    extra: {
      key: '',
      name: '',
    },
  };

  switch (type) {
    case 'deployment':
      dataObj.created.key = 'kube_deployment_created';
      dataObj.replicas.key = 'kube_deployment_spec_replicas';
      dataObj.replicas_available.key = 'kube_deployment_status_replicas_available';
      dataObj.extra.key = 'kube_deployment_status_observed_generation';
      dataObj.extra.name = 'Observed Generation';
      break;
    case 'replicaset':
      dataObj.created.key = 'kube_replicaset_created';
      dataObj.replicas.key = 'kube_replicaset_status_replicas';
      dataObj.replicas_available.key = 'kube_replicaset_status_ready_replicas';
      dataObj.extra.key = 'kube_replicaset_status_observed_generation';
      dataObj.extra.name = 'Observed Generation';
      break;
    case 'daemonset':
      dataObj.created.key = 'kube_daemonset_created';
      dataObj.replicas.key = 'kube_daemonset_status_number_available';
      dataObj.replicas_available.key = 'kube_daemonset_status_number_ready';
      dataObj.extra.key = 'kube_daemonset_status_observed_generation';
      dataObj.extra.name = 'Observed Generation';
      break;
    case 'statefulset':
      dataObj.created.key = 'kube_statefulset_created';
      dataObj.replicas.key = 'kube_statefulset_status_replicas';
      dataObj.replicas_available.key = 'kube_statefulset_status_replicas_ready';
      dataObj.extra.key = 'kube_statefulset_status_observed_generation';
      dataObj.extra.name = 'Observed Generation';
      break;
    case 'job':
      dataObj.created.key = 'kube_job_created';
      dataObj.replicas.key = 'kube_job_status_start_time';
      dataObj.replicas.name = 'Start time';
      dataObj.replicas_available.key = 'kube_job_status_succeeded';
      dataObj.replicas_available.name = 'Succeeded';
      dataObj.extra.key = 'kube_job_spec_completions';
      dataObj.extra.name = 'Completions';
      break;

    default:
      break;
  }

  return dataObj;
};

export const findMetric = (details: Array<GeneralQueryResult<DetailResult>>, key: string) => {
  // defend against "details.find is not a function" #752
  if (!details?.find) {
    return undefined;
  }
  const found = details.find((metric) => metric.metric.__name__ === key);
  return found?.value[1];
};
