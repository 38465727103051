import React from 'react';
import { SceneAppPage } from '@grafana/scenes';
import { ScenesCustomParams } from 'types';

import { routePaths } from 'helpers/scenes';
import { getWorkloadTableAppScene } from 'components/workloads/WorkloadList/WorkloadTable';
import { getDefaultAppProps } from './Cluster';
import TitleRow from '../TitleRow/TitleRow';
import logo from 'img/k8s.png';

export const getWorkloadScene = (params: ScenesCustomParams) => {
  const { relativeTimeRange } = params;

  const scene = new SceneAppPage({
    title: 'Workloads',
    renderTitle: (title: string) => <TitleRow title={title} withNavigation page="navigation/workload" />,
    titleImg: logo,
    getScene: getWorkloadTableAppScene,
    url: routePaths.workloads,
    ...getDefaultAppProps(relativeTimeRange),
  });

  return scene;
};

export default getWorkloadScene;
