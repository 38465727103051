import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { titleWrapperStyle } from 'components/ClusterList/ClusterList.styles';

const namespaceListStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    width: 100%;
  `,
  container: css`
    min-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  `,
  inputGrid: css`
    display: grid;
    padding: ${theme.spacing(1)};
    /* grid-template-columns: repeat(4, auto) 1fr; */
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 8px;
    padding-left: 0;

    @media (max-width: 1200px) {
      grid-auto-flow: unset;
      grid-template-columns: repeat(auto-fill, 250px);
    }
  `,
  inputWrapper: css`
    min-width: 250px;
    max-width: 300px;
    margin-right: 8px;
  `,
  datasourcePicker: css`
    min-width: 200px;
    max-width: 250px;
  `,
  loadingPlaceholder: css`
    width: 120px;
    justify-self: flex-end;
    display: flex;
  `,
  grid: css`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: 20px;
    grid-gap: 20px;
  `,
  verticalSpace: css`
    margin-top: 10px;
    margin-bottom: 10px;
  `,
  paddingZero: css`
    padding: 0;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  flex: css`
    display: flex;
    align-items: center;
  `,
  marginRight: css`
    margin-right: 8px;
  `,
  link: css`
    text-decoration: underline;
    margin-right: 5px;
    transition: color 0.2s ease;
    display: flex;

    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
  exploreWrapper: css`
    margin: ${theme.spacing(2)} 0;
  `,
  clusterTag: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  ...titleWrapperStyle(theme),
});

export default namespaceListStyles;
