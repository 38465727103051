import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { titleWrapperStyle } from 'components/ClusterList/ClusterList.styles';

const workloadListStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    width: 100%;
  `,
  container: css`
    min-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;

    h3 {
      font-size: 17px;
    }
  `,
  grid: css`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: 20px;
    grid-gap: 20px;
  `,
  verticalSpace: css`
    margin-top: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(4)};
  `,
  paddingZero: css`
    padding: 0;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
  `,
  flex: css`
    display: flex;
    align-items: flex-start;
    gap: 16px;
  `,
  marginRight: css`
    margin-right: 16px;
  `,
  link: css`
    text-decoration: underline;
    margin-right: 5px;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
  titleWrapper: css`
    display: flex;
    flex-wrap: wrap;
  `,
  listsWrapper: css`
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    align-items: flex-start;
    flex-wrap: wrap;
  `,
  workloadFilters: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  logsButton: css`
    margin-right: ${theme.spacing(1)};
  `,
  namespaceInfo: css`
    margin-top: ${theme.spacing(2)};

    h3 {
      margin-bottom: ${theme.spacing(3)};
    }
  `,
  gridEqual: css`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
  `,
  metadataWrapper: css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(2)} 0;

    h3 {
      font-size: 17px;
      margin-bottom: ${theme.spacing(2)};
    }
  `,
  efficiencyWrapper: css`
    margin-top: ${theme.spacing(3)};
  `,
  efficiencyHeader: css`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  `,
  loadingPlaceholder: css`
    width: 100px;
    justify-self: flex-end;
    display: flex;
  `,
  exploreWrapper: css`
    margin: ${theme.spacing(2)} 0;
  `,
  filter: css`
    min-width: 200px;
  `,
  sceneWrapper: css`
    min-height: 200px;

    > div > div > div > div {
      @media (max-width: 1200px) {
        flex-wrap: wrap;
        width: 100%;

        > div {
          width: 49%;
        }
      }

      @media (max-width: 768px) {
        width: 100%;

        > div {
          width: 100%;
        }
      }
    }
  `,
  sectionTitle: css`
    font-size: 17px;
    margin-bottom: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
  `,
  exploreButton: css`
    margin-right: 0;
  `,
  ...titleWrapperStyle(theme),
});

export default workloadListStyles;
