import React, { useState } from 'react';
import { Button, Modal, useStyles2 } from '@grafana/ui';

import LogPanel from 'components/LogPanel/LogPanel';
import { trackRudderStackEvent } from 'hooks/useRudderstack';
import { RudderstackEvents } from 'enums';

import getStyles from './ModalWithLogs.styles';

type ModalWithLogsProps = {
  query: string;
  backupQuery: string;
  className?: string;
  dataName: string;
  modalButtonText?: string;
  exploreButtonText?: string;
};

const ModalWithLogs = ({
  query,
  backupQuery,
  exploreButtonText,
  modalButtonText,
  className,
  dataName,
}: ModalWithLogsProps) => {
  const styles = useStyles2(getStyles);
  const [openLogsModal, setOpenLogsModal] = useState(false);

  return (
    <>
      <Modal
        title={`Latest logs: ${dataName} (last 100 lines)`}
        isOpen={openLogsModal}
        onDismiss={() => setOpenLogsModal(false)}
        closeOnEscape
        className={styles.modal}
      >
        <LogPanel
          className={styles.logPanel}
          query={query}
          backupQuery={backupQuery}
          height={550}
          exploreButton={{
            onClick: () => trackRudderStackEvent(RudderstackEvents.ExploreLogs, {}),
            isDisabled: !dataName,
            text: exploreButtonText || 'Explore logs',
          }}
          alertTitle="No logs found"
          alertMsg="It looks like you don't have any logs, make sure to check your selected datasource and your log query settings in the Configuration tab"
        />
      </Modal>
      <Button className={className} size="sm" icon="eye" variant="secondary" onClick={() => setOpenLogsModal(true)}>
        {modalButtonText || 'View logs'}
      </Button>
    </>
  );
};

export default React.memo(ModalWithLogs);
