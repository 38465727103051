import { css } from '@emotion/css';

const podHealthProgressStyles = () => ({
  progressOuter: css`
    width: 100%;
    height: 10px;
    border-radius: 3px;
    display: flex;
    overflow: hidden;
    margin-bottom: 5px;
  `,
  progressInner: css`
    height: 10px;
  `,
  link: css`
    text-decoration: underline;
    margin-right: 5px;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
  progresContainer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  outerContainer: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  `,
  progressPercentage: css`
    margin: 0;
    font-size: 13px;
  `,
});

export default podHealthProgressStyles;
