import React from 'react';
import { cx } from '@emotion/css';
import { useStyles2, Icon } from '@grafana/ui';
import getStyles from './Message.styles';

type MessageProps = {
  status: 'error' | 'success' | 'warning';
  children: React.ReactNode;
  className?: string;
  size?: 'base' | 'sm';
  ariaLabel?: string;
};

const Message = ({ status, children, className, size, ariaLabel }: MessageProps) => {
  const styles = useStyles2(getStyles(status));
  const isSizeBase = size === 'base';

  const icon =
    status === 'success' ? (
      <Icon size={isSizeBase ? 'md' : 'sm'} name="check" className={styles.icon} />
    ) : (
      <Icon size={isSizeBase ? 'md' : 'sm'} name="exclamation-triangle" className={styles.icon} />
    );

  return (
    <>
      <p
        data-testid="alert"
        className={cx(
          styles.alert,
          className,
        )}
      >
        {icon}
        <p role="alert" aria-label={ariaLabel || `${status} message`} className={styles.alertContent}>
          {children}
        </p>
      </p>
    </>
  );
};

export default Message;
