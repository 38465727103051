import { RawTimeRange, AppEvents } from '@grafana/data';
import { getIntegrationMetrics } from 'api/metrics';
import { FetchStatus, IntegrationConnectionErrorCause } from 'enums';
import appEvents from 'grafana/app/core/app_events';
import { isFetchLoadingOrReady } from 'helpers/helpers';
import {
  installIntegration,
  getIntegration,
  getIntegrationStaticConfig,
  getInstanceId,
  uninstallIntegration,
  installDashboards,
  removeDashboards,
  upgradeIntegration,
  getIntegrations,
  IntegrationSource,
  Source,
  StaticConfigSource,
  testIntegration,
} from 'api/integrationsApi';
import { K8S_INTEGRATION_ID } from '../constants';
import { getMachineLearningJSON } from 'api/machineLearning';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { pushFaroCount } from 'helpers/faro';

type IntegrationStateType = {
  integrationAvailableStatus: FetchStatus;
  installStatus: FetchStatus;
  updateStatus: FetchStatus;
  integrationInstalledStatus: FetchStatus;
  dataFlowingStatus: FetchStatus;
  metricUpToDateStatus: FetchStatus;
  machineLearningInstalledStatus: FetchStatus;
  machineLearningInstalled: boolean;
  assertsEnabledStatus: FetchStatus;
  assertsEnabled: boolean;
  integrationAvailable: boolean;
  integrationInstalled: boolean;
  dataFlowing: boolean;
  metricUpToDate: boolean;
  configFile: string;
  integration: any;
  integrationListStatus: FetchStatus;
  integrationStatus: FetchStatus;
  integrationConfigStatus: FetchStatus;
  integrationList: { [key: string]: IntegrationSource };
  selectedIntegration: Source | null;
  selectedIntegrationStaticConfig: StaticConfigSource | null;
  testIntegrationStatus: FetchStatus;
  testIntegrationError: IntegrationConnectionErrorCause | null;
  costDataFlowing: boolean;
  costDataFlowingStatus: FetchStatus;
};

type IntegrationActionType = {
  isDataFlowing: (
    query: string,
    selectedPromName: string,
    reset?: boolean,
    range?: RawTimeRange,
    step?: string
  ) => void;
  isCostDataFlowing: (
    query: string,
    selectedPromName: string,
    reset?: boolean,
    range?: RawTimeRange,
    step?: string
  ) => void;
  getMachineLearningInstalled: () => void;
  getAssertsEnabled: (query: string, selectedPromName: string) => void;
  installIntegration: (name?: string) => void;
  isIntegrationInstalled: () => void;
  isIntegrationAvailable: () => void;
  uninstallIntegration: (name?: string) => void;
  updateIntegration: (name?: string) => void;
  getIntegrations: () => void;
  getIntegration: (name?: string) => void;
  getIntegrationStaticConfig: (name?: string) => void;
  clearSelectedIntegration: () => void;
  testIntegration: (integrationSlug: string, promName: string, lokiName: string) => void;
};

export type IntegrationStore = IntegrationStateType & IntegrationActionType;

const initialState = {
  integrationAvailableStatus: FetchStatus.Idle,
  installStatus: FetchStatus.Idle,
  updateStatus: FetchStatus.Idle,
  integrationInstalledStatus: FetchStatus.Idle,
  dataFlowingStatus: FetchStatus.Idle,
  metricUpToDateStatus: FetchStatus.Idle,
  integrationAvailable: false,
  integrationInstalled: false,
  integration: null,
  dataFlowing: false,
  configFile: '',
  metricUpToDate: false,
  integrationListStatus: FetchStatus.Idle,
  integrationStatus: FetchStatus.Idle,
  integrationConfigStatus: FetchStatus.Idle,
  integrationList: {},
  selectedIntegration: null,
  selectedIntegrationStaticConfig: null,
  machineLearningInstalledStatus: FetchStatus.Idle,
  machineLearningInstalled: false,
  assertsEnabledStatus: FetchStatus.Idle,
  assertsEnabled: false,
  testIntegrationStatus: FetchStatus.Idle,
  testIntegrationError: null,
  costDataFlowing: false,
  costDataFlowingStatus: FetchStatus.Idle,
};

const useIntegrationStore = createWithEqualityFn<IntegrationStore>(
  (set, get) => ({
    //state
    ...initialState,

    // actions
    clearSelectedIntegration: () => {
      set({ selectedIntegration: null, testIntegrationStatus: FetchStatus.Idle, testIntegrationError: null });
    },

    testIntegration: async (integrationSlug, promName, lokiName) => {
      set({ testIntegrationStatus: FetchStatus.Fetching });

      try {
        await testIntegration(integrationSlug, promName, lokiName);
        set({ testIntegrationStatus: FetchStatus.Success, testIntegrationError: null });
      } catch (e: any) {
        set({ testIntegrationStatus: FetchStatus.Error, testIntegrationError: e?.message });
      }
    },

    getIntegration: async (name) => {
      set({ integrationStatus: FetchStatus.Fetching });

      try {
        const response = await getIntegration(name);

        set({
          selectedIntegration: response as Source,
        });
        set({ integrationStatus: FetchStatus.Success });
      } catch (e: any) {
        appEvents.emit(AppEvents.alertError, [`Error: ${e?.data?.message}`]);
        set({ integrationStatus: FetchStatus.Error });
      }
    },

    getIntegrationStaticConfig: async (name) => {
      set({ integrationConfigStatus: FetchStatus.Fetching });

      try {
        const response = await getIntegrationStaticConfig(name);

        set({
          selectedIntegrationStaticConfig: response as StaticConfigSource,
        });
        set({ integrationConfigStatus: FetchStatus.Success });
      } catch (e: any) {
        appEvents.emit(AppEvents.alertError, [`Error: ${e?.data?.message}`]);
        set({ integrationConfigStatus: FetchStatus.Error });
      }
    },

    getIntegrations: async () => {
      set({ integrationListStatus: FetchStatus.Fetching });

      try {
        const response = await getIntegrations();

        set({
          integrationListStatus: FetchStatus.Success,
          integrationList: response,
        });
      } catch (e: any) {
        appEvents.emit(AppEvents.alertError, [`Error: ${e?.data?.message}`]);
        set({ integrationListStatus: FetchStatus.Error });
      }
    },
    installIntegration: async (integration = K8S_INTEGRATION_ID) => {
      set({ installStatus: FetchStatus.Fetching });

      const instanceId = await getInstanceId();

      try {
        // Dashboards/Folders should be created first before installing
        await installDashboards(integration, instanceId);

        // Install integration
        await installIntegration(integration, instanceId);

        // Check if it was successfully installed
        const response = await getIntegration(integration);

        set({
          installStatus: FetchStatus.Success,
          integrationInstalled: !!response?.installation,
          integration: response,
          integrationInstalledStatus: FetchStatus.Success,
        });

        const selectedIntegration = get().selectedIntegration;
        if (selectedIntegration) {
          const list = {
            ...get().integrationList,
            [integration]: {
              ...get().integrationList[integration],
              installation: {},
            },
          };

          set({
            selectedIntegration: {
              ...(get().selectedIntegration as Source),
              installation: {},
            },
            integrationList: {
              ...(list as { [key: string]: IntegrationSource }),
            },
          });
        }
      } catch (e: any) {
        appEvents.emit(AppEvents.alertError, [`Error: ${e?.data?.error?.message}`]);
        set({ installStatus: FetchStatus.Error });
        // Folders should be removed if something fails, so users don't end up with dashboards and folders but
        // with no integration installed
        try {
          await removeDashboards(integration, instanceId);
        } catch (e: any) {
          set({ installStatus: FetchStatus.InstallRollbackError });
        }
      }
    },

    uninstallIntegration: async (integration = K8S_INTEGRATION_ID) => {
      set({ installStatus: FetchStatus.Fetching });

      try {
        const instanceId = await getInstanceId();

        // Folders should be removed before uninstalling, dashboards will be removed along with folders
        await removeDashboards(integration, instanceId);

        // Uninstall integration
        await uninstallIntegration(instanceId);

        set({
          installStatus: FetchStatus.Success,
          integrationInstalled: false,
          integration: null,
          integrationInstalledStatus: FetchStatus.Idle,
        });
      } catch (e: any) {
        appEvents.emit(AppEvents.alertError, [`Error: ${e?.data?.message}`]);
        set({ installStatus: FetchStatus.Error });
      }
    },

    updateIntegration: async (integration = K8S_INTEGRATION_ID) => {
      set({ updateStatus: FetchStatus.Fetching });

      try {
        const instanceId = await getInstanceId();
        await removeDashboards(integration, instanceId);
        await installDashboards(integration, instanceId);
        await upgradeIntegration(instanceId);

        const response = await getIntegration();
        set({
          updateStatus: FetchStatus.Success,
          integrationInstalled: !!response?.installation,
          integration: response,
        });
      } catch (e) {
        set({ updateStatus: FetchStatus.Error });
      }
    },
    isIntegrationInstalled: async () => {
      if (isFetchLoadingOrReady(get().integrationInstalledStatus)) {
        return;
      }

      set({ integrationInstalledStatus: FetchStatus.Fetching });

      try {
        const response = await getIntegration();
        set({
          integrationInstalledStatus: FetchStatus.Success,
          integrationInstalled: !!response?.installation,
          integration: response,
        });
      } catch (e: any) {
        appEvents.emit(AppEvents.alertError, [`Error: ${e?.data?.message}`]);
        set({ integrationInstalledStatus: FetchStatus.Error });
      }
    },

    isDataFlowing: async (
      query: string,
      selectedPromName: string,
      reset?: boolean,
      range?: RawTimeRange,
      step?: string
    ) => {
      if (isFetchLoadingOrReady(get().dataFlowingStatus) && !reset) {
        return;
      }

      set({ dataFlowingStatus: FetchStatus.Fetching, dataFlowing: false });

      try {
        const response = await getIntegrationMetrics(query, selectedPromName, range, step);
        set({ dataFlowing: !!response?.data?.data?.result?.[0], dataFlowingStatus: FetchStatus.Success });
      } catch (e) {
        set({ dataFlowing: false, dataFlowingStatus: FetchStatus.Error });
      }
    },

    isCostDataFlowing: async (
      query: string,
      selectedPromName: string,
      reset?: boolean,
      range?: RawTimeRange,
      step?: string
    ) => {
      if (isFetchLoadingOrReady(get().costDataFlowingStatus) && !reset) {
        return;
      }

      set({ costDataFlowingStatus: FetchStatus.Fetching, dataFlowing: false });

      try {
        const response = await getIntegrationMetrics(query, selectedPromName, range, step);
        const isFlowing = response?.data?.data?.result?.[0]?.value?.[1] === '1';
        pushFaroCount('cost-data-flowing', isFlowing ? 1 : 0);
        set({ costDataFlowing: isFlowing, costDataFlowingStatus: FetchStatus.Success });
      } catch (e) {
        pushFaroCount('cost-data-flowing', 0);
        set({ costDataFlowing: false, costDataFlowingStatus: FetchStatus.Error });
      }
    },

    getMachineLearningInstalled: async () => {
      if (isFetchLoadingOrReady(get().machineLearningInstalledStatus)) {
        return;
      }

      set({ machineLearningInstalledStatus: FetchStatus.Fetching, machineLearningInstalled: false });

      try {
        const initialized = await getMachineLearningJSON();
        if (initialized) {
          set({ machineLearningInstalledStatus: FetchStatus.Success, machineLearningInstalled: true });
        } else {
          set({ machineLearningInstalledStatus: FetchStatus.Success, machineLearningInstalled: false });
        }
      } catch (e) {
        set({ machineLearningInstalledStatus: FetchStatus.Error, machineLearningInstalled: false });
      }
    },

    getAssertsEnabled: async (query: string, selectedPromName: string) => {
      if (isFetchLoadingOrReady(get().assertsEnabledStatus)) {
        return;
      }
      set({ assertsEnabledStatus: FetchStatus.Fetching, assertsEnabled: false });

      try {
        const response = await getIntegrationMetrics(query, selectedPromName);
        set({ assertsEnabledStatus: FetchStatus.Success, assertsEnabled: !!response?.data?.data?.result?.[0] });
      } catch (e) {
        set({ assertsEnabledStatus: FetchStatus.Error, assertsEnabled: false });
      }
    },
    isIntegrationAvailable: async () => {
      if (isFetchLoadingOrReady(get().integrationAvailableStatus)) {
        return;
      }

      set({ integrationAvailableStatus: FetchStatus.Fetching });

      try {
        const response = await getInstanceId();
        set({ integrationAvailable: !!response, integrationAvailableStatus: FetchStatus.Success });
      } catch (e: any) {
        appEvents.emit(AppEvents.alertError, [`Error: ${e?.data?.message}`]);
        set({ integrationAvailable: false, integrationAvailableStatus: FetchStatus.Error });
      }
    },
  }),
  shallow
);

export default useIntegrationStore;
