import {
  CustomVariable,
  DataSourceVariable,
  EmbeddedScene,
  QueryVariable,
  SceneByVariableRepeater,
  SceneFlexItem,
  SceneFlexLayout,
  SceneReactObject,
  SceneVariableSet,
  VariableValueSelectors,
  sceneUtils,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { getGenericQueryRunner } from '../../../../helpers/scenes';
import { CostsScenesQueries } from 'queries';
import PrometheusPicker from 'components/PrometheusPicker';
import React from 'react';
import { ScenesCustomParams } from 'types';
import { ExplorablePanel } from 'components/scenes/ExplorablePanel';
import {
  metricsPerCluster,
  seriesPerNamespace,
  seriesPerNode,
  topMetricsPerCluster,
  topSeriesPerPod,
  totalSeriesForClusters,
} from './panelConfig';
import { VariableHide } from '@grafana/data';

const cardinalityDsRef = {
  type: 'grafanacloud-cardinality-datasource',
  uid: '$cardinalityDs',
};

export function getVariables(datasource: DataSourceRef, prometheusName: string) {
  const cardinalityDs = new DataSourceVariable({
    name: 'cardinalityDs',
    label: 'Cardinality datasource',
    pluginId: 'grafanacloud-cardinality-datasource',
    hide: VariableHide.hideVariable,
  });

  const cluster = new QueryVariable({
    includeAll: true,
    defaultToAll: true,
    isMulti: true,
    name: 'cardinalityCluster',
    query: CostsScenesQueries.clusterList,
    datasource,
  });

  const namespace = new QueryVariable({
    includeAll: true,
    defaultToAll: true,
    isMulti: true,
    name: 'cardinalityNamespace',
    query: 'label_values(namespace)',
    datasource,
  });

  const metricsNoNamespace = new CustomVariable({
    name: 'nonamespaced',
    label: 'Metrics without a namespace',
    value: '',
    isMulti: false,
    text: '',
    query: 'Included : |(), Excluded : ()',
  });

  return new SceneVariableSet({
    variables: [cardinalityDs, cluster, namespace, metricsNoNamespace],
  });
}

export const firstRow = (datasource: DataSourceRef) => {
  return new SceneFlexLayout({
    children: [
      {
        runner: getGenericQueryRunner(cardinalityDsRef, '', {
          cardinalityType: 'labels',
          parameterList: ['cluster'],
          refId: 'A',
          resultType: 'details',
          selector: '{cluster=~"${cardinalityCluster:pipe}",namespace=~"${cardinalityNamespace:pipe}${nonamespaced}"}',
          targetDatasource: datasource.uid,
        }),
        title: 'Metrics per cluster',
        type: metricsPerCluster,
        width: '70%',
      },
      {
        runner: getGenericQueryRunner(cardinalityDsRef, '', {
          cardinalityType: 'series',
          refId: 'A',
          resultType: 'total',
          selector: '{cluster=~"${cardinalityCluster:pipe}",namespace=~"${cardinalityNamespace:pipe}${nonamespaced}"}',
          targetDatasource: datasource.uid,
        }),
        title: 'Total series for selected cluster',
        type: totalSeriesForClusters,
        width: '30%',
      },
    ].map((panel) => {
      return new SceneFlexItem({
        $data: panel.runner,
        height: 400,
        width: panel.width,
        md: {
          width: '100%',
        },
        body: new ExplorablePanel(panel.type(panel)),
      });
    }),
  });
};

export const secondRow = (datasource: DataSourceRef) => {
  return new SceneByVariableRepeater({
    variableName: 'cardinalityCluster',
    body: new SceneFlexLayout({
      direction: 'column',
      children: [],
    }),
    getLayoutChild: (option) => {
      return new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexItem({
            body: new SceneReactObject({
              props: {
                style: { marginTop: 16 },
              },
              component: ({ style }) => <span style={style}>{option.label} details</span>,
            }),
          }),
          new SceneFlexLayout({
            children: [
              {
                runner: getGenericQueryRunner(cardinalityDsRef, '', {
                  cardinalityType: 'pairs',
                  refId: 'A',
                  resultType: 'details',
                  selector: `{cluster="${
                    option.value
                  }",namespace=~"${'${cardinalityNamespace:pipe}'}${'${nonamespaced}'}"}`,
                  targetDatasource: datasource.uid,
                }),
                title: `Top metrics for ${option.value} cluster`,
                type: topMetricsPerCluster,
              },
              {
                runner: getGenericQueryRunner(cardinalityDsRef, '', {
                  cardinalityType: 'labels',
                  limit: 500,
                  parameterList: ['namespace'],
                  refId: 'A',
                  resultType: 'details',
                  selector: `{cluster="${
                    option.value
                  }",namespace=~"${'${cardinalityNamespace:pipe}'}${'${nonamespaced}'}"}`,
                  targetDatasource: datasource.uid,
                }),
                title: `Series per namespace`,
                type: seriesPerNamespace,
              },
              {
                runner: getGenericQueryRunner(cardinalityDsRef, '', {
                  cardinalityType: 'labels',
                  parameterList: ['node'],
                  refId: 'A',
                  resultType: 'details',
                  selector: `{cluster="${
                    option.value
                  }",namespace=~"${'${cardinalityNamespace:pipe}'}${'${nonamespaced}'}"}`,
                  targetDatasource: datasource.uid,
                }),
                title: `Series per node`,
                type: seriesPerNode,
              },
              {
                runner: getGenericQueryRunner(cardinalityDsRef, '', {
                  cardinalityType: 'labels',
                  limit: 10,
                  parameterList: ['pod'],
                  refId: 'A',
                  resultType: 'details',
                  selector: `{cluster="${
                    option.value
                  }",namespace=~"${'${cardinalityNamespace:pipe}'}${'${nonamespaced}'}"}`,
                  targetDatasource: datasource.uid,
                }),
                title: `Top series per pod`,
                type: topSeriesPerPod,
              },
            ].map(({ type, ...panel }) => {
              return new SceneFlexItem({
                $data: panel.runner,
                height: 400,
                ySizing: 'content',
                body: new ExplorablePanel(type(panel, option.value as string)),
              });
            }),
          }),
        ],
      });
    },
  });
};

export function getCardinalityScene({ datasource, prometheusName }: ScenesCustomParams) {
  const variables = getVariables(datasource, prometheusName);

  const scene = new EmbeddedScene({
    $variables: variables,
    controls: [
      new SceneReactObject({
        reactNode: React.createElement(PrometheusPicker),
      }),
      new VariableValueSelectors({}),
    ],
    body: new SceneFlexLayout({
      direction: 'column',
      children: [...[firstRow(datasource), secondRow(datasource)]],
    }),
  });

  scene.addActivationHandler(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('var-datasource', prometheusName);
    sceneUtils.syncStateFromSearchParams(scene, params);
  });

  return scene;
}
