import { ThresholdsMode } from '@grafana/schema';
import { PanelConfig } from '../CostsOverviewScene/panelConfig';
import { CustomSceneObjectDetailState } from 'types';

const overrides = [
  {
    matcher: {
      id: 'byFrameRefID',
      options: 'rx',
    },
    properties: [
      {
        id: 'color',
        value: {
          fixedColor: 'super-light-blue',
          mode: 'shades',
        },
      },
    ],
  },
  {
    matcher: {
      id: 'byFrameRefID',
      options: 'tx',
    },
    properties: [
      {
        id: 'color',
        value: {
          fixedColor: 'dark-blue',
          mode: 'shades',
        },
      },
    ],
  },
  {
    matcher: {
      id: 'byFrameRefID',
      options: 'rx_dropped',
    },
    properties: [
      {
        id: 'color',
        value: {
          fixedColor: 'super-light-red',
          mode: 'shades',
        },
      },
      {
        id: 'unit',
        value: 'pps',
      },
    ],
  },
  {
    matcher: {
      id: 'byFrameRefID',
      options: 'tx_dropped',
    },
    properties: [
      {
        id: 'color',
        value: {
          fixedColor: 'dark-red',
          mode: 'shades',
        },
      },
      {
        id: 'unit',
        value: 'pps',
      },
    ],
  },
];

export const networkConfig = (panel: PanelConfig, type?: CustomSceneObjectDetailState['type']) => {
  let links = [];
  switch (type) {
    case 'cluster':
      links.push({
        title: 'Go to node: ${__field.labels.node}',
        url: '/a/grafana-k8s-app/navigation/nodes/${__field.labels.encoded_cluster}/${__field.labels.node}/network',
      });
      break;
    case 'node':
      links.push({
        title: 'Go to pod: ${__field.labels.pod}',
        url: '/a/grafana-k8s-app/navigation/namespace/${__field.labels.encoded_cluster}/${__field.labels.namespace}/${__field.labels.workload_type}/${__field.labels.workload}/${__field.labels.pod}/network',
      });
      break;
    case 'namespace':
      links.push({
        title: 'Go to ${__field.labels.workload_type}: ${__field.labels.workload}',
        url: '/a/grafana-k8s-app/navigation/namespace/${__field.labels.encoded_cluster}/${__field.labels.namespace}/${__field.labels.workload_type}/${__field.labels.workload}/network',
      });
      break;
    case 'workload':
      links.push({
        title: 'Go to pod: ${__field.labels.pod}',
        url: '/a/grafana-k8s-app/navigation/namespace/${__field.labels.encoded_cluster}/${__field.labels.namespace}/${__field.labels.workload_type}/${__field.labels.workload}/${__field.labels.pod}/network',
      });
      break;
    case 'pod':
      // end of the road for network drill-down
      break;
  }

  return {
    pluginId: 'timeseries',
    title: panel.title,
    $data: panel.runner,
    description: panel.description,
    options: {
      tooltip: {
        mode: 'single',
        sort: 'none',
      },
      legend: {
        showLegend: true,
        displayMode: 'table',
        placement: 'bottom',
        calcs: ['min', 'p90', 'max'],
        sortBy: '90th %',
        sortDesc: true,
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: 'line',
          lineInterpolation: 'smooth',
          barAlignment: 0,
          barWidthFactor: 0.6,
          lineWidth: 2,
          fillOpacity: 8,
          gradientMode: 'none',
          spanNulls: false,
          insertNulls: false,
          showPoints: 'never',
          pointSize: 5,
          stacking: {
            mode: 'none',
            group: 'A',
          },
          axisPlacement: 'auto',
          axisLabel: '',
          axisColorMode: 'text',
          axisBorderShow: false,
          scaleDistribution: {
            type: 'linear',
          },
          axisCenteredZero: true,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: 'off',
          },
        },
        color: {
          mode: 'palette-classic',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        unit: 'binBps',
        links: links,
      },
      overrides: overrides,
    },
  };
};
