import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { LoadingState, PanelData, RawTimeRange, TimeRange } from '@grafana/data';
import { Alert, LinkButton, PanelChrome, useStyles2 } from '@grafana/ui';
import { PanelRenderer } from '@grafana/runtime';
import useLogs from 'hooks/useLogs';
import { QueryCollection } from 'helpers/helpers';

import getStyles from './LogPanel.styles';
import useDatasourceStore from 'store/datasource';
import { lokiSelector } from 'store/selectors/datasource';
import useTimeRangeStore from 'store/timeRange';
import { shallow } from 'zustand/shallow';

type ExploreButtonType = {
  onClick?: () => void;
  text: string;
  isDisabled?: boolean;
};

type LogPanelType = {
  title?: string;
  query: string;
  backupQuery: string;
  range?: RawTimeRange;
  className?: string;
  height?: number;
  exploreButton?: ExploreButtonType;
  alertTitle: string;
  alertMsg: string;
};

const LogPanel = ({
  className,
  range,
  query,
  backupQuery,
  height = 300,
  title,
  exploreButton,
  alertTitle,
  alertMsg,
}: LogPanelType) => {
  const styles = useStyles2(getStyles);
  const [storeRange, relativeRange] = useTimeRangeStore((state) => [state.range, state.relativeRange], shallow);
  const lokiName = useDatasourceStore(lokiSelector);

  const exploreRange = {
    from: relativeRange?.from || storeRange.from.valueOf().toString(),
    to: relativeRange?.to || storeRange.to.valueOf().toString(),
  };
  const queries = new QueryCollection(
    lokiName,
    exploreRange,
    false,
    {
      primary: query,
      backup: backupQuery,
    },
    []
  );

  const results = useLogs(query);
  const backupResults = useLogs(backupQuery);

  return (
    <div className={styles.container}>
      <div className={styles.topWrapper}>
        {title && <h3 className={styles.panelTitle}>{title}</h3>}
        {exploreButton && lokiName && (
          <LinkButton
            className={styles.exploreButton}
            variant="secondary"
            target="_blank"
            size="sm"
            icon="external-link-alt"
            href={queries.toExploreUrl()}
            disabled={exploreButton.isDisabled}
            onClick={exploreButton.onClick}
          >
            {exploreButton.text}
          </LinkButton>
        )}
      </div>
      {results.dataFrame.length === 0 &&
        !results.loading &&
        backupResults.dataFrame.length === 0 &&
        !backupResults.loading && (
          <Alert title={alertTitle} severity="warning">
            {alertMsg}
          </Alert>
        )}
      <div className={className}>
        <AutoSizer disableHeight>
          {(size) => {
            return (
              <PanelChrome
                width={size.width || 800}
                height={height}
                actions={[
                  <PanelChrome.LoadingIndicator
                    key="loading-indicator"
                    loading={results.validating || backupResults.loading}
                    onCancel={() => {}}
                  />,
                ]}
              >
                {(innerWidth, innerHeight) => (
                  <PanelRenderer
                    title="Logs"
                    pluginId="logs"
                    onOptionsChange={() => {}}
                    width={innerWidth}
                    height={innerHeight}
                    data={
                      {
                        state: LoadingState.Loading,
                        series: results.dataFrame.length > 0 ? results.dataFrame : backupResults.dataFrame,
                        timeRange: storeRange as TimeRange,
                      } as PanelData
                    }
                    options={{ wrapLogMessage: true }}
                  />
                )}
              </PanelChrome>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

export default LogPanel;
