import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, LinkButton, useStyles2 } from '@grafana/ui';
import o11yButtonStyles from './O11yButton.styles';
import { o11y } from 'img';
import { cx } from '@emotion/css';
import useMetrics from 'hooks/useMetrics';
import { Queries } from 'queries';
import useDecodedParams from 'hooks/useDecodedParams';
import { isDescendant } from 'helpers/helpers';
import { GeneralQueryResult } from 'types';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import { trackRudderStackEvent } from 'hooks/useRudderstack';
import { RudderstackEvents } from 'enums';
import { pushFaroCount } from 'helpers/faro';

const O11yItem = ({
  label,
  value,
  link,
  onClick,
}: {
  label: string;
  value?: string;
  link: string;
  onClick: () => void;
}) => {
  const styles = useStyles2(o11yButtonStyles);

  return (
    <div className={styles.item}>
      <span>
        <a rel="noreferrer" className={styles.link} href={link} onClick={onClick}>
          {label}
        </a>
      </span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};

const O11yButton = ({ type }: { type: string }) => {
  const { cluster, namespace, workloadType, workload } = useDecodedParams();
  const styles = useStyles2(o11yButtonStyles);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const lokiName = useDatasourceStore(lokiSelector);

  const { data, loading } = useMetrics([
    { query: Queries.InstrumentedApps(cluster, namespace, workload, workloadType) },
  ]);

  const apps = useMemo(
    () =>
      (data?.[0] as Array<GeneralQueryResult<{ job: string; deployment_environment: string }>>)?.map?.((item) => {
        return {
          job: item.metric?.job,
          environment: item.metric?.deployment_environment || '',
        };
      }),
    [data]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        event.target instanceof Node &&
        !popupRef.current.contains(event.target) &&
        !isDescendant(event.target, buttonRef.current)
      ) {
        setPopUpOpen(false);
      }
    };

    if (popUpOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popUpOpen]);

  useEffect(() => {
    if (apps?.length) {
      pushFaroCount(`${type}-o11y-instrumented-apps`, apps?.length);
    }
  }, [apps, type]);

  return (
    <div className={styles.popupContainer}>
      <Button
        ref={buttonRef}
        className={cx({ [styles.withArrow]: popUpOpen })}
        role="button"
        variant="secondary"
        size="sm"
        icon="graph-bar"
        data-name="popup-button"
        onClick={() => {
          setPopUpOpen(!popUpOpen);
          trackRudderStackEvent(RudderstackEvents.AppO11YPopOverClick, { objectKind: type });
        }}
      >
        View application layer
      </Button>
      {popUpOpen && (
        <div className={styles.popupWrapper} ref={popupRef}>
          <div>
            <div className={styles.o11yLogoWrapper}>
              <img src={o11y} />
              <span>Application Observability</span>
            </div>
            {loading ? (
              <span>Loading...</span>
            ) : (
              <div>
                {!apps || apps?.length === 0 ? (
                  <div className={styles.message}>
                    <span>
                      Observe services, detect anomalies and identify root causes, all based on the open standards
                      OpenTelemetry and Prometheus.
                    </span>
                    <LinkButton role="link" variant="primary" href="/a/grafana-app-observability-app">
                      Go to Application Observability
                    </LinkButton>
                  </div>
                ) : (
                  apps.map((app) => (
                    <O11yItem
                      onClick={() => {
                        trackRudderStackEvent(RudderstackEvents.AppO11YPopOverClick, {
                          objectKind: type,
                          serviceNamespace: namespace,
                          serviceName: app.job,
                        });
                      }}
                      key={app.job}
                      label={app.job}
                      value={app.environment}
                      link={`/a/grafana-app-observability-app/services/${app.job?.replaceAll(
                        '/',
                        '---'
                      )}?var-prometheus=${prometheusName}&var-loki=${lokiName}`}
                    />
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default O11yButton;
