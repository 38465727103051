import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

export default (theme: GrafanaTheme2) => ({
  modal: css`
    width: 80vw;
    max-width: 1200px;
    height: 80vh;
    max-height: 700px;

    > div {
      overflow: hidden;
    }
  `,
  logPanel: css`
    [class$='-panel-content'] {
      max-height: 540px;
    }
  `,
  exploreButtonWrapper: css`
    display: flex;
    justify-content: flex-end;
  `,
});
