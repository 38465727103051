import React from 'react';
import { DeploymentConfig } from '../../../../api/config';
import { Link } from 'react-router-dom';
import { PLUGIN_ROOT_URL } from '../../../../constants';
import { encodeUrlString } from 'helpers/helpers';

type Props = {
  deploymentConfigValues: DeploymentConfig;
};

const ConfigFinal = ({ deploymentConfigValues }: Props) => {
  return (
    <>
      <h3>Done</h3>
      <p>
        Once you have completed the above steps, you can view the
        <Link
          to={`${PLUGIN_ROOT_URL}/configuration/metrics-status?var-cluster=${encodeUrlString(
            deploymentConfigValues.cluster
          )}`}
        >
          cluster status
        </Link>
        to see the features come online, or navigate to the
        <Link to={`${PLUGIN_ROOT_URL}/home`}>home page</Link>
        to see your cluster telemetry in real time.
      </p>
    </>
  );
};

export default React.memo(ConfigFinal);
