import { ComponentSize } from '@grafana/ui';
import { usePluginComponent } from 'hooks/usePluginComponent';
import React, { ReactElement } from 'react';
import useAssertsStore from 'store/asserts';
import useTimeRangeStore from 'store/timeRange';

interface EntityAssertionsWidgetProps {
  query: {
    entityName?: string;
    entityType?: string;
    start: number;
    end: number;
    scope:
      | {
          env?: string;
          site?: string;
          namespace?: string;
        }
      | undefined;
  };
  size: ComponentSize;
  source?: string;
}

export type EntityAssertionsWidgetExternal = (props: EntityAssertionsWidgetProps) => ReactElement | null;

interface Props {
  name: string;
  type: string;
}

export function EntityAssertionsWidget({ name, type }: Props) {
  const [range] = useTimeRangeStore((state) => [state.range]);
  const isAssertsEnabled = useAssertsStore((state) => state.assertsEnabled);

  const { isLoading, component: EntityAssertionsWidgetExternal } = usePluginComponent<EntityAssertionsWidgetProps>(
    'grafana-asserts-app/entity-assertions-widget/v1'
  );

  if (isLoading || !EntityAssertionsWidgetExternal || !isAssertsEnabled) {
    return null;
  }

  return (
    <EntityAssertionsWidgetExternal
      size="sm"
      query={{
        start: range.from.valueOf(),
        end: range.to.valueOf(),
        entityName: name,
        entityType: type,
        scope: undefined,
      }}
      source="Kubernetes Monitoring"
    />
  );
}
