import { ThresholdsMode } from '@grafana/data';
import { PanelConfig } from 'components/scenes/CostsOverviewScene/panelConfig';

export const graphsConfigWithLabel = (panel: PanelConfig) => ({
  pluginId: 'timeseries',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  options: {
    legend: {
      calcs: ['min', 'mean', 'max'],
      displayMode: 'table',
      placement: 'bottom',
      showLegend: true,
    },
    tooltip: {
      mode: 'multi',
      sort: 'none',
    },
  },
  fieldConfig: {
    defaults: {
      color: {
        mode: 'palette-classic',
      },
      custom: {
        drawStyle: 'line',
        lineInterpolation: 'smooth',
        barAlignment: 0,
        lineWidth: 1,
        fillOpacity: 0,
        gradientMode: 'none',
        spanNulls: false,
        insertNulls: false,
        showPoints: 'never',
        pointSize: 5,
        stacking: {
          mode: 'none',
          group: 'A',
        },
        axisPlacement: 'auto',
        axisLabel: '',
        axisColorMode: 'text',
        axisBorderShow: false,
        scaleDistribution: {
          type: 'linear',
        },
        axisCenteredZero: false,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
      unit: panel.unit || 'cores',
    },
    overrides: [
      {
        matcher: {
          id: 'byFrameRefID',
          options: 'capacity',
        },
        properties: [
          {
            id: 'color',
            value: {
              fixedColor: 'purple',
              mode: 'fixed',
            },
          },
          {
            id: 'custom.gradientMode',
            value: 'hue',
          },
          {
            id: 'custom.fillOpacity',
            value: 10,
          },
        ],
      },
      {
        matcher: {
          id: 'byFrameRefID',
          options: 'usage',
        },
        properties: [
          {
            id: 'color',
            value: {
              fixedColor: 'blue',
              mode: 'fixed',
            },
          },
        ],
      },
      {
        matcher: {
          id: 'byFrameRefID',
          options: 'requests',
        },
        properties: [
          {
            id: 'color',
            value: {
              fixedColor: 'orange',
              mode: 'fixed',
            },
          },
          {
            id: 'custom.lineStyle',
            value: {
              dash: [10, 10],
              fill: 'dash',
            },
          },
        ],
      },
      {
        matcher: {
          id: 'byFrameRefID',
          options: 'limits',
        },
        properties: [
          {
            id: 'color',
            value: {
              fixedColor: 'red',
              mode: 'fixed',
            },
          },
          {
            id: 'custom.lineStyle',
            value: {
              dash: [10, 10],
              fill: 'dash',
            },
          },
        ],
      },
      {
        matcher: {
          id: 'byFrameRefID',
          options: 'allocation',
        },
        properties: [
          {
            id: 'color',
            value: {
              fixedColor: 'green',
              mode: 'fixed',
            },
          },
          {
            id: 'custom.gradientMode',
            value: 'opacity',
          },
          {
            id: 'custom.fillOpacity',
            value: 30,
          },
          {
            id: 'custom.lineStyle',
            value: {
              dash: [0, 10],
              fill: 'dot',
            },
          },
        ],
      },
    ],
  },
});
