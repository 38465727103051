import { createContext } from 'react';

type PaginationType = {
  name: string;
  page: number;
  pageSize: number;
};

export type PaginationTrackingType = {
  [key: string]: PaginationType;
};

interface PaginationContextValue {
  tracking: {
    [key: string]: PaginationType;
  };
  updatePageTracking: (name: string, id: string, page: number, pageSize: number) => void;
  initPageTracking: (name: string, id: string) => void;
}

const PaginationContext = createContext<PaginationContextValue>({
  tracking: {},
  updatePageTracking: () => {},
  initPageTracking: () => {},
});

export default PaginationContext;
