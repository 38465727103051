import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const clipboardStyles = (loading: boolean | undefined) => (theme: GrafanaTheme2) => ({
  code: css`
    width: 100%;
    word-break: break-all;
    overflow-y: scroll;
    max-height: 100%;
    margin-bottom: ${theme.spacing(1)};
  `,
  container: css`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing(2)};
    ${loading && 'opacity: 0.5;'}
  `,
  button: css`
    margin-left: auto;
  `,
  loading: css`
    position: absolute;
    right: 6px;
    top: 3px;
  `,
  codeWrapper: css`
    position: relative;
  `,
  copyButtonInCode: css`
    position: absolute;
    top: -10px;
    right: -10px;
  `,
});

export default clipboardStyles;
