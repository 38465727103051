import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const o11yButtonStyles = (theme: GrafanaTheme2) => {
  return {
    popupContainer: css`
      position: relative;
      margin-right: ${theme.spacing(1)};
    `,
    popup: css`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: ${theme.spacing(2)};
    `,
    popupWrapper: css`
      background: ${theme.colors.background.canvas};
      min-width: 290px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      padding: ${theme.spacing(2)};
      margin-top: ${theme.spacing(2)};
      position: absolute;
      z-index: 2;
      box-shadow: ${theme.shadows.z2};
      max-width: calc(100vw - 38px);
      overflow-x: auto;
      @media (min-width: 1135px) {
        transform: translateX(-65%);
      }

      @media (min-width: 1200px) {
        transform: translateX(-50%);
      }

      @media (min-width: 1300px) {
        left: 50%;
        transform: translateX(-50%);
      }
    `,
    withArrow: css`
      position: relative;
      &:after {
        position: absolute;
        content: '';
        display: flex;
        top: 17px;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${theme.colors.background.canvas};
      }
    `,
    o11yLogoWrapper: css`
      display: flex;
      align-items: center;
      width: 100%;
      gap: ${theme.spacing(2)};
      font-size: ${theme.typography.h5.fontSize};
      margin-bottom: 12px;
    `,
    item: css`
      display: flex;
      justify-content: space-between;
      gap: 24px;
      padding: 12px 0;
      border-top: 1px solid ${theme.colors.border.weak};

      > span {
        white-space: nowrap;
      }
    `,
    link: css`
      color: ${theme.colors.text.link};
    `,
    value: css`
      color: ${theme.colors.text.secondary};
    `,
    message: css`
      padding: 12px 0;
      border-top: 1px solid ${theme.colors.border.weak};
      gap: ${theme.spacing(2)};
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,
  };
};

export default o11yButtonStyles;
