import { SelectableValue, toFixed } from '@grafana/data';
import { LoadingPlaceholder, MultiSelect, Select, useStyles2 } from '@grafana/ui';
import efficiencyCardStyles from 'components/Efficiency/EfficiencyCard.styles';
import Table from 'components/Table/Table';
import { RudderstackEvents, TableType } from 'enums';
import { filterCluster, normalizeClusterData } from 'helpers/efficency';
import { sortBy } from 'lodash';
import { NodeQueries, ResourceQueries } from 'queries';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useTimeRangeStore from 'store/timeRange';
import {
  ClusterResult,
  GeneralQueryResult,
  Node,
  SceneWithTitle,
  StoredParams,
  CommonUsageData,
  DataTableClusterUsage,
} from 'types';
import {
  EFFICIENCY_OVER_UTILIZED_THRESHOLD,
  EFFICIENCY_UNDER_UTILIZED_THRESHOLD,
  K8S_STORAGE_KEY,
  NO_DATA_FLOWING_MAP,
} from '../../constants';
import { PluginMetaContext } from 'context/PluginMetaContext';
import { pushFaroCount } from 'helpers/faro';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { countFiltersApplied, encodeUrlString, QueryCollection } from 'helpers/helpers';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';
import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneObjectBase } from '@grafana/scenes';
import NoDataFlowing from 'components/NoDataFlowing/NoDataFlowing';
import CopyObjectName from 'components/Table/CopyObjectName';
import useCostUsage from 'hooks/useCostUsage';
import { getAllColumns } from 'helpers/usageCostHelpers';
import { TableView } from 'store/filters';
import FiltersRow from 'components/FiltersRow/FiltersRow';
import AssertsWorkbenchButton from 'components/Asserts/AssertsWorkbenchButton';
import useAssertsStore from 'store/asserts';
import { assertsColumns, selectableRowDisabled, selectableRowSelected } from 'components/Asserts/helpers';
import useLocalStorage from 'hooks/useLocalStorage';
import alertColumn from 'components/Table/alertColumn';
import useDataFrame from 'hooks/useDataFrame';

export interface NamespaceUsageData extends CommonUsageData {
  name: string;
  cpu_usage: number;
  cpu_max: number;
}

interface MaxUsageProps {
  value: number;
  round?: boolean;
  showPercent?: boolean;
  percentValue?: number;
  unit?: string;
}

export const MaxUsageValue = ({ value, round, showPercent = true, percentValue, unit }: MaxUsageProps) => {
  const styles = useStyles2(efficiencyCardStyles);
  if (!value) {
    return <div>No data</div>;
  }

  let rounded = toFixed(value * 100, 2);

  if (round) {
    rounded = Math.round(parseInt(rounded, 10)).toString();
  }

  let style = styles.secondary;

  if (showPercent || (!showPercent && typeof percentValue !== 'undefined')) {
    if ((percentValue || value) <= EFFICIENCY_UNDER_UTILIZED_THRESHOLD) {
      style = styles.under;
    } else if (
      (percentValue || value) > EFFICIENCY_UNDER_UTILIZED_THRESHOLD &&
      (percentValue || value) < EFFICIENCY_OVER_UTILIZED_THRESHOLD
    ) {
      style = styles.green;
    } else if ((percentValue || value) > EFFICIENCY_OVER_UTILIZED_THRESHOLD) {
      style = styles.over;
    }
  }
  return showPercent ? (
    <div className={style}>{rounded}%</div>
  ) : (
    <div className={style}>
      {value} {unit}
    </div>
  );
};

type ClusterListProps = {
  title?: string;
};

const ClusterList = ({ title }: ClusterListProps) => {
  const meta = useContext(PluginMetaContext);
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);
  const [assertsEnabled, setSelectedAssertsRows, selectedAssertsRows, toggledClearRows] = useAssertsStore((state) => [
    state.assertsEnabled,
    state.setSelectedAssertsRows,
    state.selectedAssertsRows,
    state.toggledClearRows,
  ]);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const [tableData, setTableData] = useState<DataTableClusterUsage[]>([]);
  const [storedParams, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);
  const [clusterFilter, setClusterFilter] = useState(storedParams?.cluster?.length ? storedParams.cluster : []);
  const [providerFilter, setProviderFilter] = useState(storedParams?.clusterProvider || '');

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const {
    loading: nodeLoading,
    data: data,
    firstLoad,
  } = useDataFrame(
    [NodeQueries.NodeInfo(clusterFilter?.join('|')), ResourceQueries.ClusterAlertsCount(clusterFilter?.join('|'))],
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '', // auto step
    !hasDateChanged,
    1,
    true,
    true // instant query
  );
  const [nodeInfo, alertsData] = data as [Array<GeneralQueryResult<Node>>, Array<GeneralQueryResult<ClusterResult>>];

  useEffect(() => {
    if (!nodeLoading) {
      const normalized = normalizeClusterData(nodeInfo);
      if (normalized?.length) {
        pushFaroCount('cluster-count', normalized.length);
      }

      const alertMap = new Map<string, number>();
      alertsData?.forEach?.((metric) => {
        alertMap.set(metric?.metric?.cluster, parseInt(metric.value[1], 10));
      });

      normalized?.map?.((cluster) => {
        cluster.alertCount = alertMap.get(cluster.name) || 0;
      });

      setTableData(normalized);
    }
  }, [setTableData, nodeInfo, nodeLoading, alertsData]);

  const columns = [
    {
      name: 'Cluster',
      selector: (row: DataTableClusterUsage) => row.name,
      sortable: true,
      maxWidth: '300px',
      minWidth: '200px',
      sortFunction: (a: DataTableClusterUsage, b: DataTableClusterUsage) =>
        a.name.localeCompare(b.name, undefined, { numeric: true }),
      // eslint-disable-next-line react/display-name
      cell: (row: DataTableClusterUsage) => (
        <CopyObjectName link={`/a/${meta.id}/navigation/cluster/${encodeUrlString(row.name)}`} value={row.name} />
      ),
    },
  ];

  const [clusterOptions, providerOptions] = useMemo(() => {
    const clusters = new Map<string, SelectableValue>();
    const providers = new Map<string, SelectableValue>();

    tableData?.forEach?.((item) => {
      clusters.set(item.name, {
        label: item.name,
        value: item.name,
      });
      providers.set(item.provider, {
        label: item.provider,
        value: item.provider,
      });
    });

    return [sortBy([...clusters.values()], 'value'), sortBy([...providers.values()], 'value')];
  }, [tableData]);

  const handleClusterFilterUpdate = useCallback(
    (filters: SelectableValue[] = []) => {
      const clusters = filters?.map?.((filter) => filter.value);
      setClusterFilter(clusters);
      setStoredParams({ cluster: clusters });
    },
    [setStoredParams]
  );

  const handleProviderFilterUpdate = useCallback(
    (filter?: SelectableValue) => {
      if (filter === null) {
        setProviderFilter('');
      } else {
        setProviderFilter(filter?.value);
      }

      setStoredParams({ clusterProvider: filter?.value });
    },
    [setProviderFilter, setStoredParams]
  );

  const handleSelectedRowsChange = useCallback(
    ({ selectedRows }: { selectedRows: DataTableClusterUsage[] }) => {
      setSelectedAssertsRows(selectedRows);
    },
    [setSelectedAssertsRows]
  );

  const filteredClusters = useMemo(() => {
    return tableData?.filter?.((cluster) => filterCluster(cluster, clusterFilter, providerFilter));
  }, [tableData, clusterFilter, providerFilter]);

  const {
    usageLoading,
    usageCostTableData,
    costLoading,
    firstCostLoad,
    firstUsageLoad,
    tableView,
    onTableSort,
    sortColumn,
    exploreUrl,
  } = useCostUsage<DataTableClusterUsage>({
    type: 'cluster',
    initialData: filteredClusters,
    usageQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.ClusterUsage),
    costQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.ClusterCost),
  });

  const { currentColumns, extraColumns } = getAllColumns<DataTableClusterUsage>(
    tableView === TableView.Cost,
    columns,
    [
      {
        name: 'Provider',
        selector: (row: DataTableClusterUsage) => row.provider ?? '',
        sortable: true,
      },
      {
        name: 'Nodes',
        selector: (row: DataTableClusterUsage) => row.nodes ?? 0,
        sortable: true,
      },
    ],
    [alertColumn<DataTableClusterUsage>({ hasDateChanged, prometheusName, useNameAsCluster: true })],
    usageCostTableData.errors,
    usageLoading && firstUsageLoad,
    costLoading && firstCostLoad
  );

  const selectableRowsCallback = useMemo(() => selectableRowSelected<DataTableClusterUsage>('name'), []);

  return (
    <NoDataFlowing message={NO_DATA_FLOWING_MAP.clusters}>
      <div style={{ width: '100%' }}>
        {title && <h5>{title}</h5>}
        <FiltersRow
          visibleFilterCount={countFiltersApplied([clusterFilter, providerFilter])}
          extraButtons={
            <ExploreButton
              href={exploreUrl}
              label="Explore clusters"
              eventName={RudderstackEvents.ExploreClusters}
              size="md"
              style={{ marginRight: 0 }}
            />
          }
          visibleFilters={
            <>
              <MultiSelect
                width={27}
                isClearable
                placeholder="Filter Clusters"
                options={clusterOptions}
                value={clusterFilter}
                onChange={handleClusterFilterUpdate}
                maxVisibleValues={1}
              />
              <Select
                width={25}
                isClearable
                placeholder="Filter Providers"
                options={providerOptions}
                value={providerFilter}
                onChange={handleProviderFilterUpdate}
              />
              <AssertsWorkbenchButton label="clusters" type="KubeCluster" relativeRange={relativeRange} />
            </>
          }
        />
        <div style={{ marginTop: 20 }}>
          {(!nodeLoading || !usageLoading || !costLoading) && !firstLoad ? (
            <Table<DataTableClusterUsage>
              key={sortColumn}
              withNestedHeader
              onSort={onTableSort}
              id={TableType.Resources}
              name={TableType.Resources}
              data={usageCostTableData.data}
              columns={[...currentColumns, ...assertsColumns<DataTableClusterUsage>(), ...extraColumns]}
              noDataText="No clusters found"
              pointerOnHover={false}
              selectable={assertsEnabled}
              onSelectedRowsChange={handleSelectedRowsChange}
              clearSelectedRows={toggledClearRows}
              selectableRowDisabled={(row) => selectableRowDisabled<DataTableClusterUsage>(row, selectedAssertsRows)}
              selectableRowSelected={selectableRowsCallback}
            />
          ) : (
            <LoadingPlaceholder text="Loading clusters..." />
          )}
        </div>
      </div>
    </NoDataFlowing>
  );
};

export default ClusterList;

const ClusterListWithDataFlowing = () => (
  <NoDataFlowing message={NO_DATA_FLOWING_MAP.clusters}>
    <ClusterList />
  </NoDataFlowing>
);

export class ClusterListScene extends SceneObjectBase<SceneWithTitle> {
  static Component = ClusterListWithDataFlowing;
}

export function getClusterListScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new ClusterListScene({}),
        }),
      ],
    }),
  });
}
