import { useState, useEffect } from 'react';
import appEvents from 'grafana/app/core/app_events';
import useSWR from 'swr';

import { BackendSrvRequest, config, getBackendSrv } from '@grafana/runtime';
import { AppEvents, DataFrame, FieldType, MutableDataFrame, QueryResultMeta } from '@grafana/data';
import { Log } from 'types';
import useDatasourceStore from 'store/datasource';
import { lokiSelector } from 'store/selectors/datasource';
import useTimeRangeStore from 'store/timeRange';
import { shallow } from 'zustand/shallow';

function createUid(ts: string, labelsString: string, line: string, usedUids: any, refId?: string): string {
  // Generate id as hashed nanosecond timestamp, labels and line (this does not have to be unique)
  let id = `${ts}_${labelsString}_${line}`;

  // Check if generated id is unique
  // If not and we've already used it, append it's count after it
  if (id in usedUids) {
    // Increase the count
    const newCount = usedUids[id] + 1;
    usedUids[id] = newCount;
    // Append count to generated id to make it unique
    id = `${id}_${newCount}`;
  } else {
    // If id is unique and wasn't used, add it to usedUids and start count at 0
    usedUids[id] = 0;
  }
  // Return unique id
  if (refId) {
    return `${id}_${refId}`;
  }
  return id;
}

function constructDataFrame(
  times: string[],
  timesNs: string[],
  lines: string[],
  uids: string[],
  labels: any,
  reverse?: boolean,
  refId?: string
) {
  const dataFrame = {
    refId,
    fields: [
      { name: 'ts', type: FieldType.time, config: { displayName: 'Time' }, values: times }, // Time
      { name: 'line', type: FieldType.string, config: {}, values: lines, labels }, // Line - needs to be the first field with string type
      { name: 'id', type: FieldType.string, config: {}, values: uids },
      { name: 'tsNs', type: FieldType.time, config: { displayName: 'Time ns' }, values: timesNs }, // Time
    ],
    length: times.length,
  };

  if (reverse) {
    const mutableDataFrame = new MutableDataFrame(dataFrame);
    mutableDataFrame.reverse();
    return mutableDataFrame;
  }

  return dataFrame;
}

export function lokiStreamResultToDataFrame(stream: any, reverse?: boolean, refId?: string): DataFrame {
  const labels: any = stream.stream;
  const labelsString = Object.entries(labels)
    ?.map?.(([key, val]) => `${key}="${val}"`)
    ?.sort()
    ?.join('');

  const times: string[] = [];
  const timesNs: string[] = [];
  const lines: string[] = [];
  const uids: string[] = [];

  // We need to store and track all used uids to ensure that uids are unique
  const usedUids: { string?: number } = {};

  for (const [ts, line] of stream.values) {
    // num ns epoch in string, we convert it to iso string here so it matches old format
    times.push(new Date(parseInt(ts.substr(0, ts.length - 6), 10)).toISOString());
    timesNs.push(ts);
    lines.push(line);
    uids.push(createUid(ts, labelsString, line, usedUids, refId));
  }

  return constructDataFrame(times, timesNs, lines, uids, labels, reverse, refId);
}

export function lokiStreamsToDataFrames(response: any): DataFrame[] {
  const data = response.data.result;

  const meta: QueryResultMeta = {
    preferredVisualisationType: 'logs',
  };

  const series: DataFrame[] = data?.map?.((stream: any) => {
    const dataFrame = lokiStreamResultToDataFrame(stream);

    return {
      ...dataFrame,
      refId: 'A',
      meta,
    };
  });

  return series;
}

function useLogs(query: string, range?: string, step?: string, refresh = true) {
  const [data, setData] = useState<Log[]>();
  const [dataFrame, setDataFrame] = useState<any>([]);
  const lokiName = useDatasourceStore(lokiSelector);
  const [hasDateChanged, timeRange, refreshInterval] = useTimeRangeStore(
    (state) => [state.hasDateChanged, state.range, state.refreshInterval],
    shallow
  );

  const fetcher = (options: BackendSrvRequest) => getBackendSrv().fetch<any>(options).toPromise();
  const url = config.datasources[lokiName]?.url;

  const endpoint = '/loki/api/v1/query_range';
  const timeString = `start=${timeRange.from.unix()}&end=${timeRange.to.unix()}`;
  const urlWithParams = `${url}${endpoint}?direction=BACKWARD&query=${new URLSearchParams(
    query
  )}&limit=100&${timeString}`;

  const options: BackendSrvRequest = {
    url: urlWithParams,
    method: 'GET',
    headers: { accept: 'application/json, text/plain, */*' },
  };

  const {
    data: response,
    isLoading,
    isValidating,
    error,
  } = useSWR(options, fetcher, {
    refreshInterval: !refresh || hasDateChanged ? undefined : refreshInterval,
    revalidateOnFocus: false,
    refreshWhenHidden: false,
    revalidateOnReconnect: false,
    errorRetryCount: 5,
  });

  useEffect(() => {
    if (response) {
      const logs =
        response?.data?.data?.result[0]?.values?.map?.((l: any) => {
          return {
            time: Number(l[0]),
            value: l[1],
          };
        }) || [];
      setData(logs);
      setDataFrame(lokiStreamsToDataFrames(response.data));
    }
    if (error) {
      appEvents.emit(AppEvents.alertError, [error.data.message]);
    }
  }, [response, error]);

  return { loading: isLoading, validating: isValidating, data, dataFrame, error };
}

export default useLogs;
