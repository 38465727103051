import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneObjectBase } from '@grafana/scenes';
import React from 'react';
import { ClusterConfig } from './ClusterConfig';

const SceneRenderer = () => {
  return <ClusterConfig />;
};

class CustomAppScene extends SceneObjectBase {
  static Component = SceneRenderer;
}

export function getClusterConfigScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new CustomAppScene({}),
        }),
      ],
    }),
  });
}
