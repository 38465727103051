import { DataFrameJSON } from '@grafana/data';
import { config } from '@grafana/runtime';
import { isErrorResponse } from 'api/baseApi';
import { OutlierParams, postOutlier } from 'api/machineLearning';
import { getValidName } from 'helpers/helpers';
import { useCallback, useMemo, useState } from 'react';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';
import { DateTimeRange } from 'store/timeRange';

interface OutlierObj {
  name: string;
  value: number;
}

const parseResponse = (data: DataFrameJSON[]) => {
  if (data) {
    const arr: OutlierObj[] = [];
    data?.forEach?.((metric) => {
      const obj: OutlierObj = {
        name: getValidName(metric, 'pod') as string,
        value: metric.data?.values[1][0] as number,
      };
      // Only add outliers to the response
      if (obj.value === 1) {
        arr.push(obj);
      }
    });
    return arr;
  } else {
    return [];
  }
};

function useOutlier(query: string, timeRange: DateTimeRange) {
  const [data, setData] = useState<OutlierObj[] | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const dsId = config.datasources[prometheusName].id;

  const outlier: OutlierParams = useMemo(() => {
    return {
      data: {
        attributes: {
          response_type: 'binary',
          grafana_url: config.appUrl,
          datasource_id: dsId,
          datasource_type: 'prometheus',
          query_params: {
            expr: query,
          },
          start_end_attributes: {
            start: timeRange.from?.toISOString()?.replace('Z', ''),
            end: timeRange.to?.toISOString()?.replace('Z', ''),
            interval: 60,
          },
          algorithm: {
            name: 'dbscan',
            // This sensitivity was arbitrarily set on a value that wont make everything outliers
            // but will give reasonable outliers. Users are able to adjust this by clicking the
            // accompanying button that takes them to the ML plugin
            // https://grafana.com/docs/grafana-cloud/machine-learning/outlier-detection/
            sensitivity: 0.65,
          },
        },
      },
    };
  }, [dsId, query, timeRange.from, timeRange.to]);

  const getData = useCallback(async (): Promise<void> => {
    setLoading(true);
    const response = await postOutlier(outlier);

    if (response) {
      if (isErrorResponse(response)) {
        setError(response.data.message);
        setLoading(false);
      } else {
        const { data } = response as any;
        const parsed = parseResponse(data.results['A'].frames as DataFrameJSON[]);
        setData(parsed);
        setLoading(false);
      }
    }
  }, [setData, setLoading, outlier]);

  return [data, error, loading, getData] as [OutlierObj[], string, boolean, () => Promise<void>];
}

export default useOutlier;
