import { AppPluginMeta, DataFrameJSON } from '@grafana/data';
import { SuccessfulFetchResponse, post, get, ErrorFetchResponse } from './baseApi';

export interface ForecastResponse {
  results: {
    [key: string]: {
      frames: DataFrameJSON[];
    };
  };
  error?: string;
  message?: string;
  status?: string;
}

export interface ErrorForecastResponse {
  error: string;
  message: string;
  status: string;
}

export interface ForecastParams {
  job: {
    name: string;
    metric: string;
    description?: string;
    grafanaUrl: string;
    grafanaApiKey: string;
    datasourceId: number;
    datasourceUid: string;
    datasourceType: string;
    queryParams: {
      expr: string;
    };
    interval: number;
    algorithm: string;
    hyperParams?: object;
    trainingWindow: number;
    trainingFrequency: number;
    holidays?: string[];
  };
  forecastParams: {
    start: string;
    end: string;
    interval: number;
  };
  cacheBust?: string;
}

export interface OutlierParams {
  data: {
    attributes: {
      response_type: string;
      grafana_url: string;
      datasource_id: number;
      datasource_type: string;
      query_params: {
        expr: string;
      };
      start_end_attributes: {
        start: string;
        end: string;
        interval: number;
      };
      algorithm: {
        name: string;
        sensitivity: number;
      };
    };
  };
}

export async function postForecast(
  params: ForecastParams
): Promise<SuccessfulFetchResponse<ForecastResponse> | ErrorFetchResponse<ErrorForecastResponse>> {
  const url = `/api/plugins/grafana-ml-app/resources/predict/api/v1/forecast`;
  try {
    const response: SuccessfulFetchResponse<ForecastResponse> = await post(url, params);
    return response;
  } catch (e: any) {
    console.log({ e });
    return e;
  }
}

export async function postOutlier(
  params: OutlierParams
): Promise<SuccessfulFetchResponse<ForecastResponse> | ErrorFetchResponse<ErrorForecastResponse>> {
  const url = `/api/plugins/grafana-ml-app/resources/proxy/api/v1/outlier`;
  try {
    const response: SuccessfulFetchResponse<ForecastResponse> = await post(url, params);
    return response;
  } catch (e: any) {
    console.log({ e });
    return e;
  }
}

export async function getMachineLearningJSON() {
  // Leaving this here for the future incase we want to get more granular with our access
  // This request will talk to the ML API directly to see if user has access to the API
  // const hasAccessResponse = await get<any>('/api/plugins/grafana-ml-app/resources/tenant/api/v1/info');
  const response = await get<AppPluginMeta>('/api/plugins/grafana-ml-app/settings');
  return response?.jsonData?.initialized;
}
