import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const titleRowStyles = (withNavigation: boolean) => (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      ${!withNavigation && `border-top: 1px solid ${theme.colors.border.weak}`};
      margin-top: 0px;
      width: 100%;
      padding-top: ${!withNavigation ? theme.spacing(2) : 0};
      margin-bottom: ${theme.spacing(2)};
      display: flex;
      gap: ${theme.spacing(1)};
      align-items: center;
      cursor: pointer;
    `,
    title: css`
      color: ${theme.colors.text.primary};
      margin-bottom: 0;
    `,
    selected: css`
      background-color: ${theme.colors.background.secondary};
      &:before {
        background-image: linear-gradient(0.01deg, rgb(245, 95, 62) 0.01%, rgb(255, 136, 51) 99.99%);
        border-radius: 2px;
        content: ' ';
        display: block;
        height: 100%;
        position: absolute;
        transform: translateX(-50%);
        width: 4px;
        left: 2px;
        top: 0px;
      }
    `,
  };
};

export default titleRowStyles;
