import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export default function styles(theme: GrafanaTheme2) {
  return {
    wrapper: css`
      width: 100%;
      height: 100%;
    `,
    container: css`
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      text-align: center;
      align-items: center;
      max-width: 550px;
      margin: 80px auto;

      h3 {
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.colors.text.primary};
        font-size: 28px;
      }
    `,
    description: css`
      font-weight: ${theme.typography.fontWeightLight};
      color: ${theme.colors.text.secondary};
      font-size: 14px;
    `,
    button: css`
      margin-top: ${theme.spacing(3)};
    `,
    loading: css`
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      padding-top: 100px;
    `,
  };
}
