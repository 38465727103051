import { useStyles2 } from '@grafana/ui';
import React from 'react';
import getStyles from '../Config.styles';
import { DeploymentConfig } from '../../../../api/config';

type Props = {
  deploymentConfigValues: DeploymentConfig;
};

const AppTelemetryReceiverInfo = ({ deploymentConfigValues }: Props) => {
  const styles = useStyles2(getStyles);
  return (
    <>
      <h3>Configure Application Instrumentation</h3>
      <p>
        After the Helm chart is deployed, you will need to configure your application instrumentation to send telemetry
        data to Grafana Alloy using one of these addresses:
      </p>
      <ul className={styles.innerList}>
        {deploymentConfigValues.features.otelReceivers && (
          <>
            <li>
              <p>
                OTLP/gRPC endpoint:{' '}
                <code>
                  http://{deploymentConfigValues.releaseName}-alloy.{deploymentConfigValues.namespace}
                  .svc.cluster.local:4317
                </code>
              </p>
            </li>
            <li>
              <p>
                OTLP/HTTP endpoint:{' '}
                <code>
                  http://{deploymentConfigValues.releaseName}-alloy.{deploymentConfigValues.namespace}
                  .svc.cluster.local:4318
                </code>
              </p>
            </li>
          </>
        )}
        {deploymentConfigValues.features.zipkinReceiver && (
          <li>
            <p>
              Zipkin endpoint:{' '}
              <code>
                {deploymentConfigValues.releaseName}-alloy.{deploymentConfigValues.namespace}
                .svc.cluster.local:9411
              </code>
            </p>
          </li>
        )}
      </ul>
    </>
  );
};

export default React.memo(AppTelemetryReceiverInfo);
