import React from 'react';
import UninstallIntegrationButton from 'components/UninstallIntegrationButton';
import UpdateIntegrationButton from 'components/UpdateIntegrationButton/UpdateIntegrationButton';

const BackendInstallation = () => (
  <>
    <h3>Backend Installation</h3>
    <p>Install pre-made alert rules and recording rules to this Grafana instance.</p>
    <UninstallIntegrationButton />
    <UpdateIntegrationButton />
  </>
);

export default React.memo(BackendInstallation);
