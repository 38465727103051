import React from 'react';
import NodesList from './NodesList';
import { NodeQueries } from 'queries';
import NoDataFlowing from 'components/NoDataFlowing/NoDataFlowing';
import { NO_DATA_FLOWING_MAP } from '../../constants';
import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneObjectBase } from '@grafana/scenes';
import ClusterLoader from 'components/ClusterLoader/ClusterLoader';

const NodesListPage = () => {
  return (
    <div style={{ width: '100%' }}>
      <NoDataFlowing message={NO_DATA_FLOWING_MAP.nodes}>
        <ClusterLoader>
          {(clusters: string[]) => (
            <NodesList
              withFilters
              queries={(currentCluster: string[]) => [
                NodeQueries.NodesCondition(currentCluster?.join('|')),
                NodeQueries.PodsPerNode(currentCluster?.join('|')),
                NodeQueries.NodeInfo(currentCluster?.join('|')),
                NodeQueries.NodeAlertsCount(currentCluster?.join('|')),
              ]}
              defaultClusters={clusters}
            />
          )}
        </ClusterLoader>
      </NoDataFlowing>
    </div>
  );
};

export default NodesListPage;

export class NodesListPageScene extends SceneObjectBase {
  static Component = NodesListPage;
}

export function getNodesListPageScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new NodesListPageScene({}),
        }),
      ],
    }),
  });
}
