import { ThresholdsMode } from '@grafana/data';

export const timeseriesConfig = {
  defaults: {
    color: {
      mode: 'palette-classic',
    },
    custom: {
      axisBorderShow: false,
      axisCenteredZero: false,
      axisColorMode: 'text',
      axisGridShow: false,
      axisLabel: '',
      axisPlacement: 'left',
      barAlignment: 0,
      drawStyle: 'line',
      fillOpacity: 20,
      gradientMode: 'hue',
      hideFrom: {
        legend: false,
        tooltip: false,
        viz: false,
      },
      insertNulls: false,
      lineInterpolation: 'smooth',
      lineStyle: {
        fill: 'solid',
      },
      lineWidth: 2,
      pointSize: 5,
      scaleDistribution: {
        type: 'linear',
      },
      showPoints: 'never',
      spanNulls: false,
      stacking: {
        group: 'A',
        mode: 'none',
      },
      thresholdsStyle: {
        mode: 'off',
      },
    },
    fieldMinMax: false,
    mappings: [],
    thresholds: {
      mode: ThresholdsMode.Absolute,
      steps: [
        {
          color: 'green',
          value: 0,
        },
        {
          color: 'red',
          value: 80,
        },
      ],
    },
    unitScale: true,
  },
  overrides: [
    {
      matcher: {
        id: 'byRegexp',
        options: 'info|notify|proactive|low',
      },
      properties: [
        {
          id: 'color',
          value: {
            fixedColor: 'text',
            mode: 'fixed',
          },
        },
      ],
    },
    {
      matcher: {
        id: 'byRegexp',
        options: 'warn|warning',
      },
      properties: [
        {
          id: 'color',
          value: {
            fixedColor: 'orange',
            mode: 'fixed',
          },
        },
      ],
    },
    {
      matcher: {
        id: 'byRegexp',
        options: 'critical',
      },
      properties: [
        {
          id: 'color',
          value: {
            fixedColor: 'red',
            mode: 'fixed',
          },
        },
      ],
    },
  ],
};

export const tableConfig = () => {
  return {
    defaults: {
      custom: {
        filterable: true,
      },
    },
    overrides: [
      {
        matcher: {
          id: 'byRegexp',
          options: '/workload_type|job|encoded_cluster/',
        },
        properties: [
          {
            id: 'custom.hidden',
            value: true,
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'alertname',
        },
        properties: [
          {
            id: 'links',
            value: [
              {
                title: 'Go to alert',
                url:
                  '/alerting/list?view=state&search=' +
                  [
                    'type:alerting',
                    'datasource:"${datasource:percentencode}"',
                    'rule:${__data.fields.alertname:percentencode}',
                    'label:cluster=${__data.fields.cluster:percentencode}',
                  ].join('%20'),
                targetBlank: true,
              },
            ],
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'cluster',
        },
        properties: [
          {
            id: 'links',
            value: [
              {
                title: 'Go to cluster',
                url: '/a/grafana-k8s-app/navigation/cluster/${__data.fields.encoded_cluster}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
              },
            ],
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'node',
        },
        properties: [
          {
            id: 'links',
            value: [
              {
                title: 'Go to node',
                url: '/a/grafana-k8s-app/navigation/nodes/${__data.fields.encoded_cluster}/${__data.fields.node:percentencode}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
              },
            ],
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'namespace',
        },
        properties: [
          {
            id: 'links',
            value: [
              {
                title: 'Go to namespace',
                url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
              },
            ],
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'pod',
        },
        properties: [
          {
            id: 'links',
            value: [
              {
                title: 'Go to pod',
                url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}/${__data.fields.workload_type}/${__data.fields.workload}/${__data.fields.pod}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
              },
            ],
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'container',
        },
        properties: [
          {
            id: 'links',
            value: [
              {
                title: 'Go to container',
                url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}/${__data.fields.workload_type}/${__data.fields.workload}/${__data.fields.pod}/${__data.fields.container}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
              },
            ],
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'workload',
        },
        properties: [
          {
            id: 'links',
            value: [
              {
                title: 'Go to ${__data.fields.workload_type}',
                url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}/${__data.fields.workload_type}/${__data.fields.workload}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
              },
            ],
          },
        ],
      },
    ],
  };
};
