import React from 'react';
import { Icon, useStyles2 } from '@grafana/ui';

import platformSelectorStyles from './PlatformSelector.styles';
import { cx } from '@emotion/css';

interface Props {
  img: string;
  name: string;
  subtitle?: string;
  onClick: () => void;
  isSelected: boolean;
}

const PlatformSelector = ({ img, name, subtitle, onClick, isSelected }: Props) => {
  const styles = useStyles2(platformSelectorStyles);

  return (
    <div
      className={cx(styles.wrapper, isSelected && styles.selected)}
      onClick={onClick}
      data-testid="integration-card-wrapper"
    >
      <div className={styles.integrationWrapper}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={img} />
        </div>
        <div>
          <div className={styles.name}>{name}</div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      </div>
      {isSelected && (
        <div className={styles.installedWrapper}>
          <Icon name="check" />
        </div>
      )}
    </div>
  );
};

export default PlatformSelector;
