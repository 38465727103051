import { SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { QueryType } from '../GenericDetailOptimization/scene';
import { getGenericQueryRunner } from 'helpers/scenes';
import { ExplorablePanel } from '../ExplorablePanel';
import { energyByResourceConfig, energyBySourceConfig } from './config';
import { transformData } from 'components/HomeScene/queryRunners';
import { EnergyState } from './Energy';

export const energyBySourceRow = (datasource: DataSourceRef, queries: QueryType) => {
  return new SceneFlexLayout({
    minHeight: 400,
    children: [
      new SceneFlexItem({
        body: new ExplorablePanel(
          energyBySourceConfig({
            title: 'Energy Usage (1h)',
            runner: getGenericQueryRunner(
              datasource,
              queries.total,
              {
                instant: false,
                range: true,
                interval: '',
                refId: 'total',
                legendFormat: 'Total',
              },
              [
                {
                  expr: queries.package,
                  instant: false,
                  range: true,
                  interval: '',
                  refId: 'package',
                  legendFormat: 'Package',
                },
                {
                  expr: queries.dram,
                  instant: false,
                  range: true,
                  interval: '',
                  refId: 'dram',
                  legendFormat: 'DRAM',
                },
                {
                  expr: queries.gpu,
                  instant: false,
                  range: true,
                  interval: '',
                  refId: 'gpu',
                  legendFormat: 'GPU',
                },
                {
                  expr: queries.other,
                  instant: false,
                  range: true,
                  interval: '',
                  refId: 'other',
                  legendFormat: 'Other',
                },
              ]
            ),
          })
        ),
      }),
    ],
  });
};

export const energyByResourceRow = (
  datasource: DataSourceRef,
  type: EnergyState['type'],
  resources: string[],
  queries: QueryType
) => {
  const panels = [];
  for (const resource of resources) {
    panels.push(
      new SceneFlexItem({
        body: new ExplorablePanel(
          energyByResourceConfig(
            {
              title: `Energy Usage by ${resource} (1h)`,
              runner: getGenericQueryRunner(
                datasource,
                queries[resource],
                {
                  instant: false,
                  range: true,
                  interval: '',
                  legendFormat: `{{${resource.toLowerCase()}}}`,
                  transformations: [transformData],
                },
                []
              ),
            },
            resource
          )
        ),
      })
    );
  }

  return new SceneFlexLayout({
    minHeight: 400,
    children: panels,
  });
};
