import React, { useState } from 'react';
import { Alert, Button, Icon, Modal, Spinner, useStyles2 } from '@grafana/ui';
import useRudderstack from 'hooks/useRudderstack';
import { FetchStatus, RudderstackEvents } from 'enums';
import { config } from '@grafana/runtime';
import useIntegrationStore from 'store/integration';
import { shallow } from 'zustand/shallow';
import { CurrentUserDTO } from '@grafana/data';
import Message from '../Message/Message';

import getStyles from './UpdateIntegrationButton.styles';

interface Props {
  className?: string;
}

export default function UpdateIntegrationButton({ className }: Props) {
  const [integrationInstalled, updateStatus, integration, updateIntegration] = useIntegrationStore(
    (state) => [state.integrationInstalled, state.updateStatus, state.integration, state.updateIntegration],
    shallow
  );
  const styles = useStyles2(getStyles);
  const [showModal, setShowModal] = useState(false);
  const trackRudderStackEvent = useRudderstack();
  const user: CurrentUserDTO = config.bootData.user;

  if (updateStatus === FetchStatus.Success) {
    return (
      <Message status="success" className={styles.updateSuccess}>
        Integration was successfully updated.
      </Message>
    );
  }

  if (!integrationInstalled || integration?.version === integration?.installation?.version) {
    return null;
  }

  return (
    <>
      <Modal
        className={styles.overrideModal}
        title="Update alert rules and recording rules"
        isOpen={showModal}
        onDismiss={() => setShowModal(false)}
      >
        <div className={styles.modalContent}>
          <Alert title="Dashboard deprecation" severity="warning">
            The backend 2.0.0 update will remove the preconfigured dashboards as their features have been incorporated
            into the Kubernetes Monitoring GUI.
          </Alert>
          <p>
            We are constantly pushing updates to our integrations to add new default content and improve performance.
          </p>
          <div className={styles.alertText}>
            <Icon className={styles.icon} name="info-circle" size="lg" />
            <span>There may be downstream effects of updating this integration:</span>
          </div>
          <p className={styles.text}>
            Updating this integration will overwrite any custom changes that have been made directly to the default
            dashboards, alerts, and recording rules. If you wish to keep any custom changes made to the included
            dashboards and alerts, make a copy of these before updating. Otherwise, if you used the data from this
            integration to create your own separate custom dashboards, alerts, and recording rules, there will be no
            effect.{' '}
          </p>
          <p className={styles.text}>
            <a
              href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/release-notes/#backend-release-notes"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.a}
            >
              Release notes.
            </a>
          </p>
          <div className={styles.buttons}>
            {updateStatus === FetchStatus.Error && (
              <Message status="error" className={styles.updateSuccess}>
                Something went wrong, please try again
              </Message>
            )}
            <div>
              <Button
                variant="primary"
                disabled={updateStatus === FetchStatus.Fetching}
                onClick={() => {
                  trackRudderStackEvent(RudderstackEvents.IntegrationUpdate, {});
                  updateIntegration();
                }}
              >
                {updateStatus === FetchStatus.Fetching && <Icon className={styles.icon} name="fa fa-spinner" />}
                Update
              </Button>
              <Button
                variant="secondary"
                disabled={updateStatus === FetchStatus.Fetching}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div className={styles.buttonWrapper}>
        <Message status="warning" className={styles.updateAlert}>
          There is a newer version of the backend: {integration?.version}
        </Message>
        <Button
          variant="primary"
          type="button"
          className={className}
          disabled={updateStatus === FetchStatus.Fetching || user.orgRole !== 'Admin'}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {updateStatus === FetchStatus.Fetching && (
            <>
              <Spinner />
              &nbsp;
            </>
          )}
          Update
        </Button>
      </div>
    </>
  );
}
