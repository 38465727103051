import { lastValueFrom } from 'rxjs';

import { BackendSrvRequest, config, getBackendSrv } from '@grafana/runtime';
import { rangeUtil, RawTimeRange } from '@grafana/data';

export function getIntegrationMetrics<T>(query: string, selectedPromName: string, range?: RawTimeRange, step?: string) {
  let parsedRange;
  if (range) {
    parsedRange = rangeUtil.convertRawToRange(range);
  }

  const url = config.datasources[selectedPromName]?.url;

  const endpoint = step ? '/api/v1/query_range' : '/api/v1/query';

  const q = {
    query,
    instant: step ? false : true,
    step: step,
    range: step ? true : false,
    start: parsedRange ? parsedRange.from.unix() : undefined,
    end: parsedRange ? parsedRange.to.unix() : undefined,
  };

  const options: BackendSrvRequest = {
    url: `${url}${endpoint}`,
    method: 'POST',
    headers: { ['Content-Type']: 'application/x-www-form-urlencoded' },
    data: q,
  };

  return lastValueFrom(getBackendSrv().fetch<any>(options));
}
