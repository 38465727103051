import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { Pod, UsageCostErrors } from 'types';
import { PodLogLink } from 'components/workloads/WorkloadDetail/workloadDetailColumns';
import CopyObjectName from 'components/Table/CopyObjectName';
import { getAllColumns } from 'helpers/usageCostHelpers';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';
import { cx } from '@emotion/css';
import { getPodhealthStatus, podHealthToColorText } from 'components/workloads/podHealthUtils';
import { config } from '@grafana/runtime';
import { encodeUrlString } from 'helpers/helpers';
import { assertsColumns } from 'components/Asserts/helpers';
import alertColumn from 'components/Table/alertColumn';

export const nodePodsColumns = (
  styles: any,
  path: string,
  costView: boolean,
  errors: UsageCostErrors,
  hasDateChanged: boolean,
  prometheusName: string,
  usageLoading?: boolean,
  costLoading?: boolean
): Array<TableColumn<Pod>> => {
  const columns = [
    ...assertsColumns<Pod>(),
    {
      name: 'Pod Name',
      selector: (row: Pod) => row.pod,
      width: '400px',
      // eslint-disable-next-line react/display-name
      cell: (row: Pod) => {
        let subPath = `${row.workload_type}/${row.workload}`;
        if (row.workload_type === 'staticpod' || row.workload_type === 'pod') {
          subPath = `_/_`;
        }
        const podPath = `/a/${path}/navigation/namespace/${encodeUrlString(row.cluster)}/${row.namespace}/${subPath}/${
          row.pod
        }`;
        return <CopyObjectName link={podPath} value={row.pod} />;
      },
      sortable: true,
      sortFunction: (a: Pod, b: Pod) => a.pod.localeCompare(b.pod, undefined, { numeric: true }),
    },
  ];

  const { currentColumns, extraColumns } = getAllColumns<Pod>(
    costView,
    columns,
    [
      {
        name: 'Workload',
        selector: (row: Pod) => row.workload,
        sortable: true,
        sortFunction: (a: Pod, b: Pod) => a.workload.localeCompare(b.workload, undefined, { numeric: true }),
        // eslint-disable-next-line react/display-name
        cell: (row: Pod) => {
          if (row.workload_type === 'staticpod' || row.workload_type === 'pod') {
            return 'None';
          }
          const workloadPath = `/a/${path}/navigation/namespace/${encodeUrlString(row.cluster)}/${row.namespace}/${
            row.workload_type
          }/${row.workload}`;
          return (
            <QueryParamsLink
              className={cx(styles.link, styles.longCell)}
              to={workloadPath}
              label={row.workload || ''}
            />
          );
        },
      },
      {
        name: 'Namespace',
        selector: (row: Pod) => row.namespace,
        sortable: true,
        sortFunction: (a: Pod, b: Pod) => a.namespace.localeCompare(b.namespace, undefined, { numeric: true }),
        cell: (row: Pod) => {
          const namespacePath = `/a/${path}/navigation/namespace/${encodeUrlString(row.cluster)}/${row.namespace}`;
          return <QueryParamsLink className={styles.link} to={namespacePath} label={row.namespace} />;
        },
      },
      {
        name: 'Status',
        selector: (row: Pod) => row.phase,
        width: '120px',
        sortable: true,
        // eslint-disable-next-line react/display-name
        cell: (row: Pod) => (
          <>
            <div
              style={{
                color: podHealthToColorText(config)[getPodhealthStatus(row)],
              }}
            >
              {row.phase}
            </div>
          </>
        ),
      },
    ],
    [
      {
        name: 'Logs',
        selector: (row: Pod) => row.pod,
        // eslint-disable-next-line react/display-name
        cell: (row: Pod) => <PodLogLink pod={row.pod} namespace={row.namespace} cluster={row.cluster} />,
      },
      alertColumn<Pod>({ hasDateChanged, prometheusName }),
    ],
    errors,
    usageLoading,
    costLoading
  );

  return [...currentColumns, ...extraColumns];
};
