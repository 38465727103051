import { useStyles2 } from '@grafana/ui';
import React from 'react';
import getStyles from '../Config.styles';

const ConfigPrerequisites = () => {
  const styles = useStyles2(getStyles);
  return (
    <>
      <h3>Prerequisites</h3>
      <p>Before you begin, make sure you have the following available on your local machine:</p>
      <ul className={styles.innerList}>
        <li>
          <p>
            The <code>kubectl</code> command-line tool. To learn how install <code>kubectl</code>, refer to
            <a href="https://kubernetes.io/docs/tasks/tools/#kubectl" target="_blank" rel="noopener noreferrer">
              kubectl
            </a>
            from the Kubernetes docs.
          </p>
        </li>
        <li>
          <p>
            The <code>helm</code> command-line tool for managing Helm charts. To learn how to install <code>helm</code>,
            refer to
            <a href="https://helm.sh/docs/intro/install/" target="_blank" rel="noopener noreferrer">
              Installing Helm
            </a>
            from the Helm docs.
          </p>
        </li>
      </ul>
    </>
  );
};

export default React.memo(ConfigPrerequisites);
