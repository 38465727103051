import { RelativeRange } from 'store/timeRange';

export type WorkbenchEntityTypes = 'Node' | 'Namespace' | 'Service' | 'KubeCluster' | 'Pod';

export interface WorkbenchEntity {
  name: string;
  type: WorkbenchEntityTypes;
  scope: {
    site: string;
    namespace: string;
    env: string;
  };
}

const isRelativeRangeEqual = (relativeRange: RelativeRange) => {
  return relativeRange?.from === relativeRange?.to;
};

const buildLinkToWorkbench = (
  workbenchEntities: WorkbenchEntity[],
  type: WorkbenchEntityTypes,
  relativeRange?: RelativeRange | null
): string => {
  const params = new URLSearchParams();
  // RCA Workbench needs 'from' param to be different from 'to' param
  // so we skip to default if its something like: now-1M/M, now-1M/M
  if (relativeRange) {
    params.append('start', relativeRange.from);
    params.append('end', isRelativeRangeEqual(relativeRange) ? 'now' : relativeRange.to);
  } else {
    params.append('start', 'now-24h');
    params.append('end', 'now');
  }
  workbenchEntities?.forEach?.((entity, index) => {
    params.append(`we[${index}][name]`, entity.name);
    params.append(`we[${index}][tp]`, entity.type);
    params.append(`we[${index}][sc][site]`, entity.scope.site);
    if (type === 'Service' || type === 'Pod') {
      params.append(`we[${index}][sc][ns]`, entity.scope.namespace);
    }
    params.append(`we[${index}][sc][env]`, entity.scope.env);
  });
  params.append('view', 'BY_ENTITY');
  return `/a/grafana-asserts-app/assertions?${params.toString()}`;
};

export default buildLinkToWorkbench;
