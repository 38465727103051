import { useStyles2 } from '@grafana/ui';
import React, { useEffect, useState } from 'react';
import getStyles from '../Config.styles';
import { Clipboard } from '../../../Clipboard';
import { cx } from '@emotion/css';
import { DeploymentConfig } from '../../../../api/config';
import { HostedDataDetails } from '../../../../api/accessPolicyToken';
import { buildHelmChartInstructions } from '../../../../helpers/helmChartInstructionBuilder';
import { Platforms } from 'enums';

type Props = {
  deploymentConfigValues: DeploymentConfig;
  hostedDetails?: HostedDataDetails;
  accessPolicyToken: string;
  platform: Platforms;
};

const ConfigHelmChartInstructions = ({ deploymentConfigValues, hostedDetails, accessPolicyToken, platform }: Props) => {
  const styles = useStyles2(getStyles);

  const [instructions, setInstructions] = useState<string>('');

  useEffect(() => {
    setInstructions(buildHelmChartInstructions(deploymentConfigValues, accessPolicyToken, platform, hostedDetails));
  }, [deploymentConfigValues, accessPolicyToken, hostedDetails, platform]);

  return (
    <>
      <p>
        Copy the following command to deploy the Kubernetes Monitoring Helm chart to your cluster. To explore more
        options, see the
        <a
          href="https://github.com/grafana/k8s-monitoring-helm/tree/main/charts/k8s-monitoring"
          target="_blank"
          rel="noopener noreferrer"
        >
          chart documentation
        </a>
        .
      </p>
      <Clipboard resetTime={1000} content={instructions} className={cx(styles.clipboard, styles.config)} />
    </>
  );
};

export default React.memo(ConfigHelmChartInstructions);
