import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingPlaceholder, MultiSelect, useStyles2 } from '@grafana/ui';

import { config } from '@grafana/runtime';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import Table from 'components/Table/Table';
import { PluginMetaContext } from 'context/PluginMetaContext';
import { RudderstackEvents, TableType } from 'enums';
import { QueryCollection, getExploreUrl } from 'helpers/helpers';
import useRudderStack from 'hooks/useRudderstack';
import usePods from 'hooks/usePods';
import { Queries, ResourceQueries } from 'queries';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import { PluginExtensionLinkWorkloadContext, PluginExtensionPoints, Pod } from 'types';
import BreadcrumbTitle from '../BreadCrumbTitle';
import workloadDetailStyles from './WorkloadDetail.styles';
import WorkloadMetadata from './WorkloadMetadata';
import { PodLogLink, workloadDetailColumns } from './workloadDetailColumns';
import useTimeRangeStore from 'store/timeRange';
import useQueries from 'hooks/useQueries';
import { ExpanderComponentProps } from 'react-data-table-component';
import PredictionModal from 'components/PredictionModal/PredictionModal';
import ExpandRow from 'components/Table/TableExpandRow';
import { pushFaroCount } from 'helpers/faro';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { getWorkloadDetailOptimization } from 'components/scenes/WorkloadDetailOptimization/WorkloadDetailOptimization';
import OutlierModal from 'components/OutlierModal/OutlierModal';
import useCostUsage from 'hooks/useCostUsage';
import { TableView } from 'store/filters';
import FiltersRow from 'components/FiltersRow/FiltersRow';
import O11yButton from 'components/O11yButton';
import { SelectableValue } from '@grafana/data';
import useAssertsStore from 'store/asserts';
import { selectableRowDisabled, selectableRowSelected } from 'components/Asserts/helpers';
import AssertsWorkbenchButton from 'components/Asserts/AssertsWorkbenchButton';
import ExtensionLinks from 'components/ExtensionLinks/ExtensionLinks';
import { EntityAssertionsWidget } from 'components/EntityAssertionsWidget/EntityAssertionsWidget';
import { dateTimeFromTimestamp } from '../podHealthUtils';
import { WorkloadTypes } from './metadataUtils';

const detailQueryMap = (cluster: string, namespace: string, workload: string, workloadType: string) => {
  const m: { [key: string]: string } = {
    deployment: Queries.Deployment(cluster, namespace, workload),
    statefulset: Queries.StatefulSet(cluster, namespace, workload),
    replicaset: Queries.ReplicaSet(cluster, namespace, workload),
    daemonset: Queries.DaemonSet(cluster, namespace, workload),
    job: Queries.Job(cluster, namespace, workload),
  };

  return m[workloadType];
};

const TableActions: React.FC<ExpanderComponentProps<Pod>> = ({ data }) => {
  return (
    <ExpandRow
      actions={
        <>
          <PodLogLink pod={data.pod} namespace={data.namespace} cluster={data.cluster} />
          <PredictionModal
            buttonText="Predict Pod Memory Usage"
            title="Memory Usage Prediction Model"
            query={ResourceQueries.PodMemUsage(data.cluster, data.pod)}
            name="Pod Memory usage"
            metric="node_namespace_pod_container:container_memory_working_set_bytes"
            axisLabel="Bytes"
            hyperParamsUpperLimit={1}
          />
          <PredictionModal
            buttonText="Predict Pod CPU Usage"
            title="CPU Usage Prediction Model"
            query={ResourceQueries.PodCpuUsageVsRequested(data.namespace, data.cluster, data.pod)}
            name="Pod CPU usage"
            metric="node_cpu_seconds_total"
            hyperParamsUpperLimit={10}
          />
        </>
      }
      data={[
        { title: 'pod ip', value: data.pod_ip },
        { title: 'waiting reason', value: data.waiting_reason || 'none' },
        { title: 'uid', value: data.uid },
        { title: 'last seen', value: dateTimeFromTimestamp(data.timestamp.toString()) },
      ]}
    />
  );
};

const WorkloadDetail = ({ model }: SceneComponentProps<WorkloadDetailScene>) => {
  const { cluster, namespace, workload, workloadType } = model.useState();
  const styles = useStyles2(workloadDetailStyles);
  const { location, push } = useHistory();
  const prometheusName = useDatasourceStore(prometheusSelector);
  const prometheusDatasource = config.datasources[prometheusName];
  const lokiName = useDatasourceStore(lokiSelector);
  const lokiDatasource = config.datasources[lokiName];
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);
  const [assertsEnabled, setSelectedAssertsRows, selectedAssertsRows, toggledClearRows] = useAssertsStore((state) => [
    state.assertsEnabled,
    state.setSelectedAssertsRows,
    state.selectedAssertsRows,
    state.toggledClearRows,
  ]);
  const meta = useContext(PluginMetaContext);
  const [podsFilter, setPodsFilter] = useState<string[]>([]);

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const trackRudderstackEvent = useRudderStack();

  const {
    loading,
    validating,
    pods: rawPods,
    alertMap,
    firstLoad,
  } = usePods(cluster, namespace, workload, undefined, workloadType);

  const [filteredPods, podOpts] = useMemo(() => {
    if (rawPods) {
      let p = rawPods;
      if (rawPods?.length) {
        pushFaroCount('workload-pod-count', rawPods.length);
      }

      const opts = rawPods
        ?.map?.(({ pod }) => ({ label: pod, value: pod }))
        .sort((a, b) => a.label.localeCompare(b.label));

      if (podsFilter?.length) {
        p = rawPods?.filter?.(({ pod }) => {
          return podsFilter.some((item) => pod.includes(item));
        });
      }

      return [p, opts];
    } else {
      // Return nothing to make sure we show loading while data isn't back yet
      return [];
    }
  }, [rawPods, podsFilter]);

  const alertTotal = useMemo(() => {
    let total = 0;
    alertMap?.forEach?.((value) => {
      total += value;
    });
    return total;
  }, [alertMap]);

  // Query for specific details for this workload
  const detailQuery = detailQueryMap(cluster, namespace, workload, workloadType);
  const { data } = useQueries({
    queries: [{ query: detailQuery }],
    range: { from: range.from.valueOf(), to: range.to.valueOf() },
    step: '10m',
    refresh: false,
    maxDataPoints: 1,
    convertToRedableMetrics: true,
  });

  const scene = useMemo(
    () =>
      getWorkloadDetailOptimization({
        cluster,
        namespace,
        workload,
        workloadType,
        datasource: prometheusDatasource,
        prometheusName,
        lokiName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [cluster, namespace, workload, prometheusName, lokiName, prometheusDatasource, relativeRange, workloadType]
  );

  const podList = useMemo(() => rawPods?.map?.((item) => item.pod)?.join('|'), [rawPods]);

  const {
    usageLoading,
    usageCostTableData,
    costLoading,
    firstCostLoad,
    firstUsageLoad,
    tableView,
    onTableSort,
    sortColumn,
    exploreUrl,
  } = useCostUsage<Pod>({
    type: 'pod',
    initialData: filteredPods,
    usageQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.WorkloadPodUsage, [
      cluster,
      namespace,
      podList!,
    ]),
    costQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.WorkloadPodCost, [
      cluster,
      namespace,
      podList!,
    ]),
    withDependencies: true,
    dependencies: podList,
  });

  const podsData = usageCostTableData.data;

  const handlePodsFilterUpdate = useCallback((filters: SelectableValue[] = []) => {
    const filterArray = filters?.map?.((filter) => filter.value);
    setPodsFilter(filterArray);
  }, []);

  const handleSelectedRowsChange = useCallback(
    ({ selectedRows }: { selectedRows: Pod[] }) => {
      setSelectedAssertsRows(selectedRows);
    },
    [setSelectedAssertsRows]
  );

  const selectableRowsCallback = useMemo(() => selectableRowSelected<Pod>('pod'), []);

  const extensionContext: PluginExtensionLinkWorkloadContext = {
    timeRange: range,
    workload,
    workloadType: workloadType as WorkloadTypes,
    cluster,
    namespace,
    datasources: {
      prometheus: { name: prometheusName, uid: prometheusDatasource.uid },
      loki: { name: lokiName, uid: lokiDatasource.uid },
    },
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 16 }}
        >
          <div className={styles.titleWrapper}>
            <BreadcrumbTitle />
            <ExploreButton
              href={getExploreUrl(prometheusName, detailQuery, exploreRange, true)}
              label="Explore details"
              eventName={RudderstackEvents.ExploreWorkload}
            />
            <OutlierModal
              items={filteredPods?.length || 0}
              buttonText="Detect Outlier CPU Usage amongst Pods"
              title="Pod CPU outlier detection"
              axisLabel="Outlier value"
              query={ResourceQueries.WorkloadPodCpuUsageVsRequested(namespace, cluster, workload)}
              timeRange={range}
            />
            <O11yButton type="workload" />
            <ExtensionLinks
              context={extensionContext}
              extensionPointId={PluginExtensionPoints.WorkloadAction}
              showTooltip={false}
            />
            <EntityAssertionsWidget name={workload} type={'Service'} />
          </div>
        </div>
        <div style={{ marginTop: 16 }}>
          <WorkloadMetadata
            type={workloadType as WorkloadTypes}
            details={data[0]}
            loading={loading && firstLoad}
            pods={rawPods?.length || 0}
            namespace={namespace}
            cluster={cluster}
            alertTotal={alertTotal}
            workload={workload}
            timeRange={range}
            prometheusName={prometheusName}
          />
        </div>
        <div className={styles.sectionSpacing} style={{ minHeight: 200 }}>
          <h3 className={styles.sectionTitle}>Workload optimization</h3>
          <div className={styles.sceneWrapper}>
            <scene.Component model={scene} />
          </div>
        </div>
        <div className={styles.podList}>
          <h3 style={{ marginBottom: 16 }}>Pods</h3>
          <FiltersRow
            visibleFilters={
              <>
                <MultiSelect
                  width={25}
                  isClearable
                  placeholder="Filter by pod"
                  options={podOpts}
                  value={podsFilter}
                  onChange={handlePodsFilterUpdate}
                />
                <AssertsWorkbenchButton label="pods" type="Pod" nameKey="pod" relativeRange={relativeRange} />
              </>
            }
            extraButtons={<ExploreButton href={exploreUrl} label="Explore pods" size="md" style={{ marginRight: 0 }} />}
          />
          {(!rawPods && validating) || ((loading || usageLoading || costLoading) && firstLoad) ? (
            <LoadingPlaceholder text="Loading pods" />
          ) : (
            <Table<Pod>
              key={sortColumn}
              withNestedHeader
              id={TableType.Workload}
              name={`${cluster}-${namespace}-${workload}`}
              data={podsData || []}
              columns={workloadDetailColumns(
                styles,
                meta.id,
                location,
                tableView === TableView.Cost,
                usageCostTableData.errors,
                hasDateChanged,
                prometheusName,
                usageLoading && firstUsageLoad,
                costLoading && firstCostLoad
              )}
              noDataText="No pods found"
              expandableRows
              expandableComponent={TableActions}
              selectable={assertsEnabled}
              onSelectedRowsChange={handleSelectedRowsChange}
              selectableRowDisabled={(row) => selectableRowDisabled<Pod>(row, selectedAssertsRows)}
              selectableRowSelected={selectableRowsCallback}
              clearSelectedRows={toggledClearRows}
              onRowClicked={(row: Pod) => {
                trackRudderstackEvent(RudderstackEvents.PodListClick, {});
                push(`${location.pathname}/${encodeURIComponent(row.pod)}`);
              }}
              onSort={onTableSort}
            />
          )}
        </div>
      </div>
    </div>
  );
};

interface WorkloadDetailState extends SceneObjectState {
  cluster: string;
  namespace: string;
  workload: string;
  workloadType: string;
}

export class WorkloadDetailScene extends SceneObjectBase<WorkloadDetailState> {
  static Component = WorkloadDetail;
}

export function getWorkloadDetailAppScene(cluster: string, namespace: string, workload: string, workloadType: string) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new WorkloadDetailScene({ cluster, namespace, workload, workloadType }),
        }),
      ],
    }),
  });
}

export default WorkloadDetail;
