import { Button, useStyles2 } from '@grafana/ui';
import React, { useState } from 'react';
import { copyContent } from '../../helpers/helpers';
import _startCase from 'lodash/startCase';
import { breadcrumbStyles, titleType } from './BreadCrumbs';
import { config } from '@grafana/runtime';
import useDecodedParams from 'hooks/useDecodedParams';

const BreadcrumbTitle = () => {
  const styles = useStyles2(breadcrumbStyles);
  const [copied, setCopied] = useState(false);
  const [commandCopied, setCommandCopied] = useState(false);
  const { cluster, namespace, workload, podName, node } = useDecodedParams();
  const title = titleType(cluster, namespace, workload, podName, node);

  const titleMap = {
    pod: podName,
    workload: workload,
    namespace: namespace,
    node: node,
    cluster: cluster,
  };

  const copyTextMap = {
    pod: {
      command: `kubectl describe pods -n "${namespace}" "${podName}"`,
      copy: 'Copy kubectl describe for pod',
    },
    node: {
      command: `kubectl describe node "${node}"`,
      copy: 'Copy kubectl describe for node',
    },
  };

  const showCopyCommand = title === 'pod' || title === 'node';
  const topNavEnabled = config.featureToggles.topnav;

  return (
    <>
      {title && topNavEnabled && (
        <div className={styles.container}>
          <Button
            size="sm"
            icon={copied ? 'check' : 'clipboard-alt'}
            variant="secondary"
            style={{ marginRight: 8 }}
            onClick={() => copyContent(titleMap[title], setCopied)}
          >
            {copied ? 'Copied!' : `Copy name`}
          </Button>
          {showCopyCommand && (
            <Button
              style={{ marginRight: 8 }}
              size="sm"
              icon={commandCopied ? 'check' : 'clipboard-alt'}
              variant="secondary"
              onClick={() => copyContent(`${copyTextMap[title].command}`, setCommandCopied)}
            >
              {commandCopied ? 'Copied!' : copyTextMap[title].copy}
            </Button>
          )}
        </div>
      )}
      {title && !topNavEnabled ? (
        <div className={styles.containerFallback}>
          {title === 'namespace' ? (
            <h3 style={{ marginBottom: 0 }}>
              {_startCase(title)}: {titleMap[title]} in {cluster}
            </h3>
          ) : (
            <h3 style={{ marginBottom: 0 }}>
              {_startCase(title)}: {titleMap[title]}
            </h3>
          )}
          <Button
            size="sm"
            icon={copied ? 'check' : 'clipboard-alt'}
            variant="secondary"
            onClick={() => copyContent(titleMap[title], setCopied)}
            style={{ marginRight: 8 }}
          >
            {copied ? 'Copied!' : `Copy name`}
          </Button>
          {showCopyCommand && (
            <Button
              size="sm"
              icon={commandCopied ? 'check' : 'clipboard-alt'}
              variant="secondary"
              onClick={() => copyContent(`${copyTextMap[title].command}`, setCommandCopied)}
              style={{ marginRight: 8 }}
            >
              {commandCopied ? 'Copied!' : copyTextMap[title].copy}
            </Button>
          )}
        </div>
      ) : null}
    </>
  );
};

export default BreadcrumbTitle;
