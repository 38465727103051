import { decodeUrlString } from 'helpers/helpers';
import { useParams } from 'react-router-dom';
import { URLParams } from 'types';

function useDecodedParams() {
  const { ...params } = useParams<URLParams>();
  const decodedObj: URLParams = {
    cluster: '',
    namespace: '',
    podName: '',
    workload: '',
    workloadType: '',
    node: '',
  };

  Object.keys(params).forEach?.((key) => {
    // Only cluster requires to check for / on its name
    const decoded = key === 'cluster' ? decodeUrlString(params[key]) : params[key];
    decodedObj[key] = decoded;
  });

  return { ...decodedObj };
}

export default useDecodedParams;
