import { shallow } from 'zustand/shallow';
import { AppRootProps } from '@grafana/data';
import React, { useEffect } from 'react';
import { withErrorBoundary } from '@grafana/ui';
import { config } from '@grafana/runtime';

import PaginationProvider from 'context/PaginationProvider';
import useIntegrationStore from 'store/integration';
import useDatasourceStore from 'store/datasource';
import { AssertsQueries, Queries, SceneQueries } from 'queries';
import { PluginMetaContext } from 'context/PluginMetaContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { StoredParams } from 'types';
import { K8S_STORAGE_KEY, PLUGIN_ROOT_URL } from './constants';
import useQueryParams from 'hooks/useQueryParams';
import PluginSceneApp from 'components/scenes/Root/Root';
import { Redirect, Switch } from 'react-router-dom';
import useAssertsStore from 'store/asserts';

const Root = withErrorBoundary((props: AppRootProps) => {
  const { meta } = props;
  const { queryParams } = useQueryParams();
  const getAssertsEnabled = useAssertsStore((state) => state.getAssertsEnabled);

  // Pick each one to avoid re renders on the root component when other parts of the state changes
  const [
    isDataFlowing,
    isIntegrationAvailable,
    isIntegrationInstalled,
    getMachineLearningInstalled,
    isCostDataFlowing,
  ] = useIntegrationStore(
    (state) => [
      state.isDataFlowing,
      state.isIntegrationAvailable,
      state.isIntegrationInstalled,
      state.getMachineLearningInstalled,
      state.isCostDataFlowing,
    ],
    shallow
  );
  const [prometheusName, setInitialSources, setPrometheusName, setLokiName] = useDatasourceStore(
    (state) => [state.prometheusName, state.setInitialSources, state.setPrometheusName, state.setLokiName],
    shallow
  );
  const [storedParams, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);

  // Handle any datasource query params
  useEffect(() => {
    // Check if the query param is there and if its a valid option in the list
    const prom = queryParams.get('promName') || queryParams.get('var-datasource');
    const hasProm = prom && config.datasources.hasOwnProperty(prom);
    if (hasProm) {
      setPrometheusName(prom);
      setStoredParams({ promName: prom, namespace: '', cluster: [], node: [] }, { usePreviousValue: true });
    }

    const loki = queryParams.get('lokiName') || queryParams.get('var-loki');
    const hasLoki = loki && config.datasources.hasOwnProperty(loki);
    if (hasLoki) {
      setLokiName(loki);
      setStoredParams({ lokiName: loki }, { usePreviousValue: true });
    }

    // we only run this once when page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInitialSources();
    isIntegrationInstalled();
    isIntegrationAvailable();
    isDataFlowing(Queries.IsDataFlowing, storedParams.promName || prometheusName);
    isCostDataFlowing(SceneQueries.Config.OpenCostWithoutCluster, storedParams.promName || prometheusName);
    getAssertsEnabled(AssertsQueries.isEnabled, storedParams.promName || prometheusName);
    getMachineLearningInstalled();
  }, [
    isDataFlowing,
    isCostDataFlowing,
    isIntegrationAvailable,
    isIntegrationInstalled,
    prometheusName,
    storedParams,
    setInitialSources,
    getMachineLearningInstalled,
    getAssertsEnabled,
  ]);

  return (
    <PluginMetaContext.Provider value={meta}>
      <PaginationProvider>
        {/* Redirects created to support routes before scenes migration */}
        <Switch>
          <Redirect exact from={PLUGIN_ROOT_URL} to={`${PLUGIN_ROOT_URL}/home`} />
          {/* Redirect to home from old efficiency route */}
          <Redirect exact from={`${PLUGIN_ROOT_URL}/efficiency`} to={`${PLUGIN_ROOT_URL}/home`} />
          <Redirect exact from={`${PLUGIN_ROOT_URL}/navigation`} to={`${PLUGIN_ROOT_URL}/navigation/cluster`} />
          <Redirect exact from={`${PLUGIN_ROOT_URL}/cluster`} to={`${PLUGIN_ROOT_URL}/navigation/cluster`} />
          <Redirect
            exact
            from={`${PLUGIN_ROOT_URL}/cluster/:cluster`}
            to={`${PLUGIN_ROOT_URL}/navigation/cluster/:cluster`}
          />
          <Redirect
            exact
            from={`${PLUGIN_ROOT_URL}/cluster/:cluster/:namespace`}
            to={`${PLUGIN_ROOT_URL}/navigation/namespace/:cluster/:namespace`}
          />
          <Redirect
            exact
            from={`${PLUGIN_ROOT_URL}/cluster/:cluster/:namespace/:workloadType/:workload`}
            to={`${PLUGIN_ROOT_URL}/navigation/namespace/:cluster/:namespace/:workloadType/:workload`}
          />
          <Redirect
            exact
            from={`${PLUGIN_ROOT_URL}/cluster/:cluster/:namespace/:workloadType/:workload/:podName`}
            to={`${PLUGIN_ROOT_URL}/navigation/namespace/:cluster/:namespace/:workloadType/:workload/:podName`}
          />
          <Redirect
            exact
            from={`${PLUGIN_ROOT_URL}/nodes/:cluster/:node`}
            to={`${PLUGIN_ROOT_URL}/navigation/nodes/:cluster/:node`}
          />
          <Redirect exact from={`${PLUGIN_ROOT_URL}/nodes`} to={`${PLUGIN_ROOT_URL}/navigation/nodes`} />
          <Redirect
            exact
            from={`${PLUGIN_ROOT_URL}/configuration/settings`}
            to={`${PLUGIN_ROOT_URL}/configuration/cluster-config`}
          />
        </Switch>
        <PluginSceneApp />
      </PaginationProvider>
    </PluginMetaContext.Provider>
  );
});

export default Root;
