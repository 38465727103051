import React, { useEffect, useContext } from 'react';

import { Icon, Pagination, Select, Tooltip, useStyles2 } from '@grafana/ui';

import DataTable, { TableColumn } from 'react-data-table-component';
import { createTableTheme } from 'components/Table/tableTheme';
import { config } from '@grafana/runtime';
import { SelectableValue } from '@grafana/data';
import PaginationContext from 'context/PaginationContext';

import { PAGINATION_PAGE_SIZES, DEFAULT_TABLE_ID } from '../../constants';
import tableStyles from './Table.styles';
import { ExpanderComponentProps, SortOrder, TableProps } from 'react-data-table-component/dist/src/DataTable/types';

interface Props<T> extends TableProps<T> {
  key?: string;
  data: T[];
  columns: Array<TableColumn<T>>;
  noDataText: string;
  onRowClicked?(row: T): void;
  onSort?: (selectedColumn: TableColumn<T>, sortDirection: SortOrder, sortedRows: T[]) => void;
  pointerOnHover?: boolean;
  id?: string;
  name?: string;
  className?: string;
  dataTableProps?: any;
  defaultSortField?: string;
  expandableRows?: boolean;
  expandableComponent?: React.FC<ExpanderComponentProps<T>> | null;
  withNestedHeader?: boolean;
  selectable?: boolean;
  onSelectedRowsChange?: ({ selectedRows }: { selectedRows: T[] }) => void;
  selectableRowDisabled?: (row: T) => boolean;
  clearSelectedRows?: boolean;
  selectableRowSelected?: (row: T) => boolean;
}

const Table = <T extends unknown>({
  key,
  data,
  className,
  columns,
  noDataText,
  onRowClicked,
  onSort,
  pointerOnHover = true,
  id = DEFAULT_TABLE_ID,
  name = DEFAULT_TABLE_ID,
  dataTableProps = {},
  defaultSortField = undefined,
  expandableRows = false,
  expandableComponent = null,
  sortFunction,
  withNestedHeader = false,
  selectable = false,
  onSelectedRowsChange,
  selectableRowDisabled,
  clearSelectedRows,
  selectableRowSelected,
}: Props<T>) => {
  const { tracking, updatePageTracking, initPageTracking } = useContext(PaginationContext);
  const { page: currentPage, pageSize: currentPageSize } = tracking[id] ?? tracking[DEFAULT_TABLE_ID];

  const styles = useStyles2(tableStyles(withNestedHeader));
  createTableTheme(config.theme2.isDark);
  const expandColor = config.theme2.isDark ? 'white' : 'black';

  useEffect(() => {
    initPageTracking(name, id);
  }, [initPageTracking, name, id]);

  return (
    <div className={styles.container}>
      <DataTable
        key={key}
        className={className}
        paginationDefaultPage={currentPage}
        pagination={data?.length > currentPageSize}
        noDataComponent={noDataText}
        columns={columns}
        data={data}
        sortFunction={sortFunction}
        theme="grafana-dark"
        defaultSortFieldId={defaultSortField}
        highlightOnHover
        pointerOnHover={pointerOnHover}
        onRowClicked={(row: T) => (onRowClicked ? onRowClicked(row) : undefined)}
        onSort={onSort}
        paginationPerPage={currentPageSize}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableComponent}
        selectableRows={selectable}
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowDisabled={selectableRowDisabled}
        selectableRowsNoSelectAll={true}
        clearSelectedRows={clearSelectedRows}
        selectableRowSelected={selectableRowSelected}
        expandableIcon={{
          collapsed: (
            <Tooltip content={'Actions and additional data'} placement="top">
              <Icon size="lg" name="angle-right" style={{ color: expandColor }} />
            </Tooltip>
          ),
          expanded: <Icon size="lg" name="angle-down" style={{ color: expandColor }} />,
        }}
        paginationComponent={({ currentPage, rowCount, rowsPerPage, onChangePage, onChangeRowsPerPage }) => (
          <div className={styles.paginationWrapper}>
            <Select
              width={10}
              options={PAGINATION_PAGE_SIZES as SelectableValue[]}
              value={rowsPerPage}
              onChange={(option) => {
                onChangeRowsPerPage(option.value, currentPage);
                updatePageTracking(name, id, currentPage, option.value);
              }}
              className={styles.pageSize}
            />
            <Pagination
              currentPage={currentPage}
              numberOfPages={Math.ceil(rowCount / rowsPerPage)}
              onNavigate={(toPage) => {
                onChangePage(toPage, rowCount);
                updatePageTracking(name, id, toPage, rowsPerPage);
              }}
            />
          </div>
        )}
        {...dataTableProps}
      />
    </div>
  );
};

export default Table;
