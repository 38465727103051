import { PLUGIN_ID } from '../constants';
import { get } from './baseApi';

interface LabelPolicy {
  selector: string;
}

export enum ScopePermissions {
  READ = 'read',
  WRITE = 'write',
  DELETE = 'delete',
}

export enum Scope {
  ACCESS_POLICIES = 'accesspolicies',
  ALERTS = 'alerts',
  LOGS = 'logs',
  METRICS = 'metrics',
  PROFILES = 'profiles',
  RULES = 'rules',
  TRACES = 'traces',
}

export enum PlanType {
  Gcloud = 'gcloud',
  Free = 'free',
}

interface Token {
  accessPolicyId: string;
  createdAt: string;
  displayName: string;
  expiresAt: string | null;
  firstUsedAt: string | null;
  id: string;
  name: string;
  updatedAt: string | null;
}

export interface NewToken extends Token {
  token: string;
}

export interface CreateAccessPolicyTokenPayload {
  pluginId: string;
  stackId: number;
  regionSlug: string;
  tokenName: string;
}

export interface HostedDataDetails {
  orgSlug: string;
  orgId: number;
  slug: string;
  id: number;
  trial: number;
  trialExpiresAt: string;
  plan: PlanType;
  hmInstancePromId: number;
  hlInstanceId: number;
  hmInstanceGraphiteId: number;
  htInstanceId: number;
  hlInstanceUrl: string;
  hmInstanceGraphiteUrl: string;
  hmInstancePromUrl: string;
  hmInstanceGraphiteType: string;
  htInstanceUrl: string;
  [val: string]: string | number;
  regionSlug: string;
  url: string;
}

export enum RealmTypes {
  STACK = 'stack',
  ORG = 'org',
}

interface Realm {
  identifier: string;
  labelPolicies: LabelPolicy[];
  type: RealmTypes;
}

export interface AccessPolicy {
  createdAt: string;
  displayName: string;
  id: string;
  name: string;
  realms: Realm[];
  scopes: string[];
}

export async function getHostedDataDetails(): Promise<HostedDataDetails> {
  return await get<HostedDataDetails>(`api/plugin-proxy/${PLUGIN_ID}/grafanacom-api/instances`);
}

export function getAccessPolicyName(stackId: string | undefined): string {
  return `stack-${stackId}-integration`;
}
