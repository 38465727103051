import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const exploreButtonStyles = (theme: GrafanaTheme2) => ({
  exploreButton: css`
    margin-right: ${theme.spacing(1)};
  `,
});

export default exploreButtonStyles;
