import { GeneralQueryResult, Node } from '../types';

export function getProvider(metric: GeneralQueryResult<Node>) {
  if (metric?.metric?.provider_id) {
    return metric.metric.provider_id.split(':')[0];
  } else if (metric?.metric?.provider) {
    return metric.metric.provider;
  }
  return 'no provider data';
}
