import { InlineFieldRow, RadioButtonGroup } from '@grafana/ui';
import React from 'react';
import useFilters, { TableView } from 'store/filters';

export const tableViewOptions = [
  { label: TableView.Usage, value: TableView.Usage },
  { label: TableView.Cost, value: TableView.Cost },
];

const TableViewSwitch = () => {
  const [tableView, setTableView] = useFilters((state) => [state.tableView, state.setTableView]);

  return (
    <InlineFieldRow>
      <RadioButtonGroup
        options={tableViewOptions}
        value={tableView}
        onChange={(v) => {
          setTableView(v);
        }}
      />
    </InlineFieldRow>
  );
};

export default TableViewSwitch;
