import React from 'react';
import { shallow } from 'zustand/shallow';
import { Alert, Button, Spinner, Tooltip, useStyles2 } from '@grafana/ui';
import { config } from '@grafana/runtime';
import { CurrentUserDTO } from '@grafana/data';
import { trackRudderStackEvent } from 'hooks/useRudderstack';
import { FetchStatus, RudderstackEvents } from 'enums';
import useIntegrationStore from 'store/integration';
import { getStyles } from './InstallIntegrationButton.styles';

interface Props {
  className?: string;
  disabled?: boolean;
}

const InstallIntegrationButton = ({ className, disabled }: Props) => {
  const styles = useStyles2(getStyles);
  const [installStatus, installIntegration, integrationAvailable] = useIntegrationStore(
    (state) => [state.installStatus, state.installIntegration, state.integrationAvailable],
    shallow
  );

  const user: CurrentUserDTO = config.bootData.user;
  const tooltipMessage = !integrationAvailable
    ? 'This plugin is designed for Grafana cloud, check back later for non-cloud support!'
    : 'Alert rules and recording rules can only be installed by administrators.';

  const installDisabled =
    installStatus === FetchStatus.Fetching || user.orgRole !== 'Admin' || disabled || !integrationAvailable;
  const ButtonElem = (
    <div>
      <Button
        type="button"
        fill={installDisabled ? 'outline' : 'solid'}
        role="button"
        style={{ marginRight: 4 }}
        className={className}
        disabled={installDisabled}
        onClick={() => {
          installIntegration();
          trackRudderStackEvent(RudderstackEvents.IntegrationInstall, {});
        }}
      >
        {installStatus === FetchStatus.Fetching && (
          <>
            <Spinner />
            &nbsp;
          </>
        )}
        Install
      </Button>
      {installStatus === FetchStatus.InstallRollbackError && (
        <Alert role="alert" className={styles.alert} title="Install recording and alert rules failed" severity="error">
          Error while attempting to install the integration. There could still be some dashboards left installed. If
          retrying to install the integration does not help, try removing the following dashboards:{' '}
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href="/dashboards/f/integration---kubernetes/integration-kubernetes"
          >
            kubernetes
          </a>
          .
        </Alert>
      )}
    </div>
  );

  return (
    <>
      {user.orgRole === 'Admin' && integrationAvailable ? (
        ButtonElem
      ) : (
        <Tooltip content={tooltipMessage} placement="right">
          <div style={{ width: 'fit-content' }}>{ButtonElem}</div>
        </Tooltip>
      )}
    </>
  );
};

export default InstallIntegrationButton;
