import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import {
  useStyles2,
  LoadingPlaceholder,
  Input,
  Icon,
  Select,
  InlineField,
  InlineSwitch,
  MultiSelect,
} from '@grafana/ui';
import { useHistory } from 'react-router-dom';
import {
  DataTableWorkload,
  defaultFilters,
  matchesSearchFilter,
  WorkloadFilters,
  workloadTypeOptions,
  WorkloadTypes,
} from './workloadListUtils';
import { columnMap } from './workloadColumns';
import Table from 'components/Table/Table';
import workloadListStyles from './WorkloadList.styles';
import { RudderstackEvents, TableType } from 'enums';
import useRudderstack from 'hooks/useRudderstack';
import useTimeRangeStore from 'store/timeRange';
import { pushFaroCount } from 'helpers/faro';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { countFiltersApplied, encodeUrlString, QueryCollection } from 'helpers/helpers';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';
import { ResourceQueries } from 'queries';
import { PluginMetaContext } from 'context/PluginMetaContext';
import { DataFrameJSON, SelectableValue } from '@grafana/data';
import { isEqual } from 'lodash';
import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneObjectBase } from '@grafana/scenes';
import { K8S_STORAGE_KEY, NO_DATA_FLOWING_MAP } from '../../../constants';
import ClusterLoader from 'components/ClusterLoader/ClusterLoader';
import NoDataFlowing from 'components/NoDataFlowing/NoDataFlowing';
import FiltersRow from 'components/FiltersRow/FiltersRow';
import useCostUsage from 'hooks/useCostUsage';
import { TableView } from 'store/filters';
import useAssertsStore from 'store/asserts';
import AssertsWorkbenchButton from 'components/Asserts/AssertsWorkbenchButton';
import { selectableRowDisabled, selectableRowSelected } from 'components/Asserts/helpers';
import useWorkloads from 'hooks/useWorkloads';
import useLocalStorage from 'hooks/useLocalStorage';
import { StoredParams } from 'types';

const WorkloadTable = ({
  title,
  cluster = '.+',
  namespace = '.+',
  defaultClusters,
}: {
  title?: string;
  defaultClusters?: string[];
  cluster?: string;
  namespace?: string;
}) => {
  const styles = useStyles2(workloadListStyles);
  const { push } = useHistory();
  const prometheusName = useDatasourceStore(prometheusSelector);
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);
  const [assertsEnabled, setSelectedAssertsRows, selectedAssertsRows, toggledClearRows] = useAssertsStore((state) => [
    state.assertsEnabled,
    state.setSelectedAssertsRows,
    state.selectedAssertsRows,
    state.toggledClearRows,
  ]);

  const [storedParams, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);
  const [workloadFilters, setWorkloadFilters] = useState<WorkloadFilters>({
    ...defaultFilters,
    search: storedParams?.workloadSearch || '',
    type: (storedParams?.workloadType || '') as WorkloadTypes,
    showUnhealthy: storedParams?.showUnhealthy || false,
    namespace: storedParams?.namespace || '',
    cluster: storedParams?.cluster as string[],
  });
  const currentCluster = cluster ? [cluster] : workloadFilters?.cluster;
  const { loading, validating, workloads, firstLoad, namespaceOptions } = useWorkloads(namespace, currentCluster);
  const [workloadList, setWorkloadList] = useState<DataTableWorkload[]>([]);
  const meta = useContext(PluginMetaContext);
  const trackRudderStackEvent = useRudderstack();

  useEffect(() => {
    const filtersEmpty = isEqual(workloadFilters, defaultFilters);
    if (!filtersEmpty) {
      const newList = matchesSearchFilter(workloads, workloadFilters);
      setWorkloadList(newList);
    } else {
      if (workloads?.length) {
        pushFaroCount('workload-count', workloads.length);
      }

      setWorkloadList(workloads);
    }
  }, [workloads, workloadFilters]);

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const clusterOptions = useMemo(() => {
    if (defaultClusters) {
      return defaultClusters?.map?.((value) => ({ label: value, value }));
    }

    return [];
  }, [defaultClusters]);

  const handleClusterFilterUpdate = useCallback(
    (filters: SelectableValue[] = []) => {
      const clusters = filters?.map?.((filter) => filter.value);
      setWorkloadFilters((wf) => {
        return {
          ...wf,
          cluster: clusters,
        };
      });
      setStoredParams({ cluster: clusters });
    },
    [setWorkloadFilters, setStoredParams]
  );

  const handleFilterUpdate = useCallback(
    (type: string) => (e?: SelectableValue) => {
      const value = e ? e.value : '';
      setWorkloadFilters((wf) => {
        return {
          ...wf,
          [type]: value,
        };
      });
      setStoredParams({ [type]: value });
    },
    [setWorkloadFilters, setStoredParams]
  );

  const handleSelectedRowsChange = useCallback(
    ({ selectedRows }: { selectedRows: DataTableWorkload[] }) => {
      setSelectedAssertsRows(selectedRows);
    },
    [setSelectedAssertsRows]
  );

  const getWorkloadListRowNameFromMetric = useCallback((metric: DataFrameJSON) => {
    const fields = metric.schema?.fields;
    const fromLabelName = fields?.[fields.length - 1]?.labels?.workload;
    const fromLabelCluster = fields?.[fields.length - 1]?.labels?.cluster;
    const fromLabelNamespace = fields?.[fields.length - 1]?.labels?.namespace;
    const fromLabelType = fields?.[fields.length - 1]?.labels?.workload_type;
    return `${fromLabelName}-${fromLabelCluster}-${fromLabelNamespace}-${fromLabelType}`;
  }, []);

  const getWorkloadListRowNameFromData = useCallback((data: DataTableWorkload) => {
    return `${data.name}-${data.cluster}-${data.namespace}-${data.type}`;
  }, []);

  const {
    usageLoading,
    usageCostTableData,
    costLoading,
    onTableSort,
    sortColumn,
    tableView,
    firstUsageLoad,
    firstCostLoad,
    exploreUrl,
  } = useCostUsage<DataTableWorkload>({
    type: 'workload',
    getNameFromMetricFn: getWorkloadListRowNameFromMetric,
    getNameFromDataFn: getWorkloadListRowNameFromData,
    initialData: workloadList,
    usageQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.WorkloadUsage, [
      currentCluster?.join('|'),
      namespace,
    ]),
    costQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.WorkloadCost, [
      currentCluster?.join('|'),
      namespace,
    ]),
  });

  const selectableRowsCallback = useMemo(() => selectableRowSelected<DataTableWorkload>('name'), []);

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.workloadFilters}>
        {title && <h3>Workloads</h3>}
        <FiltersRow
          hiddenFilterCount={countFiltersApplied([
            workloadFilters?.namespace,
            workloadFilters?.type,
            workloadFilters?.showUnhealthy,
          ])}
          visibleFilterCount={countFiltersApplied([workloadFilters?.cluster, workloadFilters?.search])}
          extraButtons={
            <ExploreButton
              href={exploreUrl}
              className={styles.exploreButton}
              size="md"
              label="Explore workloads"
              eventName={RudderstackEvents.ExploreWorkloads}
            />
          }
          visibleFilters={
            <>
              {defaultClusters && (
                <MultiSelect
                  width={25}
                  placeholder="Filter by cluster"
                  options={clusterOptions}
                  value={workloadFilters?.cluster}
                  onChange={handleClusterFilterUpdate}
                  className={styles.filter}
                  maxVisibleValues={1}
                />
              )}
              <Input
                width={27}
                type="text"
                prefix={<Icon name="search" />}
                placeholder="Filter by workload"
                value={workloadFilters?.search}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setWorkloadFilters((wf) => {
                    return {
                      ...wf,
                      search: value,
                    };
                  });
                  setStoredParams({ workloadSearch: value });
                }}
              />
              <AssertsWorkbenchButton label="workloads" type="Service" relativeRange={relativeRange} />
            </>
          }
          hiddenFilters={
            <>
              {(!namespace || namespace === '.+') && (
                <>
                  <Select
                    width={25}
                    isClearable
                    placeholder="Filter by namespace"
                    options={namespaceOptions}
                    value={workloadFilters.namespace}
                    onChange={handleFilterUpdate('namespace')}
                    className={styles.filter}
                  />
                </>
              )}
              <Select
                isClearable
                placeholder="Filter by type"
                options={workloadTypeOptions}
                width={25}
                value={workloadFilters.type}
                onChange={(e) => {
                  const value = e ? e.value : '';
                  setWorkloadFilters((wf) => {
                    return {
                      ...wf,
                      type: value,
                    };
                  });
                  setStoredParams({ workloadType: value });
                }}
              />
              <InlineField label="Only show workloads with unhealthy pods">
                <InlineSwitch
                  value={workloadFilters.showUnhealthy}
                  onChange={(e) => {
                    const checked = e.currentTarget.checked;
                    setWorkloadFilters((wf) => {
                      return {
                        ...wf,
                        showUnhealthy: checked,
                      };
                    });
                    setStoredParams({ showUnhealthy: checked });
                  }}
                />
              </InlineField>
            </>
          }
        />
      </div>
      {((!workloads.length && validating) || loading || usageLoading || costLoading) && firstLoad ? (
        <LoadingPlaceholder text="Loading workloads" />
      ) : (
        <Table<DataTableWorkload>
          key={sortColumn}
          withNestedHeader
          id={TableType.Namespace}
          name={`${cluster}-${namespace}`}
          data={usageCostTableData.data}
          columns={columnMap(
            prometheusName,
            hasDateChanged,
            meta.id,
            tableView === TableView.Cost,
            usageCostTableData.errors,
            usageLoading && firstUsageLoad,
            costLoading && firstCostLoad,
            !!namespace && namespace !== '.+'
          )}
          selectable={assertsEnabled}
          onSelectedRowsChange={handleSelectedRowsChange}
          selectableRowDisabled={(row) => selectableRowDisabled<DataTableWorkload>(row, selectedAssertsRows)}
          clearSelectedRows={toggledClearRows}
          selectableRowSelected={selectableRowsCallback}
          noDataText="No workloads found"
          onRowClicked={(row: DataTableWorkload) => {
            trackRudderStackEvent(RudderstackEvents.WorkloadListClick, {});
            push(
              `/a/${meta.id}/navigation/namespace/${encodeUrlString(row.cluster)}/${
                row.namespace
              }/${row.type.toLowerCase()}/${row.name}`
            );
          }}
          onSort={onTableSort}
        />
      )}
    </div>
  );
};

const WithClusterLoader = () => (
  <NoDataFlowing message={NO_DATA_FLOWING_MAP.workloads}>
    <ClusterLoader>{(clusters: string[]) => <WorkloadTable defaultClusters={clusters} />}</ClusterLoader>
  </NoDataFlowing>
);

export default WorkloadTable;

export class WorkloadTableScene extends SceneObjectBase {
  static Component = WithClusterLoader;
}

export function getWorkloadTableAppScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new WorkloadTableScene({}),
        }),
      ],
    }),
  });
}
