import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const workloadDetailStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    width: 100%;
  `,
  container: css`
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  `,
  grid: css`
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  link: css`
    text-decoration: underline;
    margin-right: 5px;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
  health: css`
    border-radius: 50%;
    height: 20px;
    width: 20px;
  `,
  loadingIndicator: css`
    margin-right: ${theme.spacing(1)};
  `,
  headerButtons: css`
    display: flex;
  `,
  workloadButton: css`
    margin-right: ${theme.spacing(1)};
  `,
  titleWrapper: css`
    display: flex;
    flex-wrap: wrap;
  `,
  podList: css`
    margin-top: ${theme.spacing(4)};

    > h3 {
      font-size: 17px;
    }
  `,
  sceneWrapper: css`
    min-height: 200px;

    > div > div > div > div {
      @media (max-width: 1200px) {
        flex-wrap: wrap;
        width: 100%;

        > div {
          width: 49%;
        }
      }

      @media (max-width: 768px) {
        width: 100%;

        > div {
          width: 100%;
        }
      }
    }
  `,
  sectionTitle: css`
    font-size: 17px;
    margin-bottom: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
  `,
  sectionSpacing: css`
    margin-bottom: ${theme.spacing(4)};
  `,
});

export default workloadDetailStyles;
