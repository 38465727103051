import { PanelData } from '@grafana/data';
import { PanelRenderer } from '@grafana/runtime';
import { PanelChrome } from '@grafana/ui';
import React from 'react';
import Autosizer from 'react-virtualized-auto-sizer';

interface Props {
  data: PanelData;
  options: any;
  loading: boolean;
  axisLabel: string;
}

export const noop: () => void = () => {};

const TimeSeriesPanel = ({ data, options, loading, axisLabel }: Props) => {
  return (
    <>
      <Autosizer>
        {({ width, height }) => {
          return (
            <PanelChrome
              width={width || 750}
              height={height ? height - 100 : 600}
              actions={[<PanelChrome.LoadingIndicator loading={loading} onCancel={() => {}} key="loading-indicator" />]}
            >
              {(innerWidth, innerHeight) => (
                <PanelRenderer
                  title=""
                  pluginId="timeseries"
                  onOptionsChange={noop}
                  onChangeTimeRange={noop}
                  width={innerWidth}
                  height={innerHeight}
                  data={data}
                  options={options}
                  fieldConfig={{
                    overrides: [],
                    defaults: {
                      custom: {
                        axisLabel: axisLabel,
                      },
                    },
                  }}
                />
              )}
            </PanelChrome>
          );
        }}
      </Autosizer>
    </>
  );
};

export default TimeSeriesPanel;
