import React from 'react';

import { usePluginContext } from '@grafana/data';
import { ClusterConfig } from 'components/scenes/Config/ClusterConfig/ClusterConfig';
import { PluginMetaContext } from 'context/PluginMetaContext';

export function ClusterConfigExtension() {
  const { meta } = usePluginContext();
  return (
    <PluginMetaContext.Provider value={meta}>
      <ClusterConfig />
    </PluginMetaContext.Provider>
  );
}

export const ClusterConfigExtensionParams = {
  title: 'K8s Cluster Config',
  description: 'K8s Cluster configuration settings',
  targets: 'grafana/grafana-k8s-app/cluster-config/v1',
  component: ClusterConfigExtension,
};
