import { Clipboard } from '../Clipboard';
import React from 'react';
import { useStyles2 } from '@grafana/ui';
import integrationsListStyles from './IntegrationsList.styles';
import { StaticConfigSource } from '../../api/integrationsApi';

type Props = {
  source: StaticConfigSource | null;
};

const AgentOperatorIntegration = ({ source }: Props) => {
  const styles = useStyles2(integrationsListStyles);
  const noAgentOperatorData = !source?.static?.agent_operator_snippets;

  return (
    <div className={styles.sectionSpace}>
      <h4 className={styles.title}>Agent Operator configuration</h4>
      <p className={styles.description}>
        {noAgentOperatorData
          ? 'No configuration required for this integration.'
          : 'Copy the following manifest in a yaml file.'}
      </p>
      {source?.static?.agent_operator_snippets && (
        <div className={`${styles.sectionSpace} ${styles.clipboard}`}>
          <h4 className={styles.title}>Agent Operator Snippet</h4>
          <Clipboard content={source.static.agent_operator_snippets} className={styles.clipboard} resetTime={1000} />
          <p>To deploy the configuration into your cluster, run the following command:</p>
          <Clipboard
            content={`kubectl apply -f <path_to_manifest.yaml>`}
            className={styles.clipboard}
            resetTime={1000}
          />
        </div>
      )}
    </div>
  );
};

export default AgentOperatorIntegration;
