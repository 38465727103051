import {
  EmbeddedScene,
  QueryVariable,
  SceneFlexItem,
  SceneFlexLayout,
  SceneTimeRange,
  SceneTimeRangeState,
  SceneVariableSet,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';

import { addTimeRangeHandler, getGenericQueryRunner, query_result } from '../../../helpers/scenes';
import { graphsConfigWithLabel } from '../PodDetailOptimization/panelConfig';
import { SceneQueries } from 'queries';
import { ScenesCustomParams } from 'types';
import { ExplorablePanel } from '../ExplorablePanel';

import {
  coresConfig,
  gaugeConfig,
  latestTerminatedConfig,
  restartsGraphConfig,
  restartsTableConfig,
  thresholdGraphConfig,
} from './panelConfig';
import { allocationRow, idleRow } from '../GenericDetailOptimization/scene';

export function getVariables(
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  pod?: string,
  container?: string
) {
  const cpuCapacity = new QueryVariable({
    name: 'cpuCapacityUpperLimit',
    query: query_result(SceneQueries.Container.CurrentCpuRequests(cluster, namespace, pod, container)),
    datasource,
    skipUrlSync: true,
  });

  const memCapacity = new QueryVariable({
    name: 'memCapacityUpperLimit',
    query: query_result(SceneQueries.Container.CurrentMemoryRequests(cluster, namespace, pod, container)),
    datasource,
    skipUrlSync: true,
  });

  const variableSet = new SceneVariableSet({
    variables: [cpuCapacity, memCapacity],
  });

  return variableSet;
}

export const firstRow = (
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  pod?: string,
  container?: string
) => {
  return new SceneFlexLayout({
    children: [
      {
        title: 'Container CPU',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.PodDetail.CpuLimits(cluster, namespace, pod, container),
          {
            instant: false,
            interval: '',
            range: true,
            refId: 'limits',
            legendFormat: 'Container CPU limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          },
          [
            {
              expr: SceneQueries.PodDetail.CpuAllocation(cluster, namespace, pod, container),
              instant: false,
              interval: '',
              range: true,
              refId: 'allocation',
              legendFormat: 'Container CPU allocation',
            },
            {
              expr: SceneQueries.PodDetail.CpuRequests(cluster, namespace, pod, container),
              legendFormat: 'Container CPU requests',
              refId: 'requests',
              interval: '',
              instant: false,
              range: true,
            },
            {
              expr: SceneQueries.PodDetail.CpuUsage(cluster, namespace, pod, container),
              instant: false,
              interval: '',
              range: true,
              refId: 'usage',
              legendFormat: 'Container CPU usage',
            },
          ]
        ),
        unit: 'cores',
        predictable: {
          query: SceneQueries.PodDetail.CpuUsage(cluster, namespace, pod, container),
          button: 'Predict CPU Usage',
          title: 'CPU Usage Prediction Model',
          name: 'Container CPU usage',
          metric: 'container_cpu_usage_seconds_total',
          predictableVariableName: 'cpuCapacityUpperLimit',
          axisLabel: 'CPU usage (cores)',
        },
      },
      {
        title: 'Container Memory',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.PodDetail.MemoryLimits(cluster, namespace, pod, container),
          {
            instant: false,
            interval: '',
            range: true,
            refId: 'limits',
            legendFormat: 'Container memory limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          },
          [
            {
              expr: SceneQueries.PodDetail.MemoryAllocation(cluster, namespace, pod, container),
              instant: false,
              interval: '',
              range: true,
              refId: 'allocation',
              legendFormat: 'Container memory allocation',
            },
            {
              expr: SceneQueries.PodDetail.MemoryRequests(cluster, namespace, pod, container),
              instant: false,
              interval: '',
              range: true,
              legendFormat: 'Container memory requests',
              refId: 'requests',
            },
            {
              expr: SceneQueries.PodDetail.MemoryUsage(cluster, namespace, pod, container),
              instant: false,
              interval: '',
              range: true,
              refId: 'usage',
              legendFormat: 'Container memory usage',
            },
          ]
        ),
        unit: 'bytes',
        predictable: {
          query: SceneQueries.PodDetail.MemoryUsage(cluster, namespace, pod, container),
          button: 'Predict memory usage',
          title: 'Memory Usage Prediction Model',
          name: 'Container memory usage',
          metric: 'container_memory_rss',
          predictableVariableName: 'memCapacityUpperLimit',
          axisLabel: 'Memory usage (bytes)',
        },
      },
    ].map((panel) => {
      return new SceneFlexItem({
        height: 400,
        body: new ExplorablePanel(graphsConfigWithLabel(panel), panel.predictable),
      });
    }),
  });
};

export const cpuLimitsSizingRow = (
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  pod?: string,
  container?: string
) => {
  return new SceneFlexLayout({
    children: [
      {
        title: 'Current',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.CurrentCpuLimit(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: coresConfig,
        width: '25%',
      },
      {
        title: 'CPU limits sizing',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.CPULimitSizing(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: gaugeConfig,
        width: '50%',
      },
      {
        title: 'Recommended',
        description: 'Maximum CPU usage over this time range plus 40%',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.RecommendedCpuLimit(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: coresConfig,
        width: '25%',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 170,
        width: panel.width,
        md: {
          width: '100%',
        },
        body: new ExplorablePanel(type(panel)),
      });
    }),
  });
};

export const memoryLimitsSizingRow = (
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  pod?: string,
  container?: string
) => {
  return new SceneFlexLayout({
    children: [
      {
        title: 'Current',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.CurrentMemoryLimit(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1760,
          }
        ),
        type: coresConfig,
        width: '25%',
        unit: 'bytes',
      },
      {
        title: 'Memory sizing limits',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.MemoryLimitSizing(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: gaugeConfig,
        width: '50%',
        unit: 'bytes',
      },
      {
        title: 'Recommended',
        description: 'Maximum memory usage over this time range plus 20%',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.RecommendedMemoryLimit(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: coresConfig,
        width: '25%',
        unit: 'bytes',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 170,
        width: panel.width,
        md: {
          width: '100%',
        },
        body: new ExplorablePanel(type(panel)),
      });
    }),
  });
};

export const cpuRequestsSizingRow = (
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  pod?: string,
  container?: string
) => {
  return new SceneFlexLayout({
    children: [
      {
        title: 'Current',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.CurrentCpuRequests(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: coresConfig,
        width: '25%',
      },
      {
        title: 'CPU requests sizing',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.CPURequestsSizing(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: gaugeConfig,
        width: '50%',
      },
      {
        title: 'Recommended',
        description: 'Maximum CPU usage over this time range plus 20%',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.RecommendedCpuRequests(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: coresConfig,
        width: '25%',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 170,
        width: panel.width,
        md: {
          width: '100%',
        },
        body: new ExplorablePanel(type(panel)),
      });
    }),
  });
};

export const memoryRequestsSizingRow = (
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  pod?: string,
  container?: string
) => {
  return new SceneFlexLayout({
    children: [
      {
        title: 'Current',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.CurrentMemoryRequests(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1760,
          }
        ),
        type: coresConfig,
        width: '25%',
        unit: 'bytes',
      },
      {
        title: 'Memory requests sizing',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.MemoryRequestsSizing(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: gaugeConfig,
        width: '50%',
        unit: 'bytes',
      },
      {
        title: 'Recommended',
        description: 'Maximum memory usage over this time range plus 20%',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.RecommendedMemoryRequests(cluster, namespace, pod, container),
          {
            instant: true,
            interval: '',
            range: false,
            refId: 'limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          }
        ),
        type: coresConfig,
        width: '25%',
        unit: 'bytes',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 170,
        width: panel.width,
        md: {
          width: '100%',
        },
        body: new ExplorablePanel(type(panel)),
      });
    }),
  });
};

export const cpuThrottlingRow = (
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  pod?: string,
  container?: string,
  relativeTimeRange?: SceneTimeRangeState
) => {
  return new SceneFlexLayout({
    children: [
      {
        title: 'CPU throttling',
        type: thresholdGraphConfig,
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.ThrottledPercentageB(cluster, namespace, pod, container),
          {
            legendFormat: '{{container}}',
            intervalMs: '1m',
            maxDataPoints: 1718,
            instant: false,
            interval: '',
            range: true,
          }
        ),
      },
      {
        title: 'Last terminated reason',
        type: latestTerminatedConfig,
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.LastTerminatedReason(cluster, namespace, pod, container),
          {
            instant: false,
            interval: '',
            range: true,
            legendFormat: '{{reason}}',
            intervalMs: '1m',
            maxDataPoints: 1360,
          }
        ),
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 400,
        body: new ExplorablePanel(type(panel)),
      });
    }),
  });
};

export const restartsRow = (
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  pod?: string,
  container?: string
) => {
  return new SceneFlexLayout({
    children: [
      {
        title: `Container restart history`,
        type: restartsTableConfig,
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.RestartHistory(cluster, namespace, pod, container),
          {
            instant: false,
            interval: '',
            range: true,
            intervalMs: '1m',
            maxDataPoints: 1360,
            format: 'table',
            transformations: [
              {
                id: 'organize',
                options: {
                  excludeByName: {
                    Value: true,
                    instance: true,
                    job: true,
                    uid: true,
                  },
                  includeByName: {},
                  indexByName: {
                    Time: 0,
                    Value: 8,
                    cluster: 4,
                    container: 1,
                    instance: 5,
                    job: 6,
                    namespace: 3,
                    pod: 2,
                    uid: 7,
                  },
                  renameByName: {},
                },
              },
            ],
          }
        ),
      },
      {
        title: 'Container restarts',
        type: restartsGraphConfig,
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.Container.RestartHistory(cluster, namespace, pod, container),
          {
            intervalMs: '1m',
            maxDataPoints: 1501,
            instant: false,
            interval: '',
            range: true,
            format: 'time_series',
            legendFormat: '{{container}}',
          }
        ),
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 400,
        body: new ExplorablePanel(type(panel)),
      });
    }),
  });
};

export function getContainerDetailOptimization({
  datasource,
  cluster,
  namespace,
  podName,
  container,
  relativeTimeRange,
}: ScenesCustomParams) {
  const sceneTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(sceneTimeRange);

  const queries = {
    cpuAllocation: SceneQueries.PodDetail.CostCpuAllocation(cluster, namespace, podName, container),
    memoryAllocation: SceneQueries.PodDetail.CostMemoryAllocation(cluster, namespace, podName, container),
    cpuIdle: SceneQueries.PodDetail.CostCpuIdle(cluster, namespace, podName, container),
    memoryIdle: SceneQueries.PodDetail.CostMemoryIdle(cluster, namespace, podName, container),
  };

  return new EmbeddedScene({
    $variables: getVariables(datasource, cluster, namespace, podName, container),
    $timeRange: sceneTimeRange,
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        ...[
          firstRow(datasource, cluster, namespace, podName, container),
          new SceneFlexLayout({
            direction: 'row',
            children: [
              cpuLimitsSizingRow(datasource, cluster, namespace, podName, container),
              memoryLimitsSizingRow(datasource, cluster, namespace, podName, container),
            ],
          }),
          new SceneFlexLayout({
            direction: 'row',
            children: [
              cpuRequestsSizingRow(datasource, cluster, namespace, podName, container),
              memoryRequestsSizingRow(datasource, cluster, namespace, podName, container),
            ],
          }),
          new SceneFlexLayout({
            direction: 'row',
            children: [allocationRow(datasource, queries), idleRow(datasource, queries)],
          }),
          cpuThrottlingRow(datasource, cluster, namespace, podName, container),
          restartsRow(datasource, cluster, namespace, podName, container),
        ],
      ],
    }),
  });
}
