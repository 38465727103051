import { useStyles2 } from '@grafana/ui';
import React from 'react';
import tableStyles from './Table.styles';

interface Props {
  actions?: React.ReactNode;
  data: Array<{ title: string; value: string }>;
}

const ExpandRow = ({ actions, data }: Props) => {
  const styles = useStyles2(tableStyles(false));

  return (
    <div className={styles.expandRow}>
      <div className={styles.expandRowData}>
        {data?.map?.((item, index) => {
          return (
            <div key={index}>
              <p>{item.title}</p>
              <p>{item.value}</p>
            </div>
          );
        })}
      </div>
      <div className={styles.expandRowActions}>{actions}</div>
    </div>
  );
};

export default ExpandRow;
