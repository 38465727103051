import React, { useCallback, useState } from 'react';
import subRowHeaderStyles from './SubRowHeader.styles';
import { Icon, Tooltip, useStyles2 } from '@grafana/ui';
import { SortOrder } from '@grafana/schema';
import useSorting from 'store/sorting';
import { cx } from '@emotion/css';

type SubRowHeaderType = {
  info: string;
  title: string;
  type?: string;
  error?: string;
};
const SubRowHeader = ({ info, title, type, error }: SubRowHeaderType) => {
  const styles = useStyles2(subRowHeaderStyles);
  const [hover, setHover] = useState<boolean>(false);
  const [setSorting, sortOrder, sortColumn] = useSorting((state) => [
    state.setSorting,
    state.sortOrder,
    state.sortColumn,
  ]);

  const onClick = useCallback(
    (_: React.MouseEvent<HTMLElement>) => {
      const order =
        sortOrder === SortOrder.None || sortOrder === SortOrder.Descending ? SortOrder.Ascending : SortOrder.Descending;
      setSorting(order, type || '');
    },
    [sortOrder, setSorting, type]
  );

  const onMouseOver = useCallback(() => {
    setHover(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.hoverArea} onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}></div>
      <span className={styles.title}>{title}</span>
      {error ? (
        <Tooltip content={error}>
          <Icon name="exclamation-triangle" size="sm" className={cx(styles.info, styles.error)} />
        </Tooltip>
      ) : (
        <Tooltip content={info}>
          <Icon name="info-circle" size="sm" className={styles.info} />
        </Tooltip>
      )}
      {(hover || sortColumn === type) && <span className={`${styles[sortOrder]} ${styles.sort}`}>▲</span>}
    </div>
  );
};

export default SubRowHeader;
