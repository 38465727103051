import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Spinner, useStyles2 } from '@grafana/ui';
import { cx } from '@emotion/css';
import { AppEvents } from '@grafana/data';
import appEvents from 'grafana/app/core/app_events';
import { CopyToClipboard } from './CopyToClipboard';
import clipboardStyles from './Clipboard.styles';

interface Props {
  content: string;
  loading?: boolean;
  className?: string;
  truncate?: boolean;
  resetTime?: number;
  withReactMarkdown?: boolean;
}

export function Clipboard({ content, className, truncate, loading, resetTime, withReactMarkdown }: Props) {
  const styles = useStyles2(clipboardStyles(loading));

  return (
    <div className={cx(styles.container, className)}>
      {loading && <Spinner className={styles.loading} />}
      {withReactMarkdown ? (
        <ReactMarkdown
          components={{
            pre({node, ...props }) { return <pre {...props} className="hljs"/>},
            code({node, inline, className, children, ...props}) {
              return !inline ? (
                <div className={styles.codeWrapper}>
                  <code
                    {...props}
                    className={`${className}`}
                    dangerouslySetInnerHTML={{ __html: String(children) }} />
                    <CopyToClipboard
                      className={styles.copyButtonInCode}
                      resetTime={resetTime}
                      variant="primary"
                      fill="text"
                      buttonText=""
                      buttonTextCopied=""
                      content={String(children)}
                      onClipboardError={(err) => {
                        appEvents.emit(AppEvents.alertError, [`Failed to copy to clipboard: ${err}`]);
                      }}
                    />
                </div>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              )
            }
          }}
        >
          {content}
        </ReactMarkdown>
      ) : (
        <pre className={styles.code} data-testid="clipboard-content">
          <div className={styles.codeWrapper}>
            <CopyToClipboard
              className={styles.copyButtonInCode}
              resetTime={resetTime}
              variant="primary"
              fill="text"
              buttonText=""
              buttonTextCopied=""
              content={content}
              onClipboardError={(err) => {
                appEvents.emit(AppEvents.alertError, [`Failed to copy to clipboard: ${err}`]);
              }}
            />
          </div>
          {truncate ? content.slice(0, 150) + '...' : content}
        </pre>
      )}
    </div>
  );
}
