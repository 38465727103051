import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const integrationCardStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: ${theme.spacing(4)};

    @media (max-width: 1024px) {
      justify-content: center;
    }
  `,
  modalTitle: css`
    h2 {
      font-size: 18px;
      margin: 0 ${theme.spacing(1)} 0 0;
    }
  `,
  title: css`
    color: ${theme.colors.text.primary};
    font-size: 14px;
  `,
  description: css`
    color: ${theme.colors.text.secondary};
    font-size: 14px;
  `,
  sectionSpace: css`
    margin: ${theme.spacing(3)} 0;
  `,
  clipboard: css`
    pre {
      max-height: 200px;
      word-break: break-word;
    }

    ul {
      margin-left: ${theme.spacing(4)};
      margin-bottom: ${theme.spacing(2)};
    }

    ul,
    p {
      padding: 0px 8px;
    }
  `,
  installButtonWrapper: css`
    display: flex;
    justify-content: flex-end;
  `,
  connectionsButton: css`
    background-color: transparent;
    border: 1px solid ${theme.colors.border.strong};
  `,
  installButton: css`
    > span {
      align-items: flex-start;
      gap: 8px;
    }
  `,
  buttonsWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  link: css`
    text-decoration: underline;
  `,
});

export default integrationCardStyles;
