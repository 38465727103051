import { useMemo } from 'react';
import { WorkloadQueries } from 'queries';
import useTimeRangeStore from 'store/timeRange';
import { GeneralQueryResult, Workload, WorkloadQueryResult } from 'types';
import { PodHealthStatus } from 'enums';
import { SelectableValue } from '@grafana/data';
import { sortBy } from 'lodash';
import useDataFrame from './useDataFrame';

type UseWorkloadsType = {
  loading: boolean;
  validating: boolean;
  workloads: Workload[];
  firstLoad: boolean;
  namespaceOptions: SelectableValue[];
};

function useWorkloads(namespace: string, cluster: string[], workload?: string): UseWorkloadsType {
  const [range] = useTimeRangeStore((state) => [state.range, state.hasDateChanged]);
  const workloadsReadyPodsQuery = WorkloadQueries.WorkloadsReadyPods(cluster?.join('|'), namespace, workload);
  const workloadsDesiredPodsQuery = WorkloadQueries.WorkloadsDesiredPods(cluster?.join('|'), namespace, workload);
  const workloadsAlertsQuery = WorkloadQueries.WorkloadAlerts(cluster?.join('|'), namespace, workload);

  const { loading, validating, data, firstLoad } = useDataFrame(
    [workloadsReadyPodsQuery, workloadsDesiredPodsQuery, workloadsAlertsQuery],
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '',
    true,
    1,
    true,
    true
  );

  const [readyPodsData, desiredPodsData, alertsData] = data as [
    Array<GeneralQueryResult<WorkloadQueryResult>>,
    Array<GeneralQueryResult<WorkloadQueryResult>>,
    Array<GeneralQueryResult<WorkloadQueryResult>>
  ];

  const { workloads, namespaces } = useMemo(() => {
    if (!readyPodsData && !desiredPodsData && !alertsData) {
      return { workloads: [], namespaces: [] };
    } else {
      const namespaces = new Map<string, SelectableValue>();
      const resultMap = new Map<string, Workload>();

      const alertMap = new Map<string, number>();
      alertsData?.forEach?.((metric) => {
        // Start our namespace map
        namespaces.set(metric.metric.namespace, {
          label: metric.metric.namespace,
          value: metric.metric.namespace,
        });
        const key = `${metric.metric.workload}-${metric.metric.namespace}-${metric.metric.cluster}-${metric.metric.workload_type}`;
        alertMap.set(key, parseInt(metric.value[1], 10));
      });

      // Build the start of our result map with the readyPods data
      readyPodsData?.forEach?.((metric) => {
        namespaces.set(metric.metric.namespace, {
          label: metric.metric.namespace,
          value: metric.metric.namespace,
        });

        const key = `${metric.metric.workload}-${metric.metric.namespace}-${metric.metric.cluster}-${metric.metric.workload_type}`;
        resultMap.set(key, {
          readyPods: parseInt(metric.value[1], 10),
          name: metric.metric.workload,
          namespace: metric.metric.namespace,
          cluster: metric.metric.cluster,
          type: metric.metric.workload_type,
          alertCount: alertMap.get(key) || 0,
          desiredPods: 0,
          podCountByHealth: {
            [PodHealthStatus.Healthy]: parseInt(metric.value[1], 10),
            [PodHealthStatus.Unhealthy]: 0,
            [PodHealthStatus.Warning]: 0,
            [PodHealthStatus.Unknown]: 0,
          },
        });
      });

      desiredPodsData?.forEach?.((metric) => {
        // We do this again since there might be some namespaces that are not represented in readyPodsData
        namespaces.set(metric.metric.namespace, {
          label: metric.metric.namespace,
          value: metric.metric.namespace,
        });

        const key = `${metric.metric.workload}-${metric.metric.namespace}-${metric.metric.cluster}-${metric.metric.workload_type}`;
        const current = resultMap.get(key);
        const desiredPods = parseInt(metric.value[1], 10) || 0;
        const alertCount = alertMap.get(key) || 0;
        // If we already have readyPods, we just update the desiredPods and the unhealthy count
        if (current) {
          resultMap.set(key, {
            ...current,
            desiredPods,
            podCountByHealth: {
              ...current.podCountByHealth,
              [PodHealthStatus.Unhealthy]: desiredPods - current.readyPods,
            },
          });
        } else {
          // If we don't have any readyPods, we create a new entry
          resultMap.set(key, {
            desiredPods,
            name: metric.metric.workload,
            namespace: metric.metric.namespace,
            cluster: metric.metric.cluster,
            type: metric.metric.workload_type,
            alertCount,
            readyPods: 0,
            podCountByHealth: {
              [PodHealthStatus.Healthy]: 0,
              [PodHealthStatus.Unhealthy]: desiredPods,
              [PodHealthStatus.Warning]: 0,
              [PodHealthStatus.Unknown]: 0,
            },
          });
        }
      });

      return {
        workloads: Array.from(resultMap).map?.(([_, val]) => val),
        namespaces: sortBy([...namespaces.values()], 'value'),
      };
    }
  }, [readyPodsData, desiredPodsData, alertsData]) as { workloads: Workload[]; namespaces: SelectableValue[] };

  return {
    loading,
    validating,
    workloads,
    firstLoad,
    namespaceOptions: namespaces,
  };
}

export default useWorkloads;
