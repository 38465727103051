import React from 'react';
import { useStyles2 } from '@grafana/ui';

import dataListStyles from './DataList.styles';
import CopyContent from 'components/Clipboard/CopyContent';

type DataListItem = {
  label: string;
  display?: string | React.JSX.Element;
  value: string;
};
type DataListProps = {
  list: any;
  isLarge?: boolean;
  alignValueRight?: boolean;
};

const DataList = ({ list, isLarge = false, alignValueRight = false }: DataListProps) => {
  const styles = useStyles2(dataListStyles(isLarge, alignValueRight));

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <ul className={styles.list} role="list">
      {list?.map?.((item: DataListItem) => (
        <li className={styles.listItem} key={item.label}>
          <span>{item.label}</span>
          <span>
            {item.display || item.value} <CopyContent hidden content={item.value} />
          </span>
        </li>
      ))}
    </ul>
  );
};

export default DataList;
