import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

export default (theme: GrafanaTheme2) => ({
  container: css`
    margin-bottom: ${theme.spacing(3)};

    [class$='-logs-row__message'] {
      white-space: nowrap;
    }
  `,
  topWrapper: css`
    display: flex;
    margin-bottom: ${theme.spacing(1)};
  `,
  panelTitle: css`
    margin-right: ${theme.spacing(1)};
    font-size: 17px;
  `,
  exploreButton: css`
    margin-right: ${theme.spacing(1)};
  `,
});
