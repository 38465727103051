import React, { useMemo } from 'react';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRange,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { ObjectTypes, ScenesCustomParams } from 'types';
import { addTimeRangeHandler } from 'helpers/scenes';
import { QueryType } from '../GenericDetailOptimization/scene';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import useTimeRangeStore from 'store/timeRange';
import { config } from '@grafana/runtime';
import { energyByResourceRow, energyBySourceRow } from './panels';
import { getEnergyQueriesByParams, getVariables } from './helpers';

const Energy = ({ model }: SceneComponentProps<EnergyScene>) => {
  const props = model.useState();
  const prometheusName = useDatasourceStore(prometheusSelector);
  const prometheusDatasource = config.datasources[prometheusName];
  const lokiName = useDatasourceStore(lokiSelector);
  const [relativeRange] = useTimeRangeStore((state) => [state.relativeRange]);

  const scene = useMemo(
    () =>
      getEnergyScene({
        ...props,
        datasource: prometheusDatasource,
        prometheusName,
        lokiName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [relativeRange, prometheusName, lokiName, prometheusDatasource, props]
  );

  return <scene.Component model={scene} />;
};

export function getEnergyScene({
  type = 'cluster',
  cluster,
  node,
  namespace,
  workload,
  workloadType,
  podName,
  container,
  datasource,
  relativeTimeRange,
  onTimeRangeChange,
}: ScenesCustomParams) {
  const sceneTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(sceneTimeRange, onTimeRangeChange);

  const { bySource, byResource } = getEnergyQueriesByParams({
    type,
    cluster: cluster || '',
    node,
    namespace,
    podName,
    container,
  });

  const children = [...[energyBySourceRow(datasource, bySource as QueryType)]];
  switch (type) {
    case 'cluster':
      children.push(
        ...[
          energyByResourceRow(datasource, type, ['Node'], {
            Node: byResource!.Node!,
            Namespace: byResource!.Namespace!,
          }),
          energyByResourceRow(datasource, type, ['Namespace'], {
            Node: byResource!.Node!,
            Namespace: byResource!.Namespace!,
          }),
        ]
      );
      break;
    case 'node':
      children.push(
        ...[
          energyByResourceRow(datasource, type, ['Pod'], {
            Pod: byResource!.Pod!,
          }),
        ]
      );
      break;
    case 'namespace':
      children.push(
        ...[
          energyByResourceRow(datasource, type, ['Workload'], {
            Workload: byResource!.Workload!,
          }),
        ]
      );
      break;
    case 'workload':
      children.push(
        ...[
          energyByResourceRow(datasource, type, ['Pod'], {
            Pod: byResource!.Pod!,
          }),
        ]
      );
      break;
    case 'pod':
      children.push(
        ...[
          energyByResourceRow(datasource, type, ['Container'], {
            Container: byResource!.Container!,
          }),
        ]
      );
      break;
    case 'container':
      break;
  }
  return new SceneFlexLayout({
    $variables: getVariables(type, datasource, cluster, node, namespace, workload, workloadType),
    $timeRange: sceneTimeRange,
    direction: 'column',
    children,
  });
}

export interface EnergyState extends SceneObjectState {
  type: ObjectTypes;
  cluster: string;
  node?: string;
  namespace?: string;
  workload?: string;
  workloadType?: string;
  podName?: string;
  container?: string;
}

export class EnergyScene extends SceneObjectBase<EnergyState> {
  static Component = Energy;
}

export function energyScene(params: EnergyState) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new EnergyScene({
            ...params,
          }),
        }),
      ],
    }),
  });
}
