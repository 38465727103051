import React from 'react';
import { Icon, Tooltip, useStyles2 } from '@grafana/ui';
import { PodHealthStatus } from 'enums';
import { PodBreakdown, podHealthToColor } from '../podHealthUtils';
import { config } from '@grafana/runtime';
import podHealthProgressStyles from './PodHealthProgress.styles';

interface Props {
  podHealth: PodBreakdown;
  desiredPods: number;
  isStatic?: boolean | undefined;
}

const calcHealthPercentage = (podHealth: PodBreakdown, desiredPods: number, status: PodHealthStatus) => {
  const count = podHealth[status];
  return `${Math.ceil((count / desiredPods) * 100)}%`;
};

interface ProgressInnerProps {
  podHealth: PodBreakdown;
  desiredPods: number;
}

const ProgressInner = ({ podHealth, desiredPods }: ProgressInnerProps) => {
  const styles = useStyles2(podHealthProgressStyles);

  if (
    desiredPods === 0 ||
    (podHealth[PodHealthStatus.Healthy] === 0 && desiredPods === 0) ||
    podHealth[PodHealthStatus.Unknown] === desiredPods
  ) {
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: podHealthToColor(config)[PodHealthStatus.Unknown],
        }}
        className={styles.progressInner}
        data-testid="progress-inner-unknown"
      ></div>
    );
  }

  return (
    <>
      <div
        style={{
          width: calcHealthPercentage(podHealth, desiredPods, PodHealthStatus.Healthy),
          backgroundColor: podHealthToColor(config)[PodHealthStatus.Healthy],
        }}
        className={styles.progressInner}
        data-testid="progress-inner-healthy"
      ></div>
      <div
        style={{
          width: calcHealthPercentage(podHealth, desiredPods, PodHealthStatus.Unhealthy),
          backgroundColor: podHealthToColor(config)[PodHealthStatus.Unhealthy],
        }}
        className={styles.progressInner}
        data-testid="progress-inner-unhealthy"
      ></div>
    </>
  );
};

const PodHealthProgress = ({ desiredPods, podHealth, isStatic }: Props) => {
  const styles = useStyles2(podHealthProgressStyles);

  if (isStatic) {
    // if static, we don't know the status of the pod
    podHealth = {
      [PodHealthStatus.Healthy]: 0,
      [PodHealthStatus.Unhealthy]: 0,
      [PodHealthStatus.Warning]: 0,
      [PodHealthStatus.Unknown]: desiredPods,
    };
  }

  return (
    <Tooltip
      placement="top-end"
      content={
        <div data-testid="progress-tooltip-content">
          {podHealth[PodHealthStatus.Healthy] === desiredPods ? (
            <span data-testid="progress-tooltip-top">
              {podHealth[PodHealthStatus.Healthy] === 0 && desiredPods === 0 ? (
                <>No pods found</>
              ) : (
                <>All pods are healthy</>
              )}
            </span>
          ) : (
            <span data-testid="progress-tooltip-bottom">
              {isStatic ? (
                <>Static pod status unknown</>
              ) : (
                <>
                  {podHealth[PodHealthStatus.Healthy]} ready out of {desiredPods} pods desired
                </>
              )}
            </span>
          )}
        </div>
      }
    >
      <div className={styles.outerContainer} data-testid="progress-container">
        <div className={styles.progresContainer}>
          <div className={styles.progressOuter}>
            <ProgressInner podHealth={podHealth} desiredPods={desiredPods} />
          </div>
          <p style={{ margin: 0 }} data-testid="progress-ratio-text">
            {podHealth[PodHealthStatus.Healthy]} / {desiredPods}
          </p>
        </div>
        {podHealth[PodHealthStatus.Unhealthy] > podHealth[PodHealthStatus.Healthy] && (
          <Icon
            name="info-circle"
            style={{ fill: config.theme2.colors.warning.main, marginLeft: '5px', position: 'relative', top: 3 }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default PodHealthProgress;
