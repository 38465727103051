import { Tooltip } from '@grafana/ui';
import { getPrecisionValue } from 'helpers/helpers';
import React from 'react';

interface TableUsageRowProps {
  currentValue?: number;
  projectedValue?: number;
  loading?: boolean;
}

const TableUsageRow = ({ currentValue, projectedValue, loading }: TableUsageRowProps) => (
  <div style={{ display: 'flex', gap: 40 }}>
    <Tooltip content={`$${(currentValue || 0)?.toPrecision(currentValue ? 6 : 3)}`}>
      <span style={{ width: 80 }}>{loading ? 'Loading...' : getPrecisionValue(currentValue, true)}</span>
    </Tooltip>
    <Tooltip content={`$${(projectedValue || 0)?.toPrecision(currentValue ? 6 : 3)}`}>
      <span style={{ width: 80 }}>{loading ? 'Loading...' : getPrecisionValue(projectedValue, true)}</span>
    </Tooltip>
  </div>
);

export default TableUsageRow;
