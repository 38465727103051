const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
prometheus.exporter.oracledb "integrations_oracledb" {
    connection_string = "oracle://<db_user>:<db_password>@<oracledb_service>.<oracledb_namespace>.svc.cluster.local:1521/<oracledb_db_name>"
}

prometheus.scrape "integrations_oracledb" {
  targets    = prometheus.exporter.oracledb.integrations_oracledb.targets
  forward_to = [prometheus.relabel.metrics_service.receiver]
  job_name   = "integrations/oracledb"
}`;
const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "oracledb_alert_logs" {
    targets = discovery.kubernetes.pods.targets

        rule {
            action        = "keep"
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = "(<namespace_name>:oracledb.*)"
        }

        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = \`(.*):(\\w+).*\`
            replacement   = "$2.$1.svc.cluster.local:<db_port>"
            target_label  = "instance"
        }

        rule {
            replacement   = "integrations/oracledb" 
            target_label  = "job"
        }

        rule {
            source_labels = ["__meta_kubernetes_pod_node_name"]
            target_label  = "host"
        }

        rule {
            replacement   = "/opt/oracle/diag/rdbms/<db_lowercase>/<db_uppercase>/trace/alert_<db_uppercase>.log"
            target_label  = "filename"
        }
    }

loki.source.kubernetes "oracledb_alert_logs" {
    targets      = discovery.relabel.oracledb_alert_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
