import { Container, ContainerResult, GeneralQueryResult, Pod, WaitingContainerResult } from 'types';
import { useState, useEffect } from 'react';
import { dateTimeFromTimestamp } from 'components/workloads/podHealthUtils';
import { Queries } from 'queries';
import useTimeRangeStore from 'store/timeRange';
import { pushFaroCount } from 'helpers/faro';
import useDataFrame from './useDataFrame';

function usePodDetails(cluster: string, namespace: string, podName: string) {
  const [pod, setPod] = useState<Pod>();
  const [containers, setContainers] = useState<Container[]>();
  const [restarts, setRestarts] = useState<string | undefined>();
  const [range, hasDateChanged] = useTimeRangeStore((state) => [state.range, state.hasDateChanged]);

  const queryTypes = {
    singlePodStatus: Queries.SinglePodStatus(cluster, namespace, podName),
    podStartTime: Queries.PodStartTime(cluster, namespace, podName),
    podRestarts: Queries.PodRestarts(cluster, namespace, podName),
    containerInfo: Queries.ContainerInfo(cluster, namespace, podName),
    waitingContainers: Queries.WaitingContainers(cluster, namespace, podName),
    containerAlerts: Queries.ContainerAlertsCount(cluster, namespace, podName),
  };

  const { data, loading, validating, firstLoad } = useDataFrame(
    [
      queryTypes.singlePodStatus,
      queryTypes.podStartTime,
      queryTypes.podRestarts,
      queryTypes.containerInfo,
      queryTypes.waitingContainers,
      queryTypes.containerAlerts,
    ],
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '',
    !hasDateChanged,
    1,
    true,
    true
  );

  const [podInfoData, startTimeData, restartsData, containerData, containerWaitingData, containerAlertsData] = data as [
    Array<GeneralQueryResult<Pod>>,
    Array<GeneralQueryResult<Pod>>,
    Array<GeneralQueryResult<Pod>>,
    Array<GeneralQueryResult<Container>>,
    Array<GeneralQueryResult<WaitingContainerResult>>,
    Array<GeneralQueryResult<ContainerResult>>
  ];

  // Possibly switch this to lookup later
  useEffect(() => {
    if (podInfoData?.[0]) {
      setPod(podInfoData[0]?.metric);
    }

    const alertMap = new Map<string, number>();
    containerAlertsData?.forEach?.((metric) => {
      alertMap.set(metric.metric.container, parseInt(metric.value[1], 10));
    });

    const tableData = containerData?.map?.((metric) => {
      const waitingContainer = containerWaitingData?.find?.((container) => container.metric.uid === metric.metric.uid);
      return {
        name: metric.metric.container,
        container: metric.metric.container,
        image_spec: metric.metric.image_spec,
        namespace: metric.metric.namespace,
        cluster: metric.metric.cluster,
        waiting_reason: waitingContainer?.metric.reason,
        alertCount: alertMap.get(metric.metric.container) || 0,
      } as Container;
    });

    if (tableData?.length) {
      pushFaroCount('pod-container-count', tableData.length);
    }
    setContainers(tableData);
  }, [podInfoData, podName, containerData, containerWaitingData, containerAlertsData]);

  useEffect(() => {
    // initial loading state
    if (loading && restartsData === undefined) {
      setRestarts('Loading...');
      return;
    }
    // refetching, skip update
    if (loading && restarts && !restartsData?.length) {
      return;
    }
    // Done loading, either we have data or we don't
    setRestarts(String(restartsData?.[0]?.value[1] ?? 'No data'));
  }, [restartsData, restarts, loading]);

  return {
    containers,
    pod,
    loading,
    refetching: validating,
    startTime:
      startTimeData && startTimeData.length ? dateTimeFromTimestamp(startTimeData[0].value[1]?.toString()) : 'No data',
    restarts,
    queries: queryTypes,
    firstLoad,
  };
}

export default usePodDetails;
