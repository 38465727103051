import { GrafanaComApiProvider } from '@grafana-cloud/grafana-com-api';
import { RadioButtonGroup, useStyles2 } from '@grafana/ui';
import AccessPoliciesInit from 'components/AccessPolicyToken/AccessPoliciesInit';
import { PluginMetaContext } from 'context/PluginMetaContext';
import { TokenOptions } from 'enums';
import React, { useContext, useState } from 'react';
import ExistingToken from './ExistingToken';
import GenerateToken from './GenerateToken';
import tokenStyles from './TokenStyles';

export const tokenGroupOptions = [
  { label: 'Create a new token', value: TokenOptions.NewToken },
  { label: 'Use an existing token', value: TokenOptions.ExistingToken },
];

const TokenWrapper = () => {
  const styles = useStyles2(tokenStyles);
  const [tokenType, setTokenType] = useState(TokenOptions.NewToken);
  const { id } = useContext(PluginMetaContext);

  return (
    <GrafanaComApiProvider config={{ baseApiUrl: `api/plugin-proxy/${id}/grafanacom-api` }}>
      <AccessPoliciesInit>
        <div className={styles.wrapper}>
          <RadioButtonGroup
            options={tokenGroupOptions}
            size="md"
            value={tokenType}
            onChange={(v) => {
              setTokenType(v);
            }}
          />
          {tokenType === TokenOptions.NewToken && <GenerateToken />}
          {tokenType === TokenOptions.ExistingToken && <ExistingToken />}
        </div>
      </AccessPoliciesInit>
    </GrafanaComApiProvider>
  );
};

export default TokenWrapper;
