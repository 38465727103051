import React, { useContext, useEffect, useMemo } from 'react';
import { SceneApp, SceneTimeRangeState } from '@grafana/scenes';
import getCostScene from '../Cost/Cost';
import { PluginMetaContext } from 'context/PluginMetaContext';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import { config } from '@grafana/runtime';
import { ScenesCustomParams } from 'types';
import { getConfigScene } from '../Config/Config';
import { getHomeScene } from 'components/HomeScene/scene';
import useIntegrationStore from 'store/integration';
import { shallow } from 'zustand/shallow';
import { getClusterScene, getNamespaceScene, getNodesScene, getWorkloadScene } from '../Navigation';
import useTimeRangeStore from 'store/timeRange';
import { getAlertScene } from '../Alerts/scene';
import { useStyles2 } from '@grafana/ui';
import rootStyles from './Root.styles';
import useQueryParams from 'hooks/useQueryParams';
import PredictionModal from 'components/PredictionModal/PredictionModal';
import usePredictable from 'store/predictable';

const getRootScene = (params: ScenesCustomParams) =>
  new SceneApp({
    pages: [
      getHomeScene(params),
      getClusterScene(params),
      getNamespaceScene(params),
      getWorkloadScene(params),
      getNodesScene(params),
      getConfigScene(params),
      getCostScene(params),
      getAlertScene(params),
    ],
  });

function PluginSceneApp() {
  const meta = useContext(PluginMetaContext);
  const styles = useStyles2(rootStyles);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const lokiName = useDatasourceStore(lokiSelector);
  const promDsId = config.datasources[prometheusName]?.uid;
  const lokiDsId = config.datasources[lokiName]?.uid;
  const predictable = usePredictable((state) => state.predictable);
  const [relativeTimeRange, range] = useTimeRangeStore((state) => [state.relativeRange, state.range]);
  const { updateQueryParam } = useQueryParams();

  useEffect(() => {
    updateQueryParam('from', relativeTimeRange?.from as string);
    updateQueryParam('to', relativeTimeRange?.to as string);
  }, [relativeTimeRange, updateQueryParam]);

  const [dataFlowing, dataFlowingStatus] = useIntegrationStore(
    (state) => [state.dataFlowing, state.dataFlowingStatus],
    shallow
  );

  const scene = useMemo(() => {
    const datasource = {
      uid: String(promDsId),
      type: 'prometheus',
    };

    const lokiDatasource = {
      uid: String(lokiDsId),
      type: 'loki',
    };

    return getRootScene({
      datasource,
      lokiDatasource,
      prometheusName,
      lokiName,
      id: meta.id,
      dataFlowing,
      dataFlowingStatus,
      relativeTimeRange: relativeTimeRange as SceneTimeRangeState,
      range,
    });
  }, [promDsId, lokiDsId, prometheusName, lokiName, meta, dataFlowing, dataFlowingStatus, relativeTimeRange, range]);

  return (
    <div className={styles.rootContainer}>
      <scene.Component model={scene} />
      {predictable && (
        <PredictionModal
          buttonText={predictable.button}
          title={predictable.title}
          query={predictable.query}
          name={predictable.name}
          metric={predictable.metric}
          axisLabel={predictable.axisLabel}
          hyperParamsUpperLimit={100}
          scenePanel
          showModal
        />
      )}
    </div>
  );
}

export default PluginSceneApp;
