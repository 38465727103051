import { AppEvents, CurrentUserDTO, PluginMeta } from '@grafana/data';
import { BackendSrvRequest, getBackendSrv, config } from '@grafana/runtime';
import { lastValueFrom } from 'rxjs';
import appEvents from 'grafana/app/core/app_events';
import { INTEGRATIONS_API_ERROR, K8S_INTEGRATION_ID } from '../constants';
import { createDashboard, createFolder, removeFolder } from 'api/hostedGrafana';
import { get, SuccessfulFetchResponse } from 'api/baseApi';
import { GeneralQueryResult, GenericGetResult, JSONData } from 'types';
import { IntegrationConnectionErrorCause } from 'enums';

export interface SuccessfulIntegrationsApiResponse<D> {
  status: 'success';
  data: D;
}

export type ConfigFileParamsType = {
  metrics?: boolean;
  logs?: boolean;
  events?: boolean;
  allowlist?: boolean;
  operator?: boolean;
  opencost?: boolean;
};

export type Source = {
  slug: string;
  name: string;
  installation?: Installation;
  version?: string;
  logs_check_query?: string;
  metrics_check_query?: string;
  metrics?: {
    status: string;
  };
  logs?: {
    status: string;
  };
};

export type StaticConfigSource = {
  static: {
    default_type: string;
    enable_hostname_relabel_and_filter: string;
    pre_instructions: string;
    post_instructions: string;
    integrations_snippets: string;
    integrations_snippets_filtered: string;
    metrics_snippets: string;
    metrics_snippets_filtered: string;
    logs_snippets: string;
    logs_snippets_filtered: string;
    agent_example_configuration: string;
    agent_example_configuration_filtered: string;
    agent_operator_snippets: string;
  };
};

export type IntegrationSource = {
  name: string;
  slug: string;
  overview: string;
  installation: boolean;
  logo: {
    dark_theme_url: string;
    light_theme_url: string;
  };
};

export interface Installation {
  slug?: string;
  stack_id?: string;
  version?: string;
  installed_on?: string;
  dashboard_uids?: null;
}

export interface DashboardInstallInfo {
  dashboard: unknown;
  folder_name: string;
  overwrite: boolean;
}

export async function getDashboardInstallInfo(
  integration: string,
  grafanaInstanceId?: string
): Promise<DashboardInstallInfo[]> {
  const body = {
    configuration: {
      configurable_logs: {
        logs_disabled: false,
      },
    },
  };

  const response = await integrationsProxy(
    `/int-api/v2/stacks/${grafanaInstanceId}/integrations/${integration}/dashboards`,
    {
      method: 'POST',
      showErrorAlert: false,
      data: body,
    }
  );
  return response?.data?.data;
}

export async function installDashboards(integration: string, grafanaInstanceId?: string) {
  const dashboardInstallInfo = await getDashboardInstallInfo(integration, grafanaInstanceId);

  const uniqueFolderNames = new Set(dashboardInstallInfo?.map?.((installInfo) => installInfo.folder_name));
  const createFolderPromises = Array.from(uniqueFolderNames.values())?.map?.((folderName) => createFolder(folderName));
  await Promise.all(createFolderPromises);

  for (const installInfo of dashboardInstallInfo) {
    await createDashboard(installInfo.dashboard, installInfo.folder_name, installInfo.overwrite);
  }
}

export async function getInstanceId() {
  try {
    const response = await get<PluginMeta<JSONData>>('/api/plugins/grafana-easystart-app/settings', {
      showErrorAlert: false,
    });
    return response?.jsonData?.grafana_instance_id;
  } catch (e: any) {
    // 404 suggests local development or not cloud, hide error
    if (e.status && e.status === 404) {
      return;
    }
    const error = 'Integrations plugin not installed';
    appEvents.emit(AppEvents.alertError, [error]);
    throw new Error(error);
  }
}

export async function removeDashboards(integration: string, grafanaInstanceId?: string): Promise<void[]> {
  const dashboardInstallInfos = await getDashboardInstallInfo(integration, grafanaInstanceId);

  const uniqueFolderNames = new Set(
    dashboardInstallInfos?.map?.((dashboardInstallInfo) => dashboardInstallInfo.folder_name)
  );

  const removeFolderPromises = Array.from(uniqueFolderNames.values())?.map?.((folderName) => removeFolder(folderName));

  return Promise.all(removeFolderPromises);
}

export async function upgradeIntegration(instanceId?: string): Promise<void> {
  await integrationsProxy(`/int-api/v2/stacks/${instanceId}/integrations/${K8S_INTEGRATION_ID}/upgrade`, {
    method: 'POST',
    showErrorAlert: false,
  });
}

export async function installIntegration(integration: string, instanceId?: string): Promise<void> {
  const body = {
    configuration: {
      configurable_logs: {
        logs_disabled: false,
      },
    },
  };

  await integrationsProxy(`/int-api/v2/stacks/${instanceId}/integrations/${integration}/install`, {
    method: 'POST',
    showErrorAlert: false,
    data: body,
  });
}

export async function uninstallIntegration(instanceId?: string): Promise<void> {
  await integrationsProxy(`/int-api/v2/stacks/${instanceId}/integrations/${K8S_INTEGRATION_ID}/uninstall`, {
    method: 'POST',
    showErrorAlert: false,
  });
}

export async function getIntegrations(): Promise<{ [key: string]: IntegrationSource }> {
  const user: CurrentUserDTO = config.bootData.user;

  // Skip this API call if not admin, it will throw 500
  if (user.orgRole !== 'Admin') {
    return {};
  }

  try {
    const instanceId = await getInstanceId();
    const integrations = await integrationsProxy<
      SuccessfulIntegrationsApiResponse<{ [key: string]: IntegrationSource }>
    >(`/int-api/v2/stacks/${instanceId}/integrations?platform=${K8S_INTEGRATION_ID}`, {
      method: 'GET',
      showErrorAlert: false,
    });
    const integrationsJava = await integrationsProxy<
      SuccessfulIntegrationsApiResponse<{ [key: string]: IntegrationSource }>
    >(`/int-api/v2/stacks/${instanceId}/integrations?platform=${K8S_INTEGRATION_ID}&catalog=java`, {
      method: 'GET',
      showErrorAlert: false,
    });

    const responses = {
      ...(integrations?.data?.data || {}),
      ...(integrationsJava?.data?.data || {}),
    };

    return responses;
  } catch (e: any) {
    // 404 suggests local development or not cloud, hide error
    if (e.status && e.status === 404) {
      return {};
    }

    const error =
      INTEGRATIONS_API_ERROR[e.status as keyof typeof INTEGRATIONS_API_ERROR] ?? 'Error accessing integrations API';
    appEvents.emit(AppEvents.alertError, [error]);
    return {};
  }
}

export async function queryDatasource<R>(datasourceName: string, query: string): Promise<R[]> {
  const datasource = config.datasources[datasourceName];
  const datasourceUrlType = datasource.type === 'loki' ? '/loki' : '';
  const response = await get<GenericGetResult<R[]>>(
    `${datasource.url}${datasourceUrlType}/api/v1/query?query=${encodeURIComponent(query)}`
  );

  return response?.data?.result;
}

async function hasIntegrationMetrics(promName: string, query: string) {
  const metricsResult = await queryDatasource<GeneralQueryResult<object>>(promName, query);

  if (!metricsResult || metricsResult.length === 0) {
    throw new Error(IntegrationConnectionErrorCause.NoMetricsFound);
  } else if (metricsResult.every((metric) => metric.value[1] === '0')) {
    throw new Error(IntegrationConnectionErrorCause.CannotScrapeMetrics);
  }

  return true;
}

async function hasIntegrationLogs(lokiName: string, query: string) {
  const logsResult = await queryDatasource<GeneralQueryResult<object>>(lokiName, query);

  if (!logsResult || logsResult.length === 0 || logsResult.every((result) => result.value[1] === '0')) {
    throw new Error(IntegrationConnectionErrorCause.NoLogsFound);
  }

  return true;
}

export async function testIntegration(
  integrationSlug: string,
  promName: string,
  lokiName: string
): Promise<{ success: boolean; error?: string }> {
  try {
    let isTestSuccessful = false;
    const { metrics, logs, metrics_check_query, logs_check_query } = (await getIntegration(integrationSlug)) as Source;

    const isIntegrationUsingMetrics = metrics?.status === 'available';
    const isIntegrationUsingLogs = logs?.status === 'available';

    if (isIntegrationUsingMetrics && metrics_check_query) {
      isTestSuccessful = await hasIntegrationMetrics(promName, metrics_check_query);
    }

    if (isIntegrationUsingLogs && logs_check_query) {
      isTestSuccessful = await hasIntegrationLogs(lokiName, logs_check_query);
    }

    return { success: isTestSuccessful };
  } catch (e: any) {
    const message = typeof e?.message === 'string' ? e?.message : IntegrationConnectionErrorCause.Unexpected;
    throw new Error(message);
  }
}

export async function getIntegration(name = K8S_INTEGRATION_ID): Promise<Source | { installation: false }> {
  const user: CurrentUserDTO = config.bootData.user;
  // Skip this API call if not admin, it will throw 500
  if (user.orgRole !== 'Admin') {
    return { installation: false };
  }
  try {
    const instanceId = await getInstanceId();
    const response = await integrationsProxy<SuccessfulIntegrationsApiResponse<Source>>(
      `/int-api/v2/stacks/${instanceId}/integrations/${name}/`,
      {
        method: 'GET',
        showErrorAlert: false,
      }
    );

    return { ...response.data.data };
  } catch (e: any) {
    // 404 suggests local development or not cloud, hide error
    if (e.status && e.status === 404) {
      return { installation: false };
    }
    const error =
      INTEGRATIONS_API_ERROR[e.status as keyof typeof INTEGRATIONS_API_ERROR] ?? 'Error accessing integrations API';
    appEvents.emit(AppEvents.alertError, [error]);
    return { installation: false };
  }
}

export async function getIntegrationStaticConfig(
  name = K8S_INTEGRATION_ID
): Promise<StaticConfigSource | { installation: false }> {
  const user: CurrentUserDTO = config.bootData.user;
  // Skip this API call if not admin, it will throw 500
  if (user.orgRole !== 'Admin') {
    return { installation: false };
  }
  try {
    const instanceId = await getInstanceId();
    const response = await integrationsProxy<SuccessfulIntegrationsApiResponse<StaticConfigSource>>(
      `/int-api/v2/stacks/${instanceId}/integrations/${name}/agent`,
      {
        method: 'GET',
        showErrorAlert: false,
      }
    );

    return { ...response.data.data };
  } catch (e: any) {
    // 404 suggests local development or not cloud, hide error
    if (e.status && e.status === 404) {
      return { installation: false };
    }
    const error =
      INTEGRATIONS_API_ERROR[e.status as keyof typeof INTEGRATIONS_API_ERROR] ?? 'Error accessing integrations API';
    appEvents.emit(AppEvents.alertError, [error]);
    return { installation: false };
  }
}

function integrationsProxy<D = any>(
  path: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const options: BackendSrvRequest = {
    headers: {},
    method: 'GET',
    url: `api/plugin-proxy/grafana-easystart-app${path}`,
    ...optionsOverride,
  };
  return lastValueFrom(getBackendSrv().fetch<D>(options));
}
