import React from 'react';

type Props = {
  className: string;
  integrationInstalled: boolean;
  integrationVersion: string;
  version?: string;
};

const CloudVersions = ({ className, integrationInstalled, integrationVersion, version }: Props) => (
  <>
    {version && (
      <p className={className}>
        Frontend version {version} &mdash;{' '}
        <a
          href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/release-notes/#frontend-release-notes"
          target="_blank"
          rel="noopener noreferrer"
        >
          release notes
        </a>
      </p>
    )}
    {!integrationInstalled && (
      <p className={className}>
        Backend not installed &mdash;{' '}
        <a
          href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/release-notes/#backend-release-notes"
          target="_blank"
          rel="noopener noreferrer"
        >
          release notes
        </a>
      </p>
    )}
    {integrationInstalled && (
      <p className={className}>
        Backend version {integrationVersion} &mdash;{' '}
        <a
          href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/release-notes/#backend-release-notes"
          target="_blank"
          rel="noopener noreferrer"
        >
          release notes
        </a>
      </p>
    )}
  </>
);

export default React.memo(CloudVersions);
