import { Alert } from '@grafana/ui';
import { Platforms } from 'enums';
import React from 'react';

interface Props {
  platform: Platforms;
}

const PlatformAlert = ({ platform }: Props) => {
  return (
    <>
      {platform === Platforms.Autopilot && (
        <p>
          <Alert title="" severity="info">
            Selecting Autopilot will disable Node Exporter metrics as they are not supported on this platform.
          </Alert>
        </p>
      )}
      {platform === Platforms.Fargate && (
        <p>
          <Alert title="" severity="info">
            Selecting EKS on Fargate will disable Node Exporter metrics and will utilize the Kubernetes API to gather
            pod logs.
          </Alert>
        </p>
      )}
    </>
  );
};

export default PlatformAlert;
