const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
prometheus.scrape "integrations_varnish_prometheus_exporter" {
    targets    = [{"__address__" = "<varnish-cache-service>.<varnish-cache-namespace>.svc.cluster.local:<varnish-cache-exporter-port>"}]
    forward_to = [prometheus.relabel.metrics_service.receiver]
    job_name   = "integrations/varnish-cache"
}`;

const extraLogsInstructions = `
<p>
To collect logs from Varnish Cache, <code>varnishncsa</code> and Alloy sidecars must be added to the deployment configuration. 
Additional ConfigMaps for custom Alloy configurations must be created. The deployment will need an additional volume for logs and all sidecars will need <code>volumeMounts</code> for that volume.
</p>
<p>
<q>For <code>varnishncsa</code> to run, the log file needs to already exist. This can be accomplished with an <code>initContainer</code>.</q>
</p>
<p>
The mixin is expecting the <code>filename</code> label value to match with the following regex patterns for frontend and/or backend logs:
<pre>
/var/log/varnish/varnishncsa-frontend.*.log|/opt/varnish/log/varnishncsa-frontend.*.log
/var/log/varnish/varnishncsa-backend.*.log|/opt/varnish/log/varnishncsa-backend.*.log
</pre>
</p>
<p>
The <code>varnishncsa</code> sidecars need to run as a user with the appropriate permissions to write to the log file(s). If there are
frontend and backend logs, two separate sidecars will need to be defined, customized to start <code>varnishncsa</code> with the appropriate flags.
</p>
<p>
Refer to <a href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-varnish-cache/#set-up-varnish-cache-logging">this documentation</a> for more information on the commands and flags needed for frontend/backend logging. 
Additionally, for further details on <code>varnishncsa</code> refer to <a href="https://varnish-cache.org/docs/trunk/reference/varnishncsa.html">this documentation</a>
</p>
<p>
A single Alloy sidecar with a custom configuration can be defined to collect all the logs, assuming the appropriate volumes are 
mounted and that the log files are all located in the same location.
</p>
`;

export default { metrics, extraLogsInstructions };
