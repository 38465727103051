import { Icon, useStyles2 } from '@grafana/ui';
import React, { useState } from 'react';
import getStyles from '../Config.styles';
import { DeploymentConfig } from '../../../../api/config';
import { HostedDataDetails } from '../../../../api/accessPolicyToken';
import { DeploymentMethods, Platforms } from 'enums';
import PlatformSelector from './PlatformSelector/PlatformSelector';
import { helm, helmWhite, terraform } from '../../../../img';
import ConfigHelmChartInstructions from './HelmChartInstructions';
import TerraformInstructions from './TerraformInstructions';
import { config } from '@grafana/runtime';

type Props = {
  deploymentConfigValues: DeploymentConfig;
  hostedDetails?: HostedDataDetails;
  accessPolicyToken: string;
  platform: Platforms;
};

const ConfigInstructions = ({ deploymentConfigValues, hostedDetails, accessPolicyToken, platform }: Props) => {
  const styles = useStyles2(getStyles);

  const [deploymentMethod, setDeploymentMethod] = useState<DeploymentMethods>(DeploymentMethods.Helm);

  return (
    <>
      <h3>Deploy Monitoring Resources on the Cluster</h3>
      <div className={styles.upgradingWarning}>
        <Icon size={'md'} name="exclamation-triangle" className={styles.warningIcon} />
        <p style={{ padding: 0, margin: 0 }}>
          NOTE: If you are upgrading from a previous version of Kubernetes Monitoring, including Grafana Agent in static
          mode or the Grafana Agent Operator, see the
          <a
            href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/upgrade-k8s/"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>
          for guidance.
        </p>
      </div>
      <div className={styles.platformWrapper}>
        <PlatformSelector
          img={config.theme2.isLight ? helm : helmWhite}
          name="Helm"
          isSelected={deploymentMethod === DeploymentMethods.Helm}
          onClick={() => {
            setDeploymentMethod(DeploymentMethods.Helm);
          }}
        />
        <PlatformSelector
          img={terraform}
          name="Terraform"
          isSelected={deploymentMethod === DeploymentMethods.Terraform}
          onClick={() => {
            setDeploymentMethod(DeploymentMethods.Terraform);
          }}
        />
      </div>
      {deploymentMethod === DeploymentMethods.Helm && (
        <ConfigHelmChartInstructions
          deploymentConfigValues={deploymentConfigValues}
          hostedDetails={hostedDetails}
          accessPolicyToken={accessPolicyToken}
          platform={platform}
        />
      )}
      {deploymentMethod === DeploymentMethods.Terraform && (
        <TerraformInstructions
          deploymentConfigValues={deploymentConfigValues}
          hostedDetails={hostedDetails}
          accessPolicyToken={accessPolicyToken}
          platform={platform}
        />
      )}
    </>
  );
};

export default React.memo(ConfigInstructions);
