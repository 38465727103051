import { DeploymentConfig } from '../api/config';
import { Platforms } from '../enums';
import { HostedDataDetails } from '../api/accessPolicyToken';

export const buildTerraformInstructions = (
  dc: DeploymentConfig,
  accessPolicyToken: string,
  platform: Platforms,
  hostedDetails?: HostedDataDetails
) => {
  const providerFile = `terraform {
  required_providers {
    helm = {
      source  = "hashicorp/helm"
      version = "2.13.1"
    }
  }
}
provider "helm" {
  kubernetes {
    # Replace this with values that provide connection to your cluster
    config_path    = "~/.kube/config"
    config_context = "${dc.cluster}-context"
  }
}`;

  let k8sMonFile = `resource "helm_release" "grafana-k8s-monitoring" {
  name             = "grafana-k8s-monitoring"
  repository       = "https://grafana.github.io/helm-charts"
  chart            = "k8s-monitoring"
  namespace        = var.namespace
  create_namespace = true
  atomic           = true
  timeout          = 300
`;

  let varsFile = makeVar('namespace', 'string', dc.namespace);

  // Cluster vars
  k8sMonFile += makeSet('cluster.name');
  varsFile += makeVar('cluster.name', 'string', dc.cluster);
  if (platform === Platforms.Openshift) {
    k8sMonFile += makeSet('cluster.platform');
    varsFile += makeVar('cluster.platform', 'string', Platforms.Openshift);
  }

  // Metrics service vars
  if (dc.features.metrics) {
    k8sMonFile +=
      makeSet('externalServices.prometheus.host') +
      makeSetSensitive('externalServices.prometheus.basicAuth.username') +
      makeSetSensitive('externalServices.prometheus.basicAuth.password');
    varsFile +=
      makeVar('externalServices.prometheus.host', 'string', hostedDetails?.hmInstancePromUrl || 'METRICS_HOST') +
      makeVar(
        'externalServices.prometheus.basicAuth.username',
        'number',
        hostedDetails?.hmInstancePromId || 'METRICS_USERNAME'
      ) +
      makeVar('externalServices.prometheus.basicAuth.password', 'string', accessPolicyToken);
  }

  // Logs service vars
  if (dc.features.podLogs || dc.features.clusterEvents) {
    k8sMonFile +=
      makeSet('externalServices.loki.host') +
      makeSetSensitive('externalServices.loki.basicAuth.username') +
      makeSetSensitive('externalServices.loki.basicAuth.password');
    varsFile +=
      makeVar('externalServices.loki.host', 'string', hostedDetails?.hlInstanceUrl || 'LOGS_HOST') +
      makeVar('externalServices.loki.basicAuth.username', 'number', hostedDetails?.hlInstanceId || 'LOGS_USERNAME') +
      makeVar('externalServices.loki.basicAuth.password', 'string', accessPolicyToken);
  }

  // Traces service vars
  if (dc.features.otelReceivers || dc.features.zipkinReceiver) {
    k8sMonFile +=
      makeSet('externalServices.tempo.host') +
      makeSetSensitive('externalServices.tempo.basicAuth.username') +
      makeSetSensitive('externalServices.tempo.basicAuth.password');
    varsFile +=
      makeVar(
        'externalServices.tempo.host',
        'string',
        hostedDetails?.htInstanceUrl ? `${hostedDetails?.htInstanceUrl}:443` : 'TEMPO_HOST'
      ) +
      makeVar('externalServices.tempo.basicAuth.username', 'number', hostedDetails?.htInstanceId || 'TEMPO_USERNAME') +
      makeVar('externalServices.tempo.basicAuth.password', 'string', accessPolicyToken);
  }

  // Metrics vars
  k8sMonFile += makeSet('metrics.enabled');
  varsFile += makeVar('metrics.enabled', 'bool', dc.features.metrics);
  if (dc.features.metrics) {
    k8sMonFile += makeSet('metrics.alloy.metricsTuning.useIntegrationAllowList');
    varsFile += makeVar('metrics.alloy.metricsTuning.useIntegrationAllowList', 'bool', dc.features.metrics);

    k8sMonFile += makeSet('metrics.cost.enabled');
    varsFile += makeVar('metrics.cost.enabled', 'bool', dc.features.costMetrics);
    k8sMonFile += makeSet('metrics.kepler.enabled');
    varsFile += makeVar('metrics.kepler.enabled', 'bool', dc.features.energyMetrics);

    k8sMonFile += makeSet('metrics.node-exporter.enabled');
    varsFile += makeVar(
      'metrics.node-exporter.enabled',
      'bool',
      dc.features.metrics && !(platform === Platforms.Autopilot || platform === Platforms.Fargate)
    );
    if (platform === Platforms.Openshift) {
      k8sMonFile += makeSet('metrics.kube-state-metrics.service.isTLS');
      varsFile += makeVar('metrics.kube-state-metrics.service.isTLS', 'bool', true);
      k8sMonFile += makeSet('metrics.kube-state-metrics.service.port');
      varsFile += makeVar('metrics.kube-state-metrics.service.port', 'string', 'https-main');

      k8sMonFile += makeSet('metrics.node-exporter.labelMatchers.app.kubernetes.io/name');
      varsFile += makeVar('metrics.node-exporter.labelMatchers.app.kubernetes.io/name', 'string', 'node-exporter');
      k8sMonFile += makeSet('metrics.node-exporter.service.isTLS');
      varsFile += makeVar('metrics.node-exporter.service.isTLS', 'bool', true);
    }
  }

  // Logs vars
  k8sMonFile += makeSet('logs.enabled');
  varsFile += makeVar('logs.enabled', 'bool', dc.features.podLogs || dc.features.clusterEvents);
  k8sMonFile += makeSet('logs.pod_logs.enabled');
  varsFile += makeVar('logs.pod_logs.enabled', 'bool', dc.features.podLogs);
  if (dc.features.podLogs && platform === Platforms.Fargate) {
    k8sMonFile += makeSet('logs.pod_logs.gatherMethod');
    varsFile += makeVar('logs.pod_logs.gatherMethod', 'string', 'api');
  }
  k8sMonFile += makeSet('logs.cluster_events.enabled');
  varsFile += makeVar('logs.cluster_events.enabled', 'bool', dc.features.clusterEvents);

  // Traces vars
  k8sMonFile += makeSet('traces.enabled');
  varsFile += makeVar('traces.enabled', 'bool', dc.features.otelReceivers || dc.features.zipkinReceiver);
  k8sMonFile += makeSet('receivers.grpc.enabled');
  varsFile += makeVar('receivers.grpc.enabled', 'bool', dc.features.otelReceivers);
  k8sMonFile += makeSet('receivers.http.enabled');
  varsFile += makeVar('receivers.http.enabled', 'bool', dc.features.otelReceivers);
  k8sMonFile += makeSet('receivers.zipkin.enabled');
  varsFile += makeVar('receivers.zipkin.enabled', 'bool', dc.features.zipkinReceiver);
  k8sMonFile += makeSet('receivers.grafanaCloudMetrics.enabled');
  varsFile += makeVar('receivers.grafanaCloudMetrics.enabled', 'bool', dc.features.hostMetricsGeneration);

  // OpenCost deployment vars
  k8sMonFile += makeSet('opencost.enabled');
  varsFile += makeVar('opencost.enabled', 'bool', dc.features.costMetrics);
  if (dc.features.costMetrics) {
    k8sMonFile += makeSet('opencost.opencost.exporter.defaultClusterId', `var.${pathToVarName('cluster.name')}`);
    k8sMonFile += makeSet(
      'opencost.opencost.prometheus.external.url',
      `format("%s/api/prom", var.${pathToVarName('externalServices.prometheus.host')})`
    );
  }

  // Deployment vars
  k8sMonFile += makeSet('kube-state-metrics.enabled');
  varsFile += makeVar('kube-state-metrics.enabled', 'bool', dc.features.metrics && platform !== Platforms.Openshift);
  k8sMonFile += makeSet('prometheus-node-exporter.enabled');
  varsFile += makeVar(
    'prometheus-node-exporter.enabled',
    'bool',
    dc.features.metrics &&
      !(platform === Platforms.Autopilot || platform === Platforms.Fargate || platform === Platforms.Openshift)
  );

  k8sMonFile += makeSet('prometheus-operator-crds.enabled');
  varsFile += makeVar(
    'prometheus-operator-crds.enabled',
    'bool',
    dc.features.metrics && platform !== Platforms.Openshift
  );
  k8sMonFile += makeSet('kepler.enabled');
  varsFile += makeVar('kepler.enabled', 'bool', dc.features.energyMetrics);

  // Alloy deployment vars
  if (dc.features.podLogs && platform === Platforms.Fargate) {
    k8sMonFile += makeSet('alloy-logs.alloy.clustering.enabled');
    varsFile += makeVar('alloy-logs.alloy.clustering.enabled', 'bool', true);
    k8sMonFile += makeSet('alloy-logs.alloy.mounts.varlog');
    varsFile += makeVar('alloy-logs.alloy.mounts.varlog', 'bool', false);
    k8sMonFile += makeSet('alloy-logs.controller.replicas');
    varsFile += makeVar('alloy-logs.controller.replicas', 'number', 2);
    k8sMonFile += makeSet('alloy-logs.controller.type');
    varsFile += makeVar('alloy-logs.controller.type', 'string', 'deployment');
  }
  if (dc.features.podLogs && platform === Platforms.IBMCloud) {
    k8sMonFile += makeSet('alloy-logs.alloy.mounts.extra[0].name');
    varsFile += makeVar('alloy-logs.alloy.mounts.extra[0].name', 'string', 'vardata');
    k8sMonFile += makeSet('alloy-logs.alloy.mounts.extra[0].mountPath');
    varsFile += makeVar('alloy-logs.alloy.mounts.extra[0].mountPath', 'string', '/var/data');
    k8sMonFile += makeSet('alloy-logs.controller.volumes.extra[0].name');
    varsFile += makeVar('alloy-logs.controller.volumes.extra[0].name', 'string', 'vardata');
    k8sMonFile += makeSet('alloy-logs.controller.volumes.extra[0].hostPath.path');
    varsFile += makeVar('alloy-logs.controller.volumes.extra[0].hostPath.path', 'string', '/var/data');
  }
  if (platform === Platforms.Openshift) {
    for (const alloy of ['alloy', 'alloy-events', 'alloy-logs']) {
      k8sMonFile += makeSet(`${alloy}.alloy.securityContext.allowPrivilegeEscalation`);
      varsFile += makeVar(`${alloy}.alloy.securityContext.allowPrivilegeEscalation`, 'bool', false);

      k8sMonFile += makeSetList(`${alloy}.alloy.securityContext.capabilities.drop`, ['ALL']);
      k8sMonFile += makeSetList(`${alloy}.alloy.securityContext.capabilities.add`, [
        'CHOWN',
        'DAC_OVERRIDE',
        'FOWNER',
        'FSETID',
        'KILL',
        'SETGID',
        'SETUID',
        'SETPCAP',
        'NET_BIND_SERVICE',
        'NET_RAW',
        'SYS_CHROOT',
        'MKNOD',
        'AUDIT_WRITE',
        'SETFCAP',
      ]);

      if (alloy === 'alloy-logs') {
        k8sMonFile += makeSet(`${alloy}.alloy.securityContext.privileged`);
        varsFile += makeVar(`${alloy}.alloy.securityContext.privileged`, 'bool', false);
        k8sMonFile += makeSet(`${alloy}.alloy.securityContext.runAsUser`);
        varsFile += makeVar(`${alloy}.alloy.securityContext.runAsUser`, 'number', 0);
        k8sMonFile += makeSet(`${alloy}.global.podSecurityContext.seLinuxOptions.type`);
        varsFile += makeVar(`${alloy}.global.podSecurityContext.seLinuxOptions.type`, 'string', 'spc_t');
      } else {
        k8sMonFile += makeSet(`${alloy}.alloy.securityContext.seccompProfile.type`);
        varsFile += makeVar(`${alloy}.alloy.securityContext.seccompProfile.type`, 'string', 'RuntimeDefault');
      }
    }
  }

  k8sMonFile += '}';

  return {
    providerFile,
    k8sMonFile,
    varsFile,
  };
};

const pathToVarName = (path: string): string =>
  path
    .replaceAll('.', '_')
    .replaceAll('-', '_')
    .replaceAll('/', '_')
    .replaceAll('[', '_')
    .replaceAll(']', '_')
    .toLowerCase();

const makeSet = (path: string, value?: string, type?: string): string => {
  if (!value) {
    value = `var.${pathToVarName(path)}`;
  } else if (type === 'string') {
    value = `"${value}"`;
  }
  return `
  set {
    name  = "${path}"
    value = ${value}
  }
`;
};

const makeSetSensitive = (path: string, value?: string, type?: string): string => {
  if (!value) {
    value = `var.${pathToVarName(path)}`;
  } else if (type === 'string') {
    value = `"${value}"`;
  }
  return `
  set_sensitive {
    name  = "${path}"
    value = ${value}
  }
`;
};

const makeSetList = (path: string, values: any[]): string => {
  const preppedValues = values.map(
    (value) =>
      '"' + JSON.stringify(value).replaceAll('{', '\\\\{').replaceAll('}', '\\\\}').replaceAll('"', '\\"') + '"'
  );
  return `
  set_list {
    name  = "${path}"
    value = [${preppedValues}]
  }
`;
};

const makeVar = (path: string, type: string, value: any): string => {
  if (type === 'string') {
    value = `"${value}"`;
  }
  return `
variable "${pathToVarName(path)}" {
  type    = ${type}
  default = ${value}
}
`;
};
