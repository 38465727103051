import { LoadingPlaceholder } from '@grafana/ui';
import useDataFrame from 'hooks/useDataFrame';
import { Queries } from 'queries';
import React, { useState, useEffect } from 'react';
import useTimeRangeStore from 'store/timeRange';
import { Cluster, GeneralQueryResult } from 'types';

const ClusterLoader = ({ children }: { children: (clusters: string[]) => JSX.Element }) => {
  const range = useTimeRangeStore((state) => state.range);
  const [clusters, setClusters] = useState<string[] | null>(null);

  const { loading, data } = useDataFrame(
    [Queries.ClusterList],
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '',
    false,
    1,
    true,
    true
  );

  const [clustersList] = data as [Array<GeneralQueryResult<Cluster>>];

  useEffect(() => {
    if (!loading && clustersList) {
      setClusters(clustersList?.map?.((item) => item?.metric?.cluster));
    }
  }, [loading, clustersList]);

  return clusters ? (
    children(clusters)
  ) : (
    <div style={{ display: 'flex', paddingTop: 100, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <LoadingPlaceholder text="Loading..." />
    </div>
  );
};

export default ClusterLoader;
