import { Alert, Icon, LoadingPlaceholder, MultiSelect, useStyles2 } from '@grafana/ui';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { config } from '@grafana/runtime';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import Table from 'components/Table/Table';
import { PluginMetaContext } from 'context/PluginMetaContext';
import { PodHealthStatus, RudderstackEvents } from 'enums';
import { QueryCollection, encodeUrlString, getExploreUrl } from 'helpers/helpers';

import usePodDetails from 'hooks/usePodDetails';
import useRudderStack from 'hooks/useRudderstack';
import { ResourceQueries } from 'queries';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import { Container, PluginExtensionLinkPodContext, PluginExtensionPoints } from 'types';
import BreadcrumbTitle from '../BreadCrumbTitle';
import { getPodhealthStatus, podHealthToColorText } from '../podHealthUtils';
import podDetailStyles from './PodDetail.styles';
import getColumns from './podDetailColumns';
import DataList from 'components/DataList/DataList';
import useTimeRangeStore from 'store/timeRange';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { getPodDetailOptimization } from 'components/scenes/PodDetailOptimization/PodDetailOptimization';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';
import { TableView } from 'store/filters';
import FiltersRow from 'components/FiltersRow/FiltersRow';
import useCostUsage from 'hooks/useCostUsage';
import O11yButton from 'components/O11yButton';
import { SelectableValue } from '@grafana/data';
import ExtensionLinks from 'components/ExtensionLinks/ExtensionLinks';
import { EntityAssertionsWidget } from 'components/EntityAssertionsWidget/EntityAssertionsWidget';

const PodDetail = ({ model }: SceneComponentProps<PodDetailScene>) => {
  const { cluster, namespace, pod: podName, workload, workloadType } = model.useState();
  const styles = useStyles2(podDetailStyles);
  const meta = useContext(PluginMetaContext);
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);
  const trackRudderStackEvent = useRudderStack();
  const [containersFilter, setContainersFilter] = useState<string[]>([]);

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const prometheusName = useDatasourceStore(prometheusSelector);
  const prometheusDatasource = config.datasources[prometheusName];
  const lokiName = useDatasourceStore(lokiSelector);
  const lokiDatasource = config.datasources[lokiName];
  const {
    loading: podLoading,
    pod,
    startTime,
    restarts,
    containers,
    queries,
    firstLoad,
  } = usePodDetails(cluster, namespace, podName);

  const [filteredContainers, containerOpts] = useMemo(() => {
    let currentContainers = [...(containers || [])];

    const opts = currentContainers
      ?.map?.(({ container }) => ({ label: container, value: container }))
      .sort((a, b) => a.label.localeCompare(b.label));

    if (containersFilter?.length) {
      currentContainers = currentContainers?.filter?.(({ container }) => {
        return containersFilter.some((item) => container.includes(item));
      });
    }

    return [currentContainers, opts];
  }, [containers, containersFilter]);

  const {
    usageLoading,
    usageCostTableData,
    costLoading,
    firstCostLoad,
    firstUsageLoad,
    onTableSort,
    tableView,
    sortColumn,
    exploreUrl,
  } = useCostUsage<Container>({
    type: 'container',
    initialData: filteredContainers,
    usageQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.ContainerUsage, [
      cluster,
      namespace,
      podName,
    ]),
    costQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.ContainerCost, [
      cluster,
      namespace,
      podName,
    ]),
  });

  const containerData = usageCostTableData.data;
  const loading = podLoading && firstLoad;

  const podData = {
    leftList: [
      {
        label: 'status',
        value: pod?.phase || 'No data',
        display: (
          <span
            className={styles.cardBodyText}
            style={{
              color: pod
                ? podHealthToColorText(config)[getPodhealthStatus(pod)]
                : podHealthToColorText(config)[PodHealthStatus.Unknown],
            }}
          >
            {loading ? 'Loading...' : pod?.phase || 'No data'}
          </span>
        ),
      },
      {
        label: 'pod start time',
        value: startTime,
        display: (
          <span>
            {startTime}
            <a
              className={styles.exploreOnList}
              href={getExploreUrl(prometheusName, queries.podStartTime, exploreRange, true)}
              target="_blank"
              rel="noreferrer"
              onClick={() => trackRudderStackEvent(RudderstackEvents.ExplorePodStartTime, {})}
            >
              <Icon name="external-link-alt" size="xs" />
            </a>
          </span>
        ),
      },
      {
        label: 'number of restarts',
        value: restarts,
        display: (
          <span
            className={styles.cardBodyText}
            style={{
              color:
                restarts && Number(restarts) > 0
                  ? podHealthToColorText(config)[PodHealthStatus.Warning]
                  : podHealthToColorText(config)[PodHealthStatus.Healthy],
            }}
          >
            {restarts}
            <a
              className={styles.exploreOnList}
              href={getExploreUrl(prometheusName, queries.podRestarts, exploreRange, true)}
              target="_blank"
              rel="noreferrer"
              onClick={() => trackRudderStackEvent(RudderstackEvents.ExplorePodRestarts, {})}
            >
              <Icon name="external-link-alt" size="xs" />
            </a>
          </span>
        ),
      },
    ],
    rightList: [
      {
        label: 'cluster',
        value: pod?.cluster || 'No data',
        display: (
          <QueryParamsLink
            className={styles.link}
            to={`/a/${meta.id}/navigation/cluster/${encodeUrlString(pod?.cluster || '')}`}
            label={loading ? 'Loading...' : pod?.cluster || 'No data'}
          />
        ),
      },
      {
        label: 'node',
        value: pod?.node || 'No data',
        display: (
          <QueryParamsLink
            className={styles.link}
            to={`/a/${meta.id}/navigation/nodes/${encodeUrlString(pod?.cluster || '')}/${pod?.node}`}
            label={loading ? 'Loading...' : pod?.node || 'No data'}
          />
        ),
      },
      {
        label: 'namespace',
        value: pod?.namespace || 'No data',
        display: (
          <QueryParamsLink
            className={styles.link}
            to={`/a/${meta.id}/navigation/namespace/${encodeUrlString(pod?.cluster || '')}/${pod?.namespace}`}
            label={loading ? 'Loading...' : pod?.namespace || 'No data'}
          />
        ),
      },
    ],
  };

  const scene = useMemo(
    () =>
      getPodDetailOptimization({
        cluster,
        namespace,
        podName,
        datasource: prometheusDatasource,
        prometheusName,
        lokiName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [cluster, namespace, podName, prometheusName, lokiName, prometheusDatasource, relativeRange]
  );

  const handleContainerFilterUpdate = useCallback((filters: SelectableValue[] = []) => {
    const filterArray = filters?.map?.((filter) => filter.value);
    setContainersFilter(filterArray);
  }, []);

  const extensionContext: PluginExtensionLinkPodContext = {
    timeRange: range,
    workload,
    workloadType: pod?.created_by_kind,
    cluster,
    namespace,
    pod: podName,
    datasources: {
      prometheus: { name: prometheusName, uid: prometheusDatasource.uid },
      loki: { name: lokiName, uid: lokiDatasource.uid },
    },
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <BreadcrumbTitle />
            <ExploreButton
              href={getExploreUrl(prometheusName, queries.singlePodStatus, exploreRange, true)}
              label="Explore Pod"
              eventName={RudderstackEvents.ExplorePod}
            />
            <O11yButton type="pod" />
            <ExtensionLinks
              context={extensionContext}
              extensionPointId={PluginExtensionPoints.PodAction}
              showTooltip={false}
            />
            <EntityAssertionsWidget type="Pod" name={podName} />
          </div>
        </div>
        <div className={styles.sectionSpacing}>
          <h3 className={styles.sectionTitle}>Pod information</h3>
          {typeof pod === 'undefined' && !loading && firstLoad && (
            <Alert title="Pod not found" severity="warning">
              Pod doesn&apos;t exist on the selected date, try a different one.
            </Alert>
          )}
          <div className={styles.gridEqual}>
            <DataList list={podData.leftList} />
            <DataList list={podData.rightList} />
          </div>
        </div>
        <div className={styles.sectionSpacing} style={{ minHeight: 200 }}>
          <h3 className={styles.sectionTitle}>Pod optimization</h3>
          <div className={styles.sceneWrapper}>
            <scene.Component model={scene} />
          </div>
        </div>
        <div className={styles.sectionSpacing}>
          <div className={styles.exploreContainerWrapper}>
            <h3 className={styles.sectionTitle}>Containers</h3>
          </div>
          <FiltersRow
            visibleFilters={
              <MultiSelect
                width={25}
                isClearable
                placeholder="Filter by container"
                options={containerOpts}
                value={containersFilter}
                onChange={handleContainerFilterUpdate}
                maxVisibleValues={1}
              />
            }
            extraButtons={
              <ExploreButton
                href={exploreUrl}
                label="Explore containers"
                eventName={RudderstackEvents.ExploreContainers}
                style={{ marginRight: 0 }}
                size="md"
              />
            }
          />
          {firstLoad && (loading || usageLoading || costLoading) ? (
            <LoadingPlaceholder style={{ marginBottom: 0 }} text="Loading containers" />
          ) : (
            <div className={styles.marginBottom}>
              {containerData && (
                <Table<Container>
                  key={sortColumn}
                  data={containerData}
                  columns={getColumns(
                    podName,
                    tableView === TableView.Cost,
                    usageCostTableData.errors,
                    hasDateChanged,
                    prometheusName,
                    workload,
                    workloadType,
                    usageLoading && firstUsageLoad,
                    costLoading && firstCostLoad
                  )}
                  noDataText="There are no containers for this pod"
                  pointerOnHover={false}
                  onSort={onTableSort}
                  withNestedHeader
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface PodDetailState extends SceneObjectState {
  cluster: string;
  namespace: string;
  pod: string;
  workload: string;
  workloadType: string;
}

export class PodDetailScene extends SceneObjectBase<PodDetailState> {
  static Component = PodDetail;
}

export function getPodDetailAppScene(
  cluster: string,
  namespace: string,
  workload: string,
  workloadType: string,
  pod: string
) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new PodDetailScene({ cluster, namespace, pod, workload, workloadType }),
        }),
      ],
    }),
  });
}

export default PodDetail;
