import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const workloadMetadataStyles = (theme: GrafanaTheme2) => ({
  gridEqual: css`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
  `,
  metadataWrapper: css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(2)} 0;
    flex-wrap: wrap;

    h3 {
      font-size: 17px;
      margin-bottom: ${theme.spacing(2)};
    }
  `,
  flex: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `,
  marginBottom: css`
    margin-bottom: 20px;
  `,
  loadingIndicator: css`
    margin-right: ${theme.spacing(1)};
  `,
  logs: css`
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
  `,
  deploymentWrapper: css`
    margin-top: ${theme.spacing(5)};
  `,
  link: css`
    text-decoration: underline;
    margin-right: 5px;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
  workloadType: css`
    text-transform: capitalize;
  `,
  efficiencyHeader: css`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  `,
});

export default workloadMetadataStyles;
