import { PanelMenuItem } from '@grafana/data';
import { sceneGraph, VizPanel, VizPanelMenu, VizPanelState } from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';
import PredictionModal from 'components/PredictionModal/PredictionModal';
import React from 'react';
import { Link } from 'react-router-dom';

export interface DataQueryWithExpr extends DataQuery {
  expr: string;
  selector?: string;
}

interface ServiceOverviewPanelState extends VizPanelState {
  trackingAction: string;
}

export type PredictableParam = {
  button: string;
  title: string;
  name: string;
  metric: string;
  query: string;
  predictableVariableName?: string;
  axisLabel?: string;
  asMenu?: boolean;
};

export type LinkParam = {
  title: string;
  url: string;
  targetBlank: boolean;
};

export class ExplorablePanel extends VizPanel {
  constructor(state: Partial<ServiceOverviewPanelState>, predictable?: PredictableParam, link?: LinkParam) {
    super({
      ...state,
    });

    this.initHeaderActionsSync(predictable, link);
  }

  private initHeaderActionsSync(predictable?: PredictableParam, link?: LinkParam) {
    this.addActivationHandler(() => {
      const data = sceneGraph.getData(this);

      const unsubscribable = data.subscribeToState((newDataState) => {
        let queries = (newDataState.data?.request?.targets ?? []) as DataQueryWithExpr[];
        queries = queries.map?.((q) => ({
          ...q,
          expr: sceneGraph.interpolate(this, q.expr),
          selector: sceneGraph.interpolate(this, q.selector),
        }));

        const datasource = queries.find((query) => !!query.datasource?.uid)?.datasource?.uid;

        if (datasource) {
          const { from, to } = sceneGraph.getTimeRange(this).state;

          const left = encodeURIComponent(
            JSON.stringify({
              datasource,
              queries,
              range: {
                from,
                to,
              },
            })
          );

          let headerActions: React.ReactNode = [];

          if (predictable && !predictable.asMenu) {
            // Grab the required scene variable that contains the upper limit used for ML predictions
            const upperLimitResult = sceneGraph.lookupVariable(predictable.predictableVariableName as string, this);
            const upperLimit = isNaN(Number(upperLimitResult?.getValueText?.().split(' ')?.[1]))
              ? 100
              : Number(upperLimitResult?.getValueText?.().split(' ')?.[1]);
            headerActions = (
              <PredictionModal
                buttonText={predictable.button}
                title={predictable.title}
                query={sceneGraph.interpolate(this, predictable.query)}
                name={predictable.name}
                metric={predictable.metric}
                axisLabel={predictable.axisLabel}
                hyperParamsUpperLimit={upperLimit}
                scenePanel
              />
            );
          } else if (link) {
            headerActions = (
              <Link to={link.url} target={link.targetBlank ? '_blank' : ''} style={{ color: 'rgb(83, 138, 222)' }}>
                {link.title}
              </Link>
            );
          }

          const menuItems: PanelMenuItem[] = [
            {
              type: 'submenu',
              iconClassName: 'compass',
              text: 'Explore',
              href: `/explore?left=${left}`,
            },
          ];

          // Commenting so this menu is not shown, while we figure out what happens with cost queries + prediction
          // if (predictable && predictable.asMenu) {
          //   menuItems.push({
          //     type: 'submenu',
          //     text: 'Predict Cost',
          //     onClick: () => {
          //       showPredictableModal(predictable);
          //     },
          //   });
          // }

          this.setState({
            menu: new VizPanelMenu({
              items: menuItems,
            }),
            headerActions,
          });
        }
      });

      return () => unsubscribable.unsubscribe?.();
    });
  }
}
