import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export default function styles(theme: GrafanaTheme2) {
  return {
    link: css`
      text-decoration: underline;
      margin-right: 5px;
      transition: color 0.2s ease;
      &:hover {
        color: rgba(204, 204, 220, 0.65);
      }
    `,
    container: css`
      margin-top: ${theme.spacing(1)};
      display: flex;
      justify-content: flex-start;
    `,
    noData: css`
      padding: ${theme.spacing(4)} ${theme.spacing(1)};
      max-width: 800px;
    `,
    imageWrapper: css`
      margin-bottom: ${theme.spacing(8)};
    `,
  };
}
