import React, { useCallback, useEffect } from 'react';
import { useStyles2 } from '@grafana/ui';
import { shallow } from 'zustand/shallow';

import integrationsListStyles from './IntegrationsList.styles';
import { IntegrationSource } from 'api/integrationsApi';
import useIntegrationStore from 'store/integration';
import IntegrationCard from 'components/IntegrationCard/IntegrationCard';
import IntegrationModal from './IntegrationModal';
import useRudderStack from 'hooks/useRudderstack';
import { FetchStatus, RudderstackEvents } from 'enums';

const IntegrationsList = () => {
  const styles = useStyles2(integrationsListStyles);
  const [integrationModalOpen, setIntegrationModalOpen] = React.useState(false);
  const [
    getIntegrations,
    integrationList,
    selectedIntegration,
    selectedIntegrationStaticConfig,
    getIntegration,
    getIntegrationStaticConfig,
    clearSelectedIntegration,
    integrationStatus,
    integrationConfigStatus,
  ] = useIntegrationStore(
    (state) => [
      state.getIntegrations,
      state.integrationList,
      state.selectedIntegration,
      state.selectedIntegrationStaticConfig,
      state.getIntegration,
      state.getIntegrationStaticConfig,
      state.clearSelectedIntegration,
      state.integrationStatus,
      state.integrationConfigStatus,
    ],
    shallow
  );
  const trackRudderstackEvent = useRudderStack();

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  const onClick = useCallback(
    (integration: IntegrationSource) => () => {
      getIntegration(integration.slug);
      getIntegrationStaticConfig(integration.slug);
      trackRudderstackEvent(RudderstackEvents.InstalledK8sIntegration, { integration: integration.name });
    },
    [getIntegration, getIntegrationStaticConfig, trackRudderstackEvent]
  );

  useEffect(() => {
    if (integrationStatus === FetchStatus.Success && integrationConfigStatus === FetchStatus.Success) {
      setIntegrationModalOpen(true);
    }
  }, [integrationStatus, integrationConfigStatus]);

  const onModalDismiss = useCallback(() => {
    clearSelectedIntegration();
    setIntegrationModalOpen(false);
  }, [clearSelectedIntegration]);

  return (
    <>
      <IntegrationModal
        open={integrationModalOpen}
        source={selectedIntegration}
        staticConfigSource={selectedIntegrationStaticConfig}
        onDismiss={onModalDismiss}
      />
      <div className={styles.wrapper}>
        {Object.keys(integrationList)
          ?.map?.((item) => (
            <IntegrationCard
              loading={
                (integrationStatus === FetchStatus.Fetching || integrationConfigStatus === FetchStatus.Fetching) &&
                selectedIntegration?.slug === item
              }
              key={integrationList[item].slug}
              source={integrationList[item]}
              onClick={onClick(integrationList[item])}
            />
          ))
          .sort((a, b) => a.props.source.name.localeCompare(b.props.source.name))}
      </div>
    </>
  );
};

export default IntegrationsList;
