import { Clipboard } from '../Clipboard';
import React from 'react';
import { useStyles2 } from '@grafana/ui';
import integrationsListStyles from './IntegrationsList.styles';
import { StaticConfigSource } from '../../api/integrationsApi';

type Props = {
  source: StaticConfigSource | null;
};

const StaticAgentIntegration = ({ source }: Props) => {
  const styles = useStyles2(integrationsListStyles);
  const noAgentData =
    !source?.static?.integrations_snippets && !source?.static?.metrics_snippets && !source?.static?.logs_snippets;

  return (
    <div className={styles.sectionSpace}>
      <h4 className={styles.title}>Agent configuration</h4>
      <p className={styles.description}>
        {noAgentData
          ? 'No configuration required for this integration.'
          : 'Add each configuration snippet below into the appropriate section of your grafana-agent configmap.'}
      </p>
      {source?.static?.integrations_snippets && (
        <div className={`${styles.sectionSpace} ${styles.clipboard}`}>
          <h4 className={styles.title}>Integration snippets</h4>
          <Clipboard
            content={source.static.integrations_snippets}
            withReactMarkdown
            className={styles.clipboard}
            resetTime={1000}
          />
        </div>
      )}
      {source?.static?.metrics_snippets && (
        <div className={`${styles.sectionSpace} ${styles.clipboard}`}>
          <h4 className={styles.title}>Metrics snippets</h4>
          <Clipboard
            content={source.static.metrics_snippets}
            withReactMarkdown
            className={styles.clipboard}
            resetTime={1000}
          />
        </div>
      )}
      {source?.static?.logs_snippets && (
        <div className={`${styles.sectionSpace} ${styles.clipboard}`}>
          <h4 className={styles.title}>Logs snippets</h4>
          <Clipboard
            content={source.static.logs_snippets}
            withReactMarkdown
            className={styles.clipboard}
            resetTime={1000}
          />
        </div>
      )}
    </div>
  );
};

export default StaticAgentIntegration;
