import React, { useCallback } from 'react';
import { LoadingPlaceholder, useStyles2, Button } from '@grafana/ui';
import { RudderstackEvents, FetchStatus } from 'enums';
import noDataStyles from './NoDataFlowing.styles';
import { shallow } from 'zustand/shallow';
import { trackRudderStackEvent } from 'hooks/useRudderstack';
import useIntegrationStore from 'store/integration';
import { useHistory } from 'react-router-dom';
import { NO_DATA_FLOWING_MAP, PLUGIN_ROOT_URL } from '../../constants';
import PrometheusPicker from 'components/PrometheusPicker';
import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneObjectBase } from '@grafana/scenes';

type NoDataFlowingProps = {
  message: {
    title: string;
    description: string;
  };
  withDataSource?: boolean;
  children?: React.ReactElement;
};

const NoDataFlowing = ({ message = NO_DATA_FLOWING_MAP.costs, withDataSource, children }: NoDataFlowingProps) => {
  const styles = useStyles2(noDataStyles);
  const { push } = useHistory();
  const [dataFlowing, dataFlowingStatus] = useIntegrationStore(
    (state) => [state.dataFlowing, state.dataFlowingStatus],
    shallow
  );

  const onStartSendingData = useCallback(() => {
    trackRudderStackEvent(RudderstackEvents.SetupStart, {});
    push(`${PLUGIN_ROOT_URL}/configuration/cluster-config`);
  }, [push]);

  if ([FetchStatus.Fetching, FetchStatus.Idle].includes(dataFlowingStatus)) {
    const loaderComponent = (
      <div className={styles.loading}>
        <LoadingPlaceholder text="Loading ..." />
      </div>
    );

    return loaderComponent;
  }

  if (!dataFlowing && [FetchStatus.Success, FetchStatus.Error].includes(dataFlowingStatus)) {
    const messageComponent = (
      <div className={styles.wrapper}>
        {withDataSource && <PrometheusPicker />}
        <div className={styles.container}>
          <h3>{message.title}</h3>
          <span className={styles.description}>{message.description}</span>
          <Button role="button" onClick={onStartSendingData} className={styles.button}>
            Start sending data
          </Button>
        </div>
      </div>
    );

    return messageComponent;
  }

  return children ?? null;
};

export default NoDataFlowing;

export class NoDataFlowingScene extends SceneObjectBase {
  static Component = NoDataFlowing;
}

export function getNoDataFlowingAppScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new NoDataFlowingScene({}),
        }),
      ],
    }),
  });
}
