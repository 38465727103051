import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export default (status: string) => (theme: GrafanaTheme2) => ({
  alert: css`
    margin-bottom: ${theme.spacing(2)};
    display: flex;
    align-items: center;
    ${status === 'error' && `color: ${theme.colors.error.text};`}
    ${status === 'success' && `color: ${theme.colors.success.text};`}
    ${status === 'warning' && `color: ${theme.colors.warning.text};`}
  `,
  success: css`
    color: ${theme.isLight ? '#5aa64b' : '#99d98d'};
  `,
  icon: css`
    margin-right: 6px;
  `,
  alertContent: css`
    margin-bottom: 0;
  `,
});
