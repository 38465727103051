import { ClusterNamespacePodResult, GeneralQueryResult, Pod, WaitingQueryResult } from 'types';
import { useMemo } from 'react';
import { NodeQueries, Queries } from 'queries';
import useTimeRangeStore from 'store/timeRange';
import useDataFrame from './useDataFrame';

type UsePodsType = {
  loading: boolean;
  validating: boolean;
  pods: Pod[];
  alertMap: Map<string, number>;
  firstLoad: boolean;
};

export const getAlertMap = (alertData: Array<GeneralQueryResult<ClusterNamespacePodResult>>) => {
  const alertMap = new Map<string, number>();
  alertData?.forEach?.((metric) => {
    const key = `${metric.metric.cluster}-${metric.metric.namespace}-${metric.metric.pod}`;
    const value = parseInt(metric.value[1], 10);
    const current = alertMap.get(key) || 0;
    alertMap.set(key, current + value);
  });

  return alertMap;
};

export const getWaitingMap = (waitingData: Array<GeneralQueryResult<WaitingQueryResult>>) => {
  const waitingMap = new Map<string, string>();
  waitingData?.forEach?.((metric) => {
    const key = `${metric.metric.cluster}-${metric.metric.namespace}-${metric.metric.pod}`;
    waitingMap.set(key, metric.metric.reason);
  });

  return waitingMap;
};

export const mergePods = (
  statusData: Array<GeneralQueryResult<Pod>>,
  containerWaitingData: Array<GeneralQueryResult<WaitingQueryResult>>,
  alertsData: Array<GeneralQueryResult<ClusterNamespacePodResult>>
) => {
  const alertMap = getAlertMap(alertsData);
  const containerWaitingMap = getWaitingMap(containerWaitingData);

  const pods = statusData?.map?.((metric) => {
    const key = `${metric.metric.cluster}-${metric.metric.namespace}-${metric.metric.pod}`;
    const alerts = alertMap.get(key) || 0;
    return {
      ...metric.metric,
      timestamp: parseInt(metric.value[1], 10),
      waiting_reason: containerWaitingMap.get(key) || undefined,
      alertCount: alerts,
    } as Pod;
  });

  return { pods, alertMap };
};

function usePods(
  cluster: string,
  namespace?: string,
  workload?: string,
  node?: string,
  workloadType?: string
): UsePodsType {
  const [range, hasDateChanged] = useTimeRangeStore((state) => [state.range, state.hasDateChanged]);
  const statusPhaseQuery = Queries.PodStatusPhase(cluster, namespace, workload, node);
  const containersWaitingQuery = Queries.PodContainersWaiting(cluster, namespace);

  const queries = [statusPhaseQuery, containersWaitingQuery];

  if (node) {
    queries.push(NodeQueries.NodePodsAlertsCount(cluster, node));
  } else {
    queries.push(Queries.PodsAlertsCount(cluster, namespace));
  }

  const { loading, validating, data, firstLoad } = useDataFrame(
    queries,
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '',
    !hasDateChanged,
    1,
    true,
    true
  );

  const [statusData, containerWaitingData, alertsData] = data as [
    Array<GeneralQueryResult<Pod>>,
    Array<GeneralQueryResult<WaitingQueryResult>>,
    Array<GeneralQueryResult<ClusterNamespacePodResult>>
  ];

  const { pods, alertMap } = useMemo(() => {
    const { pods, alertMap } = mergePods(statusData, containerWaitingData, alertsData);

    let filteredPods = pods;
    // filter pods by expected workload type, so we don't show pods for the case
    // when we have a workload with equal cluster, namespace, workload name, but just different type
    if (workloadType) {
      filteredPods = pods?.filter((item) => item.workload_type === workloadType?.toLowerCase());
    }

    return {
      pods: filteredPods,
      alertMap,
    };
  }, [statusData, containerWaitingData, alertsData, workloadType]);

  return {
    loading,
    validating,
    pods,
    alertMap,
    firstLoad,
  };
}

export default usePods;
