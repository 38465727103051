import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const outlierModalStyles = (theme: GrafanaTheme2) => ({
  modal: css``,
  outlierList: css`
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    h4 {
      margin: 0;
    }
  `,
  link: css`
    text-decoration: underline;
    margin-right: 25px;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
});

export default outlierModalStyles;
