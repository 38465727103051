import React, { useCallback, useMemo, useState } from 'react';
import {
  GeneralQueryResult,
  GenericUsageResult,
  PluginExtensionLinkClusterContext,
  PluginExtensionPoints,
} from 'types';

import { ClusterQueries, NodeQueries } from 'queries';
import useTimeRangeStore from 'store/timeRange';
import clusterStyles from './ClusterDetail.styles';
import NodesList from 'components/NodesList/NodesList';
import { config } from '@grafana/runtime';
import { Alert, LoadingPlaceholder, useStyles2 } from '@grafana/ui';
import BreadcrumbTitle from 'components/workloads/BreadCrumbTitle';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { chunk } from 'lodash';
import DataList from 'components/DataList/DataList';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import { getExploreUrl } from 'helpers/helpers';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { getClusterDetailOptimization } from 'components/scenes/ClusterDetailOptimization/ClusterDetailOptimization';
import { MAX_ITEMS_PER_LIST, MAX_NUMBER_OF_LISTS } from '../../constants';
import ExtensionLinks from 'components/ExtensionLinks/ExtensionLinks';
import { EntityAssertionsWidget } from 'components/EntityAssertionsWidget/EntityAssertionsWidget';
import useDataFrame from 'hooks/useDataFrame';

const ClusterDetail = ({ model }: SceneComponentProps<ClusterDetailScene>) => {
  const { cluster } = model.useState();
  const prometheusName = useDatasourceStore(prometheusSelector);
  const prometheusDatasource = config.datasources[prometheusName];
  const lokiName = useDatasourceStore(lokiSelector);
  const lokiDatasource = config.datasources[lokiName];
  const styles = useStyles2(clusterStyles);
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);

  const { ...queries } = ClusterQueries(cluster);
  const [data, setData] = useState<unknown | null>(null);
  const {
    loading,
    data: queriesData,
    firstLoad,
  } = useDataFrame(
    [queries.ClusterMemory, queries.DiskSpaceSize],
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '',
    !hasDateChanged,
    1,
    true,
    true
  );

  const [memory, diskSize] = queriesData as [
    Array<GeneralQueryResult<GenericUsageResult>>,
    Array<GeneralQueryResult<GenericUsageResult>>
  ];

  const nodeQueries = [
    NodeQueries.NodesCondition(cluster),
    NodeQueries.PodsPerNode(cluster),
    NodeQueries.NodeInfo(cluster),
    NodeQueries.NodeAlertsCount(cluster),
  ];

  const diskSizeTotal = useMemo(() => parseFloat(diskSize?.[0]?.value?.[1]) || 0, [diskSize]);
  const memoryTotal = useMemo(() => parseFloat(memory?.[0]?.value?.[1]) || 0, [memory]);

  const onData = useCallback((data: unknown) => {
    setData(data);
  }, []);

  // Lets split objects into different lists (leftList/rightList)
  const clusterData = useMemo(() => {
    if (data) {
      const clonedData = {
        ...data,
        memory: `${(memoryTotal / 1024 / 1024 / 1024).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })} GiB in total`,
        'disk size': `${(diskSizeTotal / 1024 / 1024 / 1024).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })} GiB in total`,
      };
      const arrayFromObject = Object.entries(clonedData as unknown as ArrayLike<unknown>)?.map?.(([key, value]) => ({
        value,
        label: key,
      }));
      const itemsPerSubarray = Math.min(MAX_ITEMS_PER_LIST, Math.ceil(arrayFromObject.length / MAX_NUMBER_OF_LISTS));
      const finalArray = chunk(arrayFromObject, itemsPerSubarray);

      return {
        leftList: finalArray[0] || [],
        centerList: finalArray[1] || [],
        rightList: finalArray[2] || [],
      };
    }

    return {};
  }, [data, memoryTotal, diskSizeTotal]);

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const scene = useMemo(
    () =>
      getClusterDetailOptimization({
        cluster,
        datasource: prometheusDatasource,
        prometheusName,
        lokiName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [cluster, relativeRange, prometheusName, lokiName, prometheusDatasource]
  );

  const extensionContext: PluginExtensionLinkClusterContext = {
    timeRange: range,
    cluster,
    datasources: {
      prometheus: { name: prometheusName, uid: prometheusDatasource.uid },
      loki: { name: lokiName, uid: lokiDatasource.uid },
    },
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <BreadcrumbTitle />
            <ExploreButton
              href={getExploreUrl(prometheusName, NodeQueries.NodeInfo(cluster), exploreRange, true)}
              label="Explore cluster"
            />
            <ExtensionLinks
              context={extensionContext}
              extensionPointId={PluginExtensionPoints.ClusterAction}
              showTooltip={false}
            />
            <EntityAssertionsWidget name={cluster} type="KubeCluster" />
          </div>
        </div>
        {!data && !loading && hasDateChanged && (
          <div className={styles.sectionSpacing}>
            <Alert title="Cluster not found" severity="warning">
              Cluster doesn&apos;t exist on the selected date, try a different one.
            </Alert>
          </div>
        )}
        <div className={styles.sectionSpacing}>
          <h3 className={styles.sectionTitle}>Cluster information</h3>
          <div className={styles.infoWrapper}>
            {(!!data && !loading) || !firstLoad ? (
              <>
                <DataList list={clusterData?.leftList} />
                <DataList list={clusterData?.centerList} />
                <DataList list={clusterData?.rightList} />
              </>
            ) : (
              <>
                {loading ? <LoadingPlaceholder text="Loading cluster information" /> : <span>No data available.</span>}
              </>
            )}
          </div>
        </div>
        <div className={styles.sectionSpacing} style={{ minHeight: 200 }}>
          <h3 className={styles.sectionTitle}>Cluster optimization</h3>
          <div className={styles.sceneWrapper}>
            <scene.Component model={scene} />
          </div>
        </div>
        <div className={styles.sectionSpacing}>
          <h3 className={styles.sectionTitle}>Nodes</h3>
          <NodesList
            hideClusterColumn
            hideProviderColumn
            withFilters={false}
            queries={nodeQueries}
            defaultClusters={[cluster]}
            onData={onData}
          />
        </div>
      </div>
    </div>
  );
};

export default ClusterDetail;

interface ClusterDetailState extends SceneObjectState {
  cluster: string;
}

export class ClusterDetailScene extends SceneObjectBase<ClusterDetailState> {
  static Component = ClusterDetail;
}

export function getClusterDetailAppScene(cluster: string) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new ClusterDetailScene({
            cluster,
          }),
        }),
      ],
    }),
  });
}
