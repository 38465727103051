import React from 'react';
import {
  EmbeddedScene,
  SceneAppDrilldownView,
  SceneAppPage,
  SceneAppPageLike,
  SceneControlsSpacer,
  SceneDataTransformer,
  SceneQueryRunner,
  SceneReactObject,
  SceneRefreshPicker,
  SceneRouteMatch,
  SceneTimePicker,
  SceneTimeRange,
  SceneTimeRangeState,
  VizPanel,
  VizPanelMenu,
  sceneGraph,
  sceneUtils,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { DateTimeRange, setRefreshInterval, setTimeRange } from 'store/timeRange';
import { RudderstackEvents } from 'enums';
import { trackRudderStackEvent } from 'hooks/useRudderstack';
import {
  CustomTransformOperator,
  DataFrame,
  DataTransformerConfig,
  DateTime,
  PanelMenuItem,
  TimeRange,
  dateTime,
} from '@grafana/data';
import PastDataAlert from 'components/PastDataAlert/PastDataAlert';
import {
  DEFAULT_REFRESH_INTERVAL,
  DEFAULT_TIME_UNIT,
  K8S_STORAGE_KEY,
  PLUGIN_ROOT_URL,
  REFRESH_INTERVAL_OPTIONS,
} from '../constants';
import LokiPicker from 'components/LokiPicker';
import { decodeUrlString, encodeUrlString, saveRefreshInterval, sceneRefreshIntervalToMs } from './helpers';
import { ScenesCustomParams, ScenesRouteParams } from 'types';
import { DataQueryWithExpr } from 'components/scenes/ExplorablePanel';
import { Tag } from '@grafana/ui';

type QueryRunnerOptions = {
  legendFormat?: string;
  interval?: string;
  instant?: boolean;
  range?: boolean;
  refId?: string;
  intervalMs?: string;
  maxDataPoints?: number;
  transformations?: Array<DataTransformerConfig | CustomTransformOperator>;
  format?: string;
  cardinalityType?: string;
  parameterList?: string[];
  resultType?: string;
  selector?: string;
  targetDatasource?: string;
  limit?: number;
};

type Query = {
  expr?: string;
  instant?: boolean;
  interval: string;
  range?: boolean;
  refId: string;
  legendFormat: string;
  format?: string;
  cardinalityType?: string;
  parameterList?: string[];
  resultType?: string;
  selector?: string;
  targetDatasource?: string;
  limit?: number;
};

export function getGenericQueryRunner(
  datasource: DataSourceRef,
  query: string,
  options?: QueryRunnerOptions,
  extraQueries?: Query[]
) {
  const initialQuery: Query = {
    instant: options?.instant ?? true,
    interval: options?.interval ?? '',
    range: options?.range ?? false,
    refId: options?.refId ?? 'A',
    legendFormat: options?.legendFormat || '__auto',
    format: options?.format,
  };

  if (query) {
    initialQuery.expr = query;
  }

  if (options?.targetDatasource) {
    initialQuery.cardinalityType = options?.cardinalityType;
    initialQuery.parameterList = options?.parameterList;
    initialQuery.resultType = options?.resultType;
    initialQuery.selector = options?.selector;
    initialQuery.targetDatasource = options?.targetDatasource;
  }

  if (options?.limit) {
    initialQuery.limit = options?.limit;
  }

  const queries: Query[] = [initialQuery];

  if (extraQueries) {
    queries.push(...extraQueries);
  }

  const queryRunner = new SceneQueryRunner({
    datasource: datasource,
    queries,
    minInterval: options?.intervalMs ?? '',
    maxDataPoints: options?.maxDataPoints ?? 500,
  });

  if (options?.transformations) {
    const transformedRunner = new SceneDataTransformer({
      $data: queryRunner,
      transformations: options?.transformations,
    });

    return transformedRunner;
  }
  return queryRunner;
}

export function getAppPage(
  params: ScenesCustomParams,
  url: string,
  title: string,
  parent: SceneAppPageLike,
  getSceneFn: ((params: ScenesCustomParams) => EmbeddedScene) | undefined,
  drilldowns: SceneAppDrilldownView[],
  config: { timeRange: SceneTimeRangeState; range?: DateTimeRange },
  pageType: string,
  tabs?: SceneAppPage[]
) {
  const sceneTimePicker = new SceneTimePicker(config?.timeRange);
  const tabsTimeRange = new SceneTimeRange(config?.timeRange);
  addTimeRangeHandler(tabsTimeRange);

  // If not namespace or node available on url, means we are on cluster detail view, and we should decode cluster param to change --- into /
  const titleString = !params.namespace && !params.node ? decodeUrlString(title) : title;
  let tagName = pageType;
  if (pageType === 'podName') {
    tagName = 'pod';
  } else if (pageType === 'workload' && params.workloadType) {
    tagName = params.workloadType;
  }

  const sceneApp = new SceneAppPage({
    url,
    title: titleString,
    renderTitle: () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ marginRight: '10px' }}>{titleString}</h1>
        <Tag name={tagName} />
      </div>
    ),
    subTitle: <PastDataAlert sceneTimeRange={tabsTimeRange as SceneTimeRange} />,
    $timeRange: tabsTimeRange,
    getParentPage: () => parent,
    getScene: typeof getSceneFn === 'function' ? () => getSceneFn?.(params) : undefined,
    drilldowns,
    tabs,
    controls: [
      new SceneReactObject({
        reactNode: React.createElement(LokiPicker),
      }),
      sceneTimePicker,
      new SceneControlsSpacer(),
      getRefreshPicker(),
    ],
  });

  sceneApp.addActivationHandler(() => {
    tabsTimeRange.onTimeRangeChange({
      from: config?.range?.from as DateTime,
      to: config?.range?.to as DateTime,
      raw: config?.timeRange,
    });
  });

  if (parent.state.title === '_') {
    parent.setState({ hideFromBreadcrumbs: true });
  }

  return sceneApp;
}

export function createTab(
  title: string,
  url: string,
  getSceneFn: () => EmbeddedScene,
  drilldowns: SceneAppDrilldownView[] = [],
  tabs?: SceneAppPage[]
) {
  return new SceneAppPage({
    title,
    url,
    getScene: () => getSceneFn(),
    drilldowns,
    tabs,
  });
}

export function createDrilldown(
  routePath: string,
  param: string,
  getSceneFn: ((params?: ScenesCustomParams) => EmbeddedScene) | undefined,
  config: { timeRange: SceneTimeRangeState; range?: DateTimeRange },
  drilldowns: SceneAppDrilldownView[] = [],
  tabs?: (params: ScenesRouteParams) => SceneAppPage[]
) {
  return {
    routePath,
    getPage(routeMatch: SceneRouteMatch<ScenesRouteParams>, parent: SceneAppPageLike) {
      return getAppPage(
        routeMatch.params as ScenesCustomParams,
        routeMatch.url,
        routeMatch.params[param as keyof typeof routeMatch.params]!,
        parent,
        getSceneFn,
        drilldowns,
        config,
        param,
        tabs?.({ ...routeMatch.params, cluster: decodeUrlString(routeMatch.params?.cluster!) })
      );
    },
  };
}

export function addTimeRangeHandler(timeRange: SceneTimeRange, onTimeRangeChange?: (timeRange: TimeRange) => void) {
  timeRange.addActivationHandler(() => {
    const listener = timeRange.subscribeToState((newState) => {
      const { from, to, raw } = newState.value;
      const includesNow = to.isSame(dateTime(), DEFAULT_TIME_UNIT);
      const relativeRange = { from: raw.from.toString(), to: raw.to.toString() };

      if (typeof onTimeRangeChange === 'function') {
        onTimeRangeChange(newState.value);
      }

      setTimeRange({ from, to, raw }, !includesNow, relativeRange);
    });

    return () => listener?.unsubscribe();
  });
}

export function addRefreshPickerHandler(refreshPicker: SceneRefreshPicker) {
  refreshPicker.addActivationHandler(() => {
    // If no refresh interval param available, use the default one
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('refresh') === null) {
      const refreshInterval = (JSON.parse(localStorage?.getItem(K8S_STORAGE_KEY) as string) || {})?.refreshInterval;
      refreshPicker.updateFromUrl({ refresh: refreshInterval ?? DEFAULT_REFRESH_INTERVAL });
      refreshPicker.onIntervalChanged(refreshInterval ?? DEFAULT_REFRESH_INTERVAL);
    }

    const listener = refreshPicker.subscribeToState((newState) => {
      saveRefreshInterval(newState.refresh);
      setRefreshInterval(sceneRefreshIntervalToMs(newState.refresh));
      trackRudderStackEvent(RudderstackEvents.ChangeRefreshPicker, {});
    });

    return () => listener?.unsubscribe();
  });
}

export function getValueFromSeries(data: DataFrame[] | undefined, defaultValue?: unknown) {
  if (Array.isArray(data)) {
    const value = data[0]?.fields?.[1]?.values?.get(0);
    return value;
  }

  return defaultValue ?? null;
}

export function addBreadcrumbSyncHandler(scene: SceneAppPage) {
  scene.addActivationHandler(() => {
    // additional check on activation required for deep-linked drilldowns
    const hideFromBreadcrumbs = !scene.state.tabs?.some((v) => v.isActive);
    if (hideFromBreadcrumbs !== scene.state.hideFromBreadcrumbs) {
      scene.setState({ hideFromBreadcrumbs });
    }

    const unsubscribable = scene.subscribeToState((newState) => {
      let title = newState.title;

      // hide only if in drilldowns (not active tabs)
      const hideFromBreadcrumbs = !newState.tabs?.some((v) => {
        if (!v.isActive) {
          return false;
        }
        title = v.state.title;
        return true;
      });

      // prevent infinite state recursion
      if (hideFromBreadcrumbs !== newState.hideFromBreadcrumbs) {
        scene.setState({ hideFromBreadcrumbs });
      }

      // separate check as these can change independently if navigating away
      if (title !== newState.title) {
        scene.setState({ title });
      }
    });

    return () => unsubscribable.unsubscribe?.();
  });

  return scene;
}

export function getRefreshPicker(): SceneRefreshPicker {
  const refreshPicker = new SceneRefreshPicker({
    intervals: REFRESH_INTERVAL_OPTIONS,
    isOnCanvas: true,
  });

  addRefreshPickerHandler(refreshPicker);
  return refreshPicker;
}

export function addDatasourceSyncHandler(
  scene: SceneAppPage | EmbeddedScene,
  prometheusName: string,
  lokiName: string
) {
  scene.addActivationHandler(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('var-datasource', prometheusName);
    params.set('var-loki', lokiName);
    sceneUtils.syncStateFromSearchParams(scene, params);
  });

  return scene;
}

export function addVizPanelMenuHandler(panel: VizPanel, extraItems: PanelMenuItem[] = []) {
  panel.addActivationHandler(() => {
    const unsubscribable = sceneGraph.getData(panel).subscribeToState((newState) => {
      let queries = (newState.data?.request?.targets ?? []) as DataQueryWithExpr[];
      queries = queries.map?.((q) => ({
        ...q,
        expr: sceneGraph.interpolate(panel, q.expr),
        selector: sceneGraph.interpolate(panel, q.selector),
      }));

      const datasource = queries.find((query) => !!query.datasource?.uid)?.datasource?.uid;

      if (!datasource) {
        return;
      }

      const { from, to } = sceneGraph.getTimeRange(panel).state;

      const left = encodeURIComponent(
        JSON.stringify({
          datasource,
          queries,
          range: {
            from,
            to,
          },
        })
      );

      panel.setState({
        menu: new VizPanelMenu({
          items: [
            {
              type: 'submenu',
              iconClassName: 'compass',
              text: 'Explore',
              href: `/explore?left=${left}`,
            },
            ...extraItems,
          ],
        }),
      });
    });

    return () => unsubscribable.unsubscribe?.();
  });

  return panel;
}

/**
 * Scene variables using query_result() require line breaks to be removed,
 * otherwise the /api/v1/series endpoint is used instead of the correct
 * api/v1/query endpoint.
 *
 * https://github.com/grafana/grafana-k8s-plugin/issues/1208
 */
export const query_result = (q: string) => {
  return `query_result(${q.replaceAll('\n', '')})`;
};

export const routePaths = {
  navigation: `${PLUGIN_ROOT_URL}/navigation`,
  clusters: `${PLUGIN_ROOT_URL}/navigation/cluster`,
  namespaces: `${PLUGIN_ROOT_URL}/navigation/namespace`,
  workloads: `${PLUGIN_ROOT_URL}/navigation/workload`,
  nodes: `${PLUGIN_ROOT_URL}/navigation/nodes`,
  cluster: `${PLUGIN_ROOT_URL}/navigation/cluster/:cluster`,
  namespace: `${PLUGIN_ROOT_URL}/navigation/namespace/:cluster/:namespace`,
  workload: `${PLUGIN_ROOT_URL}/navigation/namespace/:cluster/:namespace/:workloadType/:workload`,
  pod: `${PLUGIN_ROOT_URL}/navigation/namespace/:cluster/:namespace/:workloadType/:workload/:podName`,
  container: `${PLUGIN_ROOT_URL}/navigation/namespace/:cluster/:namespace/:workloadType/:workload/:podName/:container`,
  node: `${PLUGIN_ROOT_URL}/navigation/nodes/:cluster/:node`,
};

export function getUrlFromRoutePath(params: ScenesRouteParams) {
  const { cluster, namespace, workloadType, workload, podName, container, node } = params;

  const replacePlaceholders = (template: string) =>
    template
      .replace(':cluster', encodeUrlString(cluster!) || '')
      .replace(':namespace', namespace || '')
      .replace(':workloadType', workloadType || '')
      .replace(':workload', workload || '')
      .replace(':podName', podName || '')
      .replace(':container', container || '')
      .replace(':node', node || '');

  return {
    cluster: replacePlaceholders(routePaths.cluster),
    namespace: replacePlaceholders(routePaths.namespace),
    workload: replacePlaceholders(routePaths.workload),
    pod: replacePlaceholders(routePaths.pod),
    container: replacePlaceholders(routePaths.container),
    node: replacePlaceholders(routePaths.node),
  };
}
