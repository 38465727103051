import React, { useState } from 'react';
import TableViewSwitch from 'components/TableViewSwitch/TableViewSwitch';
import useFilters, { TableView } from 'store/filters';
import UsageLabelRow from 'components/UsageLabels/UsageLabelRow';
import { Button, Icon, useStyles2 } from '@grafana/ui';
import filterBarStyles from './FiltersRow.styles';
import { useWindowSize } from 'react-use';
import { StoredParams } from 'types';
import useLocalStorage from 'hooks/useLocalStorage';
import { K8S_STORAGE_KEY } from '../../constants';

type FilterBarProps = {
  extraButtons?: React.ReactElement;
  visibleFilters?: React.ReactElement;
  hiddenFilters?: React.ReactElement;
  hiddenFilterCount?: number;
  visibleFilterCount?: number;
  withFilters?: boolean;
  onClearFilters?: () => void;
};

const FilterBar = ({
  extraButtons,
  visibleFilters,
  hiddenFilters,
  visibleFilterCount = 0,
  hiddenFilterCount = 0,
  withFilters = true,
  onClearFilters,
}: FilterBarProps) => {
  const [storedParams, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);
  const [filterBarOn, setFilterBarOn] = useState(storedParams?.filterBarOn || false);
  const tableView = useFilters((state) => state.tableView);
  const styles = useStyles2(filterBarStyles);
  const { width: windowWidth } = useWindowSize();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <TableViewSwitch />
          {extraButtons || null}
          <div className={styles.visibleFiltersWrapper}>{visibleFilters || null}</div>
          {visibleFilters && hiddenFilters && (
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setFilterBarOn(!filterBarOn);
                setStoredParams({ filterBarOn: !filterBarOn });
              }}
            >
              <span>
                {filterBarOn ? 'Hide' : 'Show'} filters {hiddenFilterCount >= 1 ? `(${hiddenFilterCount})` : ''}
              </span>
              <span style={{ marginLeft: 2 }}>
                <Icon name={filterBarOn ? 'arrow-up' : 'arrow-down'} size="lg" />
              </span>
            </Button>
          )}
        </div>
        {tableView === TableView.Usage && <UsageLabelRow windowWidth={withFilters ? windowWidth : 0} />}
      </div>
      {filterBarOn && hiddenFilters && (
        <div className={styles.hiddenFiltersWrapper}>
          {hiddenFilters}
          {onClearFilters && (
            <span className={styles.clearFilters} onClick={onClearFilters}>
              Clear filters
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterBar;
