import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const titleWrapperStyle = (theme: GrafanaTheme2) => ({
  mainTitleWrapper: css`
    border-top: 1px solid ${theme.colors.border.weak};
    margin-top: -16px;
    width: 100%;
    padding-top: 16px;

    h1 {
      margin-bottom: 36px;
    }
  `,
});

const clusterList = (theme: GrafanaTheme2) => ({
  ...titleWrapperStyle(theme),
});

export default clusterList;
