import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const usageLabelsStyles =
  (noValue = false) =>
  (theme: GrafanaTheme2) => ({
    wrapper: css`
      display: flex;
      align-items: center;
      gap: 8px;
      width: ${!noValue ? '130px' : 'max-content'};
    `,
    squareContainer: css`
      width: 10px;
      height: 10px;
      position: relative;
    `,
    square: css`
      width: 100%;
      height: 100%;
      background-color: transparent;
      position: relative;
      border: 1px solid ${theme.colors.text.primary};
      border-top: none;
    `,
    innerSquare: css`
      position: absolute;
      bottom: 1px;
      left: 1px;
      width: calc(100% - 2px);
      background-color: ${theme.colors.text.primary};
    `,
    lowUsage: css`
      height: calc(35% - 2px);
    `,
    medUsage: css`
      height: calc(70% - 2px);
    `,
    highUsage: css`
      height: calc(97% - 2px);
    `,
    labelRow: css`
      display: flex;
      gap: ${theme.spacing(2)};
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        gap: 16px;
      }
    `,
    labelWrapper: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(1)};
    `,
    label: css`
      width: 21px;
      border-radius: 8px;
      height: 6px;
      margin-left: 6px;
    `,
    lowLabel: css`
      color: ${theme.colors.warning.main};
    `,
    medLabel: css`
      color: ${theme.colors.success.main};
    `,
    highLabel: css`
      color: ${theme.colors.error.main};
    `,
    unknown: css`
      color: ${theme.colors.text.primary};
    `,
  });

export default usageLabelsStyles;
