import { ThresholdsMode } from '@grafana/schema';
import { PanelConfig } from '../CostsOverviewScene/panelConfig';
import { EnergyState } from './Energy';

export const energyBySourceConfig = (panel: PanelConfig, type?: EnergyState['type']) => {
  return {
    pluginId: 'timeseries',
    title: panel.title,
    $data: panel.runner,
    description: panel.description,
    options: {
      tooltip: {
        mode: 'multi',
        sort: 'none',
      },
      legend: {
        showLegend: true,
        displayMode: 'table',
        placement: 'right',
        calcs: ['min', 'mean', 'max'],
        sortBy: 'Mean',
        sortDesc: true,
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: 'line',
          lineInterpolation: 'smooth',
          barAlignment: 0,
          barWidthFactor: 0.6,
          lineWidth: 2,
          fillOpacity: 8,
          gradientMode: 'none',
          spanNulls: false,
          insertNulls: false,
          showPoints: 'never',
          pointSize: 5,
          stacking: {
            mode: 'normal',
            group: 'A',
          },
          axisPlacement: 'auto',
          axisLabel: '',
          axisColorMode: 'text',
          axisBorderShow: false,
          scaleDistribution: {
            type: 'linear',
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: 'off',
          },
        },
        color: {
          mode: 'continuous-GrYlRd',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        unit: 'kwatth',
      },
      overrides: [
        {
          matcher: {
            id: 'byName',
            options: 'Total',
          },
          properties: [
            {
              id: 'custom.stacking',
              value: {
                mode: 'none',
                group: 'A',
              },
            },
            {
              id: 'custom.fillOpacity',
              value: 0,
            },
          ],
        },
      ],
    },
  };
};

export const energyByResourceConfig = (panel: PanelConfig, resourceType: string) => {
  let links = [];
  switch (resourceType) {
    case 'Node':
      links.push({
        title: 'Go to node: ${__field.labels.node}',
        url: '/a/grafana-k8s-app/navigation/nodes/${__field.labels.encoded_cluster}/${__field.labels.node}/energy',
      });
      break;
    case 'Namespace':
      links.push({
        title: 'Go to namespace: ${__field.labels.namespace}',
        url: '/a/grafana-k8s-app/navigation/namespace/${__field.labels.encoded_cluster}/${__field.labels.namespace}/energy',
      });
      break;
    case 'Workload':
      links.push({
        title: 'Go to ${__field.labels.workload_type}: ${__field.labels.workload}',
        url: '/a/grafana-k8s-app/navigation/namespace/${__field.labels.encoded_cluster}/${__field.labels.namespace}/${__field.labels.workload_type}/${__field.labels.workload}/energy',
      });
      break;
    case 'Pod':
      links.push({
        title: 'Go to pod: ${__field.labels.pod}',
        url: '/a/grafana-k8s-app/navigation/namespace/${__field.labels.encoded_cluster}/${__field.labels.namespace}/${__field.labels.workload_type}/${__field.labels.workload}/${__field.labels.pod}/energy',
      });
      break;
    case 'Container':
      links.push({
        title: 'Go to container: ${__field.labels.container}',
        url: '/a/grafana-k8s-app/navigation/namespace/${__field.labels.encoded_cluster}/${__field.labels.namespace}/${__field.labels.workload_type}/${__field.labels.workload}/${__field.labels.pod}/${__field.labels.container}/energy',
      });
      break;
  }

  return {
    pluginId: 'timeseries',
    title: panel.title,
    $data: panel.runner,
    description: panel.description,
    options: {
      tooltip: {
        mode: 'single',
        sort: 'none',
      },
      legend: {
        showLegend: true,
        displayMode: 'table',
        placement: 'right',
        calcs: ['min', 'mean', 'max'],
        sortBy: 'Mean',
        sortDesc: true,
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: 'line',
          lineInterpolation: 'smooth',
          barAlignment: 0,
          barWidthFactor: 0.6,
          lineWidth: 2,
          fillOpacity: 0,
          gradientMode: 'none',
          spanNulls: false,
          insertNulls: false,
          showPoints: 'never',
          pointSize: 5,
          stacking: {
            mode: 'none',
            group: 'A',
          },
          axisPlacement: 'auto',
          axisLabel: '',
          axisColorMode: 'text',
          axisBorderShow: false,
          scaleDistribution: {
            type: 'linear',
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: 'off',
          },
        },
        color: {
          mode: 'palette-classic',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        unit: 'kwatth',
        links: links,
      },
      overrides: [],
    },
  };
};
