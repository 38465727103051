import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const tokenStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    padding: ${theme.spacing(2)} 0;
    max-width: 600px;
  `,
  generateTokenWrapper: css`
    font-size: ${theme.typography.size.base};
    margin-top: ${theme.spacing(3)};
  `,
});

export default tokenStyles;
