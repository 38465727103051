import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const filtersRowStyles = (theme: GrafanaTheme2) => ({
  container: css`
    margin-bottom: ${theme.spacing(4)};
  `,
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: ${theme.spacing(0)};
    gap: ${theme.spacing(2)};
    align-items: center;
  `,
  left: css`
    display: flex;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,
  visibleFilters: css`
    display: flex;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,
  hiddenFilters: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: ${theme.spacing(2)};
  `,
  hiddenFiltersWrapper: css`
    background: ${theme.colors.background.secondary};
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    align-items: center;
  `,
  visibleFiltersWrapper: css`
    display: flex;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
    align-items: flex-start;
  `,
  clearFilters: css`
    color: ${theme.colors.text.link};
    cursor: pointer;
  `,
});

export default filtersRowStyles;
