import { config } from '@grafana/runtime';
import { Button, LoadingPlaceholder, Modal, Tag, Tooltip, useStyles2 } from '@grafana/ui';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { getOutlierUrl } from 'helpers/helpers';
import useOutlier from 'hooks/MachineLearning/useOutlier';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useDatasourceStore from 'store/datasource';
import useIntegrationStore from 'store/integration';
import { prometheusSelector } from 'store/selectors/datasource';
import { shallow } from 'zustand/shallow';
import Styles from './OutlierModal.styles';
import useTimeRangeStore, { DateTimeRange } from 'store/timeRange';
import useRudderstack from 'hooks/useRudderstack';
import { RudderstackEvents } from 'enums';

interface Props {
  buttonText: string;
  query: string;
  title: string;
  axisLabel?: string;
  items: number;
  timeRange: DateTimeRange;
}

const OutlierModal = ({ buttonText, query, title, axisLabel = 'Percent usage', items, timeRange }: Props) => {
  const styles = useStyles2(Styles);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, error, loading, getData] = useOutlier(query, timeRange);
  const [isMachineLearningInstalled] = useIntegrationStore((state) => [state.machineLearningInstalled], shallow);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const dsUid = config.datasources[prometheusName].uid;
  const [hasDateChanged] = useTimeRangeStore((state) => [state.hasDateChanged], shallow);
  const trackRudderStackEvent = useRudderstack();
  const { location } = useHistory();

  useEffect(() => {
    if (isModalOpen && isMachineLearningInstalled) {
      getData();
      trackRudderStackEvent(RudderstackEvents.RunOutlierDetection, {});
    }
  }, [getData, isModalOpen, isMachineLearningInstalled, trackRudderStackEvent]);

  const ModalButton = (
    <Button
      style={{ marginRight: '10px' }}
      variant="secondary"
      size="sm"
      onClick={() => setIsModalOpen(true)}
      disabled={hasDateChanged}
    >
      {buttonText}
    </Button>
  );

  return (
    <div>
      {items <= 1 || items > 1000 ? (
        <Tooltip content="Outlier detection requires more than one item or less than 1000" placement="top">
          <Button disabled={true} style={{ marginRight: '10px' }} variant="secondary" size="sm">
            {buttonText}
          </Button>
        </Tooltip>
      ) : (
        <>
          {hasDateChanged ? (
            <Tooltip content="Outlier detection is not available while navigating through data in the past.">
              <div>{ModalButton}</div>
            </Tooltip>
          ) : (
            ModalButton
          )}
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        closeOnEscape
        title={
          <>
            <span style={{ marginRight: '8px' }}>{title}</span>
            <ExploreButton
              label="Explore this query in the Machine Learning plugin"
              href={getOutlierUrl(dsUid, query)}
            />
          </>
        }
        className={styles.modal}
      >
        {!isMachineLearningInstalled ? (
          <div>
            Machine learning plugin is not initialized. Click{' '}
            <Link style={{ textDecoration: 'underline' }} to={`/a/grafana-ml-app/home`}>
              here
            </Link>{' '}
            to initialize the plugin and come back to see any outliers.
          </div>
        ) : (
          <>
            {loading ? (
              <LoadingPlaceholder text="Generating outlier model, this may take a moment." />
            ) : (
              <>
                {!error ? (
                  <div>
                    <p>
                      Outlier detection will identify when some members of a group are behaving differently from the
                      rest. Click on the explore button above to visualize this query and adjust sensitivity settings.
                    </p>

                    {data && data.length ? (
                      <>
                        {data.map((outlier, index) => {
                          return (
                            <div key={index} className={styles.outlierList}>
                              <Link className={styles.link} to={`${location.pathname}/${outlier.name}`}>
                                {outlier.name}
                              </Link>
                              <Tag name="Outlier" colorIndex={1} />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div>No outliers found. All items are within range.</div>
                    )}
                  </div>
                ) : (
                  <>
                    <div>Note: Try using a shorter time range if your requests are timing out.</div>
                    <div>There was an error requesting outliers: {error}</div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default OutlierModal;
