import { ThresholdsMode } from '@grafana/data';
import { VizPanelState } from '@grafana/scenes';
import { PanelConfig } from 'components/scenes/CostsOverviewScene/panelConfig';

export const metricsPerCluster = (panel: PanelConfig): VizPanelState => ({
  pluginId: 'barchart',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  fieldConfig: {
    defaults: {
      custom: {
        lineWidth: 1,
        fillOpacity: 80,
        gradientMode: 'none',
        axisPlacement: 'auto',
        axisLabel: '',
        axisColorMode: 'text',
        axisBorderShow: false,
        scaleDistribution: {
          type: 'linear',
        },
        axisCenteredZero: false,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
      },
      color: {
        mode: 'continuous-BlPu',
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
        ],
      },
      unit: 'short',
    },
    overrides: [],
  },
  options: {
    orientation: 'auto',
    xTickLabelRotation: 30,
    xTickLabelSpacing: 0,
    showValue: 'never',
    stacking: 'none',
    groupWidth: 0.7,
    barWidth: 0.75,
    barRadius: 0,
    fullHighlight: false,
    tooltip: {
      mode: 'single',
      sort: 'none',
      maxHeight: 600,
    },
    legend: {
      showLegend: false,
      displayMode: 'list',
      placement: 'right',
      calcs: [],
    },
  },
});

export const totalSeriesForClusters = (panel: PanelConfig): VizPanelState => ({
  pluginId: 'stat',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  fieldConfig: {
    defaults: {
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
        ],
      },
      color: {
        mode: 'continuous-BlPu',
      },
      unit: 'short',
    },
    overrides: [],
  },
  options: {
    reduceOptions: {
      values: false,
      calcs: ['lastNotNull'],
      fields: '',
    },
    orientation: 'auto',
    textMode: 'auto',
    wideLayout: true,
    colorMode: 'background',
    graphMode: 'area',
    justifyMode: 'auto',
    showPercentChange: false,
  },
});

export const topMetricsPerCluster = (panel: PanelConfig, cluster: string): VizPanelState => ({
  pluginId: 'table',
  title: panel.title,
  description: panel.description,
  fieldConfig: {
    defaults: {
      custom: {
        align: 'auto',
        cellOptions: {
          type: 'auto',
        },
        inspect: false,
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
      color: {
        mode: 'fixed',
      },
    },
    overrides: [
      {
        matcher: {
          id: 'byName',
          options: 'Metric',
        },
        properties: [
          {
            id: 'links',
            value: [
              {
                title: 'View detailed cardinality dashboard for metric: ${__data.fields.Metric}',
                url:
                  'd/cardinality-management-metrics-detail/?var-metric=${__data.fields.Metric}&${datasource:queryparam}&var-filter=cluster%7C%3D%7C' +
                  cluster,
              },
            ],
          },
        ],
      },
    ],
  },

  options: {
    showHeader: true,
    cellHeight: 'sm',
    footer: {
      show: false,
      reducer: ['sum'],
      countRows: false,
      fields: '',
    },
    sortBy: [
      {
        desc: true,
        displayName: 'Count of series',
      },
    ],
  },
});

export const seriesPerNamespace = (panel: PanelConfig, cluster: string): VizPanelState => ({
  pluginId: 'table',
  title: panel.title,
  description: panel.description,
  fieldConfig: {
    defaults: {
      custom: {
        lineWidth: 1,
        fillOpacity: 80,
        gradientMode: 'none',
        axisPlacement: 'auto',
        axisLabel: '',
        axisColorMode: 'text',
        axisBorderShow: false,
        scaleDistribution: {
          type: 'linear',
        },
        axisCenteredZero: false,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
        axisGridShow: true,
      },
      color: {
        mode: 'continuous-BlPu',
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
        ],
      },
      links: [
        {
          title: 'View overview cardinality dashboard scoped to namespace: ${__data.fields[0]}',
          url:
            'd/cardinality-management/?${datasource:queryparam}&var-filter=namespace%7C%3D%7C${__data.fields[0]}&var-filter=cluster%7C%3D%7C' +
            cluster,
        },
      ],
      unit: 'short',
    },
    overrides: [],
  },

  options: {
    orientation: 'horizontal',
    xTickLabelRotation: 0,
    xTickLabelSpacing: 0,
    showValue: 'never',
    stacking: 'none',
    groupWidth: 0.7,
    barWidth: 0.75,
    barRadius: 0,
    fullHighlight: false,
    tooltip: {
      mode: 'single',
      sort: 'none',
      maxHeight: 600,
    },
    legend: {
      showLegend: false,
      displayMode: 'list',
      placement: 'right',
      calcs: [],
    },
    colorByField: 'Count of series',
  },
});

export const seriesPerNode = (panel: PanelConfig, cluster: string): VizPanelState => ({
  pluginId: 'barchart',
  title: panel.title,
  description: panel.description,
  fieldConfig: {
    defaults: {
      custom: {
        lineWidth: 1,
        fillOpacity: 80,
        gradientMode: 'none',
        axisPlacement: 'auto',
        axisLabel: '',
        axisColorMode: 'text',
        axisBorderShow: false,
        scaleDistribution: {
          type: 'linear',
        },
        axisCenteredZero: false,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
        axisGridShow: true,
      },
      color: {
        mode: 'continuous-BlPu',
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
        ],
      },
      links: [
        {
          title: 'View overview cardinality dashboard scoped to node: ${__data.fields[0]}',
          url:
            'd/cardinality-management/?${datasource:queryparam}&var-filter=node%7C%3D%7C${__data.fields[0]}&var-filter=cluster%7C%3D%7C' +
            cluster,
        },
      ],
      unit: 'short',
    },
    overrides: [],
  },

  options: {
    orientation: 'horizontal',
    xTickLabelRotation: 0,
    xTickLabelSpacing: 0,
    showValue: 'never',
    stacking: 'none',
    groupWidth: 0.7,
    barWidth: 0.75,
    barRadius: 0,
    fullHighlight: false,
    tooltip: {
      mode: 'single',
      sort: 'none',
      maxHeight: 600,
    },
    legend: {
      showLegend: false,
      displayMode: 'list',
      placement: 'right',
      calcs: [],
    },
    colorByField: 'Count of series',
  },
  pluginVersion: '10.0.3-cloud.4.aed62623',
});

export const topSeriesPerPod = (panel: PanelConfig, cluster: string): VizPanelState => ({
  pluginId: 'barchart',
  title: panel.title,
  description: panel.description,
  fieldConfig: {
    defaults: {
      custom: {
        lineWidth: 1,
        fillOpacity: 80,
        gradientMode: 'none',
        axisPlacement: 'auto',
        axisLabel: '',
        axisColorMode: 'text',
        axisBorderShow: false,
        scaleDistribution: {
          type: 'linear',
        },
        axisCenteredZero: false,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
        axisGridShow: true,
      },
      color: {
        mode: 'continuous-BlPu',
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Percentage,
        steps: [
          {
            color: 'green',
            value: 0,
          },
        ],
      },
      links: [
        {
          title: 'View overview cardinality dashboard scoped to pod: ${__data.fields[0]}',
          url:
            'd/cardinality-management/?${datasource:queryparam}&var-filter=pod%7C%3D%7C${__data.fields[0]}&var-filter=cluster%7C%3D%7C' +
            cluster,
        },
      ],
      unit: 'short',
    },
    overrides: [],
  },

  options: {
    orientation: 'horizontal',
    xTickLabelRotation: 0,
    xTickLabelSpacing: 0,
    showValue: 'never',
    stacking: 'none',
    groupWidth: 0.7,
    barWidth: 0.82,
    barRadius: 0,
    fullHighlight: false,
    tooltip: {
      mode: 'single',
      sort: 'none',
      maxHeight: 600,
    },
    legend: {
      showLegend: false,
      displayMode: 'list',
      placement: 'right',
      calcs: [],
    },
    colorByField: 'Count of series',
  },
});
