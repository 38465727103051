import { GenerateToken as GenerateTokenShared } from '@grafana-cloud/access-policies';
import { useStyles2 } from '@grafana/ui';
import { Scope, ScopePermissions, getAccessPolicyName } from 'api/accessPolicyToken';
import { PluginMetaContext } from 'context/PluginMetaContext';
import React, { useContext } from 'react';
import useAccessPolicyToken from 'store/accessPolicyToken';
import tokenStyles from './TokenStyles';

const GenerateToken = () => {
  const styles = useStyles2(tokenStyles);
  const { jsonData } = useContext(PluginMetaContext);
  const { token, setToken, setTokenError } = useAccessPolicyToken();

  return (
    <div className={styles.generateTokenWrapper}>
      <GenerateTokenShared
        successMessage={'Your Access Policy token has been generated below.'}
        onTokenGenerated={(tokenData) => {
          setToken(tokenData.token);
        }}
        onTokenGenerateError={(error) => {
          setTokenError(error?.data?.message);
        }}
        showExpiration={true}
        showScopes={true}
        fixedCacheKey="k8s-plugin-access-policy-token"
        tokenNameLabel="Access Policy Token name"
        tokenNamePlaceholder="Access Policy Token name"
        tokenNameDescription="The name will help you view and revoke this token in grafana.com later."
        accessPolicyName={getAccessPolicyName(jsonData?.grafana_instance_id)}
        // If changing the list of scopes, you will need to also update this first and do plugin reconciliation:
        // https://github.com/grafana/auth/blob/main/pkg/api/models/sets.go
        scopes={[Scope.METRICS, Scope.LOGS, Scope.TRACES]
          .map((scope) => scope + ':' + ScopePermissions.WRITE)
          .concat([Scope.METRICS].map((scope) => scope + ':' + ScopePermissions.READ))}
      />

      {token && (
        <>
          <p>The token has been filled into the generated manifest.</p>
          <p>Save this token. It will not be shown again.</p>
        </>
      )}
    </div>
  );
};

export default GenerateToken;
