import React from 'react';
import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneObjectBase } from '@grafana/scenes';
import { Alert, useStyles2 } from '@grafana/ui';
import getStyles from '../Config.styles';
import IntegrationsList from 'components/IntegrationsList/IntegrationsList';

class CustomAppScene extends SceneObjectBase {
  static Component = SceneRenderer;
}

function SceneRenderer() {
  const styles = useStyles2(getStyles);

  return (
    <div>
      <h4 className={styles.title}>Integrations</h4>
      <p className={styles.description}>View and install integrations that are supported in Kubernetes.</p>
      <Alert severity="info" title="" className={styles.alert}>
        The integrations catalog for Kubernetes is currently being worked on, and more integrations will be added in the
        future. To install integrations on other platforms, go to{' '}
        <a className={styles.link} target="_self" href="/a/grafana-easystart-app">
          Connections
        </a>
        .
      </Alert>
      <div>
        <IntegrationsList />
      </div>
    </div>
  );
}

export function getIntegrationsScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new CustomAppScene({}),
        }),
      ],
    }),
  });
}
