import { DataFrameJSON, LoadingState, PanelData, dataFrameFromJSON, dateTime } from '@grafana/data';
import { config } from '@grafana/runtime';
import { isErrorResponse } from 'api/baseApi';
import { ForecastParams, ForecastResponse, postForecast } from 'api/machineLearning';
import { useCallback, useMemo, useState } from 'react';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';

export const resultsToSeries = (result: ForecastResponse) => {
  const frames = result.results?.['A'].frames;
  return frames?.map?.((frame: DataFrameJSON) => {
    return dataFrameFromJSON(frame);
  });
};

const getDateRange = () => {
  return [
    new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
    new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
  ];
};

function useForecast(query: string, name: string, metric: string, hyperParamsUpperLimit?: number) {
  const [data, setData] = useState<PanelData | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const dsId = config.datasources[prometheusName].id;
  const dsUid = config.datasources[prometheusName].uid;

  const forecast: ForecastParams = useMemo(() => {
    const [weekAgo, weekAhead] = getDateRange();

    // Need to create this object here to prevent extra re-renders
    // rather than passing the whole thing as props
    let hyperParams = undefined;
    if (hyperParamsUpperLimit) {
      hyperParams = {
        growth: 'logistic',
        logistic_growth_cap: hyperParamsUpperLimit,
        logistic_growth_floor: 0,
      };
    }

    return {
      job: {
        name: name,
        metric: metric,
        grafanaUrl: config.appUrl,
        // ML injects key for us
        grafanaApiKey: 'grafana-ml',
        datasourceUid: dsUid,
        datasourceId: dsId,
        datasourceType: 'prometheus',
        queryParams: {
          expr: query,
        },
        interval: 300,
        algorithm: 'grafana_prophet_1_0_1',
        // Value in seconds
        // Needs at least 100 samples
        trainingWindow: 1209600,
        trainingFrequency: 3600,
        hyperParams,
      },
      forecastParams: {
        start: weekAgo,
        end: weekAhead,
        interval: 300,
      },
    };
  }, [dsId, dsUid, name, query, metric, hyperParamsUpperLimit]);

  const getData = useCallback(async (): Promise<void> => {
    setLoading(true);
    const response = await postForecast(forecast);
    if (response) {
      if (isErrorResponse(response)) {
        setError(response.data.message);
        setLoading(false);
      } else {
        const { data } = response;
        const panelData: PanelData = {
          state: LoadingState.Done,
          series: resultsToSeries(data),
          timeRange: {
            from: dateTime(forecast.forecastParams.start),
            to: dateTime(forecast.forecastParams.end),
            raw: {
              from: forecast.forecastParams.start,
              to: forecast.forecastParams.end,
            },
          },
        };
        setData(panelData);
        setLoading(false);
      }
    }
  }, [setData, setLoading, forecast]);

  return [data, error, loading, getData] as [PanelData, string, boolean, () => Promise<void>];
}

export default useForecast;
