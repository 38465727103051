import { VariableHide } from '@grafana/data';
import { QueryVariable, SceneVariableSet } from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { query_result } from 'helpers/scenes';
import { CostsScenesQueries } from 'queries';

export function getClusterVariable(datasource: DataSourceRef) {
  return new QueryVariable({
    includeAll: true,
    defaultToAll: true,
    allValue: '.+',
    isMulti: true,
    name: 'cluster',
    query: CostsScenesQueries.clusterList,
    datasource,
    skipUrlSync: false,
  });
}

export function getCostVariables(datasource: DataSourceRef) {
  const totalCostOverTime = [0, 15, 30, 45].map((d) => {
    return new QueryVariable({
      name: `total_cost_${d}d_${d + 15}d`,
      query: query_result(CostsScenesQueries.totalCostOverTime(d)),
      datasource,
      skipUrlSync: true,
      regex: `/([\\d\\.]+)/`,
      isReadOnly: true,
      hide: VariableHide.hideVariable,
    });
  });

  const runningPodsOverTime = [0, 15, 30, 45].map((d) => {
    return new QueryVariable({
      name: `running_pods_${d}d_${d + 15}d`,
      query: query_result(CostsScenesQueries.runningPodsOverTime(d)),
      datasource,
      skipUrlSync: true,
      isReadOnly: true,
      regex: `/([\\d\\.]+)/`,
      hide: VariableHide.hideVariable,
    });
  });

  return new SceneVariableSet({
    variables: [
      ...totalCostOverTime,
      ...runningPodsOverTime,
      getClusterVariable(datasource),

      new QueryVariable({
        name: 'total_cost_0d_30d',
        query: query_result(CostsScenesQueries.totalCost0d30d),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),

      new QueryVariable({
        name: 'total_cost_30d_60d',
        query: query_result(CostsScenesQueries.totalCost30d60d),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),

      new QueryVariable({
        name: 'running_pods_0d_30d',
        query: query_result(CostsScenesQueries.runningPods0d30d),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),

      new QueryVariable({
        name: 'running_pods_30d_60d',
        query: query_result(CostsScenesQueries.runningPods30d60d),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),

      new QueryVariable({
        name: 'total_cost_percent_change',
        query: query_result(CostsScenesQueries.totalCostPercentChange),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),

      new QueryVariable({
        name: 'cost_per_pod_percent_change',
        query: query_result(CostsScenesQueries.costPerPodPercentChange),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),

      new QueryVariable({
        name: 'running_pods_percent_change',
        query: query_result(CostsScenesQueries.runningPodsPercentChange),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),

      new QueryVariable({
        name: 'cost_per_pod_0d_30d',
        query: query_result(CostsScenesQueries.costPerPod0d30d),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),

      new QueryVariable({
        name: 'cost_per_pod_30d_60d',
        query: query_result(CostsScenesQueries.costPerPod30d60d),
        datasource,
        skipUrlSync: true,
        hide: VariableHide.hideVariable,
      }),
    ],
  });
}
