import { useStyles2 } from '@grafana/ui';
import React, { useMemo } from 'react';
import getStyles from '../Config.styles';
import { Clipboard } from '../../../Clipboard';
import { cx } from '@emotion/css';
import { DeploymentConfig } from '../../../../api/config';
import { HostedDataDetails } from '../../../../api/accessPolicyToken';
import { Platforms } from 'enums';
import { buildTerraformInstructions } from '../../../../helpers/terraformInstructionsBuilder';

type Props = {
  deploymentConfigValues: DeploymentConfig;
  hostedDetails?: HostedDataDetails;
  accessPolicyToken: string;
  platform: Platforms;
};

const ConfigTerraformInstructions = ({ deploymentConfigValues, hostedDetails, accessPolicyToken, platform }: Props) => {
  const styles = useStyles2(getStyles);

  const { providerFileContents, k8sMonFileContents, varsFileContents } = useMemo(() => {
    const { providerFile, k8sMonFile, varsFile } = buildTerraformInstructions(
      deploymentConfigValues,
      accessPolicyToken,
      platform,
      hostedDetails
    );

    return {
      providerFileContents: providerFile,
      k8sMonFileContents: k8sMonFile,
      varsFileContents: varsFile,
    };
  }, [deploymentConfigValues, accessPolicyToken, hostedDetails, platform]);

  return (
    <>
      <p>
        Copy the following files to set up your Terraform system with the Helm provider to deploy the Kubernetes
        Monitoring Helm chart to your cluster. To explore more options, see the
        <a
          href="https://github.com/grafana/k8s-monitoring-helm/tree/main/charts/k8s-monitoring"
          target="_blank"
          rel="noopener noreferrer"
        >
          chart documentation
        </a>
        .
      </p>
      <h4>provider.tf</h4>
      <p>
        This file shows the inclusion and instantiation of the{' '}
        <a
          href="https://registry.terraform.io/providers/hashicorp/helm/latest/docs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Helm provider
        </a>
        . Modify the contents as needed to be able to connect to your Kubernetes Cluster.
      </p>
      <Clipboard resetTime={1000} content={providerFileContents} className={cx(styles.clipboard, styles.config)} />
      <h4>grafana-k8s-monitoring.tf</h4>
      <p>
        This file defines how to deploy the Helm chart as well as how to translate the Terraform vars into Helm chart
        values.
      </p>
      <Clipboard resetTime={1000} content={k8sMonFileContents} className={cx(styles.clipboard, styles.config)} />
      <h4>vars.tf</h4>
      <p>This file provides the variables and their values that are used by the Helm chart during deployment.</p>
      <Clipboard resetTime={1000} content={varsFileContents} className={cx(styles.clipboard, styles.config)} />
      <h4>Deploy</h4>
      <p>After the files have been saved, use the following commands to initialize and deploy to your Cluster.</p>
      <Clipboard resetTime={1000} content="terraform init" className={cx(styles.clipboard, styles.config)} />
      <Clipboard resetTime={1000} content="terraform apply" className={cx(styles.clipboard, styles.config)} />
    </>
  );
};

export default React.memo(ConfigTerraformInstructions);
