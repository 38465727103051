import React from 'react';
import { SceneAppPage, SceneTimeRangeState } from '@grafana/scenes';
import { ScenesCustomParams, ScenesRouteParams } from 'types';
import { getNodeDetailScene } from 'components/NodesList/NodeDetail/NodeDetail';
import { createDrilldown, createTab, getUrlFromRoutePath, routePaths } from 'helpers/scenes';
import { getNodesListPageScene } from 'components/NodesList/NodesListPage';
import { getDefaultAppProps } from './Cluster';
import TitleRow from '../TitleRow/TitleRow';
import { networkScene } from '../Network/Network';
import { energyScene } from '../Energy/Energy';
import logo from 'img/k8s.png';
import { logsScene } from '../Logs/Logs';

const tabsConfig = {
  nodeDetail: (params: ScenesRouteParams) => [
    createTab('Overview', `${getUrlFromRoutePath(params).node}/overview`, () =>
      getNodeDetailScene(params.cluster!, params.node!)
    ),
    createTab('Network', `${getUrlFromRoutePath(params).node}/network`, () =>
      networkScene({ type: 'node', cluster: params.cluster!, node: params.node! })
    ),
    createTab('Energy', `${getUrlFromRoutePath(params).node}/energy`, () =>
      energyScene({ type: 'node', cluster: params.cluster!, node: params.node! })
    ),
    createTab('Logs & Events', `${getUrlFromRoutePath(params).node}/logs`, () =>
      logsScene({ type: 'node', cluster: params.cluster!, node: params.node! })
    ),
  ],
};

export const getNodesScene = (params: ScenesCustomParams) => {
  const { relativeTimeRange, range } = params;
  const config = { timeRange: relativeTimeRange as SceneTimeRangeState, range };

  const drilldowns = [
    createDrilldown(
      routePaths.node,
      'node',
      undefined,
      config,
      [],
      // node detail tabs
      tabsConfig.nodeDetail
    ),
  ];

  const scene = new SceneAppPage({
    title: 'Nodes',
    renderTitle: (title: string) => <TitleRow title={title} withNavigation page="navigation/nodes" />,
    titleImg: logo,
    getScene: getNodesListPageScene,
    drilldowns,
    url: routePaths.nodes,
    ...getDefaultAppProps(relativeTimeRange),
  });

  return scene;
};

export default getNodesScene;
