import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const colors = {
  blue03: '#538ade',
  blue04: '#343b40',
  blue07: '#245baf',
};

export default (theme: GrafanaTheme2) => ({
  divider: css`
    width: 100%;
    background-color: ${theme.colors.border.medium};
    height: 1px;
    margin: 60px 0;
  `,
  container: css`
    max-width: 1060px;
  `,
  dataSources: css``,
  picker: css`
    margin-top: ${theme.spacing(3)};
  `,
  verticalSpace: css`
    margin-top: ${theme.spacing(5)};
  `,
  title: css`
    font-size: 19px;
    color: ${theme.colors.text.primary};
  `,
  subtitle: css`
    color: ${theme.colors.text.primary};
    font-size: 13px;
    margin-bottom: ${theme.spacing(0.8)};
    display: block;
  `,
  description: css`
    color: ${theme.colors.text.secondary};
    font-size: 15px;
    line-height: 22px;
  `,
  uninstallButton: css`
    background-color: transparent;
    border: 1px solid ${theme.colors.border.strong};
  `,
  version: css`
    color: ${theme.colors.text.secondary};
    font-size: 13px;
    line-height: 22px;
  `,
  link: css`
    color: rgb(83, 138, 222);

    &:hover {
      text-decoration: underline;
    }
  `,
  alert: css`
    margin-top: ${theme.spacing(2)};
    flex-grow: 0;
  `,

  config: css`
    max-width: 85%;
  `,
  clipboard: css`
    max-height: 240px;

    > pre {
      padding-bottom: ${theme.spacing(1)};
      margin-bottom: inherit;
    }
  `,

  instructionsWrapper: css`
    max-width: 730px;

    a {
      color: ${theme.isLight ? colors.blue07 : colors.blue03};
      &:hover {
        text-decoration: underline;
      }
    }
  `,
  instructions: css`
    font-size: 20px;
    max-width: 780px;
    font-weight: ${theme.typography.fontWeightMedium};
    margin-bottom: 40px;
    margin-left: ${theme.spacing(4)};

    li {
      margin-bottom: 32px;
      font-size: 19px;

      h2 {
        margin: ${theme.spacing(2)} 0;
        line-height: 26px;
        font-size: 19px;
        color: ${theme.isLight ? colors.blue04 : theme.colors.text};
      }

      p {
        font-size: ${theme.typography.size.md};
        font-weight: ${theme.typography.fontWeightRegular};
        a {
          margin: 0 5px;
        }
      }
    }
  `,
  innerList: css`
    margin-left: 20px;
    li {
      margin-bottom: ${theme.spacing(2)};
      p {
        margin: 0;
      }
    }
  `,

  ksmAlert: css`
    font-size: 14px;

    > div > div {
      padding: 0;
      font-weight: 400;
    }
  `,

  switch: css`
    margin-bottom: ${theme.spacing(1)};
    justify-content: flex-end;
  `,
  longLabel: css`
    > label {
      height: auto;
      line-height: 16px;
      padding: 4px 8px;
    }
  `,
  switchButton: css`
    > div {
      display: flex;
    }
  `,
  switchWrapper: css`
    display: flex;
    flex-direction: row;

    label {
      width: 220px;
    }
  `,

  switchHalf: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: ${theme.spacing(4)};
  `,

  textReplaceWrapper: css`
    display: flex;
    align-items: baseline;
    margin-right: ${theme.spacing(2)};
  `,
  textReplaceLabel: css`
    font-size: 14px;
    margin-right: ${theme.spacing(1)};
    width: 100px;
  `,
  textReplaceField: css`
    margin-bottom: ${theme.spacing(2)};
    max-width: 200px;
  `,
  upgradingWarning: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
  warningIcon: css`
    color: ${theme.colors.warning.main};
  `,
  platformWrapper: css`
    display: grid;
    grid-template-columns: 300px 300px;
    row-gap: ${theme.spacing(2)};
    column-gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
  `,
});
