import React from 'react';
import { TableColumn } from 'react-data-table-component';
import AlertCell from './AlertCell';
import { config } from '@grafana/runtime';

interface AlertColumnOpts {
  hasDateChanged: boolean;
  prometheusName: string;
  enablePodWildcard?: boolean;

  /**
   * podNameOverride, if set, takes priority for pod over T.name and T.pod.
   */
  podNameOverride?: string;

  /**
   * useNameAsCluster if true, use T.name as cluster, otherwise T.name is by
   * default assumed to be pod and T.cluster is used for cluster.
   */
  useNameAsCluster?: boolean;
}

interface AlertColumnType {
  alertCount?: number;
  cluster?: string;
  node?: string;
  namespace?: string;
  name?: string;
  pod?: string;
  container?: string;
}

function alertColumn<T extends AlertColumnType>({
  hasDateChanged,
  prometheusName,
  enablePodWildcard = false,
  podNameOverride,
  useNameAsCluster = false,
}: AlertColumnOpts): TableColumn<T> {
  return {
    name: 'Alerts',
    selector: (row: T) => row.alertCount ?? 0,
    sortable: true,
    // eslint-disable-next-line react/display-name
    cell: (row: T) => (
      <AlertCell
        count={row.alertCount}
        cluster={useNameAsCluster ? row.name : row.cluster}
        node={row.node}
        namespace={row.namespace}
        pod={podNameOverride ?? (useNameAsCluster ? row.pod : row.name ?? row.pod)}
        container={row.container}
        enablePodWildcard={enablePodWildcard}
        hasDateChanged={hasDateChanged}
        prometheusName={prometheusName}
      />
    ),
    conditionalCellStyles: [
      {
        when: (row: T) => (row.alertCount ?? 0) >= 10,
        style: {
          backgroundColor: config.theme2.colors.error.transparent,
        },
      },
      {
        when: (row: T) => (row.alertCount ?? 0) > 0 && (row.alertCount ?? 0) < 10,
        style: {
          backgroundColor: config.theme2.colors.warning.transparent,
        },
      },
    ],
  };
}

export default alertColumn;
