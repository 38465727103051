import { DataFrameJSON } from '@grafana/data';
import max from 'lodash/max';
import mean from 'lodash/mean';
import {
  GeneralQueryResult,
  GenericUsageResult,
  Node,
  CommonUsageData,
  DataTableClusterUsage,
  NamespaceUsageData,
} from 'types';
import { EFFICIENCY_OVER_UTILIZED_THRESHOLD, EFFICIENCY_UNDER_UTILIZED_THRESHOLD } from '../constants';
import { getProvider } from './nodes';
import { getValidName } from './helpers';

export const normalizeData = (
  cpuData: DataFrameJSON[],
  memoryData: DataFrameJSON[],
  storageData: DataFrameJSON[],
  type: string,
  nameExtractor = getValidName
) => {
  // Normalize CPU DataFrame
  const memMap = new Map();
  const maxMemMap = new Map();
  memoryData?.forEach?.((metric) => {
    const name = nameExtractor(metric, type) as string;
    const memAvg = mean(metric.data?.values[1]);
    const maxMem = Number(max(metric.data?.values[1]));
    memMap.set(name, memAvg);
    maxMemMap.set(name, maxMem);
  });

  // Normalize CPU DataFrame
  const storageMap = new Map();
  const maxStorageMap = new Map();
  storageData?.forEach?.((metric) => {
    const namespace = nameExtractor(metric, type) as string;
    const memAvg = mean(metric.data?.values[1]);
    const maxMem = Number(max(metric.data?.values[1]));
    storageMap.set(namespace, memAvg);
    maxStorageMap.set(namespace, maxMem);
  });

  // Normalize CPU DataFrame and match mem usage
  const normalized = cpuData
    ?.reduce<NamespaceUsageData[]>((acc, metric) => {
      const name = nameExtractor(metric, type) as string;
      const avg = mean(metric.data?.values[1]);
      const maxCpu = Number(max(metric.data?.values[1]));
      const obj = {
        name: name,
        cpu_usage: avg || 0,
        cpu_max: maxCpu || 0,
        mem_usage: memMap.get(name) || 0,
        mem_max: maxMemMap.get(name) || 0,
        storage_usage: storageMap.get(name) || 0,
        storage_max: maxStorageMap.get(name) || 0,
      };
      return [...acc, obj];
    }, [])
    .sort((a, b) => {
      return b.cpu_usage - a.cpu_usage;
    });

  return normalized;
};

export const normalizeClusterData = (
  nodeInfo: Array<GeneralQueryResult<Node>>,
  cpuUsageData?: DataFrameJSON[],
  memUsageData?: DataFrameJSON[],
  storageUsageData?: DataFrameJSON[]
) => {
  const clusterMemMap = new Map();
  const clusterMaxMemMap = new Map();
  memUsageData?.forEach?.((metric) => {
    const cluster = getValidName(metric, 'cluster');
    const memAvg = mean(metric.data?.values[1]);
    const maxMem = Number(max(metric.data?.values[1]));
    clusterMemMap.set(cluster, memAvg);
    clusterMaxMemMap.set(cluster, maxMem);
  });

  const clusterStorageMap = new Map();
  const clusterMaxStorageMap = new Map();
  storageUsageData?.forEach?.((metric) => {
    const cluster = getValidName(metric, 'cluster');
    const memAvg = mean(metric.data?.values[1]);
    const maxMem = Number(max(metric.data?.values[1]));
    clusterStorageMap.set(cluster, memAvg);
    clusterMaxStorageMap.set(cluster, maxMem);
  });

  const clusterCpuAvgUsageMap = new Map();
  const clusterCpuMaxUsageMap = new Map();
  cpuUsageData?.forEach?.((metric) => {
    const cluster = getValidName(metric, 'cluster') as string;
    const avgCpu = mean(metric.data?.values[1]);
    const maxCpu = Number(max(metric.data?.values[1]));
    clusterCpuAvgUsageMap.set(cluster, avgCpu);
    clusterCpuMaxUsageMap.set(cluster, maxCpu);
  });

  // Iterate over node info
  const nodeMap = new Map<
    string,
    { nodes: number; provider: string; asserts_env: string | undefined; asserts_site: string | undefined }
  >();
  if (nodeInfo) {
    nodeInfo?.forEach?.((metric) => {
      const cluster = metric.metric.cluster;
      const asserts_env = metric.metric.asserts_env || undefined;
      const asserts_site = metric.metric.asserts_site || undefined;
      const provider = getProvider(metric);
      if (!nodeMap.has(cluster)) {
        nodeMap.set(cluster, { nodes: 1, provider: provider, asserts_env: asserts_env, asserts_site: asserts_site });
      } else {
        let currentNodes = nodeMap.get(cluster)!.nodes;
        nodeMap.set(cluster, {
          nodes: (currentNodes += 1),
          provider: provider,
          asserts_env: asserts_env,
          asserts_site: asserts_site,
        });
      }
    });
  }

  // Normalize CPU DataFrame and match mem usage
  const normalized: DataTableClusterUsage[] = [];
  nodeMap?.forEach?.((clusterInfo, cluster) => {
    normalized.push({
      name: cluster,
      cpu_usage: clusterCpuAvgUsageMap.get(cluster),
      cpu_max: clusterCpuMaxUsageMap.get(cluster),
      mem_usage: clusterMemMap.get(cluster),
      mem_max: clusterMaxMemMap.get(cluster),
      storage_usage: clusterStorageMap.get(cluster),
      storage_max: clusterMaxStorageMap.get(cluster),
      provider: clusterInfo?.provider,
      nodes: clusterInfo?.nodes,
      asserts_env: clusterInfo?.asserts_env,
      asserts_site: clusterInfo?.asserts_site,
      alertCount: 0,
    });
  });

  return normalized.sort((a, b) => {
    return b.cpu_usage - a.cpu_usage;
  });
};

export const countNodeValues = (nodeCpuData: Array<GeneralQueryResult<GenericUsageResult>>) => {
  const values = nodeCpuData.reduce(
    (acc, curr) => {
      acc.count += 1;
      const value = parseInt(curr.value[1], 10) / 100;
      if (value <= EFFICIENCY_UNDER_UTILIZED_THRESHOLD) {
        acc.under += 1;
      } else if (value > EFFICIENCY_UNDER_UTILIZED_THRESHOLD && value <= EFFICIENCY_OVER_UTILIZED_THRESHOLD) {
        acc.well += 1;
      } else if (value >= EFFICIENCY_OVER_UTILIZED_THRESHOLD) {
        acc.over += 1;
      }
      return acc;
    },
    {
      under: 0,
      over: 0,
      well: 0,
      count: 0,
    }
  );

  return values;
};

export function countEfficencyValues(data: CommonUsageData[], type: 'cpu_usage' | 'mem_usage' | 'storage_usage') {
  const values = data?.reduce(
    (acc, curr) => {
      acc.count += 1;
      if (curr[type] <= EFFICIENCY_UNDER_UTILIZED_THRESHOLD) {
        acc.under += 1;
      } else if (curr[type] > EFFICIENCY_UNDER_UTILIZED_THRESHOLD && curr[type] <= EFFICIENCY_OVER_UTILIZED_THRESHOLD) {
        acc.well += 1;
      } else if (curr[type] > EFFICIENCY_OVER_UTILIZED_THRESHOLD) {
        acc.over += 1;
      }
      return acc;
    },
    {
      under: 0,
      over: 0,
      well: 0,
      count: 0,
    }
  );

  return values;
}

export function filterByEfficiency(
  type: string,
  item: {
    cpu_usage: number;
    storage_usage: number;
    mem_usage: number;
  }
) {
  const splitType = type.split('-');
  const field = splitType[0];
  const filterType = splitType[1];

  const currentItem = item[field as keyof typeof item] as number;
  if (filterType === 'over') {
    return currentItem > EFFICIENCY_OVER_UTILIZED_THRESHOLD;
  } else if (filterType === 'under') {
    return currentItem <= EFFICIENCY_UNDER_UTILIZED_THRESHOLD;
  } else if (filterType === 'well') {
    return currentItem > EFFICIENCY_UNDER_UTILIZED_THRESHOLD && currentItem < EFFICIENCY_OVER_UTILIZED_THRESHOLD;
  }

  return true;
}

export const filterCluster = (cluster: DataTableClusterUsage, filter: string[], provider: string): Boolean => {
  const matchesCluster = filter.some((clusterItem) => cluster.name.includes(clusterItem));
  const matchesProvider = cluster?.provider?.includes(provider) || false;
  return (!filter?.length || matchesCluster) && matchesProvider;
};
