import { BackendSrvRequest, getBackendSrv } from '@grafana/runtime';
import { lastValueFrom } from 'rxjs';

export interface SuccessfulFetchResponse<T> {
  status: number;
  data: T;
}

export interface ErrorFetchResponse<E> {
  status: number;
  data: E;
}

export function isErrorResponse<D = unknown>(thing: any): thing is ErrorFetchResponse<D> {
  return (
    typeof thing === 'object' &&
    'status' in thing &&
    !isNaN(thing.status) &&
    (thing.status < 200 || thing.status >= 300)
  );
}

export function fetchApi<D>(
  url: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const options: BackendSrvRequest = {
    headers: {},
    url: url,
    showErrorAlert: false,
    ...optionsOverride,
  };
  return lastValueFrom(getBackendSrv().fetch<D>(options));
}

export async function get<D>(url: string, optionsOverride: Partial<BackendSrvRequest> = {}): Promise<D> {
  const response = await fetchApi<D>(url, { ...optionsOverride, method: 'GET' });
  return response?.data;
}

export async function post<D>(url: string, data: any): Promise<D> {
  const response = await fetchApi<D>(url, { method: 'POST', data });
  return response?.data;
}

export async function del(url: string, options: Partial<BackendSrvRequest> = {}): Promise<void> {
  await lastValueFrom(
    getBackendSrv().fetch({
      method: 'DELETE',
      url,
      ...options,
    })
  );
}
