import { SceneQueryRunner } from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { transformData } from 'components/HomeScene/queryRunners';
import { getGenericQueryRunner } from 'helpers/scenes';
import { SceneQueries } from 'queries';

export const clusterAlertsQueryRunner = (dsId: string) =>
  new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: dsId,
    },
    queries: [
      {
        refId: 'A',
        expr: SceneQueries.Alerts.Cluster,
        format: 'time_series',
        instant: false,
        range: true,
        exemplar: false,
        legendFormat: '__auto',
      },
    ],
  });

export const namespaceAlertsQueryRunner = (dsId: string) =>
  new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: dsId,
    },
    queries: [
      {
        refId: 'A',
        expr: SceneQueries.Alerts.Namespace,
        format: 'time_series',
        instant: false,
        range: true,
        exemplar: false,
        legendFormat: '__auto',
      },
    ],
  });

export const severityAlertsQueryRunner = (dsId: string) =>
  new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: dsId,
    },
    queries: [
      {
        refId: 'A',
        expr: SceneQueries.Alerts.Severity,
        format: 'time_series',
        instant: false,
        range: true,
        exemplar: false,
        legendFormat: '__auto',
      },
    ],
  });

export const alertsTableQueryRunner = (datasource: DataSourceRef) => {
  return getGenericQueryRunner(datasource, SceneQueries.Alerts.Table, {
    format: 'table',
    transformations: [
      transformData,
      {
        id: 'organize',
        options: {
          includeByName: {
            alertname: true,
            pod: true,
            container: true,
            namespace: true,
            severity: true,
            cluster: true,
            node: true,
            reason: true,
            workload: true,
            workload_type: true,
            encoded_cluster: true,
          },
          indexByName: {
            alertname: 0,
            severity: 1,
            cluster: 2,
            node: 3,
            namespace: 4,
            workload: 5,
            pod: 6,
            container: 7,
          },
        },
      },
    ],
  });
};
