import { ThresholdsMode } from '@grafana/data';
import { VizPanelState } from '@grafana/scenes';
import { PanelConfig } from 'components/scenes/CostsOverviewScene/panelConfig';

export const coresConfig = (panel: PanelConfig) => ({
  pluginId: 'stat',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  options: {
    reduceOptions: {
      values: false,
      calcs: ['lastNotNull'],
      fields: '',
    },
    orientation: 'auto',
    textMode: 'value',
    colorMode: 'value',
    graphMode: 'none',
    justifyMode: 'auto',
    text: {
      valueSize: panel.textSize ?? 30,
    },
  },
  fieldConfig: {
    defaults: {
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'text',
            value: 0,
          },
        ],
      },
      color: {
        fixedColor: 'text',
        mode: 'thresholds',
      },
      unit: panel?.unit ?? 'cores',
    },
    overrides: [],
  },
});

export const gaugeConfig = (panel: PanelConfig) => ({
  pluginId: 'gauge',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  transparent: true,
  options: {
    reduceOptions: {
      values: false,
      calcs: ['lastNotNull'],
      fields: '',
    },
    orientation: 'auto',
    showThresholdLabels: false,
    showThresholdMarkers: true,
    minVizWidth: 75,
    minVizHeight: 75,
  },
  fieldConfig: {
    defaults: {
      custom: {
        neutral: 0,
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'red',
            value: 0,
          },
          {
            color: 'red',
            value: -1.1,
          },
          {
            color: 'green',
            value: -1,
          },
          {
            color: 'green',
            value: -1,
          },
          {
            color: 'green',
            value: 1,
          },
          {
            color: 'red',
            value: 1.1,
          },
          {
            color: 'red',
            value: 10,
          },
        ],
      },
      color: {
        fixedColor: 'text',
        mode: 'thresholds',
      },
      decimals: 3,
      max: 10,
      min: -10,
      unit: panel?.unit ?? 'cores',
    },
    overrides: [],
  },
});

export const thresholdGraphConfig = (panel: PanelConfig) => ({
  pluginId: 'timeseries',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  options: {
    tooltip: {
      mode: 'single',
      sort: 'none',
    },
    legend: {
      showLegend: true,
      displayMode: 'table',
      placement: 'bottom',
      calcs: ['min', 'mean', 'max'],
    },
  },
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: 'line',
        lineInterpolation: 'smooth',
        barAlignment: 0,
        lineWidth: 1,
        fillOpacity: 0,
        gradientMode: 'none',
        spanNulls: false,
        insertNulls: false,
        showPoints: 'never',
        pointSize: 5,
        stacking: {
          mode: 'none',
          group: 'A',
        },
        axisPlacement: 'auto',
        axisLabel: '',
        axisColorMode: 'text',
        axisBorderShow: false,
        scaleDistribution: {
          type: 'linear',
        },
        axisCenteredZero: false,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'dashed+area',
        },
        lineStyle: {
          fill: 'solid',
        },
      },
      color: {
        mode: 'thresholds',
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Percentage,
        steps: [
          {
            color: 'green',
            value: 0,
          },
          {
            color: 'red',
            value: 25,
          },
        ],
      },
      max: 1,
      min: 0,
      unit: 'percentunit',
    },
    overrides: [],
  },
});

export const boolConfig = (panel: PanelConfig) => ({
  pluginId: 'stat',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  options: {
    reduceOptions: {
      values: false,
      calcs: ['first'],
      fields: '/^Time$/',
    },
    orientation: 'auto',
    textMode: 'auto',
    colorMode: 'value',
    graphMode: 'area',
    justifyMode: 'center',
    text: {
      valueSize: panel.textSize ?? 30,
    },
  },
  fieldConfig: {
    defaults: {
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'text',
            value: 0,
          },
          {
            color: 'red',
            value: 1,
          },
        ],
      },
      color: {
        mode: 'thresholds',
      },
      unit: 'bool',
    },
    overrides: [],
  },
});

export const restartsGraphConfig = (panel: PanelConfig): VizPanelState => ({
  pluginId: 'timeseries',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  options: {
    tooltip: {
      mode: 'multi',
      sort: 'none',
    },
    legend: {
      showLegend: false,
      displayMode: 'table',
      placement: 'bottom',
      calcs: ['last'],
    },
  },
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: 'bars',
        lineInterpolation: 'linear',
        barAlignment: 0,
        lineWidth: 0,
        fillOpacity: 60,
        gradientMode: 'hue',
        spanNulls: false,
        insertNulls: false,
        showPoints: 'never',
        pointSize: 5,
        stacking: {
          mode: 'none',
          group: 'A',
        },
        axisPlacement: 'auto',
        axisLabel: '',
        axisColorMode: 'text',
        axisBorderShow: false,
        scaleDistribution: {
          type: 'linear',
        },
        axisCenteredZero: false,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
        axisSoftMax: 1,
        lineStyle: {
          fill: 'solid',
        },
      },
      color: {
        mode: 'fixed',
        fixedColor: 'red',
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
          {
            color: 'red',
            value: 1,
          },
        ],
      },
      decimals: 0,
    },
    overrides: [],
  },
});

export const restartsTableConfig = (panel: PanelConfig): VizPanelState => ({
  pluginId: 'table',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  options: {
    showHeader: true,
    cellHeight: 'sm',
    footer: {
      show: false,
      reducer: ['sum'],
      countRows: false,
      fields: '',
    },
  },
  fieldConfig: {
    defaults: {
      custom: {
        align: 'auto',
        cellOptions: {
          type: 'auto',
        },
        inspect: false,
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
          {
            color: 'red',
            value: 0.00001,
          },
        ],
      },
      color: {
        mode: 'thresholds',
      },
    },
    overrides: [],
  },
});

export const latestTerminatedConfig = (panel: PanelConfig): VizPanelState => ({
  pluginId: 'timeseries',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  options: {
    tooltip: {
      mode: 'single',
      sort: 'none',
    },
    legend: {
      showLegend: true,
      displayMode: 'list',
      placement: 'bottom',
      calcs: [],
    },
  },
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: 'line',
        lineInterpolation: 'smooth',
        barAlignment: 0,
        lineWidth: 0,
        fillOpacity: 60,
        gradientMode: 'hue',
        spanNulls: false,
        insertNulls: false,
        showPoints: 'never',
        pointSize: 5,
        stacking: {
          mode: 'none',
          group: 'A',
        },
        axisPlacement: 'auto',
        axisLabel: '',
        axisColorMode: 'text',
        axisBorderShow: false,
        scaleDistribution: {
          type: 'linear',
        },
        axisCenteredZero: false,
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: 'off',
        },
        axisGridShow: true,
        axisSoftMax: 1,
      },
      color: {
        mode: 'fixed',
        fixedColor: 'red',
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
        ],
      },
      decimals: 0,
    },
    overrides: [],
  },
});
