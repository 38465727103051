import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const predictionModalStyles = (theme: GrafanaTheme2) => ({
  modal: css`
    height: 85vh;
    width: 800px;
    max-height: 1000px;
  `,
  link: css`
    color: ${theme.colors.text.link};
  `,
});

export default predictionModalStyles;
