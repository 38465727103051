import SubRowHeader from 'components/SubRowHeader/SubRowHeader';
import React from 'react';
import { OptionalStringMap, UsageCostData, UsageCostErrors } from 'types';
import UsageBox, { getUsageType } from 'components/UsageLabels/UsageBox';
import { formatBytes } from 'components/workloads/podHealthUtils';
import { TableColumn } from 'react-data-table-component';
import TableCostRow from 'components/Table/TableCostRow';

export function getCostColumns(errors: OptionalStringMap, costLoading?: boolean) {
  return [
    {
      id: 'costCurrent',
      name: (
        <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', textTransform: 'uppercase', marginLeft: -30 }}>Compute</div>
          <div style={{ display: 'flex', gap: 25 }}>
            <SubRowHeader
              info="The cost of allocated compute (CPU + memory) throughout the selected time range. Shows as undersized if usage is greater than requests."
              title="Current"
              type="costCurrent"
              error={errors.get('costCurrent')}
            />
            <SubRowHeader
              info="The current compute cost scaled to 30 days. Shows as undersized if usage is greater than requests."
              title="Projected"
              type="costProjected"
              error={errors.get('costProjected')}
            />
          </div>
        </div>
      ),
      minWidth: '320px',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: (row: UsageCostData) => (
        <TableCostRow currentValue={row.costCurrent} projectedValue={row.costProjected} loading={costLoading} />
      ),
    },
    {
      id: 'cpuIdleCurrent',
      name: (
        <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', textTransform: 'uppercase', marginLeft: -30 }}>CPU idle</div>
          <div style={{ display: 'flex', gap: 25 }}>
            <SubRowHeader
              info="The cost of idle CPU (requests minus usage) throughout the selected time range. Shows as undersized if usage is greater than requests."
              title="Current"
              type="cpuIdleCurrent"
              error={errors.get('cpuIdleCurrent')}
            />
            <SubRowHeader
              info="The current CPU idle cost scaled to 30 days. Shows as undersized if usage is greater than requests."
              title="Projected"
              type="cpuIdleProjected"
              error={errors.get('cpuIdleProjected')}
            />
          </div>
        </div>
      ),
      minWidth: '320px',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: (row: UsageCostData) => (
        <TableCostRow currentValue={row.cpuIdleCurrent} projectedValue={row.cpuIdleProjected} loading={costLoading} />
      ),
    },
    {
      id: 'memoryIdleCurrent',
      name: (
        <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', textTransform: 'uppercase', marginLeft: -30 }}>Memory idle</div>
          <div style={{ display: 'flex', gap: 25 }}>
            <SubRowHeader
              info="The cost of idle memory (requests minus usage) throughout the selected time range. Shows as undersized if usage is greater than requests."
              title="Current"
              type="memoryIdleCurrent"
              error={errors.get('memoryIdleCurrent')}
            />
            <SubRowHeader
              info="The current memory idle cost scaled to 30 days. Shows as undersized if usage is greater than requests."
              title="Projected"
              type="memoryIdleProjected"
              error={errors.get('memoryIdleProjected')}
            />
          </div>
        </div>
      ),
      minWidth: '320px',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: (row: UsageCostData) => (
        <TableCostRow
          currentValue={row.memoryIdleCurrent}
          projectedValue={row.memoryIdleProjected}
          loading={costLoading}
        />
      ),
    },
  ];
}

export function getUsageColumns(errors: OptionalStringMap, usageLoading?: boolean) {
  return [
    {
      id: 'cpuAvg',
      name: (
        <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', textTransform: 'uppercase' }}>CPU usage</div>
          <div style={{ display: 'flex', gap: 57 }}>
            <SubRowHeader
              info="The mean CPU usage observed throughout the selected time range."
              title="Average"
              type="cpuAvg"
              error={errors.get('cpuAvg')}
            />
            <SubRowHeader
              info="The maximum CPU usage observed throughout the selected time range."
              title="Max"
              type="cpuMax"
              error={errors.get('cpuMax')}
            />
          </div>
        </div>
      ),
      minWidth: '320px',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: (row: UsageCostData) => (
        <div style={{ display: 'flex', gap: 25 }}>
          <UsageBox
            type={getUsageType(row.cpuAvgPercent)}
            value={row.cpuAvg?.toPrecision(3) || 0}
            showPercent={false}
            unit="cores"
            percentValue={row.cpuAvgPercent as number}
            loading={usageLoading}
          />
          <UsageBox
            type={getUsageType(row.cpuMaxPercent)}
            value={row.cpuMax?.toPrecision(3) || 0}
            showPercent={false}
            unit="cores"
            percentValue={row.cpuMaxPercent as number}
            loading={usageLoading}
          />
        </div>
      ),
    },
    {
      id: 'memoryAvg',
      name: (
        <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', marginLeft: -50, textTransform: 'uppercase' }}>Memory usage</div>
          <div style={{ display: 'flex', gap: 34 }}>
            <SubRowHeader
              info="The mean memory usage (RSS) observed throughout the selected time range."
              title="Average"
              type="memoryAvg"
              error={errors.get('memoryAvg')}
            />
            <SubRowHeader
              info="The maximum memory usage (RSS) observed throughout the selected time range."
              title="Max"
              type="memoryMax"
              error={errors.get('memoryMax')}
            />
          </div>
        </div>
      ),
      minWidth: '300px',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: (row: UsageCostData) => (
        <div style={{ display: 'flex', gap: 0 }}>
          <UsageBox
            type={getUsageType(row.memoryAvgPercent)}
            value={formatBytes(row.memoryAvg, 2)}
            showPercent={false}
            percentValue={row.memoryAvgPercent}
            loading={usageLoading}
          />
          <UsageBox
            type={getUsageType(row.memoryMaxPercent)}
            value={formatBytes(row.memoryMax, 2)}
            showPercent={false}
            percentValue={row.memoryMaxPercent}
            loading={usageLoading}
          />
        </div>
      ),
    },
  ];
}

export function getAllColumns<T>(
  costView: boolean,
  currentColumns: Array<TableColumn<T>>,
  addToCurrentColumns: Array<TableColumn<T>>,
  addToExtraColumns: Array<TableColumn<T>>,
  errors: UsageCostErrors,
  usageLoading?: boolean,
  costLoading?: boolean
) {
  const tmpColumns = [...currentColumns];
  const extraColumns: Array<TableColumn<any>> = costView
    ? getCostColumns(errors.cost, costLoading)
    : getUsageColumns(errors.usage, usageLoading);

  if (!costView) {
    if (addToCurrentColumns) {
      tmpColumns.push(...addToCurrentColumns);
    }

    if (addToExtraColumns) {
      extraColumns.push(...addToExtraColumns);
    }
  }

  return {
    currentColumns: tmpColumns,
    extraColumns,
  };
}
