import { LoadingPlaceholder, useStyles2 } from '@grafana/ui';
import React, { useMemo } from 'react';
import { config } from '@grafana/runtime';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { RudderstackEvents } from 'enums';
import { encodeUrlString, getExploreUrl } from 'helpers/helpers';
import { SceneQueries } from 'queries';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import { ContainerInfo, GeneralQueryResult } from 'types';
import BreadcrumbTitle from '../workloads/BreadCrumbTitle';
import containerDetailStyles from './Container.styles';
import DataList from 'components/DataList/DataList';
import useTimeRangeStore from 'store/timeRange';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { chunk, get } from 'lodash';
import { getContainerDetailOptimization } from 'components/scenes/ContainerDetailOptimization/ContainerDetailOptimization';
import { MAX_ITEMS_PER_LIST, MAX_NUMBER_OF_LISTS, PLUGIN_ROOT_URL } from '../../constants';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';
import useDataFrame from 'hooks/useDataFrame';

export const getContainerInfo = (containerData: Array<GeneralQueryResult<ContainerInfo>>) => {
  const { __name__, ...rest } = get(containerData, [0, 0, 'metric'], { __name__: '' });
  return rest;
};

const ContainerDetail = ({ model }: SceneComponentProps<ContainerDetailScene>) => {
  const { cluster, namespace, pod: podName, workload, workloadType, container } = model.useState();
  const styles = useStyles2(containerDetailStyles);
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const { data, loading, firstLoad } = useDataFrame(
    [SceneQueries.Container.Info(cluster, namespace, podName, container)],
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '',
    !hasDateChanged,
    1,
    true,
    true
  );

  const prometheusName = useDatasourceStore(prometheusSelector);
  const lokiName = useDatasourceStore(lokiSelector);

  // Lets split objects into different lists (leftList/rightList)
  const containerData = useMemo(() => {
    const fieldsMapping = (key: string, value: string) => {
      const rootPath = `${PLUGIN_ROOT_URL}/navigation`;
      const mapping = {
        cluster: `${rootPath}/cluster/${encodeUrlString(value)}`,
        namespace: `${rootPath}/namespace/${encodeUrlString(cluster)}/${value}`,
        node: `${rootPath}/nodes/${encodeUrlString(cluster)}/${value}`,
        pod: `${rootPath}/namespace/${encodeUrlString(cluster)}/${namespace}/${workloadType}/${workload}/${podName}`,
      };

      if (mapping[key as keyof typeof mapping]) {
        return <QueryParamsLink className={styles.link} to={mapping[key as keyof typeof mapping]} label={value} />;
      }

      return value;
    };

    const arrayFromObject = Object.entries(getContainerInfo(data))?.map?.(([key, value]) => ({
      value,
      label: key,
      display: fieldsMapping(key, value as string),
    }));
    const itemsPerSubarray = Math.min(MAX_ITEMS_PER_LIST, Math.ceil(arrayFromObject.length / MAX_NUMBER_OF_LISTS));
    const finalArray = chunk(arrayFromObject, itemsPerSubarray);

    return {
      leftList: finalArray[0] || [],
      centerList: finalArray[1] || [],
      rightList: finalArray[2] || [],
    };
  }, [data, cluster, styles.link, namespace, workloadType, workload, podName]);

  const scene = useMemo(
    () =>
      getContainerDetailOptimization({
        cluster,
        namespace,
        podName,
        container,
        datasource: config.datasources[prometheusName],
        prometheusName,
        lokiName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [cluster, namespace, podName, container, prometheusName, lokiName, relativeRange]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <BreadcrumbTitle />
            <ExploreButton
              href={getExploreUrl(
                prometheusName,
                SceneQueries.Container.Info(cluster, namespace, podName, container),
                exploreRange,
                true
              )}
              label="Explore container"
              eventName={RudderstackEvents.ExploreContainer}
            />
          </div>
        </div>
        <div className={styles.sectionSpacing}>
          <h3 className={styles.sectionTitle}>Container information</h3>
          {(!!data && !loading) || !firstLoad ? (
            <div className={styles.gridEqual}>
              <DataList alignValueRight list={containerData?.leftList} />
              <DataList alignValueRight list={containerData?.centerList} />
              <DataList alignValueRight list={containerData?.rightList} />
            </div>
          ) : (
            <>
              {loading ? <LoadingPlaceholder text="Loading container information" /> : <span>No data available.</span>}
            </>
          )}
        </div>
        <div className={styles.sectionSpacing} style={{ minHeight: 200 }}>
          <h3 className={styles.sectionTitle}>Container optimization</h3>
          <div className={styles.sceneWrapper}>
            <scene.Component model={scene} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface ContainerDetailState extends SceneObjectState {
  cluster: string;
  namespace: string;
  pod: string;
  workload: string;
  workloadType: string;
  container: string;
}

export class ContainerDetailScene extends SceneObjectBase<ContainerDetailState> {
  static Component = ContainerDetail;
}

export function getContainerDetailAppScene(
  cluster: string,
  namespace: string,
  workload: string,
  workloadType: string,
  pod: string,
  container: string
) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new ContainerDetailScene({ cluster, namespace, pod, workload, workloadType, container }),
        }),
      ],
    }),
  });
}

export default ContainerDetail;
