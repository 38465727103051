import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { Container, UsageCostErrors } from 'types';
import ModalWithLogs from 'components/ModalWithLogs/ModalWithLogs';
import { getContainerHealthStatus, podHealthToColor } from '../podHealthUtils';
import { compileLogsQuery, compileOTelLogsQuery } from 'queries';
import { config } from '@grafana/runtime';
import CopyObjectName from 'components/Table/CopyObjectName';
import { getAllColumns } from 'helpers/usageCostHelpers';
import alertColumn from 'components/Table/alertColumn';
import { PLUGIN_ROOT_URL } from '../../../constants';
import { encodeUrlString } from 'helpers/helpers';

const getColumns = (
  podName: string,
  costView: boolean,
  errors: UsageCostErrors,
  hasDateChanged: boolean,
  prometheusName: string,
  workload: string,
  workloadType: string,
  usageLoading?: boolean,
  costLoading?: boolean
): Array<TableColumn<Container>> => {
  let columns = [
    {
      name: '',
      selector: (row: Container) => getContainerHealthStatus(row),
      // eslint-disable-next-line react/display-name
      cell: (row: Container) => (
        <div style={{ height: '44px', backgroundColor: podHealthToColor(config)[getContainerHealthStatus(row)] }}>
          &nbsp;
        </div>
      ),
      width: '20px',
      compact: true,
    },
    {
      name: 'Name',
      // eslint-disable-next-line react/display-name
      cell: (row: Container) => {
        return (
          <CopyObjectName
            link={`${PLUGIN_ROOT_URL}/navigation/namespace/${encodeUrlString(row.cluster)}/${
              row.namespace
            }/${workloadType}/${workload}/${podName}/${row.container}`}
            value={row.container}
          />
        );
      },
      selector: (row: Container) => row.container,
      sortable: true,
      minWidth: '200px',
    },
  ];

  const { currentColumns, extraColumns } = getAllColumns<Container>(
    costView,
    columns,
    [
      {
        name: 'Image Spec',
        selector: (row: Container) => row.image_spec,
        sortable: true,
        // eslint-disable-next-line react/display-name
        cell: (row: Container) => <div title={row.image_spec}>{row.image_spec}</div>,
        minWidth: '330px',
      },
      {
        name: 'Waiting Reason',
        selector: (row: Container) => row.waiting_reason ?? 'None',
        sortable: true,
        // eslint-disable-next-line react/display-name
        cell: (row: Container) => {
          return <span>{row.waiting_reason ?? 'None'}</span>;
        },
        minWidth: '150px',
      },
    ],
    [
      {
        name: 'Logs',
        selector: (row: Container) => row.container,
        sortable: false,
        // eslint-disable-next-line react/display-name
        cell: (row: Container) => (
          <ModalWithLogs
            query={compileOTelLogsQuery(row.cluster, row.namespace, podName, row.container)}
            backupQuery={compileLogsQuery(row.cluster, row.namespace, podName, row.container)}
            dataName={row.container}
          />
        ),
      },
      alertColumn<Container>({ hasDateChanged, prometheusName, podNameOverride: podName }),
    ],
    errors,
    usageLoading,
    costLoading
  );

  return [...currentColumns, ...extraColumns];
};

export default getColumns;
