import { Icon, useStyles2 } from '@grafana/ui';
import React from 'react';
import usageLabelsStyles from './UsageLabels.styles';
import { cx } from '@emotion/css';
import { MaxUsageValue } from 'components/ClusterList/ClusterList';
import { EFFICIENCY_OVER_UTILIZED_THRESHOLD, EFFICIENCY_UNDER_UTILIZED_THRESHOLD } from '../../constants';

export enum UsageType {
  Low = 'low',
  Med = 'med',
  High = 'high',
  Unknown = 'unknown',
}

type UsageBoxProps = {
  type: UsageType;
  value?: number | string | null;
  noValue?: boolean;
  round?: boolean;
  showPercent?: boolean;
  percentValue?: number;
  unit?: string;
  loading?: boolean;
};

export function getUsageType(value: number | undefined) {
  if (!value) {
    return UsageType.Unknown;
  }

  if (value <= EFFICIENCY_UNDER_UTILIZED_THRESHOLD) {
    return UsageType.Low;
  } else if (value > EFFICIENCY_UNDER_UTILIZED_THRESHOLD && value < EFFICIENCY_OVER_UTILIZED_THRESHOLD) {
    return UsageType.Med;
  } else if (value > EFFICIENCY_OVER_UTILIZED_THRESHOLD) {
    return UsageType.High;
  }

  return UsageType.Low;
}

const UsageBox = ({ type, value, noValue, round, showPercent = true, percentValue, unit, loading }: UsageBoxProps) => {
  const styles = useStyles2(usageLabelsStyles(noValue));

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <>
          <>
            {type === UsageType.Unknown ? (
              <Icon name="question-circle" style={{ width: 14, height: 14 }} />
            ) : (
              <div className={styles.squareContainer}>
                <div className={styles.square}>
                  <div
                    className={cx(
                      styles.innerSquare,
                      { [styles.lowUsage]: type === UsageType.Low },
                      { [styles.medUsage]: type === UsageType.Med },
                      { [styles.highUsage]: type === UsageType.High }
                    )}
                  ></div>
                </div>
              </div>
            )}
          </>
          {!noValue && (
            <MaxUsageValue
              value={value as number}
              round={round}
              showPercent={showPercent}
              percentValue={percentValue}
              unit={unit}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UsageBox;
