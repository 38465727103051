import React, { useMemo } from 'react';
import { locationUtil } from '@grafana/data';
import { Menu } from '@grafana/ui';
import { PluginExtensionLink } from 'types';

interface Props {
  extensions: PluginExtensionLink[];
}

const ExtensionLinkMenu = ({ extensions }: Props) => {
  const { categorised, uncategorised } = useExtensionLinksByCategory(extensions);
  const showDivider = uncategorised.length > 0 && Object.keys(categorised).length > 0;

  return (
    <Menu>
      <>
        {Object.keys(categorised)?.map?.((category) => (
          <Menu.Group key={category} label={truncateTitle(category, 25)}>
            {renderItems(categorised[category])}
          </Menu.Group>
        ))}
        {showDivider && <Menu.Divider key="divider" />}
        {renderItems(uncategorised)}
      </>
    </Menu>
  );
};

export default ExtensionLinkMenu;

function renderItems(extensions: PluginExtensionLink[]): JSX.Element[] {
  return extensions?.map?.((extension) => (
    <Menu.Item
      ariaLabel={extension.title}
      icon={extension?.icon || 'plug'}
      key={extension.id}
      label={truncateTitle(extension.title, 25)}
      onClick={(event) => {
        if (extension.path) {
          return void global.open(locationUtil.assureBaseUrl(extension.path), '_blank');
        }
        return extension.onClick?.(event);
      }}
    />
  ));
}

type ExtensionLinksResult = {
  uncategorised: PluginExtensionLink[];
  categorised: Record<string, PluginExtensionLink[]>;
};

function useExtensionLinksByCategory(extensions: PluginExtensionLink[]): ExtensionLinksResult {
  return useMemo(() => {
    const uncategorised: PluginExtensionLink[] = [];
    const categorised: Record<string, PluginExtensionLink[]> = {};

    for (const link of extensions) {
      if (!link.category) {
        uncategorised.push(link);
        continue;
      }

      if (!Array.isArray(categorised[link.category])) {
        categorised[link.category] = [];
      }
      categorised[link.category].push(link);
      continue;
    }

    return {
      uncategorised,
      categorised,
    };
  }, [extensions]);
}

// Truncate a string to a given maximum length, adding ellipsis if it was truncated.
const truncateTitle = (title: string, length: number): string => {
  if (title.length < length) {
    return title;
  }
  const part = title.slice(0, length - 3);
  return `${part.trimEnd()}...`;
};
