import { useStyles2 } from '@grafana/ui';
import React from 'react';
import integrations from '../../static/config/integrations';
import { Clipboard } from '../Clipboard';
import integrationsListStyles from './IntegrationsList.styles';

type Props = {
  slug: string;
  showTitle?: boolean;
};

const HelmChartIntegration = ({ slug, showTitle }: Props) => {
  const styles = useStyles2(integrationsListStyles);

  const metricsSnippet = integrations[slug]?.metrics;
  const extraMetricsInstructions = integrations[slug]?.extraMetricsInstructions;
  const logsSnippet = integrations[slug]?.logs;
  const extraLogsInstructions = integrations[slug]?.extraLogsInstructions;
  const noSnippet = !metricsSnippet && !logsSnippet;

  return (
    <div className={styles.sectionSpace}>
      {showTitle && <h4 className={styles.title}>Kubernetes Monitoring Helm chart configuration</h4>}
      <p className={styles.description}>
        {noSnippet
          ? 'No configuration available for this integration for the Kubernetes Monitoring Helm chart.'
          : 'To use this integration, modify your Kubernetes Monitoring Helm chart deployment with these configuration snippets.'}
      </p>
      {metricsSnippet && (
        <div className={`${styles.sectionSpace} ${styles.clipboard}`}>
          <h4 className={styles.title}>Metrics snippet</h4>
          <p className={styles.description}>
            Copy the following and add to the <code>.extraConfig</code> value of the Kubernetes Monitoring Helm chart.
          </p>
          <Clipboard content={metricsSnippet} className={styles.clipboard} resetTime={1000} />
        </div>
      )}
      {extraMetricsInstructions && (
        <div className={`${styles.sectionSpace} ${styles.clipboard}`}>
          <h4 className={styles.title}>Additional metrics configuration steps</h4>
          <p dangerouslySetInnerHTML={{ __html: extraMetricsInstructions }} className={styles.description}></p>
        </div>
      )}
      {logsSnippet && (
        <div className={`${styles.sectionSpace} ${styles.clipboard}`}>
          <h4 className={styles.title}>Logs snippet</h4>
          <p className={styles.description}>
            Copy the following and add to the <code>.logs.extraConfig</code> value of the Kubernetes Monitoring Helm
            chart.
          </p>
          <Clipboard content={logsSnippet} className={styles.clipboard} resetTime={1000} />
        </div>
      )}
      {extraLogsInstructions && (
        <div className={`${styles.sectionSpace} ${styles.clipboard}`}>
          <h4 className={styles.title}>Additional logs configuration steps</h4>
          <p dangerouslySetInnerHTML={{ __html: extraLogsInstructions }} className={styles.description}></p>
        </div>
      )}
      {!noSnippet && (
        <p className={styles.description}>
          For more information about how to set values for
          {metricsSnippet && <code>.extraConfig</code>}
          {metricsSnippet && logsSnippet && ' or '}
          {logsSnippet && <code>.logs.extraConfig</code>}, see the{' '}
          <a
            href={
              'https://github.com/grafana/k8s-monitoring-helm/blob/main/charts/k8s-monitoring/docs/UsingExtraConfig.md'
            }
          >
            Helm chart documentation
          </a>
          .
        </p>
      )}
    </div>
  );
};

export default HelmChartIntegration;
