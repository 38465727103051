import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { SortOrder } from '@grafana/schema';

export type EfficencyFilterKeys = 'nodes' | 'clusters' | 'namespaces' | '';

type StateType = {
  sortOrder: SortOrder;
  sortColumn: string;
};

type ActionType = {
  setSorting: (sortOrder: SortOrder, sortColumn: string) => void;
};

export type SortingStore = StateType & ActionType;

const initialState = {
  sortOrder: SortOrder.None,
  sortColumn: '',
};

export function setSorting(sortOrder: SortOrder, sortColumn: string) {
  useSorting.setState({
    sortOrder,
    sortColumn,
  });
}

const useSorting = createWithEqualityFn<SortingStore>(
  () => ({
    //state
    ...initialState,

    // actions

    setSorting,
  }),
  shallow
);

export default useSorting;
