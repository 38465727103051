import React, { useMemo } from 'react';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneTimeRange,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { CustomSceneObjectDetailState, ScenesCustomParams } from 'types';
import { addTimeRangeHandler } from 'helpers/scenes';
import { QueryType } from '../GenericDetailOptimization/scene';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import useTimeRangeStore from 'store/timeRange';
import { config } from '@grafana/runtime';
import { networkRowAggregated, networkRowSplit } from './panels';
import { getNetworkQueriesByParams, getVariables } from './helpers';

const Network = ({ model }: SceneComponentProps<NetworkScene>) => {
  const props = model.useState();
  const prometheusName = useDatasourceStore(prometheusSelector);
  const prometheusDatasource = config.datasources[prometheusName];
  const lokiName = useDatasourceStore(lokiSelector);
  const [relativeRange] = useTimeRangeStore((state) => [state.relativeRange]);

  const scene = useMemo(
    () =>
      getNetworkScene({
        ...props,
        datasource: prometheusDatasource,
        prometheusName,
        lokiName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [relativeRange, prometheusName, lokiName, prometheusDatasource, props]
  );

  return <scene.Component model={scene} />;
};

export function getNetworkScene({
  type = 'cluster',
  cluster,
  node,
  namespace,
  workload,
  workloadType,
  podName,
  datasource,
  relativeTimeRange,
  onTimeRangeChange,
}: ScenesCustomParams) {
  const sceneTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(sceneTimeRange, onTimeRangeChange);

  const { sum, split, splitBy } = getNetworkQueriesByParams({
    type,
    cluster: cluster || '',
    node,
    namespace,
    podName,
  });

  return new SceneFlexLayout({
    $variables: getVariables(type, datasource, cluster, node, namespace, workload, workloadType),
    $timeRange: sceneTimeRange,
    direction: 'column',
    children: [
      ...[networkRowAggregated(datasource, sum as QueryType)],
      ...[networkRowSplit(datasource, split as QueryType, splitBy as string, type)],
    ],
  });
}

export class NetworkScene extends SceneObjectBase<CustomSceneObjectDetailState> {
  static Component = Network;
}

export function networkScene(params: CustomSceneObjectDetailState) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new NetworkScene({
            ...params,
          }),
        }),
      ],
    }),
  });
}
