import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { PLUGIN_ROOT_URL } from '../../constants';
import { css } from '@emotion/css';
import React from 'react';
import { Link } from 'react-router-dom';
import _startCase from 'lodash/startCase';
import useDecodedParams from 'hooks/useDecodedParams';
import { encodeUrlString } from 'helpers/helpers';

export const breadcrumbStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: row;
  `,
  containerFallback: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  link: css`
    text-decoration: underline;
    margin-right: ${theme.spacing(1)};
    margin-bottom: 0;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
  spaceRight: css`
    margin-right: 5px;
    margin-bottom: 0;
  `,
});

export const titleType = (cluster: string, namespace: string, workload: string, podName: string, node: string) => {
  if (node) {
    return 'node';
  } else if (podName) {
    return 'pod';
  } else if (workload && !podName) {
    return 'workload';
  } else if (namespace && !workload && !podName) {
    return 'namespace';
  } else if (cluster) {
    return 'cluster';
  }

  return null;
};

const BreadCrumbs = () => {
  const styles = useStyles2(breadcrumbStyles);
  const { cluster, namespace, workloadType, workload, podName, node } = useDecodedParams();

  return (
    <div style={{ marginBottom: 20 }}>
      <div className={styles.container}>
        {cluster && !node && (
          <Link to={`${PLUGIN_ROOT_URL}/navigation/cluster`}>
            <h6 className={styles.link}>overview</h6>
          </Link>
        )}

        {node && (
          <>
            <Link to={`${PLUGIN_ROOT_URL}/navigation/nodes`}>
              <h6 className={styles.link}>nodes</h6>
            </Link>
            <h5 className={styles.spaceRight}>&#47;</h5>
            <h6>{node}</h6>
          </>
        )}

        {namespace && (
          <>
            <h5 className={styles.spaceRight}>&#47;</h5>
            <Link to={`${PLUGIN_ROOT_URL}/navigation/namespace/${encodeUrlString(cluster)}/${namespace}`}>
              <h6 className={styles.link}>
                {cluster} - {namespace}
              </h6>
            </Link>
          </>
        )}
        {workload && (
          <>
            <h5 className={styles.spaceRight}>&#47;</h5>
            <Link
              to={`${PLUGIN_ROOT_URL}/navigation/namespace/${encodeUrlString(
                cluster
              )}/${namespace}/${workloadType}/${workload}`}
            >
              <h6 className={styles.link}>{workload}</h6>
            </Link>
          </>
        )}
        {podName && (
          <>
            <h5 className={styles.spaceRight}>&#47;</h5>
            <h6>{podName}</h6>
          </>
        )}
      </div>
    </div>
  );
};

export default BreadCrumbs;
