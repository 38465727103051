import React, { useEffect, useMemo } from 'react';
import { Button, Icon, LinkButton } from '@grafana/ui';
import useAssertsStore from 'store/asserts';
import buildLinkToWorkbench, { WorkbenchEntity, WorkbenchEntityTypes } from './buildLinkToWorkbench';
import { RelativeRange } from 'store/timeRange';

interface Props {
  label: string;
  type: WorkbenchEntityTypes;
  relativeRange?: RelativeRange | null;
  nameKey?: string;
}

const AssertsWorkbenchButton = ({ label, type, nameKey, relativeRange }: Props) => {
  const [selectedAssertsRows, setSelectedAssertsRows, setToggledClearRows, toggledClearRows] = useAssertsStore(
    (state) => [
      state.selectedAssertsRows,
      state.setSelectedAssertsRows,
      state.setToggledClearRows,
      state.toggledClearRows,
    ]
  );

  const workbenchLink = useMemo(() => {
    // format data to WorkbenchEntity
    const entities: WorkbenchEntity[] = selectedAssertsRows?.map?.((row) => {
      return {
        name: nameKey ? row[nameKey] : row.name,
        type: type,
        scope: {
          site: row.asserts_site || '',
          namespace: row.namespace || '',
          env: row.asserts_env || '',
        },
      };
    });

    return buildLinkToWorkbench(entities, type, relativeRange);
  }, [selectedAssertsRows, type, nameKey, relativeRange]);

  // Clear out selectedAssertsRows when component unmounts
  useEffect(() => {
    return () => setSelectedAssertsRows([]);
  }, [setSelectedAssertsRows]);

  if (selectedAssertsRows.length <= 0) {
    return null;
  }

  const handleClearSelection = () => {
    setSelectedAssertsRows([]);
    setToggledClearRows(!toggledClearRows);
  };

  return (
    <>
      <LinkButton href={workbenchLink} target="_blank" rel="noopener noreferrer">
        Compare {label} in Asserts Workbench
      </LinkButton>
      <Button onClick={handleClearSelection}>
        <Icon name="times" />
      </Button>
    </>
  );
};

export default AssertsWorkbenchButton;
