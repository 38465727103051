import useFilters, { TableView } from 'store/filters';
import useDataFrame from './useDataFrame';
import { useMemo } from 'react';
import { QueryCollection, mergeUsageData, parseCostData, parseUsageData } from 'helpers/helpers';
import { SortOrder } from '@grafana/schema';
import useSorting from 'store/sorting';
import { TableColumn } from 'react-data-table-component';
import { DataFrameJSON } from '@grafana/data';
import useTimeRangeStore from 'store/timeRange';

type UsageCostType<T> = {
  type: string;
  initialData?: T[];
  usageQueries: QueryCollection;
  costQueries: QueryCollection;
  withDependencies?: boolean;
  dependencies?: string; // If you need to pass a list to use on the queries
  getNameFromMetricFn?: (metric: DataFrameJSON) => string;
  getNameFromDataFn?: (item: T) => string;
};

const useCostUsage = <T>({
  type,
  initialData,
  usageQueries,
  costQueries,
  withDependencies,
  dependencies,
  getNameFromMetricFn,
  getNameFromDataFn,
}: UsageCostType<T>) => {
  const tableView = useFilters((state) => state.tableView);
  const [sortColumn, sortOrder, setSorting] = useSorting((state) => [
    state.sortColumn,
    state.sortOrder,
    state.setSorting,
  ]);
  const [hasDateChanged, range] = useTimeRangeStore((state) => [state.hasDateChanged, state.range]);

  const checkDependencies = (withDependencies && !!dependencies) || !withDependencies;

  const {
    loading: usageLoading,
    data: usageData,
    firstLoad: firstUsageLoad,
    error: usageErrors,
  } = useDataFrame(
    usageQueries.toArray(),
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '', // auto step
    !hasDateChanged, // refresh
    undefined, // default maxDataPoints
    false,
    true, // instant query
    tableView === TableView.Usage && checkDependencies // enabled param, do not run this queries if false
  );

  const {
    loading: costLoading,
    data: costData,
    firstLoad: firstCostLoad,
    error: costErrors,
  } = useDataFrame(
    costQueries.toArray(),
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '', // auto step
    !hasDateChanged, // refresh
    undefined, // default maxDataPoints
    false,
    true, // instant query
    tableView === TableView.Cost && checkDependencies // enabled param, do not run this queries if false
  );

  const usageParsedData = useMemo(
    () => parseUsageData(usageData, usageErrors, type, getNameFromMetricFn),
    [usageData, usageErrors, type, getNameFromMetricFn]
  );
  const costParsedData = useMemo(
    () => parseCostData(costData, costErrors, type, getNameFromMetricFn),
    [costData, costErrors, type, getNameFromMetricFn]
  );
  const usageCostTableData = useMemo(
    () =>
      mergeUsageData(
        type,
        (initialData as T[]) || [],
        usageParsedData,
        costParsedData,
        sortOrder,
        sortColumn,
        getNameFromDataFn
      ),
    [initialData, costParsedData, usageParsedData, sortOrder, sortColumn, type, getNameFromDataFn]
  );

  const onTableSort = (item: TableColumn<any>, order: string) => {
    setSorting(order === 'asc' ? SortOrder.Ascending : SortOrder.Descending, item.name as string);
  };

  const exploreUrl = tableView === TableView.Usage ? usageQueries.toExploreUrl() : costQueries.toExploreUrl();

  return {
    usageLoading,
    costLoading,
    usageCostTableData,
    onTableSort,
    sortColumn,
    tableView,
    firstUsageLoad,
    firstCostLoad,
    exploreUrl,
  };
};

export default useCostUsage;
