import { Button, LegendDisplayMode, LoadingPlaceholder, Modal, Tooltip, useStyles2 } from '@grafana/ui';
import TimeSeriesPanel from 'components/Panel/TimeSeriesPanel';
import useForecast from 'hooks/MachineLearning/useForecast';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useIntegrationStore from 'store/integration';
import { shallow } from 'zustand/shallow';
import Styles from './PredictionModal.styles';
import useTimeRangeStore from 'store/timeRange';
import { trackRudderStackEvent } from 'hooks/useRudderstack';
import { RudderstackEvents } from 'enums';
import { getPredictUrl } from 'helpers/helpers';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';
import { config } from '@grafana/runtime';
import { showPredictableModal } from 'store/predictable';

interface Props {
  buttonText: string;
  query: string;
  name: string;
  metric: string;
  title: string;
  axisLabel?: string;
  hyperParamsUpperLimit?: number;
  scenePanel?: boolean;
  showModal?: boolean;
}

const panelOptions = {
  legend: {
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  graph: {},
  tooltipOptions: {
    mode: 'single',
  },
};

const PredictionModal = ({
  buttonText,
  query,
  name,
  metric,
  title,
  hyperParamsUpperLimit,
  axisLabel = 'Percent usage',
  scenePanel = false,
  showModal = false,
}: Props) => {
  const styles = useStyles2(Styles);
  const [isModalOpen, setIsModalOpen] = useState(showModal);
  const [data, error, loading, getData] = useForecast(query, name, metric, hyperParamsUpperLimit);
  const [isMachineLearningInstalled] = useIntegrationStore((state) => [state.machineLearningInstalled], shallow);
  const [hasDateChanged] = useTimeRangeStore((state) => [state.hasDateChanged], shallow);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const dsUid = config.datasources[prometheusName].uid;

  useEffect(() => {
    if (isModalOpen && isMachineLearningInstalled) {
      getData();
      trackRudderStackEvent(RudderstackEvents.RunPredictionModel, {});
    }
  }, [getData, isModalOpen, isMachineLearningInstalled]);

  const ModalButton = (
    <Button
      style={{ marginRight: scenePanel ? 0 : 16, marginTop: scenePanel ? 2 : 0 }}
      variant="secondary"
      size="sm"
      onClick={() => setIsModalOpen(true)}
      disabled={hasDateChanged}
    >
      {buttonText}
    </Button>
  );

  return (
    <div>
      {hasDateChanged ? (
        <Tooltip content="Prediction is not available while navigating through data in the past.">
          <div>{ModalButton}</div>
        </Tooltip>
      ) : (
        ModalButton
      )}
      <Modal
        isOpen={isModalOpen || showModal}
        onDismiss={() => {
          setIsModalOpen(false);
          showPredictableModal(null);
        }}
        closeOnEscape
        title={title}
        className={styles.modal}
      >
        {!isMachineLearningInstalled ? (
          <div>
            Machine learning plugin is not initialized. Click{' '}
            <Link style={{ textDecoration: 'underline' }} to={`/a/grafana-ml-app/home`}>
              here
            </Link>{' '}
            to initialize the plugin and come back to see your data predictions!
          </div>
        ) : (
          <>
            {loading ? (
              <LoadingPlaceholder text="Generating prediction model, this may take a moment." />
            ) : (
              <>
                {!error ? (
                  <div style={{ width: '750px', height: '65vh' }}>
                    <p>
                      This prediction model takes metrics from the past week, and shows possible upper and lower bounds
                      through a week into the future.{' '}
                      <a className={styles.link} href={getPredictUrl(dsUid, query)}>
                        Explore this query in the Machine Learning plugin.
                      </a>
                    </p>
                    <TimeSeriesPanel data={data} loading={loading} options={panelOptions} axisLabel={axisLabel} />
                  </div>
                ) : (
                  <div>There was an error requesting prediction: {error}</div>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default PredictionModal;
