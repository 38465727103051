import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const homeStyles = (theme: GrafanaTheme2) => ({
  cardCentered: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px;
    h2:first-of-type {
      display: none;
    }
    h2 {
      width: 60%;
      margin: 20px 0 40px 0;
    }
  `,
  cardGrid: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    width: 100%;
    text-align: center;
    padding: 40px;

    h3 {
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 20px;
    }

    p {
      width: 300px;
      margin: auto;
    }
  `,
  card60: css`
    display: flex;
    flex-direction: column;
    width: 66.6%;
    padding: 40px;
  `,
  card30: css`
    width: 33.3%;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    padding: 40px;

    a {
      color: ${theme.colors.text.link};
    }

    ul {
      margin-left: 20px;
    }
  `,
  cardStats: css`
    h2:first-of-type {
      display: none;
    }
    > h1 {
      width: 210px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 40px;
  `,
  stats: css`
    h2 {
      display: block !important;
    }

    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  `,
  bottomLink: css`
    margin: 20px 0 20px 0;
    display: block;
  `,
});

export default homeStyles;
