import React from 'react';
import { shallow } from 'zustand/shallow';
import { DataSourceInstanceSettings, DataSourceJsonData } from '@grafana/data';
import { DataSourcePicker } from '@grafana/runtime';
import useDatasourceStore from 'store/datasource';
import { StoredParams } from 'types';
import useLocalStorage from 'hooks/useLocalStorage';
import { K8S_STORAGE_KEY } from '../constants';
import useIntegrationStore from 'store/integration';
import { AssertsQueries, Queries } from 'queries';
import useAssertsStore from 'store/asserts';
import useQueryParams from 'hooks/useQueryParams';

interface Props {
  className?: string;
  onChange?: (ds: DataSourceInstanceSettings<DataSourceJsonData>) => void;
}

const PrometheusPicker = ({ className, onChange }: Props) => {
  const [, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);
  const [prometheusName, setPrometheusName] = useDatasourceStore(
    (state) => [state.prometheusName, state.setPrometheusName],
    shallow
  );
  const isDataFlowing = useIntegrationStore((state) => state.isDataFlowing);
  const getAssertsEnabled = useAssertsStore((state) => state.getAssertsEnabled);
  const { updateQueryParam } = useQueryParams();

  return (
    <div className={className}>
      <DataSourcePicker
        current={prometheusName}
        type="prometheus"
        pluginId="prometheus"
        onChange={(ds) => {
          setStoredParams({ promName: ds.name, namespace: '', cluster: [] });
          setPrometheusName(ds.name);
          updateQueryParam('var-datasource', ds.name);

          if (onChange) {
            onChange(ds);
          }
          getAssertsEnabled(AssertsQueries.isEnabled, ds.name, true);
          isDataFlowing(Queries.IsDataFlowing, ds.name, true);
        }}
        dashboard={false}
      />
    </div>
  );
};

export default PrometheusPicker;
