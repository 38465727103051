import React, { CSSProperties, useCallback } from 'react';
import { ComponentSize, LinkButton, useStyles2 } from '@grafana/ui';
import { trackRudderStackEvent } from 'hooks/useRudderstack';

import exploreButtonStyles from './ExploreButton.styles';
import { cx } from '@emotion/css';

type ExploreButtonProps = {
  href: string;
  isDisabled?: boolean;
  label: string;
  eventName?: string;
  size?: ComponentSize;
  className?: string;
  style?: CSSProperties;
};

const ExploreButton = ({ href, isDisabled, label, eventName, size = 'sm', className, style }: ExploreButtonProps) => {
  const styles = useStyles2(exploreButtonStyles);

  const onClick = useCallback(() => {
    if (eventName) {
      trackRudderStackEvent(eventName, {});
    }
  }, [eventName]);

  return (
    <LinkButton
      role="button"
      className={cx(styles.exploreButton, className)}
      variant="secondary"
      target="_blank"
      size={size}
      icon="external-link-alt"
      href={href}
      disabled={isDisabled}
      onClick={eventName ? onClick : undefined}
      style={style}
    >
      {label}
    </LinkButton>
  );
};

export default ExploreButton;
