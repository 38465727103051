import React from 'react';
import TokenWrapper from '../../../AccessPolicyToken/TokenWrapper';

const ConfigAPIToken = () => {
  return (
    <>
      <h3>Use a Grafana.com Access Policy Token</h3>
      <p>An Access Policy token is required for Grafana Alloy to send metrics and logs to Grafana Cloud.</p>
      <TokenWrapper />
    </>
  );
};

export default React.memo(ConfigAPIToken);
