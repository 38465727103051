import React from 'react';
import { SceneAppPage, SceneTimeRangeState } from '@grafana/scenes';
import { ScenesCustomParams, ScenesRouteParams } from 'types';
import { getWorkloadListAppScene } from 'components/workloads/WorkloadList/WorkloadList';
import { getWorkloadDetailAppScene } from 'components/workloads/WorkloadDetail/WorkloadDetail';
import { getPodDetailAppScene } from 'components/workloads/PodDetail/PodDetail';

import { createDrilldown, createTab, getUrlFromRoutePath, routePaths } from 'helpers/scenes';
import { getContainerDetailAppScene } from 'components/Container/Container';
import { getNamespaceListAppScene } from 'components/Namespace/NamespaceList';
import { getDefaultAppProps } from './Cluster';
import TitleRow from '../TitleRow/TitleRow';
import { networkScene } from '../Network/Network';
import { energyScene } from '../Energy/Energy';
import { logsScene } from '../Logs/Logs';
import logo from 'img/k8s.png';

const tabsConfig = {
  namespaceDetail: (params: ScenesRouteParams) => [
    createTab('Overview', `${getUrlFromRoutePath(params).namespace}/overview`, () =>
      getWorkloadListAppScene(params.cluster!, params.namespace!)
    ),
    createTab('Network', `${getUrlFromRoutePath(params).namespace}/network`, () =>
      networkScene({ type: 'namespace', cluster: params.cluster!, namespace: params.namespace! })
    ),
    createTab('Energy', `${getUrlFromRoutePath(params).namespace}/energy`, () =>
      energyScene({ type: 'namespace', cluster: params.cluster!, namespace: params.namespace! })
    ),
    createTab('Logs & Events', `${getUrlFromRoutePath(params).namespace}/logs`, () =>
      logsScene({ type: 'namespace', cluster: params.cluster!, namespace: params.namespace! })
    ),
  ],
  workloadDetail: (params: ScenesRouteParams) => [
    createTab('Overview', `${getUrlFromRoutePath(params).workload}/overview`, () =>
      getWorkloadDetailAppScene(params.cluster!, params.namespace!, params.workload!, params.workloadType!)
    ),
    createTab('Network', `${getUrlFromRoutePath(params).workload}/network`, () =>
      networkScene({
        type: 'workload',
        cluster: params.cluster!,
        namespace: params.namespace!,
        workload: params.workload!,
        workloadType: params.workloadType!,
      })
    ),
    createTab('Energy', `${getUrlFromRoutePath(params).workload}/energy`, () =>
      energyScene({
        type: 'workload',
        cluster: params.cluster!,
        namespace: params.namespace!,
        workload: params.workload!,
        workloadType: params.workloadType!,
      })
    ),
    createTab('Logs & Events', `${getUrlFromRoutePath(params).workload}/logs`, () =>
      logsScene({
        type: 'workload',
        cluster: params.cluster!,
        namespace: params.namespace!,
        workload: params.workload!,
        workloadType: params.workloadType!,
      })
    ),
  ],
  podDetail: (params: ScenesRouteParams) => [
    createTab('Overview', `${getUrlFromRoutePath(params).pod}/overview`, () =>
      getPodDetailAppScene(params.cluster!, params.namespace!, params.workload!, params.workloadType!, params.podName!)
    ),
    createTab('Network', `${getUrlFromRoutePath(params).pod}/network`, () =>
      networkScene({
        type: 'pod',
        cluster: params.cluster!,
        namespace: params.namespace!,
        workload: params.workload!,
        workloadType: params.workloadType!,
        podName: params.podName!,
      })
    ),
    createTab('Energy', `${getUrlFromRoutePath(params).pod}/energy`, () =>
      energyScene({
        type: 'pod',
        cluster: params.cluster!,
        namespace: params.namespace!,
        workload: params.workload!,
        workloadType: params.workloadType!,
        podName: params.podName!,
      })
    ),
    createTab('Logs & Events', `${getUrlFromRoutePath(params).pod}/logs`, () =>
      logsScene({
        type: 'pod',
        cluster: params.cluster!,
        namespace: params.namespace!,
        workload: params.workload!,
        workloadType: params.workloadType!,
        podName: params.podName!,
      })
    ),
  ],
  containerDetail: (params: ScenesRouteParams) => [
    createTab('Overview', `${getUrlFromRoutePath(params).container}/overview`, () =>
      getContainerDetailAppScene(
        params.cluster!,
        params.namespace!,
        params.workload!,
        params.workloadType!,
        params.podName!,
        params.container!
      )
    ),
    createTab('Energy', `${getUrlFromRoutePath(params).container}/energy`, () =>
      energyScene({
        type: 'container',
        cluster: params.cluster!,
        namespace: params.namespace!,
        podName: params.podName!,
        container: params.container!,
      })
    ),
    createTab('Logs & Events', `${getUrlFromRoutePath(params).container}/logs`, () =>
      logsScene({
        type: 'container',
        cluster: params.cluster!,
        namespace: params.namespace!,
        podName: params.podName!,
        container: params.container!,
      })
    ),
  ],
};

export const getNamespaceScene = (params: ScenesCustomParams) => {
  const { relativeTimeRange, range } = params;
  const config = { timeRange: relativeTimeRange as SceneTimeRangeState, range };

  const drilldowns = [
    createDrilldown(
      routePaths.namespace,
      'namespace',
      undefined,
      config,
      [
        createDrilldown(
          routePaths.workload,
          'workload',
          undefined,
          config,
          [
            createDrilldown(
              routePaths.pod,
              'podName',
              undefined,
              config,
              [
                createDrilldown(
                  routePaths.container,
                  'container',
                  undefined,
                  config,
                  [],
                  // container detail tabs
                  tabsConfig.containerDetail
                ),
              ],
              // pod detail tabs
              tabsConfig.podDetail
            ),
          ],
          // workload detail tabs
          tabsConfig.workloadDetail
        ),
      ],
      // namespace detail tabs
      tabsConfig.namespaceDetail
    ),
  ];

  const scene = new SceneAppPage({
    title: 'Namespaces',
    renderTitle: (title: string) => <TitleRow title={title} withNavigation page="navigation/namespace" />,
    titleImg: logo,
    getScene: getNamespaceListAppScene,
    drilldowns,
    url: routePaths.namespaces,
    ...getDefaultAppProps(relativeTimeRange),
  });

  return scene;
};

export default getNamespaceScene;
