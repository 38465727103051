import React, { PropsWithChildren, useState } from 'react';
import PaginationContext, { PaginationTrackingType } from './PaginationContext';
import { PAGINATION_PAGE_SIZES, DEFAULT_INITIAL_PAGE, DEFAULT_TABLE_ID, K8S_STORAGE_KEY } from '../constants';
import useLocalStorage from 'hooks/useLocalStorage';
import { StoredParams } from 'types';

const getInitialPageTracking = (storedParams: StoredParams) => {
  const pageSize = parseInt(storedParams?.page_size || PAGINATION_PAGE_SIZES[0].value.toString(), 10);
  const page = parseInt(storedParams?.page || DEFAULT_INITIAL_PAGE.toString(), 10);

  return { page, pageSize };
};

const PaginationProvider = ({ children }: PropsWithChildren<{}>) => {
  const [storedParams, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);
  const { page, pageSize } = getInitialPageTracking(storedParams);

  const [tracking, setTracking] = useState<PaginationTrackingType>({
    [DEFAULT_TABLE_ID]: { name: DEFAULT_TABLE_ID, pageSize, page },
  });

  const setTrackingValue = (name: string, id: string, newPage: number, newPageSize: number) => {
    setTracking({
      ...tracking,
      [id]: {
        name,
        pageSize: newPageSize,
        page: newPage,
      },
    });
  };

  const initPageTracking = (name: string, id: string) => {
    if (typeof tracking[id] === 'undefined') {
      // If current table id is not tracked, set initial params
      const trackingKeys = Object.keys(tracking);
      const { page: initialPage, pageSize: currentPageSize } = getInitialPageTracking(storedParams);

      // If besides default tracking, there's already a previous tracked table, means we are coming
      // from a previous table after user action, so we need to start on page 1
      const currentPage = trackingKeys.length >= 2 ? DEFAULT_INITIAL_PAGE : initialPage;

      // Set initial params
      setStoredParams({ page: currentPage.toString(), page_size: currentPageSize.toString() });

      setTrackingValue(name, id, currentPage, currentPageSize);
    } else if (tracking[id].name !== name) {
      // If current tracking table id-name is not the same as the one stored, we should reset current id page/pageSize
      // because user clicked on a different namespace/workload than the one stored
      setStoredParams({ page: DEFAULT_INITIAL_PAGE.toString(), page_size: PAGINATION_PAGE_SIZES[0].value.toString() });
      setTrackingValue(name, id, DEFAULT_INITIAL_PAGE, PAGINATION_PAGE_SIZES[0].value);
    } else if (storedParams?.page !== tracking[id].page.toString()) {
      // If current page for current table id is different from the one stored, update the page to the stored one
      setStoredParams({ page: tracking[id].page.toString(), page_size: tracking[id].pageSize.toString() });
    }
  };

  const updatePageTracking = (name: string, id: string, currentPage: number, currentPageSize: number) => {
    if (id) {
      setTrackingValue(name, id, currentPage, currentPageSize);
      setStoredParams({ page: currentPage.toString(), page_size: currentPageSize.toString() });
    }
  };

  return (
    <PaginationContext.Provider value={{ tracking, updatePageTracking, initPageTracking }}>
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationProvider;
