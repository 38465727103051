import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Select } from '@grafana/ui';
import { PLUGIN_ROOT_URL } from '../../constants';
import { SelectableValue } from '@grafana/data';

export const navigationOptions = [
  {
    label: 'Kubernetes Overview',
    value: 'home',
  },
  {
    label: 'Clusters',
    value: 'navigation/cluster',
  },
  {
    label: 'Namespaces',
    value: 'navigation/namespace',
  },
  {
    label: 'Workloads',
    value: 'navigation/workload',
  },
  {
    label: 'Nodes',
    value: 'navigation/nodes',
  },
  {
    label: 'Cost',
    value: 'cost',
  },
  {
    label: 'Alerts',
    value: 'alerts',
  },
  {
    label: 'Configuration',
    value: 'configuration',
  },
];

const DEFAULT_PAGE = 'home';
const NavigationDropdown = ({ page = DEFAULT_PAGE }) => {
  const history = useHistory();

  const onChange = useCallback(
    (option: SelectableValue) => {
      history.push(`${PLUGIN_ROOT_URL}/${option.value}`);
    },
    [history]
  );

  return <Select width={25} placeholder="Go to page" options={navigationOptions} value={page} onChange={onChange} />;
};

export default NavigationDropdown;
