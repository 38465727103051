import { SelectableValue } from '@grafana/data';
import { UsageCostData, Workload } from 'types';
import { PodHealthStatus } from 'enums';
import { PodBreakdown } from '../podHealthUtils';

export type WorkloadTypes =
  | ''
  | 'ReplicaSet'
  | 'Deployment'
  | 'DaemonSet'
  | 'StatefulSet'
  | 'Job'
  | 'Pod'
  | 'StaticPod';

export interface WorkloadFilters {
  [key: string]: string | boolean | string[];
  search: string;
  type: WorkloadTypes;
  showUnhealthy: boolean;
  providers: string;
  namespace: string;
  cluster: string[];
}

export const defaultFilters: WorkloadFilters = {
  search: '',
  type: '',
  providers: '',
  showUnhealthy: false,
  namespace: '',
  cluster: [],
};

export const matchesUnhealthyOnly = (unhealthyOnly: boolean, podCountByHealth: PodBreakdown) => {
  if (!unhealthyOnly) {
    return true;
  }
  return podCountByHealth[PodHealthStatus.Unhealthy] > 0;
};

export const matchesSearch = (search: string, name: string) => {
  if (!search) {
    return true;
  }
  return name.includes(search);
};

export const matchesType = (type: string, workload: Workload) => {
  if (!type) {
    return true;
  }
  return workload.type.toLowerCase() === type.toLowerCase();
};

export const matches = (currentValue?: string, matchValue?: string) => {
  if (!matchValue) {
    return true;
  }

  return currentValue?.includes(matchValue);
};

export const matchesSearchFilter = (workloads: DataTableWorkload[], filters: WorkloadFilters) => {
  return workloads?.filter?.((wl) => {
    const matchesCluster = filters?.cluster?.some((clusterItem) => wl.cluster?.includes(clusterItem));
    return (
      (!filters?.cluster?.length || matchesCluster) &&
      matches(wl.namespace, filters?.namespace) &&
      matchesSearch(filters?.search, wl.name) &&
      matchesType(filters?.type, wl) &&
      matchesUnhealthyOnly(filters?.showUnhealthy, wl.podCountByHealth)
    );
  });
};

export const workloadTypeOptions: SelectableValue[] = [
  {
    label: 'Deployment',
    value: 'Deployment',
  },
  {
    label: 'ReplicaSet',
    value: 'ReplicaSet',
  },
  {
    label: 'DaemonSet',
    value: 'DaemonSet',
  },
  {
    label: 'StatefulSet',
    value: 'StatefulSet',
  },
  {
    label: 'Job',
    value: 'Job',
  },
  {
    label: 'Pod',
    value: 'Pod',
  },
  {
    label: 'StaticPod',
    value: 'StaticPod',
  },
];

export interface WorkloadDetails extends UsageCostData {
  kube_statefulset_annotations?: string;
  kube_statefulset_created?: string;
  kube_statefulset_labels?: string;
  kube_statefulset_metadata_generation?: string;
  kube_statefulset_replicas?: string;
  kube_statefulset_status_current_revision?: string;
  kube_statefulset_status_observed_generation?: string;
  kube_statefulset_status_replicas?: string;
  kube_statefulset_status_replicas_available?: string;
  kube_statefulset_status_replicas_current?: string;
  kube_statefulset_status_replicas_ready?: string;
  kube_statefulset_status_replicas_updated?: string;
  kube_statefulset_status_update_revision?: string;
  kube_replicaset_annotations?: string;
  kube_replicaset_created?: string;
  kube_replicaset_labels?: string;
  kube_replicaset_metadata_generation?: string;
  kube_replicaset_owner?: string;
  kube_replicaset_spec_replicas?: string;
  kube_replicaset_status_fully_labeled_replicas?: string;
  kube_replicaset_status_observed_generation?: string;
  kube_replicaset_status_ready_replicas?: string;
  kube_replicaset_status_replicas?: string;
  kube_job_annotations?: string;
  kube_job_complete?: string;
  kube_job_created?: string;
  kube_job_info?: string;
  kube_job_labels?: string;
  kube_job_owner?: string;
  kube_job_spec_completions?: string;
  kube_job_spec_parallelism?: string;
  kube_job_status_active?: string;
  kube_job_status_completion_time?: string;
  kube_job_status_failed?: string;
  kube_job_status_start_time?: string;
  kube_job_status_succeeded?: string;
  kube_daemonset_annotations?: string;
  kube_daemonset_created?: string;
  kube_daemonset_labels?: string;
  kube_daemonset_metadata_generation?: string;
  kube_daemonset_status_current_number_scheduled?: string;
  kube_daemonset_status_desired_number_scheduled?: string;
  kube_daemonset_status_number_available?: string;
  kube_daemonset_status_number_misscheduled?: string;
  kube_daemonset_status_number_ready?: string;
  kube_daemonset_status_number_unavailable?: string;
  kube_daemonset_status_observed_generation?: string;
  kube_daemonset_status_updated_number_scheduled?: string;
  kube_deployment_annotations?: string;
  kube_deployment_status_replicas?: string;
  kube_deployment_status_replicas_ready?: string;
  kube_deployment_status_replicas_available?: string;
  kube_deployment_status_replicas_unavailable?: string;
  kube_deployment_status_replicas_updated?: string;
  kube_deployment_status_observed_generation?: string;
  kube_deployment_status_condition?: string;
  kube_deployment_spec_replicas?: string;
  kube_deployment_spec_paused?: string;
  kube_deployment_spec_strategy_rollingupdate_max_unavailable?: string;
  kube_deployment_spec_strategy_rollingupdate_max_surge?: string;
  kube_deployment_metadata_generation?: string;
  kube_deployment_labels?: string;
  kube_deployment_created?: string;
}

export interface DataTableWorkload extends Workload {
  provider_id?: string;
  provider?: string;
  asserts_env?: string | undefined;
  asserts_site?: string | undefined;
}

export const healthStatusSort = (a: DataTableWorkload, b: DataTableWorkload) => {
  const healthyA = a.podCountByHealth[PodHealthStatus.Healthy];
  const healthyB = b.podCountByHealth[PodHealthStatus.Healthy];
  return healthyB - healthyA;
};
