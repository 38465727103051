import React, { useContext } from 'react';
import { Button, Card, LinkButton, LoadingPlaceholder, useStyles2 } from '@grafana/ui';
import getStyles from './Home.styles';
import { one, two, three } from 'img';
import FeatureList from 'components/home/FeatureList';
import { SceneFlexItem, SceneFlexLayout, SceneObjectBase } from '@grafana/scenes';
import { useHistory } from 'react-router-dom';
import { PluginMetaContext } from 'context/PluginMetaContext';
import useIntegrationStore from 'store/integration';
import { FetchStatus } from 'enums';

export class HomeNoDataScene extends SceneObjectBase {
  static Component = HomeNoDataRenderer;
}

function HomeNoDataRenderer() {
  const styles = useStyles2(getStyles);
  const { push } = useHistory();
  const meta = useContext(PluginMetaContext);
  const [dataFlowingStatus] = useIntegrationStore((state) => [state.dataFlowingStatus]);

  if (dataFlowingStatus === FetchStatus.Fetching) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  return (
    <div>
      <Card className={styles.cardCentered}>
        <h2>
          Kubernetes Monitoring is the multi-purpose, turnkey solution for fleet monitoring, streamlining triage, and
          optimizing resource utilization.
        </h2>
        <div>
          <Button style={{ marginRight: '10px' }} onClick={() => push(`/a/${meta.id}/configuration/cluster-config`)}>
            Start sending data
          </Button>
          <LinkButton
            variant="secondary"
            href="https://play.grafana.org/a/grafana-k8s-app/navigation/cluster?promName=grafanacloud-play-prom"
            target="_blank"
            icon="external-link-alt"
          >
            Try it out in Grafana Play
          </LinkButton>
        </div>
      </Card>
      <Card className={styles.cardCentered}>
        <h2>Get started in 3 easy steps:</h2>
        <div className={styles.cardGrid}>
          <div>
            <img src={one} />
            <h3>Configure</h3>
            <p>Install Grafana Alloy into your cluster and start shipping metrics.</p>
          </div>
          <div>
            <img src={two} />
            <h3>Install</h3>
            <p>
              Enable out-of-the-box alert and recording rules. Create, edit, or duplicate ready made alert and recording
              rules to get the most out of your monitoring.
            </p>
          </div>
          <div>
            <img src={three} />
            <h3>Explore</h3>
            <p>
              Gain insight into your Kubernetes cluster. You can visualize data for your pods, logs, events, and more.
            </p>
          </div>
        </div>
      </Card>
      <div style={{ display: 'flex' }}>
        <Card className={styles.card60}>
          <h3>Explore your data</h3>
          <FeatureList />
        </Card>
        <Card className={styles.card30}>
          <h3>Learn more</h3>
          <div>
            <ul>
              <li>
                Configure Kubernetes monitoring using{' '}
                <a
                  href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Grafana Alloy
                </a>
                .
              </li>
              <li>
                <a
                  href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/troubleshooting/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Troubleshoot
                </a>{' '}
                common issues encountered when installing and configuring Kubernetes monitoring components.
              </li>
              <li>
                Learn how to{' '}
                <a
                  href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/upgrade-k8s/"
                  target="_blank"
                  rel="noreferrer"
                >
                  update
                </a>{' '}
                Grafana Kubernetes components.
              </li>
              <li>
                Better understand the{' '}
                <a
                  href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/optimize-resource-usage/"
                  target="_blank"
                  rel="noreferrer"
                >
                  resource utilization efficiency
                </a>{' '}
                of your clusters.
              </li>
              <li>
                Manage the{' '}
                <a
                  href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/manage-costs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  cost
                </a>{' '}
                of your infrastructure.
              </li>
              <li>
                Respond to{' '}
                <a
                  href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/respond-to-alerts/"
                  target="_blank"
                  rel="noreferrer"
                >
                  alerts
                </a>{' '}
                when issues arise with your clusters and workloads.
              </li>
            </ul>
            <a
              href="https://grafana.com/docs/grafana-cloud/kubernetes-monitoring/"
              className={styles.bottomLink}
              target="_blank"
              rel="noreferrer"
            >
              See all documentation
            </a>
          </div>
        </Card>
      </div>
      <Card className={styles.cardStats}>
        <h1>Kubernetes Monitoring worldwide</h1>
        <div className={styles.stats}>
          <div>
            <p>Active metrics</p>
            <h1>100M+</h1>
          </div>
          <div>
            <p>Organizations monitoring k8s with Grafana</p>
            <h1>1.9k+</h1>
          </div>
          <div>
            <p>Nodes monitored</p>
            <h1>100k+</h1>
          </div>
          <div>
            <p>Pods monitored</p>
            <h1>2.4M+</h1>
          </div>
        </div>
      </Card>
    </div>
  );
}

export function getHomeNoDataScene() {
  return new SceneFlexLayout({
    children: [
      new SceneFlexItem({
        body: new HomeNoDataScene({}),
      }),
    ],
  });
}
