import { LoadingPlaceholder, useStyles2, Button } from '@grafana/ui';
import React, { useEffect, useContext } from 'react';
import { FetchStatus, RudderstackEvents } from 'enums';
import noDataStyles from './NoData.styles';
import useIntegrationStore from 'store/integration';
import { Queries } from 'queries';
import useDatasourceStore from 'store/datasource';
import { cluster } from 'img';
import { prometheusSelector } from 'store/selectors/datasource';
import { shallow } from 'zustand/shallow';
import useRudderStack from 'hooks/useRudderstack';
import { useHistory } from 'react-router-dom';
import { PluginMetaContext } from 'context/PluginMetaContext';

const NoData = () => {
  const styles = useStyles2(noDataStyles);
  const [dataFlowingStatus, isDataFlowing, isIntegrationInstalled] = useIntegrationStore(
    (state) => [state.dataFlowingStatus, state.isDataFlowing, state.isIntegrationInstalled],
    shallow
  );
  const prometheusName = useDatasourceStore(prometheusSelector);
  const trackRudderstackEvent = useRudderStack();
  const history = useHistory();
  const meta = useContext(PluginMetaContext);

  useEffect(() => {
    isDataFlowing(Queries.IsDataFlowing, prometheusName);
    isIntegrationInstalled();
  }, [isDataFlowing, isIntegrationInstalled, prometheusName]);

  if (dataFlowingStatus === FetchStatus.Fetching) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.noData}>
        <h3>What is Cluster navigation?</h3>
        <p>
          Explore your Kubernetes infrastructure by navigating through the object model. Spot problems at the highest
          level and drill down within your infrastructure to find the crashing pod.
        </p>
        <div className={styles.imageWrapper}>
          <img src={cluster} alt="Cluster screenshot" />
        </div>
        <p>Before you can start exploring, you have to send data to Grafana.</p>
        <div>
          <Button
            onClick={() => {
              trackRudderstackEvent(RudderstackEvents.SetupStart, {});
              history.push(`/a/${meta.id}/configuration/cluster-config`);
            }}
          >
            Start sending data
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoData;
