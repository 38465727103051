import { FetchStatus } from 'enums';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type AccessPolicyTokenStateType = {
  tokenStatus: FetchStatus;
  token: string;
  error: string;
  existingToken: string;
};

type AccessPolicyTokenActionType = {
  setToken: (token: string) => void;
  setTokenError: (error: string) => void;
  setExistingToken: (token: string) => void;
};

export type AccessPolicyTokenStore = AccessPolicyTokenStateType & AccessPolicyTokenActionType;

const initialState = {
  tokenStatus: FetchStatus.Idle,
  token: '',
  error: '',
  existingToken: '',
};

const useAccessPolicyToken = createWithEqualityFn<AccessPolicyTokenStore>(
  (set) => ({
    //state
    ...initialState,

    setToken(token: string) {
      set({ tokenStatus: FetchStatus.Success, token, error: '' });
    },

    setTokenError(error: string) {
      set({ tokenStatus: FetchStatus.Error, error: error || '' });
    },

    setExistingToken: (token: string) => {
      set({ existingToken: token });
    },
  }),
  shallow
);

export default useAccessPolicyToken;
