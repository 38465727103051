import { cx } from '@emotion/css';
import { Alert, useStyles2 } from '@grafana/ui';
import { HostedDataDetails, getHostedDataDetails } from 'api/accessPolicyToken';
import { DeploymentConfig } from 'api/config';
import { Platforms } from 'enums';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useAccessPolicyToken from 'store/accessPolicyToken';
import { shallow } from 'zustand/shallow';
import getStyles from '../Config.styles';
import ConfigAPIToken from './APIToken';
import AppTelemetryReceiverInfo from './AppTelemetryReceiverInfo';
import ConfigClusterFeatures from './ClusterFeatures';
import ConfigInstructions from './ConfigInstructions';
import ConfigFinal from './Final';
import ConfigPrerequisites from './Prerequisites';
import BackendInstallation from './BackendInstallation';
import CloudVersions from './CloudVersions';
import { PluginMetaContext } from 'context/PluginMetaContext';
import useIntegrationStore from 'store/integration';

const DEFAULT_RELEASE_NAME = 'grafana-k8s-monitoring';
const DEFAULT_CLUSTER = 'my-cluster';
const DEFAULT_NAMESPACE = 'default';
const DEFAULT_ACCESS_POLICY_TOKEN = 'REPLACE_WITH_ACCESS_POLICY_TOKEN';

export function ClusterConfig() {
  const styles = useStyles2(getStyles);

  const meta = useContext(PluginMetaContext);
  const version = meta.info.version || undefined;
  const [integrationInstalled, integration] = useIntegrationStore(
    (state) => [state.integrationInstalled, state.integration],
    shallow
  );
  const integrationVersion = integration?.installation?.version || 'unknown';

  const [deploymentConfigValues, setDeploymentConfigValues] = useState<DeploymentConfig>({
    releaseName: DEFAULT_RELEASE_NAME,
    namespace: DEFAULT_NAMESPACE,
    cluster: DEFAULT_CLUSTER,
    features: {
      metrics: true,
      podLogs: true,
      clusterEvents: true,
      costMetrics: true,
      energyMetrics: true,
      otelReceivers: true,
      zipkinReceiver: true,
      hostMetricsGeneration: false,
    },
  });
  const [platform, setPlatform] = useState<Platforms>(Platforms.Kubernetes);
  const [hostedDetails, setHostedDetails] = useState<HostedDataDetails>();
  const [accessPolicyToken, existingToken] = useAccessPolicyToken(
    (state) => [state.token, state.existingToken],
    shallow
  );
  const validAccessPolicyToken = accessPolicyToken || existingToken || DEFAULT_ACCESS_POLICY_TOKEN;

  const onTextReplaceChange = useCallback(
    (fieldType: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setDeploymentConfigValues({ ...deploymentConfigValues, [fieldType]: e.target.value });
    },
    [deploymentConfigValues]
  );

  const onSetFeature = useCallback(
    (feature: string, state: boolean) => {
      const features = {
        ...deploymentConfigValues.features,
        [feature]: state,
      };

      // Disable special metrics if metrics are disabled
      features.costMetrics = features.costMetrics && features.metrics;
      features.energyMetrics = features.energyMetrics && features.metrics;

      // Disable Host Metrics generation if receivers are disabled
      features.hostMetricsGeneration =
        features.hostMetricsGeneration && (features.otelReceivers || features.zipkinReceiver);

      setDeploymentConfigValues({
        ...deploymentConfigValues,
        features,
      });
    },
    [deploymentConfigValues]
  );

  const getHostedDetails = useCallback(async () => {
    setHostedDetails(await getHostedDataDetails());
  }, []);

  useEffect(() => {
    getHostedDetails().catch((error) => {
      console.error('failed to get hosted details', error);
    });
  }, [getHostedDetails]);
  return (
    <>
      <div className={styles.instructionsWrapper}>
        {hostedDetails && (
          <Alert title="Grafana Cloud Stack" severity="info">
            <p>
              The configuration below is provided with credentials for this Grafana Cloud Stack (
              <code>{hostedDetails.slug}</code>):
            </p>
            <ul className={cx(styles.innerList)}>
              <li>
                Prometheus metrics service: <code>{hostedDetails.hmInstancePromUrl}</code>
              </li>
              <li>
                Loki logging service: <code>{hostedDetails.hlInstanceUrl}</code>
              </li>
              <li>
                Tempo tracing service: <code>{hostedDetails.htInstanceUrl}</code>
              </li>
            </ul>
          </Alert>
        )}
        <p>
          NOTE: These instructions show how to deploy the{' '}
          <a href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/helm-chart/">
            Kubernetes Monitoring Helm chart
          </a>{' '}
          using Grafana Alloy to your Kubernetes cluster. For alternative configuration options, refer to the{' '}
          <a href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/configure-infrastructure-manually/">
            documentation
          </a>
          .
        </p>
        <ol className={cx(styles.instructions)}>
          <li>
            <ConfigPrerequisites />
          </li>
          <li>
            <ConfigClusterFeatures
              deploymentConfigValues={deploymentConfigValues}
              onSetFeature={onSetFeature}
              onTextReplaceChange={onTextReplaceChange}
              setPlatform={setPlatform}
              platform={platform}
            />
          </li>
          <li>
            <ConfigAPIToken />
          </li>
          <li>
            <ConfigInstructions
              deploymentConfigValues={deploymentConfigValues}
              hostedDetails={hostedDetails}
              accessPolicyToken={validAccessPolicyToken}
              platform={platform}
            />
          </li>
          {(deploymentConfigValues.features.otelReceivers || deploymentConfigValues.features.zipkinReceiver) && (
            <li>
              <AppTelemetryReceiverInfo deploymentConfigValues={deploymentConfigValues} />
            </li>
          )}
          <li>
            <BackendInstallation />
          </li>
          <li>
            <ConfigFinal deploymentConfigValues={deploymentConfigValues} />
          </li>
        </ol>

        <div className={styles.divider}></div>
        <div className={styles.verticalSpace}>
          <CloudVersions
            className={styles.version}
            integrationInstalled={integrationInstalled}
            integrationVersion={integrationVersion}
            version={version}
          />
        </div>
      </div>
    </>
  );
}
