import { css } from '@emotion/css';

const rootStyles = () => ({
  rootContainer: css`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    [class$='-page-content'] > div > div > div {
      max-width: 100%;

      > div {
        max-width: 100%;
      }
    }
  `,
});

export default rootStyles;
