import React, { useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { getPluginLinkExtensions } from '@grafana/runtime';
import { Dropdown, LinkButton, ToolbarButton, useStyles2 } from '@grafana/ui';
import { PluginExtensionLink, PluginExtensionLinkContext, PluginExtensionPoints } from 'types';
import ExtensionLinkMenu from './ExtensionLinkMenu';
import { GrafanaTheme2 } from '@grafana/data';

interface Props {
  context: PluginExtensionLinkContext;
  extensionPointId: PluginExtensionPoints;
  showTooltip?: boolean;
}

const ExtensionLinks = ({ context, extensionPointId, showTooltip = true }: Props) => {
  const extensions = useExtensionLinks(context, extensionPointId);
  const styles = useStyles2(extensionLinksStyles);
  if (extensions.length === 0) {
    return null;
  }

  return (
    <div className={styles.marginRight}>
      {extensions.length < 3 ? (
        <InlineExtensionLinks extensions={extensions} showTooltip={showTooltip} />
      ) : (
        <ExtensionLinkDropdown extensions={extensions} />
      )}
    </div>
  );
};

const InlineExtensionLinks = ({
  extensions,
  showTooltip,
}: {
  extensions: PluginExtensionLink[];
  showTooltip: boolean;
}) => {
  return (
    <>
      {extensions?.map?.((extension, i) => (
        <LinkButton
          key={i}
          icon={extension.icon ?? 'plug'}
          href={extension.path}
          onClick={extension.onClick}
          size="sm"
          target="_blank"
          variant="secondary"
          tooltip={showTooltip ? extension.description : ''}
        >
          {extension.title}
        </LinkButton>
      ))}
    </>
  );
};

const ExtensionLinkDropdown = ({ extensions }: { extensions: PluginExtensionLink[] }) => {
  const styles = useStyles2(extensionLinksStyles);
  const [isOpen, setIsOpen] = useState(false);
  const menu = <ExtensionLinkMenu extensions={extensions} />;
  return (
    <Dropdown onVisibleChange={setIsOpen} placement="bottom-start" overlay={menu}>
      <ToolbarButton aria-label="Actions" variant="canvas" isOpen={isOpen} className={styles.actionsButton}>
        Actions
      </ToolbarButton>
    </Dropdown>
  );
};

function useExtensionLinks(
  context: PluginExtensionLinkContext,
  extensionPointId: PluginExtensionPoints
): PluginExtensionLink[] {
  return useMemo(() => {
    // getPluginLinkExtensions is available in Grafana>=10.0,
    // so will be undefined in earlier versions. Just return an
    // empty list of extensions in this case.
    if (getPluginLinkExtensions === undefined) {
      return [];
    }
    const { extensions } = getPluginLinkExtensions({
      extensionPointId,
      context,
      limitPerPlugin: 3,
    });

    return extensions;
  }, [context, extensionPointId]);
}

const extensionLinksStyles = (theme: GrafanaTheme2) => ({
  actionsButton: css`
    font-size: 12px;
    height: ${theme.spacing(theme.components.height.sm)};
    margin-right: ${theme.spacing(1)};
  `,
  marginRight: css({
    marginRight: theme.spacing(1),
  }),
});

export default ExtensionLinks;
