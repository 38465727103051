import React, { useCallback } from 'react';
import { Input, useStyles2 } from '@grafana/ui';
import useAccessPolicyToken from 'store/accessPolicyToken';
import tokenStyles from './TokenStyles';

const ExistingToken = () => {
  const styles = useStyles2(tokenStyles);
  const [existingToken, setExistingToken] = useAccessPolicyToken((state) => [state.existingToken, state.setExistingToken]);

  const onChangeTokenValue = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setExistingToken(e.currentTarget?.value);
    },
    [setExistingToken]
  );

  return (
    <div className={styles.wrapper}>
      <h6>Existing Access Policy Token</h6>
      <p>To use an existing Access Policy token, paste your key here and it will be filled into the generated manifest. This token is not stored by Grafana.</p>
      <p>NOTE: The token must have the ability to publish metrics, logs, and traces.</p>
      <Input value={existingToken} onChange={onChangeTokenValue} width={60} placeholder="Access Policy token" />
    </div>
  );
};

export default ExistingToken;
