import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const configStatusStyles = (theme: GrafanaTheme2) => ({
  gridItem: css`
    border: 1px solid ${theme.colors.border.medium};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `,
  gridItemTop: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  `,
  gridItemTopRight: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  container: css`
    max-width: 96rem;
  `,
  section: css`
    margin-bottom: 4rem;
  `,
  a: css`
    color: ${theme.colors.text.link};
  `,
  inputWrapper: css`
    display: flex;
    align-items: center;
    min-width: 250px;
    max-width: 300px;
    margin-bottom: 20px;
  `,
  actionItemText: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    font-style: italic;
  `,
  fullWidth: css`
    width: 100%;
  `,
  selectText: css`
    margin-right: 2rem;
  `,
  subtitleText: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    width: 100%;
  `,
  marginBottom: css`
    margin-bottom: 0;
  `,
  marginRight: css`
    margin-right: 10px;
  `,
  marginLeft: css`
    margin-left: 10px;
  `,
  centerAlign: css`
    display: flex;
    align-items: center;
  `,
  infoCircle: css`
    display: inline-flex;
    margin-left: 0.5rem;
    background: none;
    border: none;
    padding: 0;
  `,
  marginBottom16px: css`
    margin-bottom: 16px;
    margin-left: 0;
  `,
  link: css`
    transition: color 0.2s ease;
    color: ${theme.colors.text.link};
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
});
