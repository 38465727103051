import { encodeQueryString } from 'helpers/helpers';
import React from 'react';
import { Link } from 'react-router-dom';

interface AlertCellProps {
  hasDateChanged: boolean;
  prometheusName: string;

  count?: number;
  cluster?: string;
  node?: string;
  namespace?: string;
  pod?: string;
  container?: string;

  /**
   * enablePodWildcard, when true, will use a pod regex instead of exact match.
   */
  enablePodWildcard?: boolean;
}

const AlertCell = ({
  count,
  cluster,
  node,
  namespace,
  pod,
  container,
  hasDateChanged,
  prometheusName,
  enablePodWildcard = false,
}: AlertCellProps) => {
  // search contains the parameters to send to the alerting search page
  const search = [`datasource:"${encodeQueryString(prometheusName)}"`];

  cluster && search.push(`label:cluster=${encodeQueryString(cluster)}`);
  node && search.push(`label:node=${encodeQueryString(node)}`);
  namespace && search.push(`label:namespace=${namespace}`);
  if (enablePodWildcard) {
    pod && search.push(`label:pod=~${encodeQueryString(pod + '-.+')}`);
  } else {
    pod && search.push(`label:pod=${pod}`);
  }
  container && search.push(`label:container=${container}`);

  return (
    <>
      {!hasDateChanged ? (
        <Link
          to={'/alerting/list?view=state&search=' + search.join('%20')}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          {count ?? 0 > 0 ? count : 'None'}
        </Link>
      ) : count ?? 0 > 0 ? (
        count
      ) : (
        'None'
      )}
    </>
  );
};

export default AlertCell;
