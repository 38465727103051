import React, { useState } from 'react';
import { useStyles2 } from '@grafana/ui';
import { featureListStyles } from './FeatureList.styles';
import { cx } from '@emotion/css';
import * as screenshots from '../../img/screenshots';

interface TabItem {
  name: string;
  active: boolean;
  img: string;
  text: string;
}

const tabs: TabItem[] = [
  {
    name: 'Cluster Navigation',
    active: true,
    img: screenshots.cluster,
    text: 'Cluster navigation helps you explore your infrastructure by navigating through the object model, from clusters down to pods.',
  },
  {
    name: 'Nodes',
    active: false,
    img: screenshots.nodes,
    text: 'Nodes view displays all of the nodes in your clusters, as well as their condition, and current resource usage.',
  },
  {
    name: 'Alerts',
    active: false,
    img: screenshots.alerts,
    text: 'Preconfigured alerting rules come out-of-the-box. You can view and investigate the alerts in the Alerts view by clicking an alert.',
  },
  {
    name: 'Efficiency',
    active: false,
    img: screenshots.efficiency,
    text: 'The efficiency view gives you a high-level overview of your resource utilization. Showing areas where money could be saved or where more resources are required.',
  },
  {
    name: 'Configuration',
    active: false,
    img: screenshots.config,
    text: 'Set everything up with a few easy steps in our dedicated configuration view.',
  },
];

const FeatureList = () => {
  const styles = useStyles2(featureListStyles);
  const [active, setActive] = useState<TabItem>(tabs[0]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '10px' }}>
      <div className={styles.tabList}>
        {tabs.map((item, index) => {
          const isActive = item === active;
          return (
            <div key={item.name} className={styles.listItemContainer}>
              {isActive && <div className={styles.activeBar}></div>}
              <p onClick={() => setActive(tabs[index])} className={cx(styles.listItem, isActive ? styles.active : '')}>
                {item.name}
              </p>
            </div>
          );
        })}
      </div>
      <div className={styles.imgContainer}>
        <img src={active.img} />
        <p>{active.text}</p>
      </div>
    </div>
  );
};

export default FeatureList;
