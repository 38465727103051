import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const tableStyles = (withNestedHeader: boolean) => (theme: GrafanaTheme2) => ({
  container: css`
    max-width: 100%;
    overflow-x: scroll;
    min-height: 200px;

    ${withNestedHeader &&
    css`
      [role='columnheader'] {
        display: flex;
        align-items: flex-start;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 0.015px;

        > span {
          margin-top: -2px;
        }
      }

      .rdt_TableHeadRow {
        min-height: 62px;
      }
    `};

    [role='table'],
    [role='row'] {
      background-color: rgba(0, 0, 0, 0);
    }

    > div {
      background-image: linear-gradient(
          to right,
          ${theme.colors.background.primary},
          ${theme.colors.background.primary}
        ),
        linear-gradient(to right, ${theme.colors.background.primary}, ${theme.colors.background.primary}),
        linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
        linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

      background-position: left center, right center, left center, right center;
      background-repeat: no-repeat;
      background-color: ${theme.colors.background.primary};
      background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

      background-attachment: local, local, scroll, scroll;
    }
  `,
  paginationWrapper: css`
    display: flex;
    margin: ${theme.spacing(2)} 0 ${theme.spacing(1)};
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
  `,
  pageSize: css`
    margin-right: ${theme.spacing(1)};
  `,
  expandRow: css`
    padding: 20px 20px 20px 65px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: ${theme.colors.background.secondary};
  `,
  expandRowData: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    div {
      margin-right: 50px;

      p:first-child {
        font-weight: bold;
      }
    }
  `,
  expandRowActions: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
});

export default tableStyles;
