import { MatcherConfig, ThresholdsMode } from '@grafana/data';
import { SceneDataTransformer, SceneQueryRunner, VizPanelState } from '@grafana/scenes';

export type PanelConfig = {
  title: string;
  runner?: SceneQueryRunner | SceneDataTransformer;
  decimals?: number;
  axisLabel?: string;
  calcs?: string[];
  description?: string;
  type?: string | ((panel: PanelConfig) => object);
  links?: Array<{ title: string; url: string }>;
  graphMode?: string;
  unit?: string;
  textSize?: number;
  fixedColor?: string;
  overrides?: Array<{
    matcher: MatcherConfig;
    properties: Array<{
      id: string;
      value?: unknown;
    }>;
  }>;
};

export function getPanelConfig(type: string) {
  const panelTypes = {
    cost: costsConfig,
    percentage: percentageConfig,
    graph: graphsConfig,
  };

  return panelTypes[type as keyof typeof panelTypes];
}

export const percentageConfig = (panel: PanelConfig) => ({
  $data: panel.runner,
  description: panel.description,
  fieldConfig: {
    defaults: {
      color: {
        mode: 'thresholds',
      },
      decimals: panel.decimals ?? 2,
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Percentage,
        steps: [
          {
            color: 'blue',
            value: 0,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
      unit: 'percentunit',
    },
    overrides: [],
  },
  options: {
    colorMode: 'none',
    graphMode: 'none',
    justifyMode: 'auto',
    orientation: 'auto',
    text: {
      valueSize: 40,
    },
    reduceOptions: {
      calcs: ['last'],
      fields: '',
      values: false,
    },
    textMode: 'value',
  },
  pluginId: 'stat',
  title: panel.title,
});

export const costsConfig = (panel: PanelConfig) => ({
  $data: panel.runner,
  description: panel.description,
  fieldConfig: {
    defaults: {
      color: {
        mode: 'thresholds',
      },
      decimals: panel.decimals,
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
      unit: 'currencyUSD',
    },
    overrides: panel.overrides ?? [],
  },
  links: panel.links ?? [],
  options: {
    colorMode: 'none',
    graphMode: panel.graphMode ?? 'none',
    justifyMode: 'auto',
    orientation: 'auto',
    text: {
      valueSize: panel.textSize ?? 40,
    },
    reduceOptions: {
      calcs: ['sum'],
      fields: '',
      values: false,
    },
    textMode: 'value',
  },
  pluginId: 'stat',
  title: panel.title,
});

export const graphsConfig = (panel: PanelConfig) => ({
  pluginId: 'timeseries',
  title: panel.title,
  description: panel.description,
  $data: panel.runner,
  fieldConfig: {
    defaults: {
      color: {
        mode: 'palette-classic',
      },
      custom: {
        axisCenteredZero: false,
        axisColorMode: 'text',
        axisLabel: panel.axisLabel,
        axisPlacement: 'auto',
        barAlignment: 0,
        drawStyle: 'line',
        fillOpacity: 25,
        gradientMode: 'none',
        hideFrom: {
          legend: false,
          tooltip: false,
          viz: false,
        },
        lineInterpolation: 'linear',
        lineStyle: {
          fill: 'solid',
        },
        lineWidth: 1,
        pointSize: 5,
        scaleDistribution: {
          type: 'linear',
        },
        showPoints: 'auto',
        spanNulls: false,
        stacking: {
          group: 'A',
          mode: 'none',
        },
        thresholdsStyle: {
          mode: 'off',
        },
      },
      mappings: [],
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: 'green',
            value: 0,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
      unit: 'currencyUSD',
    },
    overrides: [],
  },
});

export function totalCostConfig(panel: PanelConfig): VizPanelState {
  return {
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        color: {
          fixedColor: panel.fixedColor || 'dark-blue',
          mode: 'fixed',
        },
        min: 0,
        unit: 'currencyUSD',
      },
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      text: {
        valueSize: 40,
      },
      orientation: 'auto',
      textMode: 'auto',
      wideLayout: true,
      colorMode: 'background_solid',
      graphMode: 'none',
      justifyMode: 'auto',
      showPercentChange: false,
      percentChangeColorMode: 'standard',
    },
    title: panel.title,
    pluginId: 'stat',
    description: panel.description || '',
    $data: panel.runner,
  };
}

export function costPercentChangeConfig(panel: PanelConfig): VizPanelState {
  return {
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        color: {
          fixedColor: panel.fixedColor || 'transparent',
          mode: 'fixed',
        },
        min: 0,
        noValue: '-',
        unit: 'percentunit',
      },
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      text: {
        valueSize: 40,
      },
      orientation: 'auto',
      textMode: 'auto',
      wideLayout: true,
      colorMode: 'background_solid',
      graphMode: 'none',
      justifyMode: 'auto',
      showPercentChange: false,
      percentChangeColorMode: 'standard',
    },
    title: panel.title || '',
    pluginId: 'stat',
    $data: panel.runner,
  };
}

export function countPodsConfig(panel: PanelConfig): VizPanelState {
  return {
    description: '',
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        color: {
          fixedColor: panel.fixedColor || 'dark-blue',
          mode: 'fixed',
        },
        min: 0,
        noValue: '-',
        unit: 'short',
        decimals: 0,
      },
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['mean'],
        fields: '',
      },
      text: {
        valueSize: 40,
      },
      orientation: 'auto',
      textMode: 'auto',
      wideLayout: true,
      colorMode: 'background_solid',
      graphMode: 'none',
      justifyMode: 'auto',
      showPercentChange: false,
      percentChangeColorMode: 'standard',
    },
    title: panel.title,
    pluginId: 'stat',
    $data: panel.runner,
  };
}
