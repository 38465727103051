import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const getStyles = (theme: GrafanaTheme2) => ({
  overrideModal: css`
    max-width: 600px;
    padding: 6px;
  `,
  modalContent: css`
    h3 {
      margin-bottom: ${theme.spacing(3)};
      padding-top: ${theme.spacing(2)};
    }
    button {
      margin-left: ${theme.spacing(1)};
    }
  `,
  icon: css`
    path {
      fill: ${theme.colors.warning.text};
    }

    margin-right: ${theme.spacing(1)};
  `,
  alertText: css`
    color: ${theme.colors.warning.text};
  `,
  text: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ccccdc;
    padding-left: 30px;
    margin-top: 15px;
  `,
  buttons: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
    flex-direction: column;
    align-items: flex-end;
  `,
  buttonWrapper: css`
    margin-top: ${theme.spacing(3)};
  `,
  updateAlert: css`
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(1)};
  `,
  updateSuccess: css`
    margin-top: ${theme.spacing(4)};
  `,
  a: css`
    color: ${theme.colors.text.link};
  `,
});

export default getStyles;
