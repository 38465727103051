import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const efficiencyCardStyles = (theme: GrafanaTheme2) => ({
  card: css`
    width: 100%;
    padding: ${theme.spacing(2)};
    background: ${theme.colors.background.secondary};
    margin-bottom: 20px;
    margin-right: 20px;
    padding-bottom: ${theme.spacing(1)};
  `,
  cardInner: css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-bottom: ${theme.spacing(2)};

    p {
      margin-bottom: 0;
      font-size: 19px;
    }
    h1 {
      margin-bottom: 0;
      font-size: 32px;
      font-weight: 500;
    }
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing(1)};

    > span {
      color: ${theme.colors.text.secondary};
      font-size: 13px;
    }
  `,
  title: css`
    display: flex;
    align-items: center;

    > h4 {
      margin: 0;
    }

    > div {
      margin-top: 4px;
      margin-left: 7px;
    }
  `,
  secondary: css`
    color: ${theme.colors.text.primary};
  `,
  over: css`
    color: ${theme.colors.error.text};
    text-align: center;
  `,
  under: css`
    color: ${theme.colors.warning.text};
    text-align: center;
  `,
  green: css`
    color: ${theme.colors.success.text};
    text-align: center;
  `,
  divider: css`
    height: 1px;
    background-color: ${theme.colors.border.weak};
    width: calc(100% + 30px);
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(3)};
    position: relative;
    margin-left: -15px;
  `,
  subtitle: css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing(1.5)};

    > span {
      margin-right: ${theme.spacing(1)};
      color: ${theme.colors.text.primary};
    }
  `,
  progressCard: css`
    margin-bottom: ${theme.spacing(7)};
  `,
  info: css`
    cursor: pointer;
  `,
});

export default efficiencyCardStyles;
