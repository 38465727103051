import useQueryParams from 'hooks/useQueryParams';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useDatasourceStore from 'store/datasource';
import { shallow } from 'zustand/shallow';

interface Props {
  to: string;
  label: string | JSX.Element;
  className?: string;
  style?: React.CSSProperties;
}

const QueryParamsLink = ({ to, label, className, style }: Props) => {
  const [prometheusName, lokiName] = useDatasourceStore((state) => [state.prometheusName, state.lokiName], shallow);
  const history = useHistory();
  const { queryParams } = useQueryParams();
  const refreshParam = queryParams.get('refresh') !== null ? `&refresh=${queryParams.get('refresh')}` : '';

  useEffect(() => {
    // Update URL if the datasource changes
    const params = new URLSearchParams(window.location.search);
    // Only change if needed
    if (params.get('var-datasource') !== prometheusName || params.get('var-loki') !== lokiName) {
      params.set('var-datasource', prometheusName);
      params.set('var-loki', lokiName);
      history.replace({ search: params.toString() });
    }
    return () => {};
  }, [prometheusName, lokiName, history]);

  // TODO in future: we can get existing params such as time range or cluster, then add the datasource params
  const link = `${to}?var-datasource=${prometheusName}&var-loki=${lokiName}${refreshParam}`;

  return (
    <Link className={className} to={link} style={style}>
      {label}
    </Link>
  );
};

export default React.memo(QueryParamsLink);
