import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

export const featureListStyles = (theme: GrafanaTheme2) => ({
  tabList: css`
    width: 150px;
  `,
  listItemContainer: css`
    display: flex;
    align-items: center;
    height: 40px;
    width: 150px;
  `,
  listItem: css`
    cursor: pointer;

    color: rgba(204, 204, 220, 0.65);
    margin-left: 10px;
    margin-bottom: 0;
  `,
  active: css`
    color: #ccccdc !important;
    margin-left: 6px !important;
  `,
  activeBar: css`
    background: linear-gradient(180deg, #f55f3e 26.04%, #ff8833 100%);
    border-radius: 2px;
    width: 4px;
    height: 40px;
  `,
  imgContainer: css`
    width: 100%;
    text-align: center;

    img {
      width: 80%;
      margin-bottom: 20px;
    }
    p {
      width: 80%;
      margin: auto;
      text-align: left;
    }
  `,
});
