import React from 'react';
import { Alert } from '@grafana/ui';

const OpenCostConfigurationNote = () => {
  return (
    <p>
      <Alert title="Cost Metrics" severity="info" style={{ marginBottom: 0, marginTop: 8 }}>
        By default, cost metrics will use best effort to get cluster resource pricing. Please consult the
        <a href="https://www.opencost.io/docs/" target="_blank" rel="noopener noreferrer">
          documentation
        </a>
        to set more accurate pricing configuration for your specific Kubernetes environment.
      </Alert>
    </p>
  );
};

export default React.memo(OpenCostConfigurationNote);
