import CopyContent from 'components/Clipboard/CopyContent';
import React from 'react';

import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';

interface Props {
  value: string;
  link: string;
}

const s = () => ({
  nameContainer: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:hover {
      button {
        visibility: visible !important;
      }
    }
  `,
  link: css`
    text-decoration: underline;
    margin-right: 5px;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
});

const CopyObjectName = ({ value, link }: Props) => {
  const styles = useStyles2(s);

  return (
    <div className={styles.nameContainer}>
      <QueryParamsLink className={styles.link} to={link} label={value} />
      <CopyContent hidden content={value} />
    </div>
  );
};

export default CopyObjectName;
