import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from 'zustand/middleware';

export type EfficencyFilterKeys = 'nodes' | 'clusters' | 'namespaces' | '';

export enum TableView {
  Cost = 'Cost',
  Usage = 'Usage',
}

type EfficiencyFilterType = {
  nodes: string;
  clusters: string;
  namespaces: string;
};

type StateType = {
  efficiency: EfficiencyFilterType;
  tableView: TableView;
};

type ActionType = {
  setEfficiencyFilter: (type: EfficencyFilterKeys, name: string) => void;
  setTableView: (view: TableView) => void;
};

export type FiltersStore = StateType & ActionType;

const initialState = {
  efficiency: {
    nodes: '',
    clusters: '',
    namespaces: '',
  },
  tableView: TableView.Usage,
};

const useFilters = createWithEqualityFn(
  persist<FiltersStore>(
    (set, get) => ({
      //state
      ...initialState,

      // actions
      setEfficiencyFilter: (type: EfficencyFilterKeys, filter: string) => {
        const previous = (get() as any).efficiency;
        set({
          efficiency: {
            ...previous,
            [type]: filter,
          },
        });
      },

      setTableView: (view: TableView) => {
        set({
          tableView: view,
        });
      },
    }),
    {
      name: 'filters-storage',
    }
  ),
  shallow
);

export default useFilters;
