import React, { useMemo } from 'react';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneTimeRange,
  SceneTimeRangeState,
  PanelBuilders,
} from '@grafana/scenes';
import { CustomSceneObjectDetailState, ScenesCustomParams } from 'types';
import { addTimeRangeHandler, addVizPanelMenuHandler, getGenericQueryRunner } from 'helpers/scenes';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import useTimeRangeStore from 'store/timeRange';
import { config } from '@grafana/runtime';
import { getVariables } from '../Network/helpers';
import { compileEventsQuery, compileLogsQuery, compileOTelEventsQuery, compileOTelLogsQuery } from 'queries';

const getRunnersByParams = (params: CustomSceneObjectDetailState) => {
  const { type, cluster, namespace, podName, container, lokiDatasource } = params;
  let logsQuery = '';
  let eventsQuery = '';
  let extraLogsQuery = '';
  let extraEventsQuery = '';
  switch (type) {
    case 'node':
    case 'workload':
      logsQuery = compileLogsQuery(cluster, namespace, '${podNames:pipe}');
      eventsQuery = compileEventsQuery(cluster, namespace, '${podNames:pipe}');
      extraLogsQuery = compileOTelLogsQuery(cluster, namespace, '${podNames:pipe}');
      extraEventsQuery = compileOTelEventsQuery(cluster, namespace, '${podNames:pipe}');
      break;
    case 'cluster':
    case 'namespace':
    case 'pod':
    case 'container':
      logsQuery = compileLogsQuery(cluster, namespace, podName, container);
      eventsQuery = compileEventsQuery(cluster, namespace, podName, container);
      extraLogsQuery = compileOTelLogsQuery(cluster, namespace, podName, container);
      extraEventsQuery = compileOTelEventsQuery(cluster, namespace, podName, container);
      break;
    default:
      break;
  }

  const logsQueryRunner = getGenericQueryRunner(
    lokiDatasource!,
    logsQuery,
    {
      instant: false,
      range: true,
      interval: '',
      refId: 'logs',
    },
    [
      {
        expr: extraLogsQuery,
        instant: false,
        range: true,
        interval: '',
        refId: 'logsOtel',
        legendFormat: '__auto',
      },
    ]
  );

  const eventsQueryRunner = getGenericQueryRunner(
    lokiDatasource!,
    eventsQuery,
    {
      instant: false,
      range: true,
      interval: '',
      refId: 'events',
    },
    [
      {
        expr: extraEventsQuery,
        instant: false,
        range: true,
        interval: '',
        refId: 'eventsOtel',
        legendFormat: '__auto',
      },
    ]
  );

  return { logsQueryRunner, eventsQueryRunner };
};

const Logs = ({ model }: SceneComponentProps<LogsScene>) => {
  const props = model.useState();
  const lokiName = useDatasourceStore(lokiSelector);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const prometheusDatasource = config.datasources[prometheusName];
  const lokiDatasource = config.datasources[lokiName];
  const [relativeRange] = useTimeRangeStore((state) => [state.relativeRange]);

  const scene = useMemo(
    () =>
      getLogsScene({
        ...props,
        datasource: prometheusDatasource,
        lokiDatasource,
        lokiName,
        prometheusName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [relativeRange, props, lokiDatasource, prometheusDatasource, lokiName, prometheusName]
  );

  return <scene.Component model={scene} />;
};

export function getLogsScene({
  type = 'cluster',
  cluster,
  node,
  namespace,
  workload,
  workloadType,
  podName,
  container,
  datasource,
  lokiDatasource,
  relativeTimeRange,
  onTimeRangeChange,
}: ScenesCustomParams) {
  const sceneTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(sceneTimeRange, onTimeRangeChange);

  const { logsQueryRunner, eventsQueryRunner } = getRunnersByParams({
    type,
    cluster: cluster || '',
    node,
    namespace,
    podName,
    workload,
    workloadType,
    container,
    lokiDatasource,
  });

  const logsPanel = PanelBuilders.logs().setTitle('Logs').setTimeRange(sceneTimeRange).setData(logsQueryRunner).build();
  const eventsPanel = PanelBuilders.logs()
    .setTitle('Events')
    .setTimeRange(sceneTimeRange)
    .setData(eventsQueryRunner)
    .build();

  const variables = getVariables(type, datasource, cluster, node, namespace, workload, workloadType);

  return new SceneFlexLayout({
    $variables: variables,
    $timeRange: sceneTimeRange,
    direction: 'column',
    children: [
      new SceneFlexItem({
        body: addVizPanelMenuHandler(logsPanel),
      }),
      new SceneFlexItem({
        body: addVizPanelMenuHandler(eventsPanel),
      }),
    ],
  });
}

export class LogsScene extends SceneObjectBase<CustomSceneObjectDetailState> {
  static Component = Logs;
}

export function logsScene(params: CustomSceneObjectDetailState) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new LogsScene({
            ...params,
          }),
        }),
      ],
    }),
  });
}
