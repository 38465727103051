import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { SortOrder } from '@grafana/schema';

const subRowHeaderStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    display: flex;
    position: relative;
    gap: ${theme.spacing(1)};
    min-width: 95px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  `,
  title: css`
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.018px;
    text-transform: none;
  `,
  error: css`
    color: ${theme.colors.warning.main};
  `,
  info: css`
    cursor: pointer;
    z-index: 3;
  `,
  hoverArea: css`
    position: absolute;
    z-index: 2;
    content: '';
    width: 100px;
    height: 30px;
    top: -8px;
    left: -4px;
  `,
  sort: css`
    margin-left: -4px;
  `,
  [SortOrder.Ascending]: css``,
  [SortOrder.Descending]: css`
    transform: rotate(180deg);
  `,
  [SortOrder.None]: css``,
});

export default subRowHeaderStyles;
