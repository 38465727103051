import { Alert, Button, LinkButton, LoadingPlaceholder, Modal, RadioButtonGroup, useStyles2 } from '@grafana/ui';
import React, { useCallback, useState } from 'react';

import { Source, StaticConfigSource } from 'api/integrationsApi';
import { AgentOptions, FetchStatus } from 'enums';
import useDatasourceStore from 'store/datasource';
import useIntegrationStore from 'store/integration';
import { shallow } from 'zustand/shallow';
import { agentOptions } from '../../constants';
import AgentOperatorIntegration from './AgentOperatorIntegration';
import HelmChartIntegration from './HelmChartIntegration';
import integrationsListStyles from './IntegrationsList.styles';
import StaticAgentIntegration from './StaticAgentIntegration';

type IntegrationsListProps = {
  source: Source | null;
  staticConfigSource: StaticConfigSource | null;
  onDismiss: () => void;
  open?: boolean;
};

const IntegrationModal = ({ open, source, staticConfigSource, onDismiss }: IntegrationsListProps) => {
  const styles = useStyles2(integrationsListStyles);
  const [lokiName, promName] = useDatasourceStore((state) => [state.lokiName, state.prometheusName], shallow);
  const [configType, setConfigType] = useState(AgentOptions.Alloy);
  const [installIntegration, installStatus, testIntegration, testIntegrationStatus, testIntegrationError] =
    useIntegrationStore(
      (state) => [
        state.installIntegration,
        state.installStatus,
        state.testIntegration,
        state.testIntegrationStatus,
        state.testIntegrationError,
      ],
      shallow
    );

  const onInstallClick = useCallback(() => {
    if (source?.slug) {
      installIntegration(source.slug);
    }
  }, [installIntegration, source]);

  if (!source) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={open}
        onDismiss={onDismiss}
        closeOnEscape
        title={
          <div className={styles.modalTitle}>
            <h2>{source.name}</h2>
          </div>
        }
      >
        <div>
          <div>
            <RadioButtonGroup
              options={agentOptions}
              size="md"
              value={configType}
              onChange={(v) => {
                setConfigType(v);
              }}
            />
          </div>
          <div>
            {configType === AgentOptions.Alloy && <HelmChartIntegration slug={source.slug} showTitle={true} />}
            {configType === AgentOptions.AgentInStaticMode && <StaticAgentIntegration source={staticConfigSource} />}
            {configType === AgentOptions.AgentOperator && <AgentOperatorIntegration source={staticConfigSource} />}

            <div className={styles.installButtonWrapper}>
              {!source.installation ? (
                <Button
                  variant="primary"
                  onClick={onInstallClick}
                  type="button"
                  className={styles.installButton}
                  disabled={installStatus === FetchStatus.Fetching}
                >
                  {installStatus === FetchStatus.Fetching && <LoadingPlaceholder text="" />}
                  Install integration
                </Button>
              ) : (
                <div className={styles.buttonsWrapper}>
                  {!!testIntegrationError && testIntegrationStatus === FetchStatus.Error && (
                    <Alert severity="error" title="">
                      {testIntegrationError} For more information, see the{' '}
                      <a
                        href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        documentation
                      </a>
                      .
                    </Alert>
                  )}
                  {!testIntegrationError && testIntegrationStatus === FetchStatus.Success && (
                    <Alert severity="success" title="">
                      Integration is collecting data and sending it to Grafana Cloud.
                    </Alert>
                  )}
                  <div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
                    <LinkButton
                      variant="secondary"
                      href={`/a/grafana-easystart-app/${source.slug}`}
                      type="button"
                      className={styles.connectionsButton}
                    >
                      Go to connections to manage your integration
                    </LinkButton>
                    <Button
                      variant="primary"
                      onClick={() => testIntegration(source.slug, promName, lokiName)}
                      type="button"
                      className={styles.installButton}
                      disabled={testIntegrationStatus === FetchStatus.Fetching}
                    >
                      {testIntegrationStatus === FetchStatus.Fetching && <LoadingPlaceholder text="" />}
                      Test integration
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IntegrationModal;
