import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const containerDetailStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    width: 100%;
  `,
  sceneWrapper: css`
    min-height: 200px;

    > div > div > div > div {
      @media (max-width: 1200px) {
        flex-wrap: wrap;
        width: 100%;

        > div {
          width: 49%;
        }
      }

      @media (max-width: 768px) {
        width: 100%;

        > div {
          width: 100%;
        }
      }
    }
  `,
  grid: css`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
  `,
  gridEqual: css`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  `,
  container: css`
    /* max-height: 80vh; */
    min-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  `,
  marginBottom: css`
    margin-bottom: 20px;
  `,
  loadingIndicator: css`
    margin-right: ${theme.spacing(1)};
  `,
  logs: css`
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
  `,
  card: css`
    align-items: start;
  `,
  cpuCard: css`
    padding: ${theme.spacing(2)};
    background: ${theme.colors.background.secondary};
    margin-bottom: ${theme.spacing(1)};
    height: 120px;
    position: relative;
  `,
  cardHeader: css`
    font-size: 14px;
    letter-spacing: inherit;
    line-height: 1.5;
    color: ${theme.colors.text.primary};
    font-weight: 500;
  `,
  cardBodyText: css`
    color: ${theme.colors.text.secondary};
  `,
  exploreButtonsWrapper: css`
    display: flex;
    margin-top: -${theme.spacing(3)};
    margin-bottom: ${theme.spacing(2)};
    margin-left: -${theme.spacing(1)};
  `,
  explore: css`
    position: absolute;
    top: 2px;
    right: 8px;
  `,
  infoIcon: css`
    position: absolute;
    top: 8px;
    right: 8px;
  `,
  titleWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  `,
  exploreContainerWrapper: css`
    display: flex;
  `,
  link: css`
    text-decoration: underline;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
  `,
  exploreOnList: css`
    position: relative;
    top: -2px;
    left: 4px;
  `,
  sectionTitle: css`
    font-size: 17px;
    margin-bottom: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
  `,
  sectionSpacing: css`
    margin-bottom: ${theme.spacing(4)};
  `,
});

export default containerDetailStyles;
