import { DeepPartial, FieldConfigOverridesBuilder, VizPanelBuilder, VizPanelState } from '@grafana/scenes';
import { FieldConfigType, HasSetCustomFieldConfig, HasSetOption, HasSetOverrides, OptionsType } from 'types';
import { upperFirst } from 'lodash';
import { FieldConfigSource } from '@grafana/schema';
import { pipe } from './helpers';
import { ConfigOverrideRule } from '@grafana/data';

const matcherMappings = {
  byName: 'matchFieldsWithName',
  byRegexp: 'matchFieldsWithNameByRegex',
  byValue: 'matchFieldsByValue',
  byFrameRefID: 'matchFieldsByQuery',
  byType: 'matchFieldsByType',
};

const jsonToPanelBuilders = <
  PanelBuilder extends VizPanelBuilder<PanelOptions, PanelConfig>,
  PanelOptions extends {} = OptionsType<PanelBuilder>,
  PanelConfig extends {} = FieldConfigType<PanelBuilder>
>(
  builder: PanelBuilder,
  config?: Partial<VizPanelState<PanelOptions, PanelConfig>>
) => {
  if (config) {
    const { options, fieldConfig, ...otherConfig } = config;

    pipe(
      withConfig<PanelBuilder, PanelOptions, PanelConfig>(otherConfig),
      withOptions<PanelBuilder, PanelOptions>(config?.options),
      withDefaultFieldConfig<PanelBuilder, PanelOptions, PanelConfig>(
        config.fieldConfig as Partial<FieldConfigSource<DeepPartial<FieldConfigType<PanelBuilder>>>>
      ),
      withCustomFieldConfig<PanelBuilder, PanelConfig>(config?.fieldConfig?.defaults?.custom),
      withOverrides<PanelBuilder, PanelConfig>(config?.fieldConfig?.overrides)
    )(builder);
  }

  return builder;
};

export const withConfig =
  <
    PanelBuilder extends VizPanelBuilder<PanelOptions, PanelConfig>,
    PanelOptions extends {} = OptionsType<PanelBuilder>,
    PanelConfig extends {} = FieldConfigType<PanelBuilder>
  >(
    config?: Partial<VizPanelState<DeepPartial<FieldConfigType<PanelBuilder>>>>
  ) =>
  (builder: PanelBuilder) => {
    if (config) {
      Object.keys(config as keyof PanelConfig).forEach((option) => {
        const parsedOption = `set${upperFirst(option)}` as keyof PanelBuilder;
        if (typeof builder[parsedOption] === 'function') {
          // @ts-ignore
          builder[parsedOption](config[option as keyof typeof config]);
        }
      });
    }
    return builder;
  };

export const withDefaultFieldConfig =
  <
    PanelBuilder extends VizPanelBuilder<PanelOptions, PanelConfig>,
    PanelOptions extends {} = OptionsType<PanelBuilder>,
    PanelConfig extends {} = FieldConfigType<PanelBuilder>
  >(
    fieldConfig?: Partial<FieldConfigSource<DeepPartial<FieldConfigType<PanelBuilder>>>>
  ) =>
  (builder: PanelBuilder) => {
    if (fieldConfig) {
      const { custom, ...config } = fieldConfig.defaults || {};

      Object.keys(config as keyof PanelConfig).forEach((option) => {
        const parsedOption = `set${upperFirst(option)}` as keyof PanelBuilder;
        if (typeof builder[parsedOption] === 'function') {
          // @ts-ignore
          builder[parsedOption](config[option as keyof typeof config]);
        }
      });
    }
    return builder;
  };

export const withOptions =
  <PanelBuilder extends HasSetOption<T>, T = OptionsType<PanelBuilder>>(options?: DeepPartial<T>) =>
  (builder: PanelBuilder) => {
    if (!options) {
      return builder;
    }

    return Object.keys(options).reduce((acc, option) => {
      acc.setOption(option as keyof T, options[option as keyof typeof options]);
      return acc;
    }, builder);
  };

export const withCustomFieldConfig =
  <PanelBuilder extends HasSetCustomFieldConfig<T>, T = FieldConfigType<PanelBuilder>>(options?: DeepPartial<T>) =>
  (builder: PanelBuilder) => {
    if (!options) {
      return builder;
    }

    return Object.keys(options).reduce((acc, option) => {
      acc.setCustomFieldConfig(option as keyof T, options[option as keyof typeof options]);
      return acc;
    }, builder);
  };
export const withOverrides =
  <PanelBuilder extends HasSetOverrides<T>, T = FieldConfigType<PanelBuilder>>(matchersList?: ConfigOverrideRule[]) =>
  (builder: PanelBuilder) => {
    if (!matchersList) {
      return builder;
    }

    matchersList.forEach((matcherItem) => {
      builder.setOverrides((b) => {
        // matcherFn = b.matchFieldsWithName('<Name>')
        const matcherFn = b[matcherMappings[matcherItem.matcher.id as keyof typeof matcherMappings] as keyof typeof b](
          matcherItem.matcher.options as never,
          true as never
        ) as FieldConfigOverridesBuilder<T>;

        matcherItem.properties.forEach((property) => {
          const isCustomProperty = property.id.includes('custom.');
          let parsedProperty;
          if (isCustomProperty) {
            parsedProperty = property.id.replace('custom.', '');
            matcherFn?.overrideCustomFieldConfig?.(parsedProperty as keyof T, property.value);
          } else {
            parsedProperty = `override${upperFirst(property.id)}`;
            matcherFn?.[parsedProperty as keyof typeof matcherFn]?.(property.value as never, true as never);
          }
        });
      });
    });

    return builder;
  };

export default jsonToPanelBuilders;
