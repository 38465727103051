import {
  EmbeddedScene,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { CostsScenesQueries } from 'queries';
import { getGenericQueryRunner } from 'helpers/scenes';
import { ExplorablePanel } from '../ExplorablePanel';
import { getPanelConfig } from '../CostsOverviewScene/panelConfig';
import { DataFrame, DataTransformContext } from '@grafana/data';
import { Observable, map } from 'rxjs';
import { getClusterVariable } from '../CostsOverviewScene/variables';

const enum QueryRefId {
  Cost = 'cost',
  Savings = 'savings',
}

const transformData = (type: QueryRefId) => (_context: DataTransformContext) => (source: Observable<DataFrame[]>) =>
  source.pipe(map((data) => data?.filter?.((f) => f.refId === type)));

const cpuRow = (datasource: DataSourceRef) => {
  return new SceneFlexLayout({
    direction: 'row',
    height: 200,
    $data: getGenericQueryRunner(
      datasource,
      CostsScenesQueries.cpuCost,
      {
        legendFormat: 'Cost',
        refId: QueryRefId.Cost,
        instant: false,
        range: true,
        interval: '1h',
      },
      [
        {
          expr: CostsScenesQueries.cpuSavings,
          instant: false,
          interval: '1h',
          range: true,
          refId: QueryRefId.Savings,
          legendFormat: 'Savings',
        },
      ]
    ),
    children: [
      {
        title: 'CPU Cost',
        decimals: 2,
        type: 'cost',
        description: '[Range] The cost of CPU over the current time range.',
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Cost)],
        }),
      },
      {
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Savings)],
        }),
        title: 'CPU Savings',
        decimals: 2,
        type: 'cost',
        description:
          '[Range] The theoretical savings if all unused CPU cores could have been deprovisioned over the current time range.',
      },
      {
        title: 'CPU Cost vs savings',
        description: '[Range] Cost vs savings over the current time range.',
        type: 'graph',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 200,
        body: new ExplorablePanel(getPanelConfig(type)(panel)),
      });
    }),
  });
};

const ramRow = (datasource: DataSourceRef) => {
  return new SceneFlexLayout({
    direction: 'row',
    height: 200,
    $data: getGenericQueryRunner(
      datasource,
      CostsScenesQueries.ramCost,
      {
        legendFormat: 'Cost',
        refId: QueryRefId.Cost,
        instant: false,
        range: true,
        interval: '1h',
      },
      [
        {
          expr: CostsScenesQueries.ramSavings,
          instant: false,
          interval: '1h',
          range: true,
          refId: QueryRefId.Savings,
          legendFormat: 'Savings',
        },
      ]
    ),
    children: [
      {
        title: 'RAM Cost',
        decimals: 2,
        type: 'cost',
        description: '[Range] The cost of RAM over the current time range.',
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Cost)],
        }),
      },
      {
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Savings)],
        }),
        title: 'RAM Savings',
        decimals: 2,
        type: 'cost',
        description:
          '[Range] The theoretical savings if all unused RAM could have been deprovisioned over the current time range.',
      },
      {
        title: 'RAM Cost vs savings',
        description: '[Range] Cost vs savings over the current time range.',
        type: 'graph',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 200,
        body: new ExplorablePanel(getPanelConfig(type)(panel)),
      });
    }),
  });
};

const storageRow = (datasource: DataSourceRef) => {
  return new SceneFlexLayout({
    direction: 'row',
    height: 200,
    $data: getGenericQueryRunner(
      datasource,
      CostsScenesQueries.storageCost,
      {
        legendFormat: 'Cost',
        refId: QueryRefId.Cost,
        instant: false,
        range: true,
        interval: '1h',
      },
      [
        {
          expr: CostsScenesQueries.storageSavings,
          instant: false,
          interval: '1h',
          range: true,
          refId: QueryRefId.Savings,
          legendFormat: 'Savings',
        },
      ]
    ),
    children: [
      {
        title: 'Storage Cost (PV)',
        decimals: 2,
        type: 'cost',
        description: '[Range] The cost of storage allocated to Persistent Volumes over the current time range.',
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Cost)],
        }),
      },
      {
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Savings)],
        }),
        title: 'Storage Savings',
        decimals: 2,
        type: 'cost',
        description:
          '[Range] The theoretical savings if all unused storage could have been deprovisioned over the current time range.',
      },
      {
        title: 'Storage Cost vs savings',
        description: '[Range] Cost vs savings over the current time range.',
        type: 'graph',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 200,
        body: new ExplorablePanel(getPanelConfig(type)(panel)),
      });
    }),
  });
};

const networkRow = (datasource: DataSourceRef) => {
  return new SceneFlexLayout({
    direction: 'row',
    height: 200,
    children: [
      {
        runner: getGenericQueryRunner(datasource, CostsScenesQueries.networkEgressCost, {
          instant: false,
          range: true,
          interval: '',
          intervalMs: '60',
        }),
        title: 'Network Egress Cost',
        type: 'cost',
        description: '[Range] The cost of network egress over the current time range.',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 200,
        body: new ExplorablePanel(getPanelConfig(type)(panel)),
      });
    }),
  });
};

const gpuRow = (datasource: DataSourceRef) => {
  return new SceneFlexLayout({
    direction: 'row',
    height: 200,
    $data: getGenericQueryRunner(
      datasource,
      CostsScenesQueries.gpuCost,
      {
        legendFormat: 'Cost',
        refId: QueryRefId.Cost,
        instant: false,
        range: true,
        interval: '1h',
      },
      [
        {
          expr: CostsScenesQueries.gpuSavings,
          instant: false,
          interval: '1h',
          range: true,
          refId: QueryRefId.Savings,
          legendFormat: 'Savings',
        },
      ]
    ),
    children: [
      {
        title: 'GPU Cost',
        decimals: 2,
        type: 'cost',
        description: '[Range] The cost of GPU over the current time range.',
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Cost)],
        }),
      },
      {
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Savings)],
        }),
        title: 'GPU Savings',
        decimals: 2,
        type: 'cost',
        description:
          '[Range] The theoretical savings if all unused GPUs could have been deprovisioned over the current time range.',
      },
      {
        title: 'GPU Cost vs savings',
        description: '[Range] Cost vs savings over the current time range.',
        type: 'graph',
      },
    ].map(({ type, ...panel }) => {
      return new SceneFlexItem({
        height: 200,
        body: new ExplorablePanel(getPanelConfig(type)(panel)),
      });
    }),
  });
};

export function getSavingsScene({ datasource }: { datasource: DataSourceRef }) {
  return new EmbeddedScene({
    $variables: new SceneVariableSet({
      variables: [getClusterVariable(datasource)],
    }),
    $behaviors: [],
    controls: [new VariableValueSelectors({})],
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        ...[cpuRow(datasource), ramRow(datasource), storageRow(datasource), gpuRow(datasource), networkRow(datasource)],
      ],
    }),
  });
}
