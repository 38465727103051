import { AppPlugin, AppRootProps } from '@grafana/data';
import { config } from '@grafana/runtime';
import { ComponentClass } from 'react';
import Root from './Root';

import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { ClusterConfigExtensionParams } from 'components/Extensions/ClusterConfigExtension';
import {
  HelmChartIntegrationConfigExtensionParams,
  HelmChartConfigLinkParams,
} from 'components/Extensions/HelmChartIntegrationConfigExtension';
import { FARO_APP_NAME, FARO_ENDPOINT } from './constants';
import { ObjectDetailExtensionProps, ObjectDetailExtensionParams } from 'components/Extensions/ObjectDetailExtension';

export const faro = initializeFaro({
  url: FARO_ENDPOINT,
  app: {
    name: FARO_APP_NAME,
    version: config.apps['grafana-k8s-app'].version,
    environment: process.env.NODE_ENV,
  },
  isolate: true,
  user: {
    id: config.bootData.user.orgName,
  },
  instrumentations: [...getWebInstrumentations()],
});

export const plugin = new AppPlugin().setRootPage(Root as unknown as ComponentClass<AppRootProps>);

if (typeof plugin.addComponent !== 'undefined') {
  // Grafana > v11.1.0
  plugin.addComponent<{}>(ClusterConfigExtensionParams);
  plugin.addComponent<{ context?: { slug: string; showTitle?: boolean | undefined } | undefined }>(
    HelmChartIntegrationConfigExtensionParams
  );
} else if (typeof plugin.configureExtensionComponent !== 'undefined') {
  // Grafana <= v11.1.0
  plugin.configureExtensionComponent<{}>({
    ...ClusterConfigExtensionParams,
    extensionPointId: ClusterConfigExtensionParams.targets,
  });

  plugin.configureExtensionComponent<{ context?: { slug: string; showTitle?: boolean | undefined } | undefined }>({
    ...HelmChartIntegrationConfigExtensionParams,
    extensionPointId: HelmChartIntegrationConfigExtensionParams.targets,
  });
}

if (typeof plugin.exposeComponent !== 'undefined') {
  // Grafana > v11.1.0
  plugin.exposeComponent<ObjectDetailExtensionProps>(ObjectDetailExtensionParams);
}

// We need to check whether given integration has logs or metrics snippets
// this is needed as the integrations-api returns k8s integrations even if there are no snippets.
// This approach uses link extensions temporarily before we get plugin capabilities/exports in Grafana.
if (typeof plugin.addLink !== 'undefined') {
  // Grafana > v11.1.0
  plugin.addLink<{ slug: string }>(HelmChartConfigLinkParams);
} else if (typeof plugin.configureExtensionLink !== 'undefined') {
  // Grafana <= v11.1.0
  plugin.configureExtensionLink<{ slug: string }>({
    ...HelmChartConfigLinkParams,
    extensionPointId: HelmChartConfigLinkParams.targets as string,
  });
}
