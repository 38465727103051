import { Tooltip, useStyles2 } from '@grafana/ui';
import React from 'react';
import usageLabelsStyles from './UsageLabels.styles';
import UsageBox, { UsageType } from './UsageBox';
import { cx } from '@emotion/css';
import { windowBreakpoints } from '../../constants';

const Label = ({ type, tooltip = '' }: { type: UsageType; tooltip: string }) => {
  const styles = useStyles2(usageLabelsStyles());

  return (
    <Tooltip content={tooltip}>
      <div className={styles.labelWrapper}>
        <UsageBox type={type} noValue showPercent={false} />
        <span
          className={cx({
            [styles.lowLabel]: type === UsageType.Low,
            [styles.medLabel]: type === UsageType.Med,
            [styles.highLabel]: type === UsageType.High,
            [styles.unknown]: type === UsageType.Unknown,
          })}
        >
          {type}
        </span>
      </div>
    </Tooltip>
  );
};

const UsageLabelRow = ({ windowWidth }: { windowWidth?: number }) => {
  const styles = useStyles2(usageLabelsStyles());
  return (
    <div className={styles.labelRow}>
      {(!windowWidth || windowWidth > windowBreakpoints.xl) && <span style={{ marginRight: 2 }}>Resource usage: </span>}
      <Label type={UsageType.Low} tooltip="Usage is under 60% (underutilized)" />
      <Label type={UsageType.Med} tooltip="Usage is between 60% and 90% (well utilized)" />
      <Label type={UsageType.High} tooltip="Usage is over 90% (overutilized)" />
      <Label type={UsageType.Unknown} tooltip="Requests not set" />
    </div>
  );
};

export default UsageLabelRow;
