import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { colors } from '../../constants';

export const getStyles = (theme: GrafanaTheme2) => ({
  alert: css`
    margin-top: ${theme.spacing(2)};
  `,
  link: css`
    color: ${theme.isLight ? colors.blue07 : colors.blue03};

    &:hover {
      text-decoration: underline;
    }
  `
});
