import { SceneQueries } from 'queries';
import { EnergyState } from './Energy';
import { DataSourceRef } from '@grafana/schema';
import { ConstantVariable, QueryVariable, SceneVariableSet } from '@grafana/scenes';
import { VariableHide, VariableRefresh } from '@grafana/data';
import { query_result } from '../../../helpers/scenes';
import { ObjectTypes } from '../../../types';

export function getVariables(
  type: ObjectTypes,
  datasource: DataSourceRef,
  cluster?: string,
  node?: string,
  namespace?: string,
  workload?: string,
  workloadType?: string
) {
  let query = '';
  switch (type) {
    case 'workload':
      query = query_result(SceneQueries.WorkloadDetail.PodNames(cluster, namespace, workload, workloadType));
      break;
    case 'node':
      query = query_result(SceneQueries.NodeDetail.PodNames(cluster, node));
      break;
  }
  const podNames = new QueryVariable({
    name: 'podNames',
    query,
    includeAll: true,
    defaultToAll: true,
    datasource,
    skipUrlSync: true,
    regex: '/.*pod="(?<value>[^"]*)".*/', // extract pod label value
    refresh: VariableRefresh.onTimeRangeChanged,
    hide: VariableHide.hideVariable,
  });

  const conversion = new ConstantVariable({
    name: 'watt_per_second_to_kWh',
    value: 1.0 / 1000 / 3600,
  });

  return new SceneVariableSet({
    variables: [podNames, conversion],
  });
}

export const getEnergyQueriesByParams = ({ ...params }: EnergyState) => {
  const { type, cluster, node, namespace, podName, container } = params;
  switch (type) {
    case 'cluster':
      return {
        bySource: {
          total: SceneQueries.ClusterDetail.Energy.Total(cluster),
          package: SceneQueries.ClusterDetail.Energy.Package(cluster),
          dram: SceneQueries.ClusterDetail.Energy.DRAM(cluster),
          gpu: SceneQueries.ClusterDetail.Energy.GPU(cluster),
          other: SceneQueries.ClusterDetail.Energy.Other(cluster),
        },
        byResource: {
          Node: SceneQueries.ClusterDetail.Energy.ByNode(cluster),
          Namespace: SceneQueries.ClusterDetail.Energy.ByNamespace(cluster),
        },
      };
    case 'node':
      return {
        bySource: {
          total: SceneQueries.NodeDetail.Energy.Total(cluster, node),
          package: SceneQueries.NodeDetail.Energy.Package(cluster, node),
          dram: SceneQueries.NodeDetail.Energy.DRAM(cluster, node),
          gpu: SceneQueries.NodeDetail.Energy.GPU(cluster, node),
          other: SceneQueries.NodeDetail.Energy.Other(cluster, node),
        },
        byResource: {
          Pod: SceneQueries.NodeDetail.Energy.ByPod(cluster),
        },
      };
    case 'namespace':
      return {
        bySource: {
          total: SceneQueries.NamespaceDetail.Energy.Total(cluster, namespace),
          package: SceneQueries.NamespaceDetail.Energy.Package(cluster, namespace),
          dram: SceneQueries.NamespaceDetail.Energy.DRAM(cluster, namespace),
          gpu: SceneQueries.NamespaceDetail.Energy.GPU(cluster, namespace),
          other: SceneQueries.NamespaceDetail.Energy.Other(cluster, namespace),
        },
        byResource: {
          Workload: SceneQueries.NamespaceDetail.Energy.ByWorkload(cluster, namespace),
        },
      };
    case 'workload':
      return {
        bySource: {
          total: SceneQueries.WorkloadDetail.Energy.Total(cluster),
          package: SceneQueries.WorkloadDetail.Energy.Package(cluster),
          dram: SceneQueries.WorkloadDetail.Energy.DRAM(cluster),
          gpu: SceneQueries.WorkloadDetail.Energy.GPU(cluster),
          other: SceneQueries.WorkloadDetail.Energy.Other(cluster),
        },
        byResource: {
          Pod: SceneQueries.WorkloadDetail.Energy.ByPod(cluster),
        },
      };
    case 'pod':
      return {
        bySource: {
          total: SceneQueries.PodDetail.Energy.Total(cluster, namespace, podName),
          package: SceneQueries.PodDetail.Energy.Package(cluster, namespace, podName),
          dram: SceneQueries.PodDetail.Energy.DRAM(cluster, namespace, podName),
          gpu: SceneQueries.PodDetail.Energy.GPU(cluster, namespace, podName),
          other: SceneQueries.PodDetail.Energy.Other(cluster, namespace, podName),
        },
        byResource: {
          Container: SceneQueries.PodDetail.Energy.ByContainer(cluster, namespace, podName),
        },
      };
    case 'container':
      return {
        bySource: {
          total: SceneQueries.Container.Energy.Total(cluster, namespace, podName, container),
          package: SceneQueries.Container.Energy.Package(cluster, namespace, podName, container),
          dram: SceneQueries.Container.Energy.DRAM(cluster, namespace, podName, container),
          gpu: SceneQueries.Container.Energy.GPU(cluster, namespace, podName, container),
          other: SceneQueries.Container.Energy.Other(cluster, namespace, podName, container),
        },
      };
    default:
      return {};
  }
};
