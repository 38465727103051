import React from 'react';
import { Icon, LoadingPlaceholder, useStyles2 } from '@grafana/ui';

import integrationCardStyles from './IntegrationCard.styles';
import { IntegrationSource } from 'api/integrationsApi';

type IntegrationCardProps = {
  source: IntegrationSource;
  onClick: () => void;
  loading?: boolean;
};

const IntegrationCard = ({ source, onClick, loading }: IntegrationCardProps) => {
  const styles = useStyles2(integrationCardStyles);

  return (
    <div className={styles.wrapper} onClick={onClick} data-testid="integration-card-wrapper">
      <div className={styles.integrationWrapper}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={source.logo.dark_theme_url} />
        </div>
        <span className={styles.name}>{source.name}</span>
      </div>
      {!loading ? (
        <>
          {source.installation && (
            <div className={styles.installedWrapper}>
              <Icon name="check" />
            </div>
          )}
        </>
      ) : (
        <LoadingPlaceholder text="" style={{ marginBottom: 0 }} />
      )}
    </div>
  );
};

export default IntegrationCard;
