import HelmChartIntegration from 'components/IntegrationsList/HelmChartIntegration';
import React from 'react';
import integrations from 'static/config/integrations';
import { ExtensionLink } from 'types';

export interface ChartConfigProps {
  slug: string;
  showTitle?: boolean;
}

export function HelmChartIntegrationConfigExtension({ context }: { context?: ChartConfigProps }) {
  return <HelmChartIntegration showTitle={context?.showTitle} slug={context?.slug || ''} />;
}

export const HelmChartIntegrationConfigExtensionParams = {
  title: 'K8s Helm Chart Config',
  description: 'K8s Helm Chart Integration Snippets',
  targets: 'grafana/grafana-k8s-app/helm-chart-integration-snippets/v1',
  component: HelmChartIntegrationConfigExtension,
};

export const HelmChartConfigLinkParams: ExtensionLink = {
  title: 'K8s Helm Chart Config Check',
  description: 'K8s Helm Chart Integration Snippets Check',
  targets: 'grafana/grafana-k8s-app/helm-chart-integration-snippets-check/v1',
  path: '/a/grafana-k8s-app/helm-chart-integration-snippets-check/v1',
  configure(context) {
    if (!context?.slug || integrations[context.slug]?.logs || integrations[context.slug]?.metrics) {
      return {};
    } else {
      return undefined;
    }
  },
};
