import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useStyles2 } from '@grafana/ui';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { DataTableWorkload, defaultFilters, matchesSearchFilter, WorkloadFilters } from './workloadListUtils';
import workloadListStyles from './WorkloadList.styles';
import { RudderstackEvents } from 'enums';
import BreadcrumbTitle from '../BreadCrumbTitle';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { encodeQueryString, encodeUrlString, getExploreUrl } from 'helpers/helpers';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import { config } from '@grafana/runtime';
import DataList from 'components/DataList/DataList';
import { Queries } from 'queries';
import {
  GeneralQueryResult,
  GenericUsageResult,
  PluginExtensionLinkNamespaceContext,
  PluginExtensionPoints,
} from 'types';
import useTimeRangeStore from 'store/timeRange';
import useQueries from 'hooks/useQueries';
import { pushFaroCount } from 'helpers/faro';
import { PluginMetaContext } from 'context/PluginMetaContext';
import WorkloadTable from './WorkloadTable';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { getNamespaceDetailOptimization } from 'components/scenes/NamespaceDetailOptimization/NamespaceDetailOptimization';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';
import ExtensionLinks from 'components/ExtensionLinks/ExtensionLinks';
import useWorkloads from 'hooks/useWorkloads';
import { EntityAssertionsWidget } from 'components/EntityAssertionsWidget/EntityAssertionsWidget';

const WorkloadList = ({ model }: SceneComponentProps<WorkloadListScene>) => {
  const { cluster, namespace } = model.useState();
  const styles = useStyles2(workloadListStyles);
  const meta = useContext(PluginMetaContext);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const prometheusDatasource = config.datasources[prometheusName];
  const lokiName = useDatasourceStore(lokiSelector);
  const lokiDatasource = config.datasources[lokiName];
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);
  const { loading, workloads } = useWorkloads(namespace, [cluster]);
  const [workloadList, setWorkloadList] = useState<DataTableWorkload[]>([]);
  const [workloadFilters] = useState<WorkloadFilters>(defaultFilters);
  const { loading: namespaceLoading, data: namespaceData } = useQueries({
    queries: [{ query: Queries.AlertsByNamespace(namespace) }, { query: Queries.NamespacePhase(namespace, cluster) }],
    range: { from: range.from.valueOf(), to: range.to.valueOf() },
    step: '10m',
    refresh: !hasDateChanged,
    maxDataPoints: 1,
    convertToRedableMetrics: true,
  });

  const [namespaceAlertData, namespacePhase] = namespaceData as [
    Array<GeneralQueryResult<GenericUsageResult>>,
    Array<GeneralQueryResult<GenericUsageResult>>
  ];

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  useEffect(() => {
    const filtersEmpty = isEqual(workloadFilters, defaultFilters);
    if (!filtersEmpty) {
      const newList = matchesSearchFilter(workloads, workloadFilters);
      setWorkloadList(newList);
    } else {
      if (workloads?.length) {
        pushFaroCount('workload-count', workloads.length);
      }

      setWorkloadList(workloads);
    }
  }, [workloads, workloadFilters]);

  const phase = hasDateChanged
    ? namespacePhase?.[namespacePhase.length - 1]?.metric?.phase
    : namespacePhase?.[0]?.metric?.phase;

  const namespaceInfo = {
    leftList: [
      {
        label: 'cluster',
        value: cluster,
        display: (
          <QueryParamsLink
            className={styles.link}
            to={`/a/${meta.id}/navigation/cluster/${encodeUrlString(cluster)}`}
            label={loading ? 'Loading...' : cluster || 'No data'}
          />
        ),
      },
      {
        label: 'workloads',
        value: !loading ? workloadList.length || '0' : 'Loading..',
      },
    ],
    rightList: [
      {
        label: 'alerts',
        value: (namespaceAlertData?.[0]?.value?.[1] || 0) ?? '0',
        display: !namespaceLoading ? (
          <>
            {!hasDateChanged ? (
              <Link
                to={
                  '/alerting/list?view=state&search=' +
                  [
                    'type:alerting',
                    'label:here=true',
                    `datasource:"${encodeQueryString(prometheusName)}"`,
                    `label:cluster=${encodeQueryString(cluster)}`,
                    `label:namespace=${namespace}`,
                  ].join('%20')
                }
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {(namespaceAlertData?.[0]?.value?.[1] || 0) ?? '0'}
              </Link>
            ) : (
              (namespaceAlertData?.[0]?.value?.[1] || 0) ?? '0'
            )}
          </>
        ) : (
          'Loading..'
        ),
      },
      {
        label: 'phase',
        value: !namespaceLoading ? phase : 'Loading..',
      },
    ],
  };

  const scene = useMemo(
    () =>
      getNamespaceDetailOptimization({
        cluster,
        namespace,
        datasource: prometheusDatasource,
        prometheusName,
        lokiName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [cluster, namespace, prometheusName, lokiName, prometheusDatasource, relativeRange]
  );

  const extensionContext: PluginExtensionLinkNamespaceContext = {
    timeRange: range,
    cluster,
    namespace,
    datasources: {
      prometheus: { name: prometheusName, uid: prometheusDatasource.uid },
      loki: { name: lokiName, uid: lokiDatasource.uid },
    },
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div>
          <div className={styles.titleWrapper}>
            <div className={styles.titleWrapper}>
              <BreadcrumbTitle />
              <ExploreButton
                href={getExploreUrl(prometheusName, Queries.PodStatusPhase(cluster, namespace), exploreRange, true)}
                label="Explore pod status"
                eventName={RudderstackEvents.ExplorePodStatus}
              />
              <ExtensionLinks
                context={extensionContext}
                extensionPointId={PluginExtensionPoints.NamespaceAction}
                showTooltip={false}
              />
              <EntityAssertionsWidget name={namespace} type="Namespace" />
            </div>
          </div>
        </div>
        <div className={styles.namespaceInfo}>
          <h3>Namespace information</h3>
          <div className={styles.listsWrapper}>
            <DataList list={namespaceInfo.leftList} />
            <DataList list={namespaceInfo.rightList} />
          </div>
        </div>
        <div className={styles.verticalSpace} style={{ minHeight: 200 }}>
          <h3 className={styles.sectionTitle}>Namespace optimization</h3>
          <div className={styles.sceneWrapper}>
            <scene.Component model={scene} />
          </div>
        </div>
        <WorkloadTable cluster={cluster} namespace={namespace} title="Workloads" />
      </div>
    </div>
  );
};

export default WorkloadList;

interface NamespaceDetailState extends SceneObjectState {
  cluster: string;
  namespace: string;
}

export class WorkloadListScene extends SceneObjectBase<NamespaceDetailState> {
  static Component = WorkloadList;

  constructor(state?: Partial<NamespaceDetailState>) {
    super({ cluster: '.+', namespace: '.+', ...state });
  }
}

export function getWorkloadListAppScene(cluster: string, namespace: string) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new WorkloadListScene({ cluster, namespace }),
        }),
      ],
    }),
  });
}
