import { TableColumn } from 'react-data-table-component';
import useAssertsStore from 'store/asserts';

export const assertsColumns = <T extends { asserts_env?: string; asserts_site?: string }>(): Array<TableColumn<T>> => {
  return [
    {
      name: 'Asserts Env',
      selector: (row: T) => row.asserts_env || '',
      omit: true,
    },
    {
      name: 'Asserts Site',
      selector: (row: T) => row.asserts_site || '',
      omit: true,
    },
  ];
};

export const selectableRowDisabled = <T extends { asserts_env?: string; asserts_site?: string }>(
  row: T,
  selectedRows: T[]
) => {
  return row.asserts_env === undefined || row.asserts_site === undefined || selectedRows.length >= 20;
};

export const selectableRowSelected = <T>(key: keyof T) => {
  return (row: T) =>
    useAssertsStore.getState().selectedAssertsRows.some((selectedRow) => row[key] === selectedRow[key]);
};
