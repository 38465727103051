import { get, del, post, isErrorResponse, SuccessfulFetchResponse } from './baseApi';

export async function getFolderId(folderName: string): Promise<number> {
  const folders = await get<Array<{ id: number; uid: string; title: string }>>('api/folders');
  const folder = folders.find((folder) => folder.title === folderName);
  if (!folder) {
    throw Error(`Folder with name ${folderName} not found`);
  }
  return folder.id;
}

function getFolderUid(folderName: string): string {
  return folderName.toLowerCase().replace(/ /g, '-');
}

export async function createFolder(folderName: string): Promise<number> {
  try {
    const folderUid = getFolderUid(folderName);
    const response = await post<{ id: number }>('/api/folders', { title: folderName, uid: folderUid });
    return response.id;
  } catch (error) {
    if (isErrorResponse(error) && (error.status === 409 || error.status === 412)) {
      // folder already exists. Let's return its id.
      return await getFolderId(folderName);
    } else {
      throw error;
    }
  }
}

export async function removeFolder(folderName: string): Promise<void> {
  try {
    const folderUid = getFolderUid(folderName);
    await del(`/api/folders/${folderUid}`);
  } catch (error) {
    if (isErrorResponse(error) && error.status === 404) {
      return Promise.resolve();
    } else {
      throw error;
    }
  }
}

export async function createDashboard(dashboardData: unknown, folderName: string, overwrite: boolean): Promise<void> {
  const folderUid = getFolderUid(folderName);
  await post<SuccessfulFetchResponse<{}>>('/api/dashboards/db', {
    dashboard: dashboardData,
    folderUid,
    overwrite,
    message: 'creating dashboard from the Kubernetes plugin',
  });
}
