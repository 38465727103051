import React, { useState } from 'react';
import { IconButton } from '@grafana/ui';

import { copyContent } from 'helpers/helpers';

const CopyContent = ({ content, className, hidden }: { content: string; className?: string; hidden?: boolean }) => {
  const [copied, setCopied] = useState(false);

  let parsedValue = content;
  if (typeof parsedValue === 'object') {
    parsedValue = (parsedValue as React.ReactElement)?.props?.children;
  }

  return (
    <IconButton
      aria-label="Copy content"
      size="sm"
      name={copied ? 'check' : 'clipboard-alt'}
      variant="secondary"
      onClick={() => copyContent(parsedValue, setCopied)}
      style={{ marginLeft: 16, visibility: hidden ? 'hidden' : 'visible' }}
      className={className}
    />
  );
};

export default CopyContent;
