import React from 'react';

import { AccessPoliciesProvider, RealmTypes } from '@grafana-cloud/access-policies';
import { useGetInstanceQuery } from '@grafana-cloud/grafana-com-api';

const AccessPoliciesInit = ({ children }: { children: JSX.Element }) => {
  const { data } = useGetInstanceQuery();

  return (
    <AccessPoliciesProvider
      config={{
        realm: { realmType: RealmTypes.STACK, realmIdentifier: String(data?.id) },
        region: data?.regionSlug!,
      }}
    >
      {children}
    </AccessPoliciesProvider>
  );
};

export default AccessPoliciesInit;
