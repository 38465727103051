import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const integrationCardStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    background: ${theme.colors.background.secondary};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    padding: 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    cursor: pointer;
    position: relative;
    height: 96px;
  `,
  integrationWrapper: css`
    display: flex;
    align-items: center;
  `,
  imageWrapper: css`
    display: flex;
    justify-content: center;
    margin-right: 10px;
    width: 30px;
    height: 30px;
  `,
  image: css`
    max-width: 100%;
    max-height: 100%;
    align-self: center;
  `,
  name: css`
    font-size: 16px;
    line-height: 22px;
  `,
  installedWrapper: css`
    color: ${theme.colors.success.text};
    border: 1px solid ${theme.colors.success.text};
    padding: 1px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    background: ${theme.colors.background.primary};
  `,
});

export default integrationCardStyles;
