import { LoadingPlaceholder, useStyles2 } from '@grafana/ui';
import DataList from 'components/DataList/DataList';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GeneralQueryResult } from 'types';
import { dateTimeFromTimestamp } from '../podHealthUtils';
import { dataKeys, findMetric, WorkloadTypes } from './metadataUtils';
import workloadMetadataStyles from './WorkloadMetadata.styles';
import useTimeRangeStore, { DateTimeRange } from 'store/timeRange';
import { shallow } from 'zustand/shallow';
import { PluginMetaContext } from '../../../context/PluginMetaContext';
import { encodeQueryString, encodeUrlString } from 'helpers/helpers';

interface Props {
  type: WorkloadTypes;
  details: Array<GeneralQueryResult<DetailResult>>;
  loading: boolean;
  pods: number;
  namespace: string;
  alertTotal: number;
  cluster: string;
  workload: string;
  timeRange: DateTimeRange;
  prometheusName: string;
}

export interface DetailResult {
  __name__: string;
  job: string;
  instance: string;
}

const WorkloadMetadata = ({
  type,
  details,
  pods,
  namespace,
  cluster,
  loading,
  alertTotal,
  workload,
  timeRange,
  prometheusName,
}: Props) => {
  const styles = useStyles2(workloadMetadataStyles);
  const meta = useContext(PluginMetaContext);
  const [hasDateChanged] = useTimeRangeStore((state) => [state.hasDateChanged], shallow);
  const data = dataKeys(type);

  if ((!details || details.length === 0) && loading) {
    return <LoadingPlaceholder text="Loading metadata..." />;
  }

  const deploymentData = {
    leftList: [
      {
        label: 'ready replicas',
        value: loading
          ? 'Loading..'
          : `${findMetric(details, data.replicas_available.key) || 0} / ${findMetric(details, data.replicas.key) || 0}`,
      },
      {
        label: 'pods',
        value: loading ? 'Loading..' : pods,
      },
      {
        label: 'create date',
        value: dateTimeFromTimestamp(findMetric(details, data.created.key)?.toString() || ''),
      },
    ],
    rightList: [
      {
        label: 'observed generation',
        value: loading ? 'Loading..' : Number(findMetric(details, data.extra.key) || 0).toLocaleString(),
      },
      {
        label: 'alerts',
        value: alertTotal || 'none',
        display: loading ? (
          'Loading..'
        ) : alertTotal ? (
          <>
            {!hasDateChanged ? (
              <Link
                to={
                  '/alerting/list?view=state&search=' +
                  [
                    'type:alerting',
                    `datasource:"${encodeQueryString(prometheusName)}"`,
                    `label:cluster=${encodeQueryString(cluster)}`,
                    `label:namespace=${namespace}`,
                    `label:pod=~${encodeQueryString(workload + '-.+')}`,
                  ].join('%20')
                }
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {alertTotal}
              </Link>
            ) : (
              alertTotal
            )}
          </>
        ) : (
          'none'
        ),
      },
      {
        label: 'cluster',
        value: cluster || 'No data',
        display: (
          <Link className={styles.link} to={`/a/${meta.id}/navigation/cluster/${encodeUrlString(cluster)}`}>
            {loading ? 'Loading...' : cluster || 'No data'}
          </Link>
        ),
      },
      {
        label: 'namespace',
        value: namespace || 'No data',
        display: (
          <Link
            className={styles.link}
            to={`/a/${meta.id}/navigation/namespace/${encodeUrlString(cluster)}/${namespace}`}
          >
            {loading ? 'Loading...' : namespace || 'No data'}
          </Link>
        ),
      },
    ],
  };

  return (
    <div className={styles.metadataWrapper}>
      <div>
        <h3>
          <span className={styles.workloadType}>{type}</span> information
        </h3>
        <div className={styles.gridEqual}>
          <DataList list={deploymentData.leftList} />
          <DataList list={deploymentData.rightList} />
        </div>
      </div>
    </div>
  );
};

export default WorkloadMetadata;
