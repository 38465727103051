import { Button, Spinner } from '@grafana/ui';
import useRudderstack from 'hooks/useRudderstack';
import React from 'react';
import { FetchStatus, RudderstackEvents } from 'enums';
import InstallIntegrationButton from './InstallIntegrationButton/InstallIntegrationButton';
import { config } from '@grafana/runtime';
import useIntegrationStore from 'store/integration';
import { shallow } from 'zustand/shallow';
import { CurrentUserDTO } from '@grafana/data';
import Message from './Message/Message';

interface Props {
  className?: string;
}

export default function UninstallIntegrationButton({ className }: Props) {
  const [integrationInstalled, installStatus, uninstallIntegration] = useIntegrationStore(
    (state) => [state.integrationInstalled, state.installStatus, state.uninstallIntegration],
    shallow
  );
  const trackRudderStackEvent = useRudderstack();
  const user: CurrentUserDTO = config.bootData.user;

  if (!integrationInstalled) {
    return <InstallIntegrationButton className={className} />;
  }

  return (
    <>
      <Message status="success">Alert rules and recording rules installed</Message>
      <Button
        variant="secondary"
        type="button"
        className={className}
        disabled={installStatus === FetchStatus.Fetching || user.orgRole !== 'Admin'}
        onClick={() => {
          trackRudderStackEvent(RudderstackEvents.IntegrationUninstall, {});
          uninstallIntegration();
        }}
      >
        {installStatus === FetchStatus.Fetching && (
          <>
            <Spinner />
            &nbsp;
          </>
        )}
        Uninstall
      </Button>
    </>
  );
}
