import {
  EmbeddedScene,
  QueryVariable,
  SceneCSSGridLayout,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneReactObject,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { addTimeRangeHandler, getGenericQueryRunner, getRefreshPicker } from '../../../../helpers/scenes';
import {
  compileEventsQuery,
  compileLogsQuery,
  compileOTelEventsQuery,
  compileOTelLogsQuery,
  CostsScenesQueries,
  SceneQueries,
} from 'queries';
import PrometheusPicker from 'components/PrometheusPicker';
import React from 'react';
import LokiPicker from 'components/LokiPicker';
import { ScenesCustomParams } from 'types';
import { StatusPanel } from './ClusterStatusSceneObject';

export function getVariables(datasource: DataSourceRef, clusterName: string) {
  const cluster = new QueryVariable({
    includeAll: false,
    defaultToAll: false,
    isMulti: false,
    name: 'cluster',
    query: CostsScenesQueries.clusterList,
    value: clusterName,
    datasource,
    skipUrlSync: false,
  });

  return new SceneVariableSet({
    variables: [cluster],
  });
}

const statusGrid = (datasource: DataSourceRef, lokiDatasource: DataSourceRef) => {
  const podLogQuery = `sum(count_over_time(${compileLogsQuery('$cluster')}[$__range]))`;
  const otelPodLogQuery = `sum(count_over_time(${compileOTelLogsQuery('$cluster')}[$__range]))`;
  const clusterEventsQuery = `sum(count_over_time(${compileEventsQuery('$cluster')}[$__range]))`;
  const otelClusterEventsQuery = `sum(count_over_time(${compileOTelEventsQuery('$cluster')}[$__range]))`;
  const items = [
    {
      data: getGenericQueryRunner(datasource, SceneQueries.Config.GrafanaAlloy, {
        instant: true,
        range: false,
      }),
      title: 'Grafana Alloy',
      subtitle:
        'Shows if metrics from Grafana Alloy are being scraped, and if so, what version of Alloy is deployed. Uses the agent_build_info and alloy_build_info metrics.',
      version: true,
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#grafana-alloy',
    },
    {
      data: getGenericQueryRunner(datasource, SceneQueries.Config.NodeExporter, {
        instant: true,
        range: false,
      }),
      title: 'Node Exporter',
      isMultiple: true,
      subtitle: 'Shows if metrics from Node Exporter are being scraped. Uses the node_exporter_build_info metric.',
      version: true,
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#node-exporter',
    },
    {
      data: getGenericQueryRunner(datasource, SceneQueries.Config.WindowsExporter, {
        instant: true,
        range: false,
      }),
      title: 'Windows Exporter',
      isMultiple: true,
      subtitle:
        'Shows if metrics from Windows Exporter are being scraped. Uses the windows_exporter_build_info metric.',
      version: true,
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#windows-exporter',
    },
    {
      data: getGenericQueryRunner(datasource, SceneQueries.Config.KubeStateMetrics, {
        instant: true,
        range: false,
      }),
      title: 'Kube State Metrics',
      isMultiple: true,
      subtitle: 'Shows if metrics from Kube State Metrics are being scraped. Uses the kube_node_info metric.',
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#kube-state-metrics',
    },
    {
      data: getGenericQueryRunner(datasource, SceneQueries.Config.OpenCost, {
        instant: true,
        range: false,
      }),
      title: 'Opencost',
      isMultiple: true,
      subtitle:
        'Shows if cost metrics from OpenCost are being scraped, and if so, what version of OpenCost is deployed. Uses the opencost_build_info metric.',
      version: true,
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#opencost-cost-calculations',
    },
    {
      data: getGenericQueryRunner(datasource, SceneQueries.Config.Kepler, {
        instant: true,
        range: false,
      }),
      title: 'Kepler',
      isMultiple: true,
      subtitle:
        'Shows if energy metrics from Kepler are being scraped, and if so, what version of Kepler is deployed. Uses the kepler_exporter_build_info metric.',
      version: true,
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#kepler-energy-calculations',
    },
    {
      data: getGenericQueryRunner(datasource, SceneQueries.Config.cAdvisor, {
        instant: true,
        range: false,
      }),
      title: 'cAdvisor',
      isMultiple: true,
      subtitle: 'Shows if metrics from cAdvisor are being scraped. Uses the machine_memory_bytes metric.',
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#cadvisor',
    },
    {
      data: getGenericQueryRunner(datasource, SceneQueries.Config.Kubelet, {
        instant: true,
        range: false,
      }),
      title: 'Kubelet',
      isMultiple: true,
      subtitle: 'Shows if metrics from the Kubelet are being scraped. Uses the kubernetes_build_info metric.',
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration//#kubelet',
    },
    {
      data: getGenericQueryRunner(
        lokiDatasource,
        podLogQuery,
        {
          instant: true,
          interval: '',
          range: false,
          refId: 'podLogs',
          legendFormat: 'Pod Logs',
        },
        [
          {
            expr: otelPodLogQuery,
            instant: true,
            interval: '',
            range: false,
            refId: 'otelPodLogs',
            legendFormat: 'Pod Logs using OTel labels',
          },
        ]
      ),
      title: 'Pod Logs',
      subtitle:
        'Shows if pod logs have been scraped during the selected time range. Uses the {job!="integrations/kubernetes/eventhandler"} LogQL query.',
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#pod-logs',
    },
    {
      data: getGenericQueryRunner(
        lokiDatasource,
        clusterEventsQuery,
        {
          instant: true,
          interval: '',
          range: false,
          refId: 'clusterEvents',
          legendFormat: 'Cluster Events',
        },
        [
          {
            expr: otelClusterEventsQuery,
            instant: true,
            interval: '',
            range: false,
            refId: 'otelClusterEvents',
            legendFormat: 'Cluster Events using OTel labels',
          },
        ]
      ),
      title: 'Cluster Events',
      subtitle:
        'Shows if Kubernetes cluster events have been scraped during the selected time range. Uses the {job="integrations/kubernetes/eventhandler"} LogQL query.',
      docUrl:
        'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/manage-configuration/#cluster-events',
    },
  ].map((item) => {
    return new SceneFlexItem({
      $data: item.data,
      height: '120px',
      width: '100%',
      body: new StatusPanel({
        title: item.title,
        subtitle: item.subtitle,
        version: item.version ? true : false,
        isMultiple: item.isMultiple ? true : false,
        docUrl: item.docUrl,
      }),
    });
  });

  return items;
};

export function getClusterStatusScene({
  datasource,
  clusterName,
  lokiDatasource,
  relativeTimeRange,
}: ScenesCustomParams) {
  const timeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(timeRange);

  const variables = getVariables(datasource, clusterName as string);
  return new EmbeddedScene({
    $timeRange: timeRange,
    $variables: variables,
    controls: [
      new SceneReactObject({
        reactNode: React.createElement(PrometheusPicker),
      }),
      new SceneReactObject({
        reactNode: React.createElement(LokiPicker),
      }),
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      getRefreshPicker(),
    ],
    body: new SceneCSSGridLayout({
      templateColumns: 'repeat(3, 1fr)',
      autoRows: '120px',
      rowGap: 1,
      columnGap: 1,
      children: [...statusGrid(datasource, lokiDatasource as DataSourceRef)],
      md: {
        templateColumns: 'repeat(1, 1fr)',
        rowGap: 1,
        columnGap: 0,
      },
    }),
  });
}
