import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { FetchStatus } from 'enums';
import { isFetchLoadingOrReady } from 'helpers/helpers';
import { getIntegrationMetrics } from 'api/metrics';

type StateType = {
  assertsEnabledStatus: FetchStatus;
  assertsEnabled: boolean;
  selectedAssertsRows: any[];
  toggledClearRows: boolean;
};

type ActionType = {
  setSelectedAssertsRows: (selectedAssertsRow: any[]) => void;
  getAssertsEnabled: (query: string, selectedPromName: string, reset?: boolean) => void;
  setToggledClearRows: (toggledClearRows: boolean) => void;
};

export type DatasourceStore = StateType & ActionType;

const initialState = {
  selectedAssertsRows: [],
  assertsEnabledStatus: FetchStatus.Idle,
  assertsEnabled: false,
  toggledClearRows: false,
};

const useAssertsStore = createWithEqualityFn<DatasourceStore>(
  (set, get) => ({
    //state
    ...initialState,
    getAssertsEnabled: async (query: string, selectedPromName: string, reset?: boolean) => {
      if (isFetchLoadingOrReady(get().assertsEnabledStatus) && !reset) {
        return;
      }
      set({ assertsEnabledStatus: FetchStatus.Fetching, assertsEnabled: false });

      try {
        const response = await getIntegrationMetrics(query, selectedPromName);
        set({ assertsEnabledStatus: FetchStatus.Success, assertsEnabled: !!response?.data?.data?.result?.[0] });
      } catch (e) {
        set({ assertsEnabledStatus: FetchStatus.Error, assertsEnabled: false });
      }
    },
    setSelectedAssertsRows: (selectedAssertsRows: any[]) => {
      set({ selectedAssertsRows });
    },

    setToggledClearRows: (toggledClearRows: boolean) => {
      set({ toggledClearRows });
    },
  }),
  shallow
);

export default useAssertsStore;
