import React from 'react';
import {
  SceneAppPage,
  SceneAppPageState,
  SceneControlsSpacer,
  SceneReactObject,
  SceneTimePicker,
  SceneTimeRange,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { ScenesCustomParams, ScenesRouteParams } from 'types';
import { getClusterDetailAppScene } from 'components/ClusterDetail/ClusterDetail';
import { getClusterListScene } from 'components/ClusterList/ClusterList';
import {
  addTimeRangeHandler,
  createDrilldown,
  createTab,
  getRefreshPicker,
  getUrlFromRoutePath,
  routePaths,
} from 'helpers/scenes';
import PrometheusPicker from 'components/PrometheusPicker';
import PastDataAlert from 'components/PastDataAlert/PastDataAlert';
import TitleRow from '../TitleRow/TitleRow';
import { networkScene } from '../Network/Network';
import { energyScene } from '../Energy/Energy';
import logo from 'img/k8s.png';
import { logsScene } from '../Logs/Logs';

export function getDefaultAppProps(relativeTimeRange?: SceneTimeRangeState): Partial<SceneAppPageState> {
  const pageTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(pageTimeRange);

  return {
    $timeRange: pageTimeRange,
    subTitle: <PastDataAlert sceneTimeRange={pageTimeRange} />,
    $behaviors: [],
    hideFromBreadcrumbs: false,
    controls: [
      new SceneReactObject({
        reactNode: React.createElement(PrometheusPicker),
      }),
      new SceneControlsSpacer(),
      new SceneTimePicker({}),
      new SceneControlsSpacer(),
      getRefreshPicker(),
    ],
  };
}

const tabsConfig = {
  clusterDetail: (params: ScenesRouteParams) => [
    createTab('Overview', `${getUrlFromRoutePath(params).cluster}/overview`, () =>
      getClusterDetailAppScene(params.cluster!)
    ),
    createTab('Network', `${getUrlFromRoutePath(params).cluster}/network`, () =>
      networkScene({ type: 'cluster', cluster: params.cluster! })
    ),
    createTab('Energy', `${getUrlFromRoutePath(params).cluster}/energy`, () =>
      energyScene({ type: 'cluster', cluster: params.cluster! })
    ),
    createTab('Logs & Events', `${getUrlFromRoutePath(params).cluster}/logs`, () =>
      logsScene({ type: 'cluster', cluster: params.cluster! })
    ),
  ],
};

export const getClusterScene = (params: ScenesCustomParams) => {
  const { relativeTimeRange, range } = params;
  const config = { timeRange: relativeTimeRange as SceneTimeRangeState, range };

  const drilldowns = [
    createDrilldown(
      routePaths.cluster,
      'cluster',
      undefined,
      config,
      undefined,
      // cluster detail tabs
      tabsConfig.clusterDetail
    ),
  ];

  const scene = new SceneAppPage({
    title: 'Clusters',
    renderTitle: (title: string) => <TitleRow title={title} withNavigation page="navigation/cluster" />,
    titleImg: logo,
    getScene: getClusterListScene,
    drilldowns,
    url: routePaths.clusters,
    ...getDefaultAppProps(relativeTimeRange),
  });

  return scene;
};

export default getClusterScene;
